import React from 'react'

import { LoadingFailed } from '@real-work/ui'

import Sentry from '@/utils/sentry'

import type { Props } from './types'

function ErrorBoundary({
  children,
  onError,
}: Props): React.ReactElement {
  return (
    <Sentry.ErrorBoundary
      fallback={<LoadingFailed />}
      onError={(error: Error, componentStack: string, eventId: string) => {
        onError && onError(error, componentStack, eventId)
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
