import React, {
  useRef,
  useState,
} from 'react'
import {
  Animated,
  Easing,
} from 'react-native'
import { Progress as NbProgress } from 'native-base'

import { Props } from './types'

function Progress({
  value: valueProp = 0,
  ...props
}: Props): React.ReactElement {
  const progressAnimation = useRef(new Animated.Value(0)).current

  Animated.timing(progressAnimation, {
    easing: Easing.linear,
    toValue: valueProp,
    duration: 250,
    useNativeDriver: false,
  })
    .start()
  const [
    value,
    setValue,
  ] = useState(0)
  progressAnimation.addListener(state => {
    setValue(state.value)
  })

  return (
    <NbProgress
      width='100%'
      value={value}
      {...props}
    />
  )
}

export default React.memo(Progress)

export * from './types'
