import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Box,
  Button,
  Divider,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
} from '@real-work/ui'

import Filters, { FiltersContext } from '@/components/Filters'
import Job from '@/components/Job'

import useSession from '@/hooks/useSession'

import { useGetJobsQuery } from '@/services/job'

import { hasMorePages } from '@/utils/pagination'

function JobsOpen(): React.ReactElement {
  const filtersContext = useContext(FiltersContext)

  const { user } = useSession()

  const [
    jobFilterParams,
    setJobFilterParams,
  ] = useState(filtersContext.values)

  useEffect(() => {
    setJobFilterParams({
      ...filtersContext.values,
      page: 1, // Page must be reset to 1 each time the filters changes to prevent mixed filtered results from merging into the jobs list
    })
  }, [ filtersContext.values ])

  const {
    data: {
      jobs,
      pagination,
    } = {},
    isError,
    isFetching,
  } = useGetJobsQuery({
    query: {
      ...jobFilterParams,
      companyId: (user?.role?.slug === 'employer' && user.employer?.companyOwned?.id) || undefined,
      perPage: 25,
      status: 'open',
    },
  }, { refetchOnMountOrArgChange: true })

  const isLoadingFirstPage = useMemo(() => (isFetching && (jobFilterParams?.page || 1) < 2), [
    isFetching,
    jobFilterParams,
  ])

  const onClickShowMoreButton = useCallback(() => {
    setJobFilterParams(prev => ({
      ...prev,
      page: (prev.page || 1) + 1,
    }))
  }, [])

  return (
    <>
      {user?.role.slug === 'worker' && <Filters mode='jobs' />}

      {(isLoadingFirstPage && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
        <>
          {jobs?.length === 0 ? <NoContent /> : jobs?.map(job => (
            <React.Fragment key={job.id}>
              <Job job={job} />
              <Divider />
            </React.Fragment>
          ))}
          {hasMorePages(pagination) && (
            <Box mx='auto' mt='6'>
              <Button
                isLoading={isFetching}
                onPress={onClickShowMoreButton}
              >
                Show More
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default React.memo(JobsOpen)
