import React from 'react'

import {
  Box,
  Container,
  Heading,
} from '@real-work/ui'

import useSession from '@/hooks/useSession'

import DocumentWrapper from '@/components/DocumentWrapper'
import { FiltersProvider } from '@/components/Filters/context'
import ScreenWrapper from '@/components/ScreenWrapper'
import Tabs, { TabContent } from '@/components/Tabs'

import AllWorkers from './All'
import FavoriteWorkers from './Favorite'

import InterestedWorkers from './Interested'

const tabs = {
  0: {
    label: 'All Workers',
    slug: 'all',
  },
  1: {
    label: 'Favorite Workers',
    slug: 'favorite',
  },
  2: {
    label: 'Interested Workers',
    slug: 'interested',
  },
}

function Workers(): React.ReactElement {
  const { user } = useSession()

  return (
    <DocumentWrapper title='Real Work | Workers'>
      <ScreenWrapper>
        <Container>
          <Heading mb='6'>Real Workers</Heading>
          <Box width='100%'>
            {(user?.role.slug === 'employer' && (
              <Tabs
                aria-label='Workers'
                tabs={tabs}
              >
                {tabs => (
                  <>
                    <TabContent
                      index={0}
                      tabs={tabs}
                    >
                      <FiltersProvider>
                        <AllWorkers />
                      </FiltersProvider>
                    </TabContent>

                    <TabContent
                      index={1}
                      tabs={tabs}
                      unmountWhileUnfocused={true}
                    >
                      <FavoriteWorkers />
                    </TabContent>

                    <TabContent
                      index={2}
                      tabs={tabs}
                      unmountWhileUnfocused={true}
                    >
                      <InterestedWorkers />
                    </TabContent>
                  </>
                )}
              </Tabs>
            )) || <AllWorkers />}
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Workers)
