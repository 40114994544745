import React, {
  useCallback,
  useState,
} from 'react'
import {
  loadStripe,
  Stripe,
  StripeElements as IStripeElements,
} from '@stripe/stripe-js'
import {
  Elements as StripeElements,
  ElementsConsumer as StripeElementsConsumer,
  PaymentElement as StripePaymentElement,
} from '@stripe/react-stripe-js'

import { lang } from '@real-work/common'
import {
  Box,
  LoadingFailed,
  LoadingIndicator,
  useToast,
} from '@real-work/ui'

import ErrorBoundary from '@/components/ErrorBoundary'
import useNavigate from '@/hooks/useNavigate'
import useCompanySubscription from '@/hooks/useCompanySubscription'
import { useUpdatePaymentMethodMutation } from '@/services/stripe'

import config from '@/config'

import type {
  Props,
  StripeFormProps,
} from './types'

const stripePromise = loadStripe(config.stripe.publishableKey)

function StripeFormWrapper({
  children,
  clientSecret,
  onSuccesssRedirectTo,
  type,
  ...props
}: Props): React.ReactElement {
  if (!clientSecret) {
    return <LoadingFailed />
  }

  return (
    <ErrorBoundary>
      <StripeElements
        options={{
          clientSecret,
          /**
           * Match the Payment Element with the design of your site with the appearance option.
           * The layout of the Payment Element stays consistent, but you can modify colors, fonts, borders, padding, and more.
           *
           * @docs https://stripe.com/docs/stripe-js/appearance-api
           */
          appearance: {},
        }}
        stripe={stripePromise}
      >
        <StripeForm
          {...props}
          onSuccesssRedirectTo={onSuccesssRedirectTo}
          type={type}
        >
          {children}
        </StripeForm>
      </StripeElements>
    </ErrorBoundary>
  )
}

function StripeForm({
  children,
  onSuccesssRedirectTo,
  type,
  ...props
}: StripeFormProps) {
  const {
    refetchCompanySubscription,
    subscription,
  } = useCompanySubscription()

  const [ updatePaymentMethod ] = useUpdatePaymentMethodMutation()

  const toast = useToast()
  const navigate = useNavigate()

  const [
    isSubmitting,
    setIsSubmitting,
  ] = useState(false)

  const onSubmit = useCallback(({
    elements,
    stripe,
  }: {
    elements: IStripeElements | null,
    stripe: Stripe | null,
  }) => async () => {
    if (!stripe || !elements) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    setIsSubmitting(true)

    if (type === 'submitPayment') {
      const {
        error,
        paymentIntent,
      } = await stripe.confirmPayment({
        confirmParams: { return_url: window.location.href },
        elements,
        redirect: 'if_required',
      })

      if (error) {
        toast.show({
          description: error.message || lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })

        setIsSubmitting(false)

        return
      }

      if (subscription?.customerId && paymentIntent?.payment_method) {
        await updatePaymentMethod({
          body: {
            paymentMethodId: typeof paymentIntent.payment_method === 'string' ? paymentIntent.payment_method : paymentIntent.payment_method.id,
            stripeCustomerId: subscription.customerId,
            subscriptionId: subscription.id,
          },
        })
      }

      toast.show({
        description: lang().messages.nowAMemberMessage(),
        title: lang().messages.genericSuccessHeading(),
        type: 'success',
      })
    }
    else if (type === 'updatePaymentMethod') {
      const {
        error,
        setupIntent,
      } = await stripe.confirmSetup({
        confirmParams: { return_url: window.location.href },
        elements,
        redirect: 'if_required',
      })

      if (error) {
        toast.show({
          description: error.message || lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })

        setIsSubmitting(false)

        return
      }

      if (!subscription?.customerId || !setupIntent.payment_method) {
        return
      }

      await updatePaymentMethod({
        body: {
          paymentMethodId: typeof setupIntent.payment_method === 'object' ? setupIntent.payment_method.id : setupIntent.payment_method,
          stripeCustomerId: subscription?.customerId,
          subscriptionId: subscription?.id,
        },
      })
        .catch(() => {
          toast.show({
            description: lang().messages.unknownError(),
            title: lang().messages.genericErrorHeading(),
            type: 'error',
          })

          setIsSubmitting(false)
        })

      if (!subscription.isActive) {
        elements.submit()

        const { error: paymentError } = !subscription.paymentIntent.clientSecret ? { error: true } : await stripe.confirmPayment({
          clientSecret: subscription.paymentIntent.clientSecret,
          confirmParams: { return_url: window.location.href },
          elements,
          redirect: 'if_required',
        })

        if (paymentError) {
          toast.show({
            description: lang().messages.unknownError(),
            title: lang().messages.genericErrorHeading(),
            type: 'error',
          })

          setIsSubmitting(false)

          return
        }
      }
    }

    toast.show({
      description: lang().messages.changesSaved(),
      title: lang().messages.genericSuccessHeading(),
      type: 'success',
    })

    refetchCompanySubscription()

    navigate(onSuccesssRedirectTo)
  }, [
    navigate,
    onSuccesssRedirectTo,
    refetchCompanySubscription,
    subscription,
    toast,
    type,
    updatePaymentMethod,
  ])

  return (
    <StripeElementsConsumer>
      {({
        elements,
        stripe,
      }) => ((!stripe || !elements) && <LoadingIndicator />) || (
        <Box
          width='100%'
        >
          {children({
            isSubmitting,
            onSubmit: onSubmit({
              elements,
              stripe,
            }),
            PaymentForm: (
              <StripePaymentElement
                {...props}
              />
            ),
          })}
        </Box>
      )}
    </StripeElementsConsumer>
  )
}

export default StripeFormWrapper
