import React from 'react'

import Box from '../Box'
import Button from '../Button'
import CloseX from '../CloseX'
import InputArray from '../InputArray'
import InputError from '../InputError'

import {
  Item,
  Props,
  Value,
} from './types'

function InputRepeating<T extends object>({
  addAnotherText = 'Add Another',
  fields,
  name,
}: Props<T>): React.ReactElement {
  return (
    <InputArray name={name}>
      {helpers => (
        <>
          {(helpers.form.values[name] as Item<T>[] || []).map((item: Item<T>, index: number) => fields.map(field => (
            <Box key={String(field.name)} mb={2}>
              {field.render({
                errorElement: <InputError name={`${name}.${index}.${String(field.name)}`} />,
                index,
                name: `${name}.${index}.${String(field.name)}`,
                removeElement: (
                  <CloseX
                    onPress={() => {
                      helpers.remove(index)
                    }}
                    position='absolute'
                    top={-27}
                    right={3}
                  />
                ),
                value: item[field.name] as unknown as Value,
              })}
            </Box>
          )))}

          <Box alignItems='flex-start' mb='2'>
            <Button
              onPress={() => helpers.push({ id: undefined })}
              size='sm'
              _text={{ fontSize: 'md' }}
            >
              {addAnotherText}
            </Button>
          </Box>
        </>
      )}
    </InputArray>
  )
}

export default InputRepeating
