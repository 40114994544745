import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import type { Models } from '@real-work/orm'
import {
  api,
  lang,
} from '@real-work/common'
import {
  Box,
  Button,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  HStack,
  Input,
  LoadingFailed,
  LoadingIndicator,
  Select,
  SelectTypes,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useMediaQuery,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import { useGetIndustriesQuery } from '@/services/industry'
import { useGetOptionsQuery } from '@/services/option'
import { usePostUserMutation } from '@/services/user'

import {
  setSubscriptionPlanSlug,
  signUpSelectors,
} from '@/state/slices/signUp'

import Terms from '../../components/Terms'

import SelectedPlanAlert from '../components/SelectedPlanAlert'

import type {
  FormValues,
  OnSubmit,
} from './types'

function SignUpEmployerDetails(): React.ReactElement {
  const dispatch = useDispatch()
  const handleFormApiErrors = useHandleFormApiErrors()
  const [ isDesktop ] = useMediaQuery({ minWidth: 860 })
  const navigate = useNavigate()
  const {
    isLoadingSignIn,
    signIn,
  } = useSession()
  const subscriptionPlanSlug = useSelector(signUpSelectors.subscriptionPlanSlug)

  const {
    data: { industries } = {},
    isLoading: isIndustriesLoading,
    isError: isIndustriesError,
  } = useGetIndustriesQuery()

  const {
    data: { options } = { options: undefined },
    isLoading: isOptionsLoading,
    isError: isOptionsError,
  } = useGetOptionsQuery()

  const selectOptions = useMemo(() => options?.reduce((acc, option) => {
    if (option.type === 'numberOfEmployees' || option.type === 'numberOfLocations') {
      if (!acc[option.type]) {
        acc[option.type] = []
      }

      acc[option.type].push({
        label: option.label,
        value: option.id,
      })
    }

    return acc
  }, {} as Record<Models.Option.OptionType, SelectTypes.Option[]>), [ options ])

  const isLoading = useMemo(() => isIndustriesLoading || isOptionsLoading, [
    isIndustriesLoading,
    isOptionsLoading,
  ])

  const isError = useMemo(() => isIndustriesError || isOptionsError, [
    isIndustriesError,
    isOptionsError,
  ])

  const [
    postUser,
    { isLoading: isPostUserLoading },
  ] = usePostUserMutation()

  const isSubmitting = useMemo(() => isLoadingSignIn || isPostUserLoading, [
    isLoadingSignIn,
    isPostUserLoading,
  ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    postUser({ body: values })
      .unwrap()
      .then(() => {
        signIn({
          email: values.email,
          password: values.password,
        })
          .then(() => {
            dispatch(setSubscriptionPlanSlug(undefined))

            navigate('/sign-up/employer/payment')
          })
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    dispatch,
    handleFormApiErrors,
    navigate,
    postUser,
    signIn,
  ])

  return (
    <DocumentWrapper title='Real Work | Employer Sign Up'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
          <>
            <Container>
              <Heading>Details</Heading>
              <SelectedPlanAlert />
              <Text>
                Please provide some detail about you and your company.
              </Text>
            </Container>
            <Container mt='4'>
              <Heading size='md' color='brand.primary'>Personal</Heading>
              <Divider />
              <Form<FormValues>
                initialValues={{
                  email: '',
                  subscriptionPlanSlug,
                  fullName: '',
                  password: '',
                  phone: '',
                  role: 'employer',
                }}
                onSubmit={onSubmit}
                rules={api.endpoints.users.post.validation.body.rules}
              >
                <Box maxWidth='620'>
                  <FormControl
                    name='fullName'
                    label='Full Name'
                  >
                    <Input
                      type='text'
                    />
                  </FormControl>
                  <FormControl
                    name='position'
                    label='Position'
                  >
                    <Input
                      type='text'
                    />
                  </FormControl>
                  <FormControl
                    name='phone'
                    label='Phone'
                  >
                    <Input
                      type='phone'
                    />
                  </FormControl>
                  <FormControl
                    name='email'
                    label='Email'
                  >
                    <Input
                      type='email'
                    />
                  </FormControl>
                  <FormControl
                    name='password'
                    label='Password'
                  >
                    <Text fontSize='md' mb='1'>{lang().validation.passwordHelperText()}</Text>
                    <Input
                      type='password'
                    />
                  </FormControl>
                </Box>
                <Heading size='md' color='brand.primary' mt='6'>Company</Heading>
                <Divider />
                <Box maxWidth='620px'>
                  <FormControl
                    name='companyName'
                    label='Company Name'
                  >
                    <Input
                      type='text'
                    />
                  </FormControl>
                  <FormControl
                    name='industryId'
                    label='Industry'
                  >
                    <Select
                      options={industries?.map(industry => ({
                        label: industry.name,
                        value: industry.id,
                      }))}
                    />
                  </FormControl>
                  <HStack
                    justifyContent='space-between'
                    flexDirection={isDesktop ? 'row' : 'column'}
                  >
                    <FormControl
                      name='numberOfLocationsOptionId'
                      label='Number of Locations'
                      maxWidth={isDesktop ? '300' : '100%'}
                    >
                      <Select
                        options={selectOptions?.numberOfLocations}
                      />
                    </FormControl>
                    <FormControl
                      name='numberOfEmployeesOptionId'
                      label='Number of Employees'
                      maxWidth={isDesktop ? '300' : '100%'}
                    >
                      <Select
                        options={selectOptions?.numberOfEmployees}
                      />
                    </FormControl>
                  </HStack>
                  <FormControl
                    name='website'
                    label='Website'
                    labelNote='(Optional)'
                  >
                    <Input
                      type='text'
                    />
                  </FormControl>
                  <Terms />
                  <HStack flexDirection='row' mt='4' mb='4' space='4'>
                    <SubmitButton isLoading={isSubmitting}>Create Account</SubmitButton>
                    <Button
                      onPress={() => dispatch(setSubscriptionPlanSlug(undefined))}
                      variant='outline'
                    >
                      Cancel
                    </Button>
                  </HStack>
                </Box>
              </Form>
            </Container>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SignUpEmployerDetails)
