import { useMemo } from 'react'
import {
  FormikContextType,
  useFormikContext,
} from 'formik'

import {
  object,
  string,
} from '@real-work/common'

import type { Value } from '../useFormInput'

export default (name: string) => {
  const {
    errors,
    status,
  } = useFormikContext() as FormikContextType<{ [name: string]: Value }>

  const message = useMemo(() => {
    const errorKey = Object.keys(errors).find(field => field.match(new RegExp(`^${name}(\\[\\d*\\])?$`)))
    const error = object.get(errors, name) || (errorKey && errors[errorKey]) || undefined

    if (!object.has(status?.errors || {}, name) && !error) {
      return undefined
    }

    const message = object.get(status?.errors || {}, name) || error

    if (typeof message !== 'string') {
      return undefined
    }

    return `${message || `${string.toTitleCase(name)} is invalid`}.`.replace(/\.\.$/, '.')
  }, [
    errors,
    name,
    status,
  ])

  return {
    isValid: !message,
    message,
  }
}
