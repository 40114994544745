import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import {
  persistReducer,
  persistStore,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { isTest } from '@real-work/common'

import Sentry from '../utils/sentry'

import realWorkApi from './apis/realWork'

import signUpReducer from './slices/signUp'

export const rootReducer = combineReducers({
  signUp: signUpReducer,
  [realWorkApi.reducerPath]: realWorkApi.reducer,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage: AsyncStorage,
  whitelist: [ 'signUp' ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
        REHYDRATE,
      ],
    },
  }).concat(realWorkApi.middleware),
  enhancers: [ ...(!isTest ? [ Sentry.createReduxEnhancer() ] : []) ],
})

export default store

export const persistor = persistStore(store)

export const makeMockStore = (preloadedState: Partial<RootState>) => configureStore({
  preloadedState,
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
