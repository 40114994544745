import React, { useCallback } from 'react'
import { Checkbox as NBCheckbox } from 'native-base'

import useFormInput, { Value } from '../../hooks/useFormInput'

import { Props } from './types'

function CheckboxGroup({
  children,
  defaultValue = [],
  name,
  onChange: onChangeProps,
  ...props
}: Props) {
  const { setValue } = useFormInput({
    ...props,
    dataType: 'array',
    defaultValue,
    name,
  })

  const onChange = useCallback((value: Value) => {
    onChangeProps && onChangeProps(value)

    setValue && setValue(value)
  }, [
    onChangeProps,
    setValue,
  ])

  return (
    <NBCheckbox.Group
      accessibilityLabel={name}
      {...props}
      defaultValue={defaultValue}
      onChange={onChange}
    >
      {children}
    </NBCheckbox.Group>
  )
}

export default React.memo(CheckboxGroup)

export * from './types'
