import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import type { Point } from 'react-easy-crop/types'
import Slider from '@mui/material/Slider'

import Button from '../Button'
import Image from '../Image'
import Text from '../Text'
import VStack from '../VStack'
import HStack from '../HStack'

import { PresentationalProps } from './types'

const defaultCrop = {
  x: 0,
  y: 0,
}

function FileInput({
  mode,
  addButtonText = mode === 'image' ? 'Browse for Image' : 'Browse for File',
  aspectRatio,
  canCrop,
  data,
  initialCrop,
  initialZoom = 1,
  onCropComplete,
  onOpen,
  onRemove,
  removeButtonText = mode === 'image' ? 'Remove Image' : 'Remove File',
}: PresentationalProps): React.ReactElement {
  const [
    crop,
    setCrop,
  ] = useState<Point>(defaultCrop)

  const [
    zoom,
    setZoom,
  ] = useState(initialZoom)

  return (
    <VStack>
      {data && (
        <VStack
          marginRight='2'
          width={[
            '240px',
            '400px',
          ]}
          height={[
            '240px',
            '400px',
          ]}
        >
          {(mode !== 'image' && <Text>{data}</Text>) || (!canCrop && <Image height='150' width='150' source={{ uri: data }} alt='' />) || (
            <>
              <Cropper
                aspect={aspectRatio}
                crop={crop}
                image={data}
                initialCroppedAreaPixels={initialCrop}
                onCropChange={setCrop}
                onCropComplete={(_, crop) => onCropComplete(crop, zoom)}
                onZoomChange={setZoom}
                style={{
                  containerStyle: {
                    marginTop: '40px',
                    marginBottom: '20px',
                  },
                }}
                zoom={zoom}
              />
              <Slider
                aria-labelledby='Zoom'
                min={1}
                max={3}
                onChange={(e, zoom) => setZoom(Number(zoom))}
                step={0.1}
                value={zoom}
                sx={{ color: '#0891B2' }}
              />
            </>
          )}
        </VStack>
      )}

      <HStack space={4} justifyContent='space-between'>
        {data && (
          <Button onPress={onRemove}>
            {removeButtonText}
          </Button>
        )}
        <Button onPress={onOpen}>
          {addButtonText}
        </Button>
      </HStack>
    </VStack>
  )
}

export default React.memo(FileInput)
