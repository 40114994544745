import {
  useEffect,
  useMemo,
  useState,
} from 'react'

import type { SelectTypes } from '@real-work/ui'

import { array } from '@real-work/common'

import { InterdependentSelectOptions } from './types'

function useInterdependentSelects(options: InterdependentSelectOptions[], defaultValues: SelectTypes.Value[], defaultParentValue?: string) {
  const [
    selectedParentOption,
    setSelectedParentOption,
  ] = useState<InterdependentSelectOptions>()

  const [
    availableChildOptions,
    setAvailableChildOptions,
  ] = useState<SelectTypes.Option[]>([])

  const [
    selectedChildOptions,
    setSelectedChildOptions,
  ] = useState<SelectTypes.Option[]>([])

  const allChildren = useMemo(() => options.flatMap(opt => opt.children), [ options ])

  // Populate default options on mount
  useEffect(() => {
    const defaultChildOptions = defaultValues.map(val => allChildren.find(child => child.value === val)).filter(val => val !== undefined)
    setSelectedChildOptions(defaultChildOptions as SelectTypes.Option[])

    if (defaultParentValue) {
      const parentOpt = options.find(opt => opt.value === defaultParentValue)
      setSelectedParentOption(parentOpt)
    }
  }, [ options ]) // eslint-disable-line react-hooks/exhaustive-deps
  // The above dependency array should only include `options`. If we provide `defaultValues`, we will get an infinite render loop
  // since this hook causes the parent to re-render

  useEffect(() => {
    const parentsChildren = selectedParentOption?.children || []
    // We never want to add a child option to the list of options unless its selected or in the parents children
    const newAvailableChildOptions = array.uniqueByValue<SelectTypes.Option>(
      [
        ...selectedChildOptions,
        ...parentsChildren,
      ],
      'value',
    )
    setAvailableChildOptions(newAvailableChildOptions)
  }, [
    selectedChildOptions,
    selectedParentOption,
  ])

  const onSelectParent: SelectTypes.OnChange = val => {
    if (Array.isArray(val)) return
    const parentOption = options.find(opt => opt.value === val)
    setSelectedParentOption(parentOption)
  }

  const onSelectChild: SelectTypes.OnChange = selectedChildren => {
    if (!Array.isArray(selectedChildren)) return

    const newOpts: SelectTypes.Option[] = []

    selectedChildren.forEach(val => {
      const childOption = availableChildOptions.find(opt => opt.value === val)
      if (childOption) newOpts.push(childOption)
    })

    setSelectedChildOptions(prev => array.uniqueByValue<SelectTypes.Option>([
      ...prev,
      ...newOpts,
    ], 'value').filter(a => selectedChildren.includes(a.value)))
  }

  const availableParentOptions = useMemo(
    () => options.map(opt => ({
      label: opt.label,
      value: opt.value,
    })),
    [ options ],
  )

  return {
    availableChildOptions,
    availableParentOptions,
    onSelectChild,
    onSelectParent,
    selectedChildOptions,
  }
}

export default useInterdependentSelects

export * from './types'
