import React, {
  useCallback,
  useState,
} from 'react'

import {
  lang,
  string,
} from '@real-work/common'

import {
  Box,
  Button,
  Confirm,
  Container,
  DescriptionList,
  Divider,
  Heading,
  HStack,
  LoadingFailed,
  LoadingIndicator,
  Modal,
  NoContent,
  SectionHeader,
  Text,
  useMediaQuery,
  useToast,
  VStack,
} from '@real-work/ui'

import AvatarCompany from '@/components/Avatar/Company'
import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import { useGetCompanyByIdQuery } from '@/services/company'
import { useDeleteCompanyLocationByIdMutation } from '@/services/companyLocation'

function Company(): React.ReactElement {
  const [ isBreakpoint ] = useMediaQuery({ minWidth: 1380 })

  const { id } = useRouteParams()
  const { user } = useSession()
  const toast = useToast()

  const [
    deleteCompanyLocationById,
    { isLoading: isCompanyLocationDeleting },
  ] = useDeleteCompanyLocationByIdMutation()

  const {
    data: {
      company,
      subscription,
    } = {},
    error,
    isLoading,
    isFetching: isFetchingCompany,
  } = useGetCompanyByIdQuery({ params: { id: id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  })

  const [
    isContactUsModalOpen,
    setIsContactUsModalOpen,
  ] = useState(false)

  const [
    isConfirmDeleteOpen,
    setIsConfirmDeleteOpen,
  ] = useState(false)

  const showConfirmDeleteDialog = useCallback(() => setIsConfirmDeleteOpen(true), [])

  const hideConfirmDeleteDialog = useCallback(() => setIsConfirmDeleteOpen(false), [])

  const handleDelete = useCallback((locationId: string) => {
    if (!locationId) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    deleteCompanyLocationById({ params: { id: locationId } })
      .unwrap()
      .then(() => {
        toast.show({
          description: lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
      })
      .catch(() => {
        toast.show({
          description: lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    deleteCompanyLocationById,
    toast,
  ])

  const onConfirmDelete = useCallback((locationId: string) => {
    handleDelete(locationId)

    hideConfirmDeleteDialog()
  }, [
    handleDelete,
    hideConfirmDeleteDialog,
  ])

  const onPressContactUs = useCallback(() => {
    if (!user) {
      toast.show({
        description: lang().messages.mustBeAuthedToContactCompany(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    if (!subscription?.isActive) {
      toast.show({
        description: lang().messages.inactiveEmployerCannotContact(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    setIsContactUsModalOpen(true)
  }, [
    subscription?.isActive,
    toast,
    user,
  ])

  return (
    <DocumentWrapper title='Real Work | Company Profile'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (company && (
          <>
            <Container>
              <Heading>Company Profile</Heading>
              {company.accountOwnerUserId === user?.id && <Text>Your company profile.</Text>}
            </Container>
            <SectionHeader title='Overview' />
            <Container>
              <Box width='100%'>
                <Box
                  position='absolute'
                  right='0'
                  zIndex={2}
                >
                  {company.accountOwnerUserId === user?.id && <Link type='button' to={`/companies/${company.id}/edit`}>Edit</Link>}
                  <Button onPress={onPressContactUs}>Contact Us</Button>
                </Box>
                <Box
                  position={isBreakpoint ? 'absolute' : 'relative'}
                  left={isBreakpoint ? '-140px' : '0'}
                  mb={isBreakpoint ? '0' : '4'}
                >
                  <AvatarCompany company={company} />
                </Box>
                <Heading size='md' mb='4'>{company.name}</Heading>
                <VStack space='4'>
                  {company.industry && <Text fontFamily='BarlowMedium'>Industry: <Text fontFamily='BarlowRegular'>{company.industry.name}</Text></Text>}
                  {company.numberOfEmployees && <Text fontFamily='BarlowMedium'>Number of Employees: <Text fontFamily='BarlowRegular'>{company.numberOfEmployees.label}</Text></Text>}
                  {company.numberOfLocations && <Text fontFamily='BarlowMedium'>Number of Locations: <Text fontFamily='BarlowRegular'>{company.numberOfLocations.label}</Text></Text>}
                  {company.website && <Text fontFamily='BarlowMedium'>Website: {(string.isUrl(company.website) && <Link to={company.website} fontFamily='BarlowRegular'>{company.website}</Link>) || <Text fontFamily='BarlowRegular'>{company.website}</Text>}</Text>}
                  {company.aboutUs && (
                    <VStack>
                      <Text fontWeight='medium'>About Us:</Text>
                      <Text>{company.aboutUs}</Text>
                    </VStack>
                  )}
                </VStack>
              </Box>
            </Container>
            {user && (!!company.locations?.length || company.accountOwnerUserId === user?.id) && (
              <>
                <SectionHeader title='Locations' />
                <Container>
                  {(company.locations || []).map(location => (
                    <React.Fragment key={location.id}>
                      <HStack
                        flexWrap='wrap'
                        width='100%'
                      >
                        <DescriptionList
                          flex='1'
                          items={[
                            {
                              title: 'Location',
                              description: location.title,
                            },
                            {
                              title: 'Address',
                              description: location.displayLocation,
                            },
                            {
                              title: 'Phone',
                              description: location.phone,
                            },
                          ]}
                        />
                        {company.accountOwnerUserId === user?.id && (
                          <HStack space='4' alignItems='flex-start' justifyContent='flex-end'>
                            <Link type='button' to={`/companies/${company.id}/locations/${location.id}/edit`}>Edit</Link>
                            <Button
                              isLoading={isCompanyLocationDeleting || isFetchingCompany}
                              onPress={showConfirmDeleteDialog}
                              width='100px'
                            >
                              Delete
                            </Button>
                            <Confirm
                              cancelText='Cancel'
                              confirmText='Delete'
                              isOpen={isConfirmDeleteOpen}
                              onCancel={hideConfirmDeleteDialog}
                              onConfirm={() => onConfirmDelete(location.id)}
                            >
                              {lang().messages.removeFromProfileConfirmation('location')}
                            </Confirm>
                          </HStack>
                        )}
                      </HStack>
                      <Divider my='6' />
                    </React.Fragment>
                  ))}

                  {company.accountOwnerUserId === user?.id && <Box><Link type='button' to={`/companies/${company.id}/locations/add`}>Add Location</Link></Box>}
                </Container>
              </>
            )}
            {user && subscription?.isActive && (
              <Modal isOpen={isContactUsModalOpen} onClose={() => setIsContactUsModalOpen(false)}>
                <Modal.Content maxWidth='400px'>
                  <Modal.CloseButton />
                  <Modal.Header>Contact Us</Modal.Header>
                  <Modal.Body>
                    <VStack space='3'>
                      <Text fontFamily='BarlowMedium'>Recruiter: <Text fontFamily='BarlowRegular'>{company.accountOwner.user.fullName}</Text></Text>
                      <HStack>
                        <Text fontWeight='medium' mr='1'>Email:</Text>
                        <Link
                          to={`mailto:${company.accountOwner.user.email}`}
                          isExternal={false}
                          _text={{ color: 'black' }}
                        >
                          {company.accountOwner.user.email}
                        </Link>
                      </HStack>
                      <Text fontFamily='BarlowMedium'>Phone: <Text fontFamily='BarlowRegular'>{company.accountOwner.user.phone}</Text></Text>
                    </VStack>
                  </Modal.Body>
                </Modal.Content>
              </Modal>
            )}
          </>
        )) || ((error as { status: number })?.status === 404 && <NoContent />) || <LoadingFailed />}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Company)
