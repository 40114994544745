import React, { useRef } from 'react'
import { Animated } from 'react-native'

import { Props } from './types'

function AnimatorFadeIn({
  children,
  timing = { duration: 1000 },
  ...props
}: Props): React.ReactElement {
  const animation = useRef(new Animated.Value(0)).current

  Animated.timing(animation, {
    ...timing,
    toValue: 1,
    useNativeDriver: false,
  })
    .start()

  return (
    <Animated.View
      style={{ opacity: animation }}
      {...props}
    >
      {children}
    </Animated.View>
  )
}

export default React.memo(AnimatorFadeIn)
