import React from 'react'
import { ScrollView as NbScrollView } from 'native-base'

import { Props } from './types'

function ScrollView({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbScrollView {...props}>{children}</NbScrollView>
  )
}

export default React.memo(ScrollView)

export * from './types'
