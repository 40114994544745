import React from 'react'

import {
  Box,
  Spinner,
  Text,
  useMediaQuery,
} from '@real-work/ui'

import Link from '@/components/Link'
import NotificationsBell from '@/components/Notifications/Bell'

import useSession from '@/hooks/useSession'

import { Props } from './types'

function HeaderProfile({ ...props }: Props): React.ReactElement {
  const [ isDesktop ] = useMediaQuery({ minWidth: 640 })

  const session = useSession()

  return (
    <Box
      flexDirection='row'
      {...props}
    >
      {(session.isLoadingSession && <Spinner color='white' />) || (session.user && (
        <>
          {isDesktop && (
            <>
              <Text color='singletons.white'>
                <Link
                  isUnderlined={false}
                  _text={{ color: 'singletons.white' }}
                  to='/account'
                >
                  Hi, {session.user.firstName}
                </Link>
              </Text>
              <Text color='singletons.white' ml='1'>|</Text>
            </>
          )}
          {isDesktop && (
            <>
              <NotificationsBell />
              <Text color='singletons.white' mr='1'>|</Text>
              <Link
                isUnderlined={false}
                _text={{ color: 'singletons.white' }}
                to='/sign-out'
              >
                Sign Out
              </Link>
            </>
          )}
        </>
      )) || (
        <Link
          isUnderlined={false}
          _text={{ color: 'singletons.white' }}
          to='/sign-in'
        >
          Sign In &gt;
        </Link>
      )}
    </Box>
  )
}

export default React.memo(HeaderProfile)

export * from './types'
