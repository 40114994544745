"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHeaders = exports.getMeta = exports.findManyArgs = void 0;
const findManyArgs = (query, defaultPerPage = 25) => {
    const page = pageFromQuery(query);
    const perPage = Number(query.perPage || defaultPerPage);
    return perPage === -1 ? {
        take: undefined,
        skip: 0,
    } : {
        take: Number(perPage),
        skip: Math.max(0, perPage * (page - 1)),
    };
};
exports.findManyArgs = findManyArgs;
const getMeta = (totalRecords, query) => {
    if (!query) {
        return { isPaginated: false };
    }
    const paginationArgs = (0, exports.findManyArgs)(query);
    const page = pageFromQuery(query);
    const totalPages = paginationArgs.take === undefined ? 1 : Math.ceil(totalRecords / paginationArgs.take);
    return {
        currentPage: page,
        isPaginated: true,
        totalPages,
        totalRecords,
    };
};
exports.getMeta = getMeta;
const pageFromQuery = (query) => Math.max(Number(query.page || 0), 1);
const getHeaders = (meta) => ({
    'X-Current-Page': meta.currentPage,
    'X-Is-Paginated': meta.isPaginated ? 1 : 0,
    'X-Total-Pages': meta.totalPages,
    'X-Total-Records': meta.totalRecords,
});
exports.getHeaders = getHeaders;
__exportStar(require("./types"), exports);
