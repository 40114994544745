import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import { GetUsStatesResponse } from './types'

const path = 'v1/us-states'

const usStatesService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getUsStates: build.query<api.AsJson<GetUsStatesResponse>, void>({
      query: () => ({
        url: path,
        method: 'GET',
      }),
    }),
  }),
})

export default usStatesService

export const { useGetUsStatesQuery } = usStatesService

export const { endpoints: { getUsStates } } = usStatesService
