import React from 'react'
import {
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom'

import { LoadingIndicator } from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useRouteQuery from '@/hooks/useRouteQuery'
import useSession from '@/hooks/useSession'

import Error403 from '@/screens/Error403'

import { Props } from './types'

function RequireSignIn({
  children,
  only,
  otherwise,
  roleSlug,
}: Props): React.ReactElement {
  const location = useLocation()
  const routeQuery = useRouteQuery()
  const session = useSession()

  if (only && !only({ routeQuery })) {
    return children || <Outlet />
  }

  if (session.isLoadingSession) {
    return (
      <DocumentWrapper title='Real Work | Loading...'>
        <ScreenWrapper>
          <LoadingIndicator />
        </ScreenWrapper>
      </DocumentWrapper>
    )
  }

  if (!session.user) {
    const isSigningOut = location?.state?.isSigningOut || false

    return (
      <Navigate
        to='/sign-in'
        state={{ from: !isSigningOut ? location : undefined }}
      />
    )
  }

  if (!roleSlug || (roleSlug && session.user.role.slug === roleSlug)) {
    return children || <Outlet />
  }

  return otherwise || <Error403 />
}

export default React.memo(RequireSignIn)
