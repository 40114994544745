import React from 'react'

import { url } from '@real-work/common'

import Link from '../Link'

function PrivacyTermsLinks() {
  return (
    <>
      <Link to={url.marketingSite('/privacy')} _text={{ color: 'singletons.black' }}>Privacy Policy</Link> and <Link to={url.marketingSite('/terms')} _text={{ color: 'singletons.black' }}>Terms & Conditions</Link>
    </>
  )
}

export default React.memo(PrivacyTermsLinks)
