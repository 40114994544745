import React from 'react'

import {
  HStack,
  Spinner,
  Text,
} from '@real-work/ui'

import { Props } from './types'

function AccountListItem({
  description,
  isLoading,
  title,
  ...props
}: Props): React.ReactElement {
  return (
    <HStack {...props}>
      <Text fontWeight='700' mr='1'>{title}:</Text>{isLoading ? <Spinner /> : <Text>{description}</Text>}
    </HStack>
  )
}

export default React.memo(AccountListItem)

export * from './types'
