"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ResourceError extends Error {
    status = 500;
    constructor({ message, status, }) {
        super(message);
        this.status = status;
    }
}
exports.default = ResourceError;
