import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import {
  OptionsGetRequest,
  OptionsResponse,
} from './types'

const path = 'v1/options'

const optionService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getOptions: build.query<api.AsJson<OptionsResponse>, void>({
      query: () => ({
        url: path,
        method: 'GET',
      }),
    }),
    getOptionsByType: build.query<api.AsJson<OptionsResponse>, OptionsGetRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.type}`,
        method: 'GET',
      }),
    }),
  }),
})

export default optionService

export const {
  useGetOptionsQuery,
  useGetOptionsByTypeQuery,
} = optionService

export const {
  endpoints: {
    getOptions,
    getOptionsByType,
  },
} = optionService
