import React, {
  useMemo,
  useState,
} from 'react'
import {
  Badge,
  Box,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  NoContent,
  Pressable,
  SectionSubHeader,
  Text,
  useMediaQuery,
  VStack,
} from '@real-work/ui'

import JobStatusBadge from '@/components/JobStatusBadge'
import Link from '@/components/Link'
import Worker from '@/components/Worker'

import dateTime from '@/utils/dateTime'

import { Props } from './types'

function Job({
  job,
  onAfterUpdateCompanyFavoriteWorker,
  ...props
}: Props): React.ReactElement {
  const [ rowBreakpoint ] = useMediaQuery({ minWidth: 1200 })

  const [
    isExpanded,
    setIsExpanded,
  ] = useState(false)

  const hasNewActivity = useMemo(() => new Date(job.statusUpdatedAt || job.updatedAt || job.createdAt) >= dateTime.sub(new Date(), { hours: 24 }), [
    job.createdAt,
    job.statusUpdatedAt,
    job.updatedAt,
  ])

  return (
    <VStack
      my='4'
      {...props}
      width='100%'
    >
      <VStack backgroundColor='muted.200' borderColor='white' borderWidth='1' shadow='4' zIndex='2' pl='70px' pr='6' py='5'>
        <HStack position='relative'>
          <Pressable onPress={() => setIsExpanded(prev => !prev)} width='100%'>
            <HStack mb='3' alignItems='center'>
              <Heading size='md' mr='1'>{job.title}</Heading> {hasNewActivity && <Box><Badge colorScheme='secondary' variant='solid'>New Activity</Badge></Box>}
            </HStack>
            <HStack alignItems='center' flexWrap='wrap' justifyContent='space-between' maxW='860px'>
              <HStack space='1' mr='2'>
                <Text fontWeight='medium'>Date Posted </Text>
                <Text>{dateTime.format(new Date(job.createdAt), 'date')}</Text>
              </HStack>
              <HStack space='1' mr='2'>
                <Text fontWeight='medium'>Job Status </Text>
                <Box><JobStatusBadge job={job} /></Box>
              </HStack>
              <HStack space='1' mr='2'>
                <Text fontWeight='medium'>Interested Workers </Text>
                <Box borderRadius='full' p='1' width='30px' height='30px' backgroundColor='muted.500' alignItems='center' justifyContent='center'>
                  <Text color='white'>{job.interestedWorkers?.length || 0}</Text>
                </Box>
              </HStack>
              <HStack space='1'>
                <Text fontWeight='medium'>Favorite Workers </Text>
                <Box borderRadius='full' p='1' width='30px' height='30px' backgroundColor='muted.500' alignItems='center' justifyContent='center'>
                  <Text color='white'>{job.favoriteWorkers?.length || 0}</Text>
                </Box>
              </HStack>
            </HStack>
            <Text fontSize='5xl' color='brand.primary' lineHeight='0' position='absolute' left='-50px' top='3'>{isExpanded ? '-' : '+'}</Text>
            <Text fontSize='md' fontWeight='bold' color='brand.primary' mt='2' underline={true}>{isExpanded ? 'Hide Details >' : 'Show Details >'}</Text>
          </Pressable>
        </HStack>
      </VStack>
      {isExpanded && (
        <VStack backgroundColor='muted.100' px='72px' pb='10' pt='5'>
          <ButtonGroup justifyContent={rowBreakpoint ? 'flex-end' : 'center'} mb='4'>
            <Link
              to={`/jobs/${job.id}`}
              type='button'
              button={{
                size: 'sm',
                _text: { fontSize: 'sm' },
              }}
            >
              View Job
            </Link>
            <Link
              to={`/jobs/${job.id}/edit`}
              type='button'
              button={{
                size: 'sm',
                _text: { fontSize: 'sm' },
              }}
            >
              Edit Job
            </Link>
          </ButtonGroup>

          <SectionSubHeader title='Recent Interested Workers' />
          {(!job.interestedWorkers?.length && <NoContent isWholeScreen={false}>No workers have expressed interest in this job yet.</NoContent>) || (
            <HStack flexDirection={rowBreakpoint ? 'row' : 'column'} space='30px' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
              {job.interestedWorkers?.slice(0, 2).map((interestedWorker, index) => (
                <React.Fragment key={interestedWorker.workerUserId}>
                  <Worker
                    flex='1'
                    job={job}
                    mode='card'
                    onAfterUpdateCompanyFavoriteWorker={onAfterUpdateCompanyFavoriteWorker}
                    worker={interestedWorker.worker}
                  />
                  {index === 0 && rowBreakpoint && <Divider mx='1' maxHeight='150px' orientation='vertical' />}
                </React.Fragment>
              ))}
            </HStack>
          )}
          <Divider variant='dark' />
          <Link
            to='/workers/?tab=interested'
            _text={{
              fontWeight: 'bold',
              fontSize: 'md',
            }}
          >
            View All Interested Workers &gt;
          </Link>

          <SectionSubHeader title='Recent Favorite Workers' mt='16' />
          {(!job.favoriteWorkers.length && <NoContent isWholeScreen={false}>You have not added any favorite workers to this job yet.</NoContent>) || (
            <HStack flexDirection={rowBreakpoint ? 'row' : 'column'} space='30px' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
              {job.favoriteWorkers?.slice(0, 2).map((favoriteWorker, index) => (
                <React.Fragment key={favoriteWorker.workerUserId}>
                  <Worker
                    flex='1'
                    mode='card'
                    worker={favoriteWorker.worker}
                  />
                  {index === 0 && rowBreakpoint && <Divider mx='1' maxHeight='150px' orientation='vertical' />}
                </React.Fragment>
              ))}
            </HStack>
          )}
          <Divider variant='dark' />
          <Link
            to='/workers/?tab=favorite'
            _text={{
              fontWeight: 'bold',
              fontSize: 'md',
            }}
          >
            View All Favorite Workers &gt;
          </Link>
        </VStack>
      )}
    </VStack>
  )
}

export default React.memo(Job)
