import React from 'react'
import { Heading as NbHeading } from 'native-base'

import { Props } from './types'

function Heading({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbHeading
      accessibilityRole='header'
      role='heading'
      {...props}
    >
      {children}
    </NbHeading>
  )
}

export default React.memo(Heading)

export * from './types'
