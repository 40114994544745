import React, {
  useCallback,
  useMemo,
} from 'react'

import {
  api,
  lang,
} from '@real-work/common'

import {
  Button,
  Container,
  Form,
  FormControl,
  Heading,
  HStack,
  Input,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import ScreenWrapper from '@/components/ScreenWrapper'
import DocumentWrapper from '@/components/DocumentWrapper'

import useNavigate from '@/hooks/useNavigate'
import useRouteQuery from '@/hooks/useRouteQuery'
import useSession from '@/hooks/useSession'

import {
  usePostUserEmailVerificationSendMutation,
  usePostUserEmailVerificationVerifyMutation,
} from '@/services/user/emailVerification'

import {
  FormValues,
  OnSubmit,
} from './types'

function EmailVerification(): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()
  const navigate = useNavigate()
  const query = useRouteQuery()
  const {
    isFetchingSession,
    isLoadingSession,
    isLoadingSignOut,
    refetchSession,
    signOut,
    user,
  } = useSession()
  const toast = useToast()

  const queryCode = query.get('code')
  const initialValues = useMemo(() => queryCode ? { code: queryCode } : undefined, [ queryCode ])

  const [
    postUserEmailVerificationSend,
    { isLoading: isLoadingResendCode },
  ] = usePostUserEmailVerificationSendMutation()

  const onSend = useCallback(async () => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    postUserEmailVerificationSend({ params: { userId: user.id } })
      .unwrap()
      .then(async response => {
        await refetchSession()

        toast.show({
          description: response?.message,
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors: false,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    handleFormApiErrors,
    postUserEmailVerificationSend,
    refetchSession,
    toast,
    user?.id,
  ])

  const [
    postUserEmailVerificationVerify,
    { isLoading: isLoadingPostUserEmailVerificationVerify },
  ] = usePostUserEmailVerificationVerifyMutation()

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    postUserEmailVerificationVerify({
      body: values,
      params: { userId: user.id },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.userEmailVerified(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    postUserEmailVerificationVerify,
    toast,
    user,
  ])

  const isLoadingSubmit = useMemo(() => (isFetchingSession && !isLoadingSession) || isLoadingPostUserEmailVerificationVerify, [
    isFetchingSession,
    isLoadingSession,
    isLoadingPostUserEmailVerificationVerify,
  ])

  const onCancel = useCallback(() => {
    signOut()
      .then(() => {
        navigate('/sign-in')
      })
  }, [
    navigate,
    signOut,
  ])

  return (
    <DocumentWrapper title='Real Work | Sign Up Verify Email'>
      <ScreenWrapper>
        <Container>
          <Heading>Verify Email</Heading>
          <Text>
            Please enter the code sent to your email address below.
          </Text>
        </Container>
        <Container mt='4'>
          <Form<FormValues>
            initialValues={initialValues}
            onSubmit={onSubmit}
            maxWidth='620px'
            rules={api.endpoints.users.emailVerification.verify.post.validation.body.rules}
          >
            {formik => {
              if (formik.values.code?.length === 6 && formik.submitCount === 0) {
                setTimeout(formik.submitForm, 100)
              }

              return (
                <>
                  <FormControl
                    name='code'
                    label='Code'
                  >
                    <Input
                      autoFocus={true}
                      type='text'
                    />
                  </FormControl>
                  <HStack flexDirection='row' mt='4' mb='4' space='4' flexWrap='wrap'>
                    <SubmitButton isLoading={isLoadingSubmit}>Continue</SubmitButton>
                    <Button variant='outline' isLoading={isLoadingResendCode} onPress={onSend}>Resend Code</Button>
                    <Button
                      isLoading={isLoadingSignOut}
                      onPress={onCancel}
                      variant='outline'
                    >
                      Cancel
                    </Button>
                  </HStack>
                </>
              )
            }}
          </Form>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(EmailVerification)
