import React from 'react'

import Box from '../Box'
import Divider from '../Divider'
import Heading from '../Heading'

import { Props } from './types'

function SectionSubHeader({
  title,
  ...props
}: Props): React.ReactElement {
  return (
    <Box
      width='100%'
      {...props}
    >
      <Heading size='md' color='brand.primary'>{title}</Heading>
      <Divider />
    </Box>
  )
}

export default React.memo(SectionSubHeader)

export * from './types'
