import React from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import { BrowserTracing } from '@sentry/tracing'

import {
  isProduction,
  isTest,
} from '@real-work/common'
import log from '@real-work/common/dist/src/log/browser'

import { UiProvider } from '@real-work/ui'

import BrowserRouter from '@/components/BrowserRouter'
import Cache from '@/App/Cache'
import DevMenu from '@/components/DevMenu'
import OnNavigationChange from '@/components/OnNavigationChange'
import ScreenWrapperContextProvider from '@/components/ScreenWrapper/context'
import MenuContextProvider from '@/components/ScreenWrapper/Header/Menu/context'

import config, { version } from '@/config'

import theme from '@/theme'

import Sentry from '@/utils/sentry'

import Router, { history } from './Router'

const sentryEnabled = config.sentryEnabled && !isTest
if (sentryEnabled) {
  if (!config.sentryDsn) {
    log('error', '🐞 Missing sentryDsn in config.')
  }
  else {
    Sentry.init({
      dsn: config.sentryDsn,
      debug: config.isDebug,
      environment: config.appEnv,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      release: version,
      tracesSampleRate: 1.0,
    })
  }
}

function App(): React.ReactElement {
  return (
    <BrowserRouter history={history}>
      <UiProvider theme={theme}>
        <ScreenWrapperContextProvider>
          <MenuContextProvider>
            {!isProduction && !isTest && <DevMenu />}

            <Cache />

            <OnNavigationChange />

            <Router />
          </MenuContextProvider>
        </ScreenWrapperContextProvider>
      </UiProvider>
    </BrowserRouter>
  )
}

export default App
