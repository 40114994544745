import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  api,
  lang,
  validation,
} from '@real-work/common'
import type { Models } from '@real-work/orm'

import {
  Box,
  Button,
  Container,
  FileInput,
  Form,
  FormControl,
  Heading,
  HStack,
  Input,
  LoadingFailed,
  LoadingIndicator,
  Select,
  SelectTypes,
  SubmitButton,
  Text,
  TextArea,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'

import { useGetCompanyProfilePictureByCompanyIdQuery } from '@/services/asset'
import {
  useGetCompanyByIdQuery,
  usePatchCompanyByIdMutation,
} from '@/services/company'
import { useGetIndustriesQuery } from '@/services/industry'
import { useGetOptionsQuery } from '@/services/option'

import DocumentWrapper from '../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
} from './types'

function CompanyEdit(): React.ReactElement {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()

  const { id } = useRouteParams()
  const toast = useToast()

  const {
    isLoading: isCompanyLoading,
    isError: isCompanyError,
    data: { company } = {},
  } = useGetCompanyByIdQuery({ params: { id: id || '' } }, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  })

  const {
    base64: profilePicture,
    crop: profilePictureCrop,
    isLoading: isLoadingProfilePicture,
  } = useGetCompanyProfilePictureByCompanyIdQuery({
    params: {
      companyId: company?.id || '',
      variant: 'original',
    },
  }, { skip: !company?.id })

  const {
    data: { industries } = {},
    isLoading: isIndustriesLoading,
    isError: isIndustriesError,
  } = useGetIndustriesQuery()

  const {
    data: { options } = { options: undefined },
    isLoading: isOptionsLoading,
    isError: isOptionsError,
  } = useGetOptionsQuery()

  const [
    patchCompanyById,
    { isLoading: isComanySubmitting },
  ] = usePatchCompanyByIdMutation()

  const validationRules = api.endpoints.companies.patchById.validation.body.rules

  const rules = useMemo(() => ({
    aboutUs: validationRules.aboutUs,
    industryId: validationRules.industryId,
    name: validation.string().required().label('Company Name'),
    numberOfEmployeesOptionId: validationRules.numberOfEmployeesOptionId,
    numberOfLocationsOptionId: validationRules.numberOfLocationsOptionId,
    profilePicture: validationRules.profilePicture,
    services: validation.string().required().label('Services'),
    website: validationRules.website,
  }), [ validationRules ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    patchCompanyById({
      params: { id },
      body: values,
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate(`/companies/${id}`)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    id,
    navigate,
    toast,
    patchCompanyById,
  ])

  const isLoading = useMemo(() => isIndustriesLoading || isCompanyLoading || isOptionsLoading || isLoadingProfilePicture, [
    isCompanyLoading,
    isIndustriesLoading,
    isOptionsLoading,
    isLoadingProfilePicture,
  ])

  const isError = useMemo(() => isIndustriesError || isCompanyError || isOptionsError, [
    isCompanyError,
    isIndustriesError,
    isOptionsError,
  ])

  const selectOptions = useMemo(() => options?.reduce((acc, option) => {
    if (option.type === 'numberOfLocations' || option.type === 'numberOfEmployees') {
      if (!acc[option.type]) {
        acc[option.type] = []
      }

      acc[option.type].push({
        label: option.label,
        value: option.id,
      })
    }

    return acc
  }, {} as Record<Models.Option.OptionType, SelectTypes.Option[]>), [ options ])

  return (
    <DocumentWrapper title='Real Work | Edit Company Profile'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
          <Container>
            <Heading>Edit Company Profile</Heading>
            <Text mb='6'>
              Edit your company profile.
            </Text>
            <Form<FormValues>
              enableReinitialize={true}
              initialValues={{
                aboutUs: company?.aboutUs,
                industryId: company?.industryId,
                name: company?.name || '',
                numberOfEmployeesOptionId: company?.numberOfEmployeesOptionId,
                numberOfLocationsOptionId: company?.numberOfLocationsOptionId,
                profilePicture,
                profilePictureCrop,
                services: company?.services,
                website: company?.website,
              }}
              onSubmit={onSubmit}
              rules={rules}
            >
              <Box flexDir='row'>
                <FormControl
                  name='profilePicture'
                >
                  <FileInput
                    mode='image'
                    addButtonText='Browse for Photo'
                    crop={{ aspectRatio: 1 }}
                    name='profilePicture'
                    removeButtonText='Remove Photo'
                  />
                </FormControl>
              </Box>
              <FormControl
                name='name'
                label='Company Name'
              >
                <Input
                  type='text'
                  value='Joe Allen Plumbing & Electric'
                />
              </FormControl>
              <FormControl
                name='industryId'
                label='Industry'
              >
                <Select
                  options={industries?.map(industry => ({
                    label: industry.name,
                    value: industry.id,
                  }))}
                />
              </FormControl>
              <FormControl
                name='services'
                label='Services'
              >
                <Input
                  type='text'
                  value='Commercial and residential plumbing'
                />
              </FormControl>
              <FormControl
                name='numberOfEmployeesOptionId'
                label='Number of Employees'
              >
                <Select
                  options={selectOptions?.numberOfEmployees}
                />
              </FormControl>
              <FormControl
                name='numberOfLocationsOptionId'
                label='Number of Locations'
              >
                <Select
                  options={selectOptions?.numberOfLocations}
                />
              </FormControl>
              <FormControl
                name='website'
                label='Website'
                labelNote='(Optional)'
              >
                <Input
                  type='text'
                  value='www.joeallenelectric.com'
                />
              </FormControl>
              <FormControl
                name='aboutUs'
                label='About Us'
                labelNote='(Optional)'
              >
                <TextArea>Joe Allen Plumbing is a premier provider of plumbing solutions in greater Puget Sound. We were founded over
                  25 years ago, and started as a small family operation. Now we have teams working commercial and residential
                  jobs across the area.
                </TextArea>
              </FormControl>
              <HStack flexDirection='row' mt='4' mb='4' space='4'>
                <SubmitButton isLoading={isComanySubmitting}>Save</SubmitButton>
                <Button
                  onPress={() => navigate(`/companies/${id}`)}
                  variant='outline'
                >
                  Cancel
                </Button>
              </HStack>

            </Form>
          </Container>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(CompanyEdit)
