import React from 'react'

import Box from '../Box'
import Container from '../Container'
import Heading from '../Heading'
import Image from '../Image'

import { Props } from './types'

function SectionHeader({
  container,
  isCentered = false,
  image,
  title,
  ...props
}: Props): React.ReactElement {
  return (
    <Box
      backgroundColor='primary.800'
      width='100%'
      my={image ? '8' : '4'}
      {...props}
    >
      <Container
        paddingLeft={image ? '85px' : '4'}
        {...container}
        variant={image && 'large'}
      >
        <Heading
          color='white'
          maxW='100%'
          py='2'
          pl={image && '16px'}
          size='sm'
          textAlign={isCentered ? 'center' : 'left'}
          textTransform='uppercase'
          width='100%'
        >
          {image && (
            <Box
              position='absolute'
              left='16px'
              top='-20'
              backgroundColor='primary.800'
              borderColor='white'
              borderWidth='2'
              borderRadius='full'
              alignItems='center'
              justifyContent='center'
              height='72px'
              width='72px'
            >
              <Image {...image} />
            </Box>
          )}
          {title}
        </Heading>
      </Container>
    </Box>
  )
}

export default React.memo(SectionHeader)

export * from './types'
