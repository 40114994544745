import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  Alert,
  Box,
  Spinner,
} from '@real-work/ui'

import useCompanySubscription from '@/hooks/useCompanySubscription'

import { useGetSubscriptionPlansQuery } from '@/services/subscriptionPlan'

import { signUpSelectors } from '@/state/slices/signUp'

function SelectedPlanAlert(): React.ReactElement {
  const { subscription } = useCompanySubscription()
  const signUpSubscriptionPlanSlug = useSelector(signUpSelectors.subscriptionPlanSlug)

  const {
    data: { subscriptionPlans } = {},
    isLoading,
  } = useGetSubscriptionPlansQuery()

  const subscriptionPlan = useMemo(() => {
    // If user is logged in, we use their actual subscription plan - otherwise use the signUpSelector value set during sign up flow
    const planSlug = subscription?.plan.slug || signUpSubscriptionPlanSlug

    return subscriptionPlans?.find(plan => plan.slug === planSlug)
  }, [
    subscriptionPlans,
    signUpSubscriptionPlanSlug,
    subscription,
  ])

  return (
    <Box
      mt='6'
      mb='2'
    >
      {(isLoading && <Spinner />) || (subscriptionPlan && (
        <Alert
          alignSelf='flex-start'
          mx='0'
          title={`You are enrolling in the Real Work ${subscriptionPlan.name} Plan${subscriptionPlan.price ? ` – $${subscriptionPlan.price / 100}/mo` : ''}`}
          type='info'
        />
      ))}
    </Box>
  )
}

export default SelectedPlanAlert
