import { string } from '@real-work/common'

export const getVariant = (name?: string) => {
  const nameAlphabeticIndex = string.getAlphabeticIndex(name)

  const variantIndex = Math.ceil((nameAlphabeticIndex + 1) / 2)

  const variant: keyof typeof avatarBoxVariants = `avatar${variantIndex as 1|2|3|4|5|6|7|8|9|10|11|12|13}`

  return variant
}

/**
 * !! This object must have exactly 13 entries, with exactly these keys. Values may be changed as needed !!
 */
export const avatarBoxVariants: Record<
  | 'avatar1'
  | 'avatar2'
  | 'avatar3'
  | 'avatar4'
  | 'avatar5'
  | 'avatar6'
  | 'avatar7'
  | 'avatar8'
  | 'avatar9'
  | 'avatar10'
  | 'avatar11'
  | 'avatar12'
  | 'avatar13',
  { background: string }
> = {
  avatar1: { background: '#FFFFFF' },
  avatar2: { background: '#FCD34D' },
  avatar3: { background: '#FF5F00' },
  avatar4: { background: '#155E75' },
  avatar5: { background: '#FF9D6C' },
  avatar6: { background: '#54565A' },
  avatar7: { background: '#21A7C7' },
  avatar8: { background: '#FF5F00' },
  avatar9: { background: '#FFFFFF' },
  avatar10: { background: '#D7DCE0' },
  avatar11: { background: '#FF9D6C' },
  avatar12: { background: '#FCD34D' },
  avatar13: { background: '#21A7C7' },
}

export const avatarTextVariants: Record<keyof typeof avatarBoxVariants, { color: string }> = {
  avatar1: { color: '#FF5F00' },
  avatar2: { color: '#FF5F00' },
  avatar3: { color: '#FFFFFF' },
  avatar4: { color: '#FFFFFF' },
  avatar5: { color: '#FFFFFF' },
  avatar6: { color: '#FFFFFF' },
  avatar7: { color: '#FFFFFF' },
  avatar8: { color: '#000000' },
  avatar9: { color: '#000000' },
  avatar10: { color: '#000000' },
  avatar11: { color: '#000000' },
  avatar12: { color: '#000000' },
  avatar13: { color: '#000000' },
}
