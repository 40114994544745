import React from 'react'
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
  VStack,
} from '@real-work/ui'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import { useGetJobsWithInterestedWorkersQuery } from '@/services/job'

import Worker from '@/components/Worker'

function InterestedWorkers(): React.ReactElement {
  const navigate = useNavigate()

  const session = useSession()

  const {
    isError,
    isFetching,
    data: { jobs } = {},
  } = useGetJobsWithInterestedWorkersQuery({
    query: {
      interestedWorkersByJobCompanyId: session.user?.employer?.companyOwned?.id,
      perPage: -1,
    },
  }, { refetchOnMountOrArgChange: true })

  if (isFetching) {
    return <LoadingIndicator />
  }

  if (isError) {
    return <LoadingFailed />
  }

  return (jobs || []).length === 0 ? <NoContent /> : (
    <VStack mt='4'>
      {(jobs || []).map(job => (
        <VStack mb='4' key={job.id}>
          <HStack justifyContent='space-between' mb='4'>
            <Heading mr='4' size='md'>{job.title}</Heading>
            <Box>
              <Button
                onPress={() => navigate(`/jobs/${job.id}`)}
                size='sm'
                _text={{ fontSize: 'sm' }}
              >
                View Job
              </Button>
            </Box>
          </HStack>
          {(job.interestedWorkers || []).map(jobInterestedWorker => (
            <React.Fragment key={jobInterestedWorker.workerUserId}>
              <Worker
                job={job}
                worker={jobInterestedWorker.worker}
              />
              <Divider />
            </React.Fragment>
          ))}
        </VStack>
      ))}
    </VStack>
  )
}

export default React.memo(InterestedWorkers)
