import React, {
  useCallback,
  useMemo,
} from 'react'
import { CheckIcon } from 'native-base'

import Box from '../../Box'
import Heading from '../../Heading'
import HStack from '../../HStack'
import Pressable from '../../Pressable'
import Text from '../../Text'

import { Props } from './types'

function PlanCard({
  bullets = [],
  children,
  currentPlan,
  description = '',
  isActive,
  selectedPlan,
  setSelectedPlan,
  slug,
  title = '',
}: Props): React.ReactElement {
  const isCurrent = useMemo(() => currentPlan === slug, [
    currentPlan,
    slug,
  ])

  const isSelected = useMemo(() => selectedPlan === slug, [
    selectedPlan,
    slug,
  ])

  const togglePlan = useCallback(() => {
    if (currentPlan === slug) {
      return
    }

    setSelectedPlan(prev => prev === slug ? undefined : slug)
  }, [
    currentPlan,
    setSelectedPlan,
    slug,
  ])

  return (
    <Pressable
      flex='1'
      isDisabled={currentPlan === slug || !isActive}
      onPress={togglePlan}
    >
      <Box m='4' flex='1' opacity={isActive ? '1' : '0.4'}>
        {isSelected && (
          <Box backgroundColor='secondary.600' position='absolute' zIndex='2' rounded='full' padding='1' top='-5' left='-5'>
            <CheckIcon color='singletons.white' size='2xl' />
          </Box>
        )}
        <Box
          rounded='3xl'
          borderColor={isSelected ? 'secondary.300' : 'muted.300'}
          borderWidth='1'
          overflow='hidden'
          position='relative'
          flex='1'
        >
          <Box py='4' px='6'>
            <Heading size='md' alignSelf='center'>{title}</Heading>
            <Text textAlign='center' py='2'>{description}</Text>
            {bullets.map(bullet => (
              <HStack mb='2' key={bullet}>
                <Box>
                  <CheckIcon color='primary.600' size='md' />
                </Box>
                <Text ml='1' fontWeight='600' fontSize='lg'>{bullet}</Text>
              </HStack>
            ))}
            {children}
          </Box>
          <Box
            backgroundColor={(isSelected) ? 'singletons.white' : 'muted.200'}
            py='2'
            mt='auto'
            flexDirection='row'
            justifyContent='center'
            position='relative'
            height='65px'
            alignItems='center'
          >
            {!isCurrent
              ? (
                <Box position='absolute' top='10px' backgroundColor={isSelected ? 'rgba(8, 145, 178, .4)' : 'primary.600'} height='45px' width='100px' rounded='full'>
                  <Text size='sm' color='singletons.white' width='100px' textAlign='center' pt='2' bold={true}>Choose</Text>
                </Box>
              )
              : <Text fontStyle='italic'>Current Plan</Text>}
          </Box>
        </Box>
      </Box>
    </Pressable>
  )
}

export default React.memo(PlanCard)
