import React from 'react'

import { url } from '@real-work/common'

import {
  Center,
  Heading,
  Text,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

function AccountCancelSuccess(): React.ReactElement {
  return (
    <DocumentWrapper title='Real Work | Account Canceled'>
      <ScreenWrapper>
        <Center py='6' px='4'>
          <Heading my='6'>Account Canceled</Heading>
          <Text mb='6' fontSize='xl' textAlign='center'>Your account with Real Work has been canceled. Please let us know if there is anything we can do to improve our platform.</Text>
          <Link to={url.marketingSite('/feedback')} type='button'>Contact Us</Link>
        </Center>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(AccountCancelSuccess)
