import React from 'react'
import {
  Alert as NbAlert,
  IconButton,
} from 'native-base'

import CloseX from '../CloseX'
import HStack from '../HStack'
import Text from '../Text'
import VStack from '../VStack'

import { Props } from './types'

function Alert({
  description,
  onClose,
  title,
  type,
  ...props
}: Props): React.ReactElement {
  return (
    <NbAlert
      alignSelf='center'
      flexDirection='row'
      maxWidth='md'
      mx='4'
      status={type}
      {...props}
    >
      <VStack space={1} flexShrink={1} w='100%'>
        <HStack flexShrink={1} alignItems='center' justifyContent='space-between'>
          <HStack space={2} flexShrink={1} alignItems='center'>
            <NbAlert.Icon />

            <VStack flexShrink={1}>
              <Text fontSize='sm' fontWeight='regular' flexShrink={1} color='black'>
                {title}
              </Text>
              <HStack space={0} flexShrink={1} alignItems='center'>
                {(typeof description === 'string' && (
                  <Text fontSize='sm' fontWeight='light' flexShrink={1} color='black'>
                    {description}
                  </Text>
                )) || description || undefined}
              </HStack>
            </VStack>
          </HStack>

          {onClose && (
            <IconButton
              icon={(
                <CloseX
                  color='black'
                  onPress={onClose}
                  size='3'
                />
              )}
            />
          )}
        </HStack>
      </VStack>
    </NbAlert>
  )
}

export default React.memo(Alert)

export * from './types'
