import React, { useCallback } from 'react'

import {
  api,
  url,
} from '@real-work/common'

import {
  Box,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  Stack,
  Input,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useMediaQuery,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useLocation from '@/hooks/useLocation'
import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import {
  FormValues,
  OnSubmit,
} from './types'

function SignIn(): React.ReactElement {
  const {
    isLoadingSignIn,
    signIn,
  } = useSession()

  const location = useLocation()

  const handleFormApiErrors = useHandleFormApiErrors()

  const [ isDesktop ] = useMediaQuery({ minWidth: 767 })

  const navigate = useNavigate()

  const onSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    signIn(values)
      .then(async () => {
        const from = `${location.state?.from?.pathname || ''}${location.state?.from?.search || ''}` || undefined

        navigate(from || '/dashboard')
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    location.state?.from,
    navigate,
    signIn,
  ])

  return (
    <DocumentWrapper title='Real Work | Sign In'>
      <ScreenWrapper>
        <Container>
          <Heading>Sign In</Heading>
          <Text>
            Please enter your details below.
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>Personal Details</Heading>
          <Divider />
          <Form<FormValues>
            onSubmit={onSubmit}
            rules={api.endpoints.sessions.post.validation.body.rules}
          >
            <FormControl
              name='email'
              label='Email'
            >
              <Input
                type='email'
              />
            </FormControl>
            <FormControl
              name='password'
              label='Password'
            >
              <Input
                type='password'
              />
            </FormControl>
            <Box flexDirection='row' mb='2'>
              <Link
                variant='emphasis'
                to='/sign-in/forgot-password'
              >
                Forgot Password? &gt;
              </Link>
            </Box>
            <Box flexDirection='row' mt='4' mb='6'>
              <SubmitButton isLoading={isLoadingSignIn}>Sign In</SubmitButton>
            </Box>
          </Form>
        </Container>
        <Container>
          <Heading size='md' color='brand.primary'>Don’t have an account yet?</Heading>
          <Divider mt='4' mb='4' />
          <Stack
            direction={[
              'column',
              'column',
              'row',
            ]}
            width='100%'
          >
            <Box py='4'>
              <Heading size='sm'>REAL WORKERS</Heading>
              <Text mb='8'>Ready to find work that fits your life?</Text>
              <Box maxW='240'><Link type='button' to='/sign-up/worker'>Real Worker Sign Up</Link></Box>
            </Box>
            {isDesktop ? <Divider orientation='vertical' mx='8' /> : <Divider orientation='horizontal' />}
            <Box py='4'>
              <Heading size='sm'>EMPLOYERS</Heading>
              <Text mb='8'>Looking for your ideal Real Worker?</Text>
              <Box maxW='240'><Link type='button' to='/sign-up/employer'>Employer Sign Up</Link></Box>
            </Box>
          </Stack>
          <Box backgroundColor='muted.200' p='6' rounded='lg' width='100%' textAlign='center' mt='6'>
            <Text fontSize='xl' alignSelf='center'>Learn more about Real Work’s full feature set for Real Workers and Employers on&nbsp;
              <Link
                to={url.marketingSite('/')}
                isExternal={true}
              >
                Realwork.com
              </Link>
            </Text>
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SignIn)
