import React, { useMemo } from 'react'

import {
  api,
  array,
  lang,
} from '@real-work/common'

import { Models } from '@real-work/orm'

import {
  Box,
  BulletList,
  BulletListItem,
  Container,
  DescriptionList,
  Heading,
  HStack,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
  SectionHeader,
  SectionSubHeader,
  Text,
  useMediaQuery,
  VStack,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'
import Worker from '@/components/Worker'

import useRouterParms from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import { useGetWorkerByUserIdQuery } from '@/services/worker'

import dateTime from '@/utils/dateTime'

import HideShow from './components/HideShow'

function WorkerProfile(): React.ReactElement {
  const { id } = useRouterParms()
  const { user } = useSession()

  const [ isDesktop ] = useMediaQuery({ minWidth: 800 })

  const {
    data,
    error,
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  })

  const trades = useMemo(() => {
    if (!data?.worker?.specialties) {
      return {}
    }

    const trades = (data.worker?.specialties || []).reduce((acc, specialty) => {
      if (!specialty.specialty?.trade?.name || !specialty.specialty?.name) {
        return acc
      }

      if (!acc[specialty.specialty?.trade.name]) {
        acc[specialty.specialty?.trade.name] = []
      }

      (acc[specialty.specialty?.trade.name] as string[]).push(specialty.specialty?.name)

      return acc
    }, {} as Record<string, string[]>)

    return trades
  }, [ data?.worker?.specialties ])

  const showExperienceSection = useMemo(() => (
    Object.keys(trades).length
    + (data?.worker.licenses?.length || 0)
    + (data?.worker.certifications?.length || 0)
    + (data?.worker.productExperiences?.length || 0)
    + (data?.worker.skills?.length || 0)
    + (data?.worker.workExperiences?.length || 0)
    + (data?.worker.educations?.length || 0)
    + (data?.worker.memberships?.length || 0)
  ) > 0, [
    data?.worker.licenses?.length,
    data?.worker.certifications?.length,
    data?.worker.productExperiences?.length,
    data?.worker.skills?.length,
    data?.worker.workExperiences?.length,
    data?.worker.educations?.length,
    data?.worker.memberships?.length,
    trades,
  ])

  return (
    <DocumentWrapper title={`Real Work | ${data?.worker?.user?.fullName || lang().messages.notFound()}`}>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (data?.worker && (
          <>
            <Container>
              {data.worker.userId === user?.id && (
                <HStack width='100%' justifyContent='space-between' alignItems='center' flexWrap='wrap' mb='6'>
                  <VStack flex='1'>
                    <Heading>My Profile</Heading>
                    <Text>How potential employers see me.</Text>
                  </VStack>
                  <Link type='button' to='/profile/edit'>Edit Profile</Link>
                </HStack>
              )}
              <Box
                rounded='3xl'
                borderColor='muted.300'
                borderWidth='1'
                overflow='hidden'
                position='relative'
                alignItems='center'
                width='100%'
                margin={isDesktop ? '0' : 'auto'}
              >
                <Worker
                  worker={data.worker}
                />
              </Box>
            </Container>
            <>
              {data.worker.personalSummary && (
                <>
                  <SectionHeader title='About Me' isCentered={true} />
                  <Container>
                    <Text maxW='100%'>
                      {data.worker.personalSummary}
                    </Text>
                  </Container>
                </>
              )}
              {showExperienceSection && (
                <>
                  <SectionHeader title='Experience' isCentered={true} />
                  <Container>
                    {Object.keys(trades).length > 0 && (
                      <>
                        <SectionSubHeader title='Trades' />
                        <BulletList>
                          {Object.keys(trades).map(tradeName => (<BulletListItem key={tradeName}><Text>{tradeName} | {array.toListString(trades[tradeName] as string[])}</Text></BulletListItem>))}
                        </BulletList>
                      </>
                    )}
                    {!!data.worker.licenses?.length && (
                      <>
                        <SectionSubHeader title='Licenses' />
                        <BulletList>
                          {data.worker.licenses.map(license => (
                            <BulletListItem key={license.id}>
                              <HideShow
                                title={getLicenseTitle(license)}
                              >
                                <VStack>
                                  {license.licenseNumber && <Text>License Number: {license.licenseNumber}</Text>}
                                  {license.receivedAt && <Text>Date Received: {dateTime.format(new Date(license.receivedAt.toString()), 'date')}</Text>}
                                  {license.expiresAt && <Text>Expiration Date: {dateTime.format(new Date(license.expiresAt.toString()), 'date')}</Text>}
                                </VStack>
                              </HideShow>
                            </BulletListItem>
                          ))}
                        </BulletList>
                      </>
                    )}
                    {!!data.worker.certifications?.length && (
                      <>
                        <SectionSubHeader title='Certifications / Other Qualifications' />
                        {data.worker.certifications.map(certification => (
                          <BulletList key={certification.id}>
                            <BulletListItem>
                              <HideShow
                                title={getCertificationTitle(certification)}
                              >
                                <VStack>
                                  {certification.certificationNumber && <Text>Certification Number: {certification.certificationNumber}</Text>}
                                  {certification.receivedAt && <Text>Date Received: {dateTime.format(new Date(certification.receivedAt.toString()), 'date')}</Text>}
                                  {certification.expiresAt && <Text>Expiration Date: {dateTime.format(new Date(certification.expiresAt.toString()), 'date')}</Text>}
                                </VStack>
                              </HideShow>
                            </BulletListItem>
                          </BulletList>
                        ))}
                      </>
                    )}
                    {!!data.worker.productExperiences?.length && (
                      <>
                        <SectionSubHeader title='Product Experience' />
                        {data.worker.productExperiences.map(productExperience => (
                          <BulletList key={productExperience.id}>
                            <BulletListItem><Text>{productExperience.product} - {productExperience.manufacturer}</Text></BulletListItem>
                          </BulletList>
                        ))}
                      </>
                    )}
                    {!!data.worker.skills?.length && (
                      <>
                        <SectionSubHeader title='Skills' />
                        <BulletList>
                          {data.worker.skills.map(skill => <BulletListItem key={skill.id}><Text>{skill.name}</Text></BulletListItem>)}
                        </BulletList>
                      </>
                    )}
                    {!!data.worker.workExperiences?.length && (
                      <>
                        <SectionSubHeader title='Work History' />
                        {data.worker.workExperiences.map(workExperience => (
                          <BulletList key={workExperience.id}>
                            <BulletListItem>
                              <VStack>
                                <HideShow
                                  title={`${workExperience.title}, ${workExperience.company}`}
                                  date={getDateRangeString(workExperience.startedAt, workExperience.endedAt)}
                                >
                                  <DescriptionList
                                    items={[
                                      {
                                        title: 'Employment Type',
                                        description: (workExperience.type === 'full-time' && 'Full Time') || (workExperience.type === 'contract' && 'Contract') || undefined,
                                      },
                                      {
                                        title: 'Trades Used in Role',
                                        description: getTradesForWorkExperienceString(workExperience) || undefined,
                                      },
                                      {
                                        title: 'Location',
                                        description: workExperience.location,
                                      },
                                      {
                                        title: 'Description',
                                        description: workExperience.description || undefined,
                                      },
                                    ]}
                                  />
                                </HideShow>
                              </VStack>
                            </BulletListItem>
                          </BulletList>
                        ))}
                      </>
                    )}
                    {!!data.worker.educations?.length && (
                      <>
                        <SectionSubHeader title='Education' />
                        {data.worker.educations.map(education => (
                          <BulletList key={education.id}>
                            <BulletListItem>
                              <VStack>
                                <HideShow title={education.school}>
                                  {education.degree && <Text>{education.degree}</Text>}
                                  <Text>{getDateRangeString(education.startedAt, education.endedAt)}</Text>
                                  <DescriptionList
                                    items={[
                                      {
                                        title: 'Notes',
                                        description: education.notes || undefined,
                                      },
                                    ]}
                                  />
                                </HideShow>
                              </VStack>
                            </BulletListItem>
                          </BulletList>
                        ))}
                      </>
                    )}
                    {!!data.worker.memberships?.length && (
                      <>
                        <SectionSubHeader title='Memberships' />
                        {data.worker.memberships.map(membership => (
                          <BulletList key={membership.id}>
                            <BulletListItem>
                              <VStack>
                                <HideShow title={membership.title}>
                                  <Text>{getDateRangeString(membership.startedAt, membership.endedAt)}</Text>
                                  <DescriptionList
                                    items={[
                                      {
                                        title: 'Notes',
                                        description: membership.notes || undefined,
                                      },
                                    ]}
                                  />
                                </HideShow>
                              </VStack>
                            </BulletListItem>
                          </BulletList>
                        ))}
                      </>
                    )}
                  </Container>
                </>
              )}
            </>
          </>
        )) || ((error as { status: number })?.status === 404 && <NoContent isWholeScreen={true}>Worker Not Found</NoContent>) || <LoadingFailed />}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

const getDateRangeString = (start: Date | string, end?: Date | string | null) => `${dateTime.format(new Date(start), 'monthAndYear')} - ${end ? dateTime.format(new Date(end), 'monthAndYear') : 'Current'}`

const getCertificationTitle = (workerCertification: api.AsJson<Models.WorkerCertification.WithCertification<Models.WorkerCertification.default>>) => {
  if (!workerCertification) {
    return ''
  }

  let title = ''

  if (workerCertification?.certification?.name) {
    title = workerCertification?.certification?.name
  }

  if (workerCertification?.certification?.usStateAbbr) {
    title = `${title} - ${workerCertification?.certification.usStateAbbr}`
  }

  if (workerCertification?.expiresAt) {
    title = `${title}, ${workerCertification?.expiresAt && new Date(workerCertification?.expiresAt) <= new Date() ? 'Expired' : 'Current'}`
  }

  return title
}

const getLicenseTitle = (workerLicense: api.AsJson<Models.WorkerLicense.WithLicense<Models.WorkerLicense.default>>) => {
  if (!workerLicense) {
    return ''
  }

  let title = ''

  if (workerLicense?.license?.name) {
    title = workerLicense?.license?.name
  }

  if (workerLicense?.license?.usStateAbbr) {
    title = `${title} - ${workerLicense?.license.usStateAbbr}`
  }

  if (workerLicense?.expiresAt) {
    title = `${title}, ${workerLicense?.expiresAt && new Date(workerLicense?.expiresAt) <= new Date() ? 'Expired' : 'Current'}`
  }

  return title
}

const getTradesForWorkExperienceString = (workExperience: api.AsJson<Models.WorkerWorkExperience.WithSpecialties<Models.WorkerWorkExperience.default>>) => {
  const trades = workExperience.specialties
    .map(pivot => `${pivot?.specialty?.name}${pivot?.specialty?.trade?.name ? ` | ${pivot?.specialty?.trade?.name}` : ''}` || '')

  return array.toListString(trades)
}

export default React.memo(WorkerProfile)
