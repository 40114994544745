import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import {
  array,
  lang,
} from '@real-work/common'

import {
  Badge,
  Box,
  BulletList,
  BulletListItem,
  Button,
  Confirm,
  Container,
  Heading,
  HStack,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
  SectionHeader,
  SectionSubHeader,
  Text,
  useMediaQuery,
  VStack,
  useToast,
} from '@real-work/ui'

import AvatarCompany from '@/components/Avatar/Company'
import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import {
  useDeleteJobByIdMutation,
  useGetJobByIdQuery,
  useGetJobsImInterestedInQuery,
  usePostJobInterestedWorkerMutation,
  useUpdateJobByIdMutation,
} from '@/services/job'

import dateTime from '@/utils/dateTime'

import overview from './assets/overview.svg'
import salary from './assets/salary.svg'
import skills from './assets/skills.svg'

function Job(): React.ReactElement {
  const [ isDesktop ] = useMediaQuery({ minWidth: 800 })
  const { id } = useRouteParams()
  const { user } = useSession()
  const toast = useToast()
  const navigate = useNavigate()

  const {
    data: { job } = {},
    error,
    isLoading,
    refetch: refetchJob,
  } = useGetJobByIdQuery({ params: { id: id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  })

  const jobsImInterestedIn = useGetJobsImInterestedInQuery({ query: { perPage: -1 } }, { skip: !user?.id || user?.role.slug !== 'worker' })

  const isInterested = useMemo(() => !!id && jobsImInterestedIn.data?.jobs.map(job => job.id).includes(id), [
    id,
    jobsImInterestedIn.data?.jobs,
  ])

  const numInterestedWorkers = useMemo(() => job?.interestedWorkers ? job.interestedWorkers.length : 0, [ job ])

  const [ postJobInterestedWorker ] = usePostJobInterestedWorkerMutation()

  const [
    updateJobById,
    { isLoading: isUpdatingJob },
  ] = useUpdateJobByIdMutation()

  const [
    deleteJobById,
    { isLoading: isDeletingJob },
  ] = useDeleteJobByIdMutation()

  const [
    isCloseModalOpen,
    setIsCloseModalOpen,
  ] = useState(false)

  const [
    isDeleteModalOpen,
    setIsDeleteModalOpen,
  ] = useState(false)

  const [
    isReopenModalOpen,
    setIsReopenModalOpen,
  ] = useState(false)

  const onPressInterested = useCallback(() => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    postJobInterestedWorker({
      params: {
        jobId: id,
        userId: user.id,
      },
    })
      .unwrap()
      .then(() => {
        toast.show({
          description: lang().messages.jobInterestSuccess(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        refetchJob()
      })
      .catch(error => {
        toast.show({
          description: error.data?.message || lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    id,
    postJobInterestedWorker,
    refetchJob,
    toast,
    user?.id,
  ])

  const onDeleteJob = useCallback(() => {
    if (!job?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    deleteJobById({ params: { jobId: job.id } })
      .unwrap()
      .then(() => {
        toast.show({
          description: lang().messages.entryHasBeenDeleted(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
        navigate('/jobs')
      })
      .catch(error => {
        toast.show({
          description: error.data.message || lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    deleteJobById,
    job?.id,
    navigate,
    toast,
  ])

  const onOpenOrCloseJob = useCallback((status: 'open' | 'closed') => {
    if (!job?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    updateJobById({
      params: { jobId: job.id },
      body: { status },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message,
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
        refetchJob()
        setIsReopenModalOpen(false)
        setIsCloseModalOpen(false)
      })
      .catch(error => {
        toast.show({
          description: error.data.message || lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    job?.id,
    toast,
    refetchJob,
    updateJobById,
  ])

  const companyLocationsText = useMemo(() => {
    if (!job?.companyLocations) {
      return undefined
    }

    const locations = job.companyLocations.reduce((acc: string[], pivot) => {
      if (pivot.companyLocation?.displayLocation) {
        acc.push(pivot.companyLocation.displayLocation)
      }

      return acc
    }, [])

    return array.toListString(locations.filter(location => !!location))
  }, [ job?.companyLocations ])

  const tradesWithSpecialtiesTexts = useMemo(() => {
    if (!job?.specialties) {
      return {}
    }

    const trades = (job.specialties || []).reduce((acc, specialty) => {
      if (!specialty.specialty?.trade?.name || !specialty.specialty?.name) {
        return acc
      }

      if (!acc[specialty.specialty?.trade.name]) {
        acc[specialty.specialty?.trade.name] = []
      }

      (acc[specialty.specialty?.trade.name] as string[]).push(specialty.specialty?.name)

      return acc
    }, {} as Record<string, string[]>)

    return trades
  }, [ job?.specialties ])

  const workShiftsText = useMemo(() => {
    if (!job?.workShifts) {
      return undefined
    }

    const workShiftsOfJob = job?.workShifts.map(workShift => workShift.option?.label)

    return array.toListString(workShiftsOfJob as string[])
  }, [ job?.workShifts ])

  return (
    <DocumentWrapper title='Real Work | Job'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (job && (
          <>
            <Container>
              <Box alignItems='flex-end' width='100%' pb='6'>
                {user?.role.slug === 'employer' && user.id === job.company.accountOwnerUserId && (
                  <HStack space='2' flexWrap='wrap'>
                    <Link to={`/jobs/${job.id}/edit`} type='button'>Edit</Link>
                    {job.status === 'open' && <Button isLoading={isUpdatingJob} onPress={() => setIsCloseModalOpen(true)}>Close</Button>}
                    {job.status === 'closed' && <Button isLoading={isDeletingJob} onPress={() => setIsDeleteModalOpen(true)}>Delete</Button>}
                    {job.status === 'closed' && <Button isLoading={isUpdatingJob} onPress={() => setIsReopenModalOpen(true)}>Reopen</Button>}
                  </HStack>
                )}
                {user?.role.slug === 'worker' && (
                  <Button
                    onPress={onPressInterested}
                    isDisabled={jobsImInterestedIn.isLoading || isInterested}
                    isLoading={jobsImInterestedIn.isLoading}
                  >
                    I’m Interested
                  </Button>
                )}
              </Box>
            </Container>

            <Box backgroundColor='muted.200' pt='6' pb='8'>
              <Container>
                <Heading size='md' mb='2'>{job.title}</Heading>
                {job.licenses?.length > 0 && (
                  <HStack flexWrap='wrap'><Text fontWeight='medium'>Licenses </Text>
                    {job.licenses.map((pivot, i) => (
                      <Text key={pivot.id}>{`${pivot.license?.usStateAbbr ? `${pivot.license?.usStateAbbr} |` : ''} ${pivot.license?.name || 'Unknown'}`}{((i < job.licenses.length - 1) && ', ') || ''}</Text>
                    ))}
                  </HStack>
                )}
                {job.certifications?.length > 0 && (
                  <HStack flexWrap='wrap'><Text fontWeight='medium'>Certifications </Text>
                    {job.certifications.map((pivot, i) => (
                      <Text key={pivot.id}>{`${pivot.certification?.usStateAbbr ? `${pivot.certification?.usStateAbbr} |` : ''} ${pivot.certification?.name || 'Unknown'}`}{((i < job.certifications.length - 1) && ', ') || ''}</Text>
                    ))}
                  </HStack>
                )}
                <HStack mt='2' flexWrap='wrap' width='100%' flexDir={isDesktop ? 'row' : 'column'}>
                  <HStack flex='1'><Text fontWeight='medium'>Date Posted </Text><Text>{dateTime.format(new Date(job.createdAt), 'date')}</Text></HStack>
                  <HStack flex='1'><Text fontWeight='medium'>Job Status </Text><Badge colorScheme={job.status === 'open' ? 'green' : 'red'} variant='solid'>{job.status}</Badge></HStack>
                  <HStack flex='1'><Text fontWeight='medium'>Interested Applicants </Text><Text>{numInterestedWorkers}</Text></HStack>
                </HStack>
              </Container>
            </Box>
            <SectionHeader
              mt='0'
              container={{ variant: 'large' }}
              image={{
                source: overview,
                height: 10,
                width: 8,
                alt: 'Overview Section Icon',
              }}
              title='Overview'
            />
            <Container>
              <SectionSubHeader title='Company' />
              <HStack space='4' my='6'>
                <AvatarCompany company={job.company} isLarge={false} />
                <VStack alignItems='flex-start' justifyContent='space-between' flexShrink={1}>
                  <Heading size='md'>{job.company.name}</Heading>
                  <Link to={`/companies/${job.company.id}`} type='button' button={{ variant: 'small' }}>View Profile</Link>
                </VStack>
              </HStack>
              <HStack mt='2' flexWrap='wrap' width='100%' mb='6' flexDir={isDesktop ? 'row' : 'column'}>
                {Object.keys(tradesWithSpecialtiesTexts).length > 0 && (
                  <VStack flex='1'>
                    <Text fontWeight='bold'>Trade(s)</Text>
                    {Object.keys(tradesWithSpecialtiesTexts).map(tradeName => <HStack key={tradeName}><Text fontWeight='medium'>{tradeName}</Text><Text> | {array.toListString(tradesWithSpecialtiesTexts[tradeName])}</Text></HStack>)}
                  </VStack>
                )}
                <VStack flex='1'><Text fontWeight='bold'>Job Type </Text><Text>{job.jobTypeOption.label}</Text></VStack>
              </HStack>
              <HStack mt='2' flexWrap='wrap' width='100%' mb='6' flexDir={isDesktop ? 'row' : 'column'}>
                {companyLocationsText && <VStack flex='1'><Text fontWeight='bold'>Location</Text><Text>{companyLocationsText}</Text></VStack>}
                {workShiftsText && <VStack flex='1'><Text fontWeight='bold'>Work Hours</Text><Text>{workShiftsText}</Text></VStack>}
              </HStack>
              <VStack mb='6'>
                <Text fontWeight='bold'>Description</Text>
                <Text>{job.description}</Text>
              </VStack>
            </Container>
            <SectionHeader
              container={{ variant: 'large' }}
              image={{
                source: skills,
                height: 10,
                width: 10,
                alt: 'Skills & Experience Section Icon',
              }}
              title='Skills & Experience'
            />
            <Container>
              {job.skills?.length > 0 && (
                <>
                  <SectionSubHeader title='Skills' />
                  {job.skills.map(skill => (
                    <BulletList key={skill.id}>
                      <BulletListItem><Text>{skill.name}</Text></BulletListItem>
                    </BulletList>
                  ))}
                </>
              )}

              <SectionSubHeader title='Experience' />
              <Text>{job.experience}</Text>
            </Container>
            <SectionHeader
              container={{ variant: 'large' }}
              image={{
                source: salary,
                height: 10,
                width: 10,
                alt: 'Salary Section Icon',
              }}
              title='Salary'
            />
            <Container mb='4'>
              <SectionSubHeader title='Hourly Rate' />
              <Text>{`$${job.hourlyRate}/hr`}</Text>
            </Container>
            {job.otherCompensation && (
              <Container>
                <SectionSubHeader title='Other Compensation' />
                <Text>{job.otherCompensation}</Text>
              </Container>
            )}

            {/*
            <Modal isOpen={isCloseModalOpen} onClose={() => setIsCloseModalOpen(false)}>
              <Modal.Content maxWidth='400px'>
                <Modal.CloseButton />
                <Modal.Header>Close Job?</Modal.Header>
                <Modal.Body>
                  <Form>
                    <Text>Are you sure you want to close this job?</Text>
                    <FormControl
                      mt='2'
                      name='jobType'
                    >
                      <HStack space='4'>
                        <Checkbox value='fullTime'>Yes</Checkbox>
                        <Checkbox value='partTime'>No</Checkbox>
                      </HStack>
                    </FormControl>
                    <Divider />
                    <Text>Did you hire someone for this job?</Text>
                    <FormControl
                      mt='2'
                      name='jobType'
                    >
                      <HStack space='4'>
                        <Checkbox value='fullTime'>Yes</Checkbox>
                        <Checkbox value='partTime'>No</Checkbox>
                      </HStack>
                    </FormControl>
                    <Divider />
                    <Text>Did you hire this person on Real Work?</Text>
                    <FormControl
                      mt='2'
                      name='jobType'
                    >
                      <HStack space='4'>
                        <Checkbox value='fullTime'>Yes</Checkbox>
                        <Checkbox value='partTime'>No</Checkbox>
                      </HStack>
                    </FormControl>
                    <Divider />
                    <Text>Who did you hire using Real Work?</Text>
                    <FormControl
                      mt='2'
                      name='jobType'
                    >
                      <Input type='text' />
                    </FormControl>
                    <ButtonGroup space={2}>
                      <Button onPress={() => setIsCloseModalOpen(false)}>
                        Save
                      </Button>
                    </ButtonGroup>
                  </Form>
                </Modal.Body>
              </Modal.Content>
            </Modal>
            */}

            {job.status === 'open' && (
              <Confirm
                cancelText='No'
                isOpen={isCloseModalOpen}
                isSubmitting={isUpdatingJob}
                onCancel={() => setIsCloseModalOpen(false)}
                onConfirm={() => onOpenOrCloseJob('closed')}
              >
                Are you sure you want to close this job?
              </Confirm>
            )}
            {job.status === 'closed' && (
              <>
                <Confirm
                  cancelText='No'
                  isOpen={isDeleteModalOpen}
                  isSubmitting={isDeletingJob}
                  onCancel={() => setIsDeleteModalOpen(false)}
                  onConfirm={onDeleteJob}
                >
                  Are you sure you want to delete this job? This is not undoable!
                </Confirm>
                <Confirm
                  cancelText='No'
                  isOpen={isReopenModalOpen}
                  isSubmitting={isUpdatingJob}
                  onCancel={() => setIsReopenModalOpen(false)}
                  onConfirm={() => onOpenOrCloseJob('open')}
                >
                  Are you sure you want to reopen this job?
                </Confirm>
              </>
            )}
          </>
        )) || ((error as { status: number })?.status === 404 && <NoContent>Job Not Found</NoContent>) || <LoadingFailed />}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Job)
