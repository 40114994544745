import React from 'react'
import {
  FormControl,
  HStack,
  Radio as NBRadio,
} from 'native-base'

import useFormInput from '../../hooks/useFormInput'

import Text from '../Text'
import VStack from '../VStack'

import {
  Props,
  RadioItem,
} from './types'

function Radio({
  accessibilityLabel,
  defaultValue,
  isVertical = false,
  items,
  name = '',
  ...props
}: Props) {
  const { setValue } = useFormInput({
    defaultValue,
    name,
  })

  return (
    <NBRadio.Group
      accessibilityLabel={accessibilityLabel}
      defaultValue={defaultValue}
      name={name}
      onChange={setValue}
      {...props}
    >
      <HStack
        flexDir={isVertical ? 'column' : 'row'}
        space={2}
      >
        {items.map((item: RadioItem) => (
          <NBRadio
            key={item.value}
            colorScheme='primary'
            value={item.value}
            my={1}
          >
            <VStack>
              <FormControl.Label fontWeight='medium'>{item.label}</FormControl.Label>
              {item.labelNote && <Text>{item.labelNote}</Text>}
            </VStack>
          </NBRadio>
        ))}
      </HStack>
    </NBRadio.Group>
  )
}

export default React.memo(Radio)

export * from './types'
