import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import {
  Box,
  Button,
  Divider,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
  VStack,
} from '@real-work/ui'

import Job from '@/components/Job'

import useSession from '@/hooks/useSession'

import { useGetClosedJobsQuery } from '@/services/job'

import { hasMorePages } from '@/utils/pagination'

function JobsClosed(): React.ReactElement {
  const { user } = useSession()
  const [
    page,
    setPage,
  ] = useState(1)

  const companyId = (user?.role.slug === 'employer' && user.employer?.companyOwned?.id) || undefined

  const {
    data: {
      jobs,
      pagination,
    } = {},
    isFetching,
  } = useGetClosedJobsQuery({
    query: {
      companyId,
      page,
    },
  }, {
    skip: !companyId,
    refetchOnMountOrArgChange: true,
  })

  const isLoadingFirstPage = useMemo(() => (isFetching && (page || 1) < 2), [
    isFetching,
    page,
  ])

  const onClickShowMoreButton = useCallback(() => setPage(prevPage => (prevPage || 1) + 1), [])

  return (
    <VStack mb='4'>
      {(isLoadingFirstPage && <LoadingIndicator />) || (jobs && (
        <>
          {jobs.length === 0 ? <NoContent /> : jobs.map(job => (
            <React.Fragment key={job.id}>
              <Job job={job} />
              <Divider />
            </React.Fragment>
          ))}

          {hasMorePages(pagination) && (
            <Box mx='auto' mt='6'>
              <Button
                isLoading={isFetching}
                onPress={onClickShowMoreButton}
              >
                Show More
              </Button>
            </Box>
          )}
        </>
      )
      ) || <LoadingFailed />}
    </VStack>
  )
}

export default React.memo(JobsClosed)
