import React from 'react'
import { Divider as NbDivider } from 'native-base'

import { Props } from './types'

function Divider({
  children,
  my = '2',
  ...props
}: Props): React.ReactElement {
  return (
    <NbDivider
      my={my}
      {...props}
    >
      {children}
    </NbDivider>
  )
}

export default React.memo(Divider)

export * from './types'
