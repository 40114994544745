import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import {
  PutCompanyFavoriteWorkerRequest,
  PutCompanyFavoriteWorkerResponse,
} from './types'

const path = 'v1/companies/:companyId/favorite-workers/:workerUserId'

const companyFavoriteWorkerService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    updateCompanyFavoriteWorker: build.mutation<api.AsJson<PutCompanyFavoriteWorkerResponse>, PutCompanyFavoriteWorkerRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: path.replace(':companyId', params.companyId).replace(':workerUserId', params.workerUserId),
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export default companyFavoriteWorkerService

export const { useUpdateCompanyFavoriteWorkerMutation } = companyFavoriteWorkerService

export const { endpoints: { updateCompanyFavoriteWorker } } = companyFavoriteWorkerService
