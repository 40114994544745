import React, { useEffect } from 'react'

import { lang } from '@real-work/common'
import {
  Text,
  Toast,
} from '@real-work/ui'

import useCompanySubscription from '@/hooks/useCompanySubscription'
import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

export const MenuContext = React.createContext(undefined)

const toastId = 'failed-payment'

function ScreenWrapperContextProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const navigate = useNavigate()
  const { user } = useSession()
  const { subscription } = useCompanySubscription()

  useEffect(() => {
    if (user?.role.slug === 'employer' && subscription?.didComplete && subscription?.isActive === false) {
      Toast.show({
        id: toastId,
        title: lang().messages.genericErrorHeading(),
        description: (
          <Text fontSize='md' flexShrink={1} color='black'>
            <Text>Your payment failed. </Text>
            <Text
              onPress={() => navigate('/account/payment-method/edit')}
              underline={true}
            >
              Please update your payment method
            </Text>.
          </Text>
        ),
        allowDuplicate: false,
        isClosable: false,
        type: 'warning',
      })
    }
    else {
      Toast.close(toastId)
    }
  }, [
    navigate,
    subscription,
    user,
  ])

  return React.createElement(
    MenuContext.Provider,
    { value: undefined },
    children,
  )
}

export default ScreenWrapperContextProvider
