import React from 'react'

import { AvatarWorker as UiAvatarWorker } from '@real-work/ui'

import { useGetWorkerProfilePictureByUserIdQuery } from '@/services/asset'

import { Props } from './types'

function AvatarWorker({
  mode = 'profile',
  worker,
  ...props
}: Props): React.ReactElement {
  const {
    blob: profilePicture,
    isLoading: isLoadingProfilePicture,
  } = useGetWorkerProfilePictureByUserIdQuery({
    params: {
      variant: mode === 'list' ? '120x120' : '150x150',
      userId: worker.userId,
    },
  })

  return (
    <UiAvatarWorker
      alt={worker.user.fullName}
      isLarge={mode !== 'list'}
      isLoading={isLoadingProfilePicture}
      profilePicture={profilePicture}
      {...props}
    />
  )
}

export default React.memo(AvatarWorker)

export * from './types'
