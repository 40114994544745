import React from 'react'
import { Box as NbBox } from 'native-base'

import { Props } from './types'

function Box({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbBox {...props}>{children}</NbBox>
  )
}

export default React.memo(Box)

export * from './types'
