import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'

import Button, { OnPress } from '../Button'

import { Props } from './types'

function SubmitButton({
  children,
  onPress: onPressProp,
  ...props
}: Props) {
  const { handleSubmit: formikSubmit } = useFormikContext()

  const onPress: OnPress = useCallback(event => {
    onPressProp && onPressProp(event)

    formikSubmit()
  }, [
    formikSubmit,
    onPressProp,
  ])

  return (
    <Button
      {...props}
      onPress={onPress}
    >
      { children }
    </Button>
  )
}

export default React.memo(SubmitButton)

export * from './types'
