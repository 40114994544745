import { useMemo } from 'react'

import { api } from '@real-work/common'

import type { Models } from '@real-work/orm'

import useSession from '@/hooks/useSession'

function useWorkerMeta(worker?: api.AsJson<Models.Worker.default>) {
  const session = useSession()

  const isAvailable = useMemo(() => {
    if (!worker?.openToWorkSince) {
      return false
    }

    return new Date(worker?.openToWorkSince) <= new Date()
  }, [ worker?.openToWorkSince ])

  const isMe = useMemo(() => worker?.userId === session?.user?.id, [
    session?.user?.id,
    worker?.userId,
  ])

  const canContact = useMemo(() => {
    if (!worker) {
      return false
    }

    return true
  }, [ worker ])

  const canFavorite = useMemo(() => {
    if (!worker) {
      return false
    }

    return session?.user?.role?.slug === 'employer' && !isMe
  }, [
    isMe,
    session?.user?.role?.slug,
    worker,
  ])

  return ({
    canContact,
    canFavorite,
    isAvailable,
    isMe,
  })
}

export default useWorkerMeta
