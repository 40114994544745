import React, {
  useCallback,
  useMemo,
} from 'react'

import {
  api,
  lang,
} from '@real-work/common'

import {
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  HStack,
  Input,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import { usePatchUserByIdMutation } from '@/services/user'

import type {
  FormValues,
  OnSubmit,
} from './types'

function AccountEditPassword(): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()
  const navigate = useNavigate()
  const session = useSession()
  const toast = useToast()

  const [
    patchUserById,
    { isLoading: isSubmitting },
  ] = usePatchUserByIdMutation()

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!session.user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    patchUserById({
      body: values,
      params: { id: session.user.id },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message,
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate(-1)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    navigate,
    patchUserById,
    session.user?.id,
    toast,
  ])

  const validationRules = api.endpoints.users.patchById.validation.body.rules
  const rules = useMemo(() => ({
    password: validationRules.password.required(),
    passwordConfirm: validationRules.passwordConfirm.required(),
    passwordCurrent: validationRules.passwordCurrent.required(),
  }), [ validationRules ])

  return (
    <DocumentWrapper title='Real Work | My Account'>
      <ScreenWrapper>
        <Container>
          <Heading>My Account</Heading>
          <Text>
            Edit your password below.
          </Text>
        </Container>
        <Container mt='4'>
          <Form<FormValues>
            onSubmit={onSubmit}
            rules={rules}
          >
            <Heading size='md' variant='secondary'>Password</Heading>
            <Divider />
            <FormControl
              name='passwordCurrent'
              label='Current Password'
            >
              <Input type='password' />
            </FormControl>
            <FormControl
              name='password'
              label='New Password'
            >
              <Text fontSize='md' mb='1'>{lang().validation.passwordHelperText()}</Text>
              <Input type='password' />
            </FormControl>
            <FormControl
              name='passwordConfirm'
              label='Confirm New Password'
            >
              <Input type='password' />
            </FormControl>
            <HStack flexDirection='row' mt='4' mb='4' space='4'>
              <SubmitButton isLoading={isSubmitting}>Save Changes</SubmitButton>
              <Link
                to='/account'
                type='button'
                button={{ variant: 'outline' }}
              >
                Cancel
              </Link>
            </HStack>
          </Form>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(AccountEditPassword)
