import React, { useState } from 'react'

import {
  Box,
  Pressable,
  Text,
  VStack,
} from '@real-work/ui'

import { Props } from './types'

function HideShow({
  children,
  date,
  title,
  ...props
}: Props): React.ReactElement {
  const [
    isVisible,
    setIsVisible,
  ] = useState(false)

  return (
    <VStack {...props} flexShrink={1}>
      <Text fontWeight='bold'>{title}</Text>
      {date && <Text>{date}</Text>}

      {isVisible && <Box my='1'>{children}</Box>}

      <Pressable onPress={() => setIsVisible(prev => !prev)}>
        <Text fontSize='md' underline={true} mb='2'>{isVisible ? '( Hide Details )' : '( Details )'}</Text>
      </Pressable>
    </VStack>
  )
}

export default React.memo(HideShow)
