import React, {
  useCallback,
  useMemo,
} from 'react'

import {
  api,
  lang,
} from '@real-work/common'

import {
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  DateTimeInput,
  Divider,
  Form,
  FormControl,
  Heading,
  Input,
  LoadingFailed,
  LoadingIndicator,
  SubmitButton,
  Text,
  TextArea,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import {
  useGetWorkerByUserIdQuery,
  useUpsertWorkerEducationMutation,
} from '@/services/worker'

import DocumentWrapper from '../../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function EducationAddEdit({ mode }: Props): React.ReactElement {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const toast = useToast()

  const { id } = useRouteParams()
  const { user } = useSession()

  const {
    data,
    isError,
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, { skip: !id })

  const [
    upsertWorkerEducation,
    { isLoading: isSubmitting },
  ] = useUpsertWorkerEducationMutation()

  const validationRules = api.endpoints.workers.putEducation.validation.body.rules

  const rules = useMemo(() => ({
    school: validationRules.school,
    degree: validationRules.degree,
    isCurrentlyEnrolled: validationRules.isCurrentlyEnrolled,
    notes: validationRules.notes,
  }), [ validationRules ])

  const education = data?.worker.educations?.find(item => item.id === id)

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertWorkerEducation({
      params: {
        userId: user.id,
        id,
      },
      body: {
        ...values,
        startedAt: api.dates.inputValueToApiValue(values.startedAt),
        endedAt: api.dates.inputValueToApiValueOptional(values.endedAt),
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
        navigate('/profile/educations')
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    id,
    navigate,
    toast,
    upsertWorkerEducation,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Education'>
      <ScreenWrapper>
        <Container>
          <Heading>Education Experience</Heading>
          <Text>
            {mode === 'edit' ? 'Edit education below.' : 'Add education below.'}
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>{mode === 'edit' ? 'Edit Education' : 'Add Education'}</Heading>
          <Divider />
          {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
            <Form<FormValues>
              enableReinitialize={true}
              initialValues={{
                school: education?.school || '',
                degree: education?.degree,
                isCurrentlyEnrolled: education?.isCurrentlyEnrolled || false,
                notes: education?.notes,
                startedAt: api.dates.apiValueToInputValue(education?.startedAt) || new Date(),
                endedAt: api.dates.apiValueToInputValue(education?.endedAt) || undefined,
              }}
              rules={rules}
              onSubmit={onSubmit}
            >
              <FormControl
                name='school'
                label='School or Institution'
              >
                <Input
                  type='text'
                />
              </FormControl>
              <FormControl
                name='degree'
                label='Degree or Certification'
                labelNote='(Optional)'
              >
                <Input
                  type='text'
                />
              </FormControl>
              <FormControl
                name='isCurrentlyEnrolled'
                mt='1'
              >
                <Checkbox accessibilityLabel='Currently Enrolled'>Currently Enrolled</Checkbox>
              </FormControl>
              <FormControl
                name='startedAt'
                label='Start Date'
              >
                <DateTimeInput
                  mode='date'
                />
              </FormControl>
              <FormControl
                name='endedAt'
                label='Completion Date'
                labelNote='(Optional)'
              >
                <DateTimeInput
                  isOptional={true}
                  mode='date'
                />
              </FormControl>
              <FormControl
                name='notes'
                label='Notes'
                labelNote='(Optional)'
              >
                <TextArea />
              </FormControl>
              <ButtonGroup>
                <SubmitButton isLoading={isSubmitting}>Save</SubmitButton>
                <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
              </ButtonGroup>
            </Form>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(EducationAddEdit)
