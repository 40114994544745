"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uniqueByValue = exports.ifEmptyThenUndefined = exports.toListString = void 0;
const toListString = (input, { conjunction = 'and', oxfordComma = true, } = {}) => {
    if (!Array.isArray(input)) {
        return '';
    }
    input = input.filter(item => typeof item === 'string');
    return input.length < 3 ? input.join(` ${conjunction} `) : [
        input.slice(0, -1).join(', '),
        input.slice(-1),
    ].join(`${oxfordComma ? ',' : ''} ${conjunction} `);
};
exports.toListString = toListString;
const ifEmptyThenUndefined = (...arrays) => {
    const totalLength = arrays.reduce((acc, array) => acc + (array || []).length, 0);
    if (!totalLength) {
        return undefined;
    }
    return Array.prototype.concat(...arrays.map(array => array || []));
};
exports.ifEmptyThenUndefined = ifEmptyThenUndefined;
const uniqueByValue = (arr, key) => {
    const seen = new Set();
    return arr.filter(item => {
        const val = item[key];
        return seen.has(val) ? false : seen.add(val);
    });
};
exports.uniqueByValue = uniqueByValue;
