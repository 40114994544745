import React, { useEffect } from 'react'
import { NativeBaseProvider } from 'native-base'
import { useFonts } from 'expo-font'

import fonts from '../../theme/fonts'

import { Props } from './types'

function UiProvider({
  children,
  onReady = undefined,
  theme,
  ...props
}: Props): React.ReactElement|null {
  const [ areFontsLoaded ] = useFonts(fonts)

  useEffect(() => {
    if (areFontsLoaded) {
      onReady && onReady()
    }
  }, [
    areFontsLoaded,
    onReady,
  ])

  if (!areFontsLoaded) {
    return null // eslint-disable-line no-null/no-null
  }

  return (
    <NativeBaseProvider
      theme={theme}
      {...props}
    >
      {children}
    </NativeBaseProvider>
  )
}

export default UiProvider

export * from './types'
