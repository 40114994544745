import React from 'react'
import { Button as NbButton } from 'native-base'

import { Props } from './types'

function Button({
  children,
  marginTop = 1,
  marginBottom = 1,
  ...props
}: Props): React.ReactElement {
  if (props.variant === 'link') {
    props = {
      padding: 0,
      mt: 0,
      paddingLeft: 0,
      _text: {
        color: 'black',
        fontSize: 15,
        fontWeight: 'medium',
      },
      ...props,
    }
  }

  return (
    <NbButton
      mt={marginTop}
      mb={marginBottom}
      {...props}
    >
      {children}
    </NbButton>
  )
}

export default React.memo(Button)

export const ButtonGroup = NbButton.Group

export * from './types'
