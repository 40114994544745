import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import { url } from '@real-work/common'
import {
  Button,
  Container,
  Divider,
  HStack,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
  SectionHeader,
  SectionSubHeader,
  useMediaQuery,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'
import Worker from '@/components/Worker'

import useSession from '@/hooks/useSession'

import { useGetFavoriteWorkersByCompanyIdQuery } from '@/services/company'
import { useGetJobsQuery } from '@/services/job'

import { hasMorePages } from '@/utils/pagination'

import Job from '../components/Job'

import addJob from '../assets/addJob.svg'
import findWorkers from '../assets/findWorkers.svg'
import browseFav from '../assets/browseFav.svg'
import review from '../assets/review.svg'
// import invite from '../assets/invite.svg'
import share from '../assets/share.svg'
import openJobs from '../assets/openJobs.svg'
import recruiting from '../assets/recruiting.svg'

function Dashboard(): React.ReactElement {
  const [ rowBreakpoint ] = useMediaQuery({ minWidth: 1000 })
  const { user } = useSession()

  const [
    openJobsPage,
    setOpenJobsPage,
  ] = useState(1)

  const {
    data: {
      jobs,
      pagination: paginationOpenJobs,
    } = {},
    isError: isErrorOpenJobs,
    isFetching: isFetchingOpenJobs,
    isLoading: isLoadingOpenJobs,
    refetch: refetchOpenJobs,
  } = useGetJobsQuery({
    query: {
      companyId: user?.employer?.companyOwned?.id,
      page: openJobsPage,
      perPage: 3,
      sort: '-expressedInterestAt',
    },
  }, {
    refetchOnMountOrArgChange: true,
    skip: !user?.employer?.companyOwned?.id,
  })

  const isLoadingFirstPageOfOpenJobs = useMemo(() => (isLoadingOpenJobs && (openJobsPage || 1) < 2), [
    isLoadingOpenJobs,
    openJobsPage,
  ])

  const {
    data: { jobsAndGeneralRecruiting } = {},
    isError: isErrorFavoriteWorkers,
    isLoading: isLoadingFavoriteWorkers,
    refetch: refetchFavoriteWorkers,
  } = useGetFavoriteWorkersByCompanyIdQuery({
    params: { companyId: user?.employer?.companyOwned?.id || '' },
    query: { jobId: 'GENERAL_RECRUITING' },
  }, {
    refetchOnMountOrArgChange: true,
    skip: !user?.employer?.companyOwned?.id,
  })

  const generalRecruitingFavoriteWorkers = useMemo(
    // eslint-disable-next-line no-null/no-null
    () => jobsAndGeneralRecruiting?.find(job => job.id === null)?.favoriteWorkers?.slice(0, 2),
    [ jobsAndGeneralRecruiting ],
  )

  const refetch = useCallback(() => {
    refetchFavoriteWorkers()
    refetchOpenJobs()
  }, [
    refetchFavoriteWorkers,
    refetchOpenJobs,
  ])

  const onClickShowMorOpenJobsButton = useCallback(() => setOpenJobsPage(prevPage => (prevPage || 1) + 1), [])

  return (
    <DocumentWrapper title='Real Work | Employer Dashboard'>
      <ScreenWrapper>
        <Container>
          <HStack alignSelf='center' flexWrap='wrap' justifyContent='center' my='4'>
            <Link
              to='/jobs/new'
              type='squareIconButton'
              icon={{
                source: addJob,
                alt: 'Jobs Icon',
                height: '8',
                width: '9',
              }}
            >
              Add{'\n'}Job
            </Link>
            <Link
              to='/workers'
              type='squareIconButton'
              icon={{
                source: findWorkers,
                alt: 'Find Workers Icon',
              }}
            >
              Find Real{'\n'}Workers
            </Link>
            <Link
              to='/workers/?tab=favorite'
              type='squareIconButton'
              icon={{
                source: browseFav,
                alt: 'Browse Icon',
                height: '8',
                width: '9',
              }}
            >
              Browse{'\n'}Favorites
            </Link>
            <Link
              to={`/companies/${user?.employer?.companyOwned?.id}`}
              type='squareIconButton'
              icon={{
                source: review,
                alt: 'Review Profile Icon',
                height: '8',
                width: '7',
              }}
            >
              Review{'\n'}Profile
            </Link>
            {/* <Link
              to='/invite'
              type='squareIconButton'
              icon={{
                source: invite,
                alt: 'Invite Team Icon',
                height: '36px',
                width: '49px',
              }}
            >
              Invite Team{'\n'}Members
            </Link> */}
            <Link
              isExternal={true}
              to={url.marketingSite('/')}
              type='squareIconButton'
              icon={{
                source: share,
                alt: 'Invite Team Icon',
              }}
            >
              Share{'\n'}Real Work
            </Link>
          </HStack>
        </Container>

        <SectionHeader
          container={{ variant: 'large' }}
          image={{
            source: openJobs,
            height: 41,
            width: 41,
            alt: 'Open Jobs Section Icon',
          }}
          title='Open Jobs'
        />
        {(isLoadingFirstPageOfOpenJobs && <LoadingIndicator />) || (
          <Container variant='large'>
            {(isErrorOpenJobs && <LoadingFailed alignSelf='center' />) || (!!jobs?.length && (
              <>
                {jobs.map(job => (
                  <Job
                    key={job.id}
                    job={job}
                    onAfterUpdateCompanyFavoriteWorker={refetch}
                  />
                ))}
                {hasMorePages(paginationOpenJobs) && (
                  <Container mb='12'>
                    <Divider variant='dark' />
                    <Button
                      isLoading={isFetchingOpenJobs}
                      onPress={onClickShowMorOpenJobsButton}
                      variant='unstyled'
                      style={{ padding: '0' }}
                      _text={{
                        fontSize: 'md',
                        color: 'brand.primary',
                        underline: true,
                      }}
                    >
                      Show More Jobs &gt;
                    </Button>
                  </Container>
                )}
              </>
            )) || <NoContent isWholeScreen={false} />}
          </Container>
        )}

        <SectionHeader
          container={{ variant: 'large' }}
          image={{
            source: recruiting,
            height: 43,
            width: 35,
            alt: 'General Recruiting Section Icon',
          }}
          title='General Recruiting'
        />
        {(isLoadingFavoriteWorkers && <LoadingIndicator />) || (
          <Container mt='8'>
            {(isErrorFavoriteWorkers && <LoadingFailed alignSelf='center' />) || (!!generalRecruitingFavoriteWorkers?.length && (
              <>
                <SectionSubHeader title='Recent Favorite Workers' />
                <HStack flexDirection={rowBreakpoint ? 'row' : 'column'} space='30px' alignItems='center' justifyContent='space-between' width='100%'>
                  {generalRecruitingFavoriteWorkers?.map((favoriteWorker, index) => (
                    <React.Fragment key={favoriteWorker.workerUserId}>
                      <Worker
                        mode='card'
                        onAfterUpdateCompanyFavoriteWorker={refetch}
                        worker={favoriteWorker.worker}
                      />
                      {rowBreakpoint && index === 0 && <Divider mx='8' maxHeight='150px' orientation='vertical' />}
                    </React.Fragment>
                  ))}
                </HStack>
              </>
            )) || <NoContent isWholeScreen={false}>You have not added any favorite workers to General Recruiting yet.</NoContent>}
            <Divider variant='dark' />
            <Link
              to='/workers/?tab=favorite'
              _text={{
                fontWeight: 'bold',
                fontSize: 'md',
              }}
            >
              View All Favorite Workers &gt;
            </Link>
          </Container>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Dashboard)
