"use strict";
/**
 * NOTE: These values are stored in .env files IN APP WORKSPACES, not in this package
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    apiBaseUrl: getValue('API_BASE_URL'),
    appEnv: getValue('APP_ENV'),
    assetsBaseUrl: getValue('ASSETS_BASE_URL'),
    google: { apiKey: getValue('GOOGLE_API_KEY') },
    isDebug: getValue('APP_DEBUG', 'bool', false),
    logging: {
        server: {
            appName: getValue('LOG_APP_NAME'),
            dir: getValue('LOG_DIR', 'string', '../../logs'),
            maxFiles: getValue('LOG_MAX_FILES', 'string', '30d'),
            maxSize: getValue('LOG_MAX_SIZE', 'string', '20m'),
        },
    },
    marketingSiteBaseUrl: getValue('MARKETING_SITE_BASE_URL'),
    sentryDsn: getValue('SENTRY_DSN'),
    sentryEnabled: getValue('SENTRY_ENABLED', 'bool', false),
    webAppBaseUrl: getValue('WEB_APP_BASE_URL'),
};
function getValue(key, type = 'string', dflt = '') {
    const value = process.env[key] || process.env[`REACT_APP_${key}`];
    if (type === 'bool') {
        if (value === 'true') {
            return true;
        }
        if (value === 'false') {
            return false;
        }
        return dflt;
    }
    if (type === 'number') {
        return Number(value);
    }
    if (type === 'string') {
        return (value || dflt);
    }
    return value;
}
