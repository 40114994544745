import React from 'react'

import Container from '../Container'

import { Props } from './types'

function Checklist({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <Container {...props}>
      {children}
    </Container>
  )
}

export default React.memo(Checklist)
