import React, { useCallback } from 'react'

import { lang } from '@real-work/common'

import {
  Container,
  Divider,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  ProfileList,
  ProfileListItem,
  Text,
  useToast,
} from '@real-work/ui'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import {
  useGetWorkerByUserIdQuery,
  useDeleteWorkerSpecialtyMutation,
} from '@/services/worker'

import Link from '../../../../../components/Link'

import DocumentWrapper from '../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../components/ScreenWrapper'

function Trades(): React.ReactElement {
  const { user } = useSession()

  const navigate = useNavigate()
  const toast = useToast()

  const {
    data: { worker } = {},
    isError,
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, { skip: !user?.id })

  const [ deleteWorkerSpecialty ] = useDeleteWorkerSpecialtyMutation()

  const handleDelete = useCallback((id: string) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    deleteWorkerSpecialty({
      params: {
        id,
        userId: user.id,
      },
    })
      .unwrap()
      .then(() => {
        toast.show({
          description: lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
      })
      .catch(error => {
        toast.show({
          description: error.data?.message || lang().messages.unableToUnassignSpecialty(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    deleteWorkerSpecialty,
    toast,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Profile Trades'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
          <>
            <Container>
              <Heading>Trades</Heading>
              <Text>
                Add additional trades, or, make edits below.
              </Text>
            </Container>
            <ProfileList heading='All Trades'>
              {worker?.specialties?.map(pivot => (
                <ProfileListItem
                  key={pivot.id}
                  title={pivot.specialty?.name}
                  description={`${pivot.specialty?.trade?.name}${pivot.isPrimary ? ' - (Primary)' : ''}`}
                  onPressDelete={() => handleDelete(pivot.id)}
                  onPressEdit={() => navigate(`/profile/trades/${pivot.id}/edit`)}
                  onPressDeleteConfirmText={lang().messages.removeFromProfileConfirmation('trade')}
                />
              ))}
              <Link to='/profile/trades/add' type='button'>Add Another</Link>
              <Divider my='8' />
              <Link to='/profile/edit' variant='emphasis'>&lt; Back to Edit Profile</Link>
            </ProfileList>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Trades)
