import React from 'react'

import {
  Box,
  Divider,
  ExpandableSection,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
} from '@real-work/ui'

import Worker from '@/components/Worker'

import useSession from '@/hooks/useSession'
import { useGetFavoriteWorkersByCompanyIdQuery } from '@/services/company'

function FavoriteWorkers(): React.ReactElement {
  const session = useSession()
  const {
    data: { jobsAndGeneralRecruiting } = {},
    isLoading,
    isError,
    refetch,
  } = useGetFavoriteWorkersByCompanyIdQuery({
    params: { companyId: session?.user?.employer?.companyOwned?.id || '' },
    query: { 'job.status': 'open' },
  }, {
    refetchOnMountOrArgChange: true,
    skip: !session?.user?.employer?.companyOwned?.id,
  })

  return (
    <Box>
      {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (!jobsAndGeneralRecruiting?.length && <NoContent />) || (
        jobsAndGeneralRecruiting?.map(job => (
          <React.Fragment key={job.id}>
            <ExpandableSection title={job.title}>
              {job.favoriteWorkers?.map((favoriteWorker, index) => (
                <React.Fragment key={favoriteWorker.workerUserId}>
                  <Worker
                    onAfterUpdateCompanyFavoriteWorker={refetch}
                    worker={favoriteWorker.worker}
                  />
                  {index < job.favoriteWorkers.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </ExpandableSection>
          </React.Fragment>
        ))
      )}
    </Box>
  )
}

export default React.memo(FavoriteWorkers)
