import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import {
  DeleteUserByIdRequest,
  DeleteUserByIdResponse,
  GetUserByIdRequest,
  GetUserByIdResponse,
  PatchUserByIdRequest,
  PatchUserByIdResponse,
  PostUserRequest,
  PostUserResponse,
} from './types'

const path = 'v1/users'

const userService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    deleteUserById: build.mutation<api.AsJson<DeleteUserByIdResponse>, DeleteUserByIdRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Users' ],
    }),
    getUserById: build.query<api.AsJson<GetUserByIdResponse>, GetUserByIdRequest>({ query: ({ params }) => `${path}/${params.id}` }),
    patchUserById: build.mutation<api.AsJson<PatchUserByIdResponse>, PatchUserByIdRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'Sessions',
        'Users',
      ],
    }),
    postUser: build.mutation<api.AsJson<PostUserResponse>, PostUserRequest>({
      query: ({ body }) => ({
        url: path,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export default userService

export const {
  useDeleteUserByIdMutation,
  useGetUserByIdQuery,
  usePatchUserByIdMutation,
  usePostUserMutation,
} = userService

export const {
  endpoints: {
    deleteUserById,
    getUserById,
    patchUserById,
    postUser,
  },
} = userService
