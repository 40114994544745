"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputValueToApiValueOptional = exports.inputValueToApiValue = exports.apiValueToInputValue = exports.apiValueToDatabaseDateTime = exports.apiValueToDatabaseDateTimeNullable = exports.apiValueToDatabaseDate = exports.apiValueToDatabaseDateNullable = void 0;
const apiValueToDatabaseDateNullable = (value) => {
    if (value === null) { // eslint-disable-line no-null/no-null
        return null; // eslint-disable-line no-null/no-null
    }
    return (value && (0, exports.apiValueToDatabaseDate)(value)) || undefined;
};
exports.apiValueToDatabaseDateNullable = apiValueToDatabaseDateNullable;
const apiValueToDatabaseDate = (value) => new Date(value.replace(/T.*Z$/, 'T00:00:00.000Z'));
exports.apiValueToDatabaseDate = apiValueToDatabaseDate;
const apiValueToDatabaseDateTimeNullable = (value) => {
    if (value === null) { // eslint-disable-line no-null/no-null
        return null; // eslint-disable-line no-null/no-null
    }
    return (value && (0, exports.apiValueToDatabaseDateTime)(value)) || undefined;
};
exports.apiValueToDatabaseDateTimeNullable = apiValueToDatabaseDateTimeNullable;
const apiValueToDatabaseDateTime = (value) => new Date(value);
exports.apiValueToDatabaseDateTime = apiValueToDatabaseDateTime;
const apiValueToInputValue = (value, mode = 'date') => {
    if (!value) {
        return undefined;
    }
    if (mode === 'datetime') {
        return new Date(value);
    }
    return new Date(value.replace(/T.*Z$/, 'T00:00:00.000'));
};
exports.apiValueToInputValue = apiValueToInputValue;
const inputValueToApiValue = (value) => (value || new Date()).toISOString();
exports.inputValueToApiValue = inputValueToApiValue;
// eslint-disable-next-line no-null/no-null
const inputValueToApiValueOptional = (value) => value === null ? null : value?.toISOString();
exports.inputValueToApiValueOptional = inputValueToApiValueOptional;
