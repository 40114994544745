import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  FormikContextType,
  useFormikContext,
} from 'formik'

import log from '@real-work/common/dist/src/log/browser'

import {
  FormInput,
  HandleChange,
  InputType,
  Props,
  Value,
} from './types'

export default <T extends InputType>({
  defaultValue = undefined,
  dataType,
  onBlur = undefined,
  onChange = undefined,
  name,
}: Props<T>): FormInput<T> => {
  const formikContext = useFormikContext()

  if (!formikContext) {
    throw Error('Input components of all types must be used within a Form component.')
  }

  const {
    getFieldProps,
    initialValues,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    status,
  } = formikContext as FormikContextType<{ [name: string]: Value }>

  const value = useMemo(() => getFieldProps(name).value, [
    getFieldProps,
    name,
  ])

  const currentValue = useMemo(() => {
    if (dataType === 'array') {
      return value
    }

    if (dataType === 'boolean') {
      return value === true ?? (defaultValue !== undefined ? defaultValue : false)
    }

    if (dataType === 'date') {
      const dateValue = value ?? defaultValue

      return dateValue ? new Date(dateValue) : null // eslint-disable-line no-null/no-null
    }

    if (dataType === 'number') {
      return Number(value ?? (defaultValue || 0))
    }

    if (dataType === 'object') {
      return JSON.parse((value || '{}') as string)
    }

    return value === undefined ? (defaultValue ?? '') : value
  }, [
    defaultValue,
    dataType,
    value,
  ])

  const setValue = useCallback<HandleChange>(value => {
    let newValue = value
    if (dataType === 'object') {
      newValue = JSON.stringify(value)
    }

    if (!name) {
      log('error', '🐞 Form Input `name` not set. Cannot update form state.')
    }
    else {
      setFieldValue(name, newValue)

      const isValueInitial = (dataType === 'array' && (value === initialValues[name] || (typeof value === 'string' && value.length === 0 && initialValues[name] === undefined))) || newValue === initialValues[name]

      if (!isValueInitial) {
        setFieldError(name, undefined)
        if (status?.errors?.[name]) {
          setStatus({
            ...(status || {}),
            errors: {
              ...(status?.errors || {}),
              [name]: undefined,
            },
          })
        }
      }

      setFieldTouched(name, true)
    }

    onChange && onChange(newValue)
  }, [
    initialValues,
    dataType,
    name,
    onChange,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setStatus,
    status,
  ])

  return {
    handleBlur: onBlur,
    setValue,
    value: currentValue,
  }
}

export const isFormInput = (elem: React.ReactElement): boolean => {
  const type = ((elem.type as unknown as React.ReactElement)?.type || elem.type || {}) as { name?: string }

  return !!type.name?.match(/^Checkbox|DateTimeInput|Dropdown|FileInput|SelectOne|Switch|TextInput/)
}

export * from './types'
