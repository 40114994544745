import React from 'react'

import { url } from '@real-work/common'

import { Text } from '@real-work/ui'

import Link from '../../../../components/Link'
import PrivacyTermsLinks from '../../../../components/PrivacyTermsLinks'

function Terms() {
  return (
    <Text>By clicking “Create Account” you agree to the <PrivacyTermsLinks />. To learn more, <Link to={url.marketingSite('/feedback')} _text={{ color: 'singletons.black' }}>Contact Us</Link>.</Text>
  )
}

export default React.memo(Terms)
