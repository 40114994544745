import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import type { Models } from '@real-work/orm'

import {
  array,
  lang,
} from '@real-work/common'

import {
  Box,
  Badge,
  BulletList,
  BulletListItem,
  Button,
  ButtonGroup,
  Form,
  FormControl,
  Heading,
  HStack,
  Modal,
  Select,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useMediaQuery,
  useToast,
  VStack,
} from '@real-work/ui'

import AvatarWorker from '@/components/Avatar/Worker'
import Link from '@/components/Link'

import useSession from '@/hooks/useSession'
import useWorkerMeta from '@/hooks/useWorkerMeta'

import { useUpdateCompanyFavoriteWorkerMutation } from '@/services/companyFavoriteWorker'
import { useGetJobsQuery } from '@/services/job'

import dateTime from '@/utils/dateTime'

import {
  FormValues,
  OnSubmit,
  Props,
} from './types'

const GENERAL_RECRUITING = 'GENERAL_RECRUITING'

const expressedInterestNewThresholdDays = 7

function Worker({
  job,
  mode = 'profile',
  onAfterUpdateCompanyFavoriteWorker,
  worker,
  ...props
}: Props): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()
  const session = useSession()
  const toast = useToast()

  const {
    canContact,
    canFavorite,
    isAvailable,
    isMe,
  } = useWorkerMeta(worker)

  const [ isDesktop ] = useMediaQuery({ minWidth: 860 })
  const [ isMobile ] = useMediaQuery({ minWidth: 520 })

  const [
    isSpecialtiesExpanded,
    setIsSpecialtiesExpanded,
  ] = useState(false)

  const [
    isContactMeModalOpen,
    setIsContactMeModalOpen,
  ] = useState(false)

  const [
    isAddToFavoritesModalOpen,
    setIsAddToFavoritesModalOpen,
  ] = useState(false)

  const [
    updateCompanyFavoriteWorker,
    { isLoading: isSubmitting },
  ] = useUpdateCompanyFavoriteWorkerMutation()

  const { data: { jobs } = {} } = useGetJobsQuery({
    query: {
      companyId: session.user?.employer?.companyOwned?.id,
      perPage: -1,
    },
  }, { skip: !session.user?.employer?.companyOwned?.id })

  const isSpecialtiesExpandable = useMemo(() => worker?.specialties && worker?.specialties?.length > 1, [ worker?.specialties ])

  const specialties = useMemo(() => {
    let specialties = []

    if (isSpecialtiesExpanded) {
      specialties = worker?.specialties || []
    }
    else {
      specialties = (!!worker?.specialties?.length && [ worker.specialties[0] ]) || []
    }

    const specs = specialties.reduce((acc: Models.Specialty.WithTrade<Models.Specialty.default>[], pivot) => {
      if (!pivot.specialty) {
        return acc
      }

      acc.push(pivot.specialty)

      return acc
    }, [])

    return specs
  }, [
    worker?.specialties,
    isSpecialtiesExpanded,
  ])

  const jobTypesText = useMemo(() => {
    const jobNames = (worker?.jobTypes || []).map(jobType => jobType.option?.label).filter(jobName => !!jobName)

    return array.toListString(jobNames as string[])
  }, [ worker?.jobTypes ])

  const workShiftsText = useMemo(() => {
    if (!worker?.workShifts || worker?.workShifts?.length === 0) {
      return undefined
    }

    const workShifts = worker.workShifts.map(workShift => workShift?.option?.label).filter(workShift => !!workShift)

    return array.toListString(workShifts as string[])
  }, [ worker?.workShifts ])

  const expressedInterestAt = useMemo(() => job?.interestedWorkers?.find(interestedWorker => interestedWorker.workerUserId === worker.userId && interestedWorker.jobId === job.id)?.createdAt, [
    job,
    worker.userId,
  ])

  const isExpressedInterestNew = useMemo(() => {
    if (!expressedInterestAt) return false

    if (Math.abs(dateTime.differenceInDays(new Date(expressedInterestAt), new Date())) <= expressedInterestNewThresholdDays) return true

    return false
  }, [ expressedInterestAt ])

  const jobOptions = useMemo(() => [
    {
      label: 'General Recruiting',
      value: GENERAL_RECRUITING,
    },
    ...(jobs || []).map(job => ({
      label: job.title,
      value: job.id,
    })),
  ], [ jobs ])

  const onSaveFavoriteWorker = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!worker.userId || !session.user?.employer?.companyOwned?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    updateCompanyFavoriteWorker({
      body: { jobIds: values.jobIds.map(jobId => jobId === GENERAL_RECRUITING ? null : jobId) }, // eslint-disable-line no-null/no-null
      params: {
        companyId: session.user.employer.companyOwned?.id,
        workerUserId: worker.userId,
      },
    })
      .then(() => {
        toast.show({
          description: lang().messages.workerSavedToFavorites(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        onAfterUpdateCompanyFavoriteWorker && onAfterUpdateCompanyFavoriteWorker()

        setIsAddToFavoritesModalOpen(false)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    handleFormApiErrors,
    onAfterUpdateCompanyFavoriteWorker,
    session.user?.employer?.companyOwned?.id,
    toast,
    updateCompanyFavoriteWorker,
    worker.userId,
  ])

  const onPressContactMe = () => {
    if (!session.user) {
      toast.show({
        description: lang().messages.mustBeAuthedToContactWorker(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    setIsContactMeModalOpen(true)
  }

  return (
    <>
      <HStack
        alignItems={(mode === 'card' || mode === 'list') ? 'center' : undefined}
        flexDirection={isDesktop ? 'row' : 'column'}
        justifyContent='space-between'
        px='8'
        py='6'
        width={(mode !== 'card') && isDesktop ? '100%' : 'auto'}
        {...props}
      >
        <HStack
          flexDirection={isDesktop ? 'row' : 'column'}
          space='6'
        >
          <Link to={`/workers/${worker.userId}`}>
            <Box
              mx={isDesktop ? '0' : 'auto'}
              mb={isDesktop ? '0' : '4'}
            >
              <AvatarWorker
                mode={mode}
                worker={worker}
              />
            </Box>
          </Link>
          <VStack justifyContent='flex-start' mb={isDesktop ? '0' : '4'}>
            <VStack maxW={mode === 'card' ? '310' : '360'}>
              {expressedInterestAt && <HStack mb='2'><Text fontWeight='medium' mr='1'>Expressed Interest:</Text> <Badge colorScheme='info' variant='solid' mr='1'>{dateTime.format(new Date(expressedInterestAt))}</Badge>{isExpressedInterestNew && <Badge colorScheme='secondary' variant='solid'>NEW</Badge>}</HStack> }
              <Link to={`/workers/${worker.userId}`}><Heading textAlign={isDesktop ? 'left' : 'center'}>{worker?.user?.fullName}</Heading></Link>
              <VStack
                mb={2}
                justifyContent={isDesktop ? 'flex-start' : 'center'}
              >
                {specialties.map(specialty => (
                  <HStack key={specialty.id}>
                    <Text lineHeight='1em' fontSize={mode === 'card' ? 'md' : 'lg'} fontWeight='medium' textAlign={isDesktop ? 'left' : 'center'} my='1'>
                      {specialty.name}{specialty.trade?.name ? ` | ${specialty.trade?.name}` : ''}
                    </Text>
                  </HStack>
                ))}
                <HStack>
                  {isSpecialtiesExpandable && (
                    <Button
                      variant='link'
                      onPress={() => setIsSpecialtiesExpanded(!isSpecialtiesExpanded)}
                    >
                      {isSpecialtiesExpanded ? 'Less' : ' & More...'}
                    </Button>
                  )}
                </HStack>
              </VStack>
            </VStack>
            <VStack maxW='360' my={isDesktop ? '2' : '4'} alignItems={isDesktop ? 'flex-start' : 'center'}>
              <HStack>
                <Text mr='1'>I am currently:</Text>
                <Badge
                  variant='solid'
                  {...(isAvailable ? {
                    colorScheme: 'yellow',
                    _text: { color: '#000' },
                  } : {
                    colorScheme: 'muted',
                    _text: { color: '#000' },
                  })}
                >
                  {isAvailable ? 'Open to Work' : 'Not Open to Work'}
                </Badge>
              </HStack>
              <HStack mt='2'>
                <Text mr='1'>My profile is:</Text>
                <Badge
                  variant='solid'
                  {...(worker?.profilePublicSince ? {
                    colorScheme: 'yellow',
                    _text: { color: '#000' },
                  } : {
                    colorScheme: 'muted',
                    _text: { color: '#000' },
                  })}
                >
                  {worker?.profilePublicSince ? 'Public' : 'Private'}
                </Badge>
              </HStack>
            </VStack>

            {(mode === 'card' || mode === 'list') && (
              <HStack alignSelf={isDesktop ? 'flex-start' : 'center'} space='2' mt='2'>
                {isMe && <Link to='/profile/work-preferences/edit' variant='emphasis'>Edit Work Status &gt;</Link>}
                {canContact && (
                  <Button
                    onPress={onPressContactMe}
                    size='sm'
                    _text={{ fontSize: 'sm' }}
                  >
                    Contact Me
                  </Button>
                )}
                {canFavorite && (
                  <Button
                    onPress={() => setIsAddToFavoritesModalOpen(true)}
                    size='sm'
                    _text={{ fontSize: 'sm' }}
                  >
                    + Favorites
                  </Button>
                )}
              </HStack>
            )}
          </VStack>
        </HStack>
        {(mode === 'profile' || mode === 'list') && (
          <BulletList>
            {worker?.displayLocation && <BulletListItem><Text fontWeight='medium'>Location:</Text><Text>{worker.displayLocation}</Text></BulletListItem>}
            {worker?.travelDistance?.label && <BulletListItem><Text fontWeight='medium'>Travel:</Text><Text>{worker.travelDistance.label}</Text></BulletListItem>}
            {jobTypesText && <BulletListItem><Text fontWeight='medium'>Open to:</Text><Text>{jobTypesText}</Text></BulletListItem>}
            {worker?.availableStartingAtText && <BulletListItem><Text fontWeight='medium'>Within:</Text><Text>{worker?.availableStartingAtText}</Text></BulletListItem>}
            {worker?.hourlyRate && <BulletListItem><Text fontWeight='medium'>Compensation:</Text><Text>{`$${worker.hourlyRate}/hr`}</Text></BulletListItem>}
            {workShiftsText && <BulletListItem><Text fontWeight='medium'>Preferred Hours:</Text><Text>{workShiftsText}</Text></BulletListItem>}
          </BulletList>
        )}
      </HStack>

      {mode === 'profile' && (
        <HStack width='100%' backgroundColor='muted.200' py='2' mt='auto' flexDirection='row' justifyContent='center' position='relative' height='65px' space='4'>
          {canContact && (
            <Button
              onPress={onPressContactMe}
              size='sm'
              _text={{ fontSize: 'md' }}
            >
              Contact Me
            </Button>
          )}
          {canFavorite && (
            <Button
              onPress={() => setIsAddToFavoritesModalOpen(true)}
              size='sm'
              _text={{ fontSize: 'md' }}
            >
              + Favorites
            </Button>
          )}
        </HStack>
      )}

      {session.user && canContact && (
        <Modal isOpen={isContactMeModalOpen} onClose={() => setIsContactMeModalOpen(false)}>
          <Modal.Content maxWidth='400px'>
            <Modal.CloseButton />
            <Modal.Header>Contact Me</Modal.Header>
            <Modal.Body>
              <HStack>
                <Text fontWeight='medium' mr='1'>Real Worker:</Text>
                <Text>{worker?.user?.fullName}</Text>
              </HStack>
              <HStack>
                <Text fontWeight='medium' mr='1'>Email:</Text>
                <Link
                  to={`mailto:${worker?.user?.email}`}
                  isExternal={false}
                >
                  <Text maxWidth={isMobile ? 300 : 180} underline={true}>
                    {worker?.user?.email}
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Text fontWeight='medium' mr='1'>Phone:</Text>
                <Text>{worker?.user?.phone}</Text>
              </HStack>
            </Modal.Body>
            <Modal.Footer>
              <ButtonGroup space={2}>
                <Button
                  variant='outline'
                  colorScheme='primary'
                  onPress={() => setIsContactMeModalOpen(false)}
                >
                  Close
                </Button>
              </ButtonGroup>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
      {canFavorite && (
        <Modal isOpen={isAddToFavoritesModalOpen} onClose={() => setIsAddToFavoritesModalOpen(false)}>
          <Form<FormValues>
            initialValues={{ jobIds: worker.favoritedOns?.map(job => job.jobId === null ? GENERAL_RECRUITING : job.jobId) || [] }} // eslint-disable-line no-null/no-null
            onSubmit={onSaveFavoriteWorker}
          >
            <Modal.Content maxWidth='400px'>
              <Modal.CloseButton />
              <Modal.Header>Add to Favorites</Modal.Header>
              <Modal.Body>
                <VStack>
                  <FormControl
                    name='jobIds'
                    label='Choose one or more jobs'
                  >
                    <Select
                      isMulti={true}
                      options={jobOptions}
                      isSearchable={false}
                      pillsLocation='outside'
                    />
                  </FormControl>
                </VStack>
              </Modal.Body>
              <Modal.Footer>
                <ButtonGroup space={2}>
                  <SubmitButton colorScheme='primary' isLoading={isSubmitting}>
                    Save
                  </SubmitButton>
                </ButtonGroup>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        </Modal>
      )}
    </>
  )
}

export default React.memo(Worker)

export * from './types'
