import React from 'react'
import { Image as NbImage } from 'native-base'

import {
  Props,
  ImageSvgProps,
} from './types'

function Image({
  source,
  ...props
}: Props): React.ReactElement {
  if (typeof source === 'function') {
    // Imported SVG file
    return React.createElement(
      source,
      props as ImageSvgProps,
    )
  }

  return (
    <NbImage
      {...props}
      source={source}
    />
  )
}

export default React.memo(Image)

export * from './types'
