import realWorkApi from '../../../state/apis/realWork'

import {
  EmailVerificationRequest,
  EmailVerificationResponse,
  EmailVerificationSendRequest,
  EmailVerificationSendResponse,
} from './types'

const path = 'v1/users/:userId/email-verification'

const userEmailVerificationService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    postUserEmailVerificationSend: build.mutation<EmailVerificationSendResponse, EmailVerificationSendRequest>({
      query: ({ params }) => ({
        url: `${path.replace(':userId', params.userId)}/send`,
        method: 'POST',
      }),
    }),
    postUserEmailVerificationVerify: build.mutation<EmailVerificationResponse, EmailVerificationRequest>({
      invalidatesTags: [ 'Sessions' ],
      query: ({
        body,
        params,
      }) => ({
        url: `${path.replace(':userId', params.userId)}/verify`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export default userEmailVerificationService

export const {
  usePostUserEmailVerificationSendMutation,
  usePostUserEmailVerificationVerifyMutation,
} = userEmailVerificationService

export const {
  endpoints: {
    postUserEmailVerificationSend,
    postUserEmailVerificationVerify,
  },
} = userEmailVerificationService
