import React, { useState } from 'react'

import {
  Box,
  Container,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  PlanCards,
  PlanCardsTypes,
  Text,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useCompanySubscription from '@/hooks/useCompanySubscription'

import { useGetSubscriptionPlansQuery } from '@/services/subscriptionPlan'

function SubscriptionsEdit(): React.ReactElement {
  const { subscription } = useCompanySubscription()

  const {
    data: { subscriptionPlans } = {},
    isLoading,
  } = useGetSubscriptionPlansQuery()

  const [
    selectedPlan,
    setSelectedPlan,
  ] = useState<PlanCardsTypes.PlanSlug | undefined>()

  return (
    <DocumentWrapper title='Real Work | Edit Subscription'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (subscriptionPlans && (
          <>
            <Container>
              <Box textAlign='center' maxWidth='640px' alignSelf='center' mb='6'>
                <Heading mb='6'>My Account</Heading>
                <Text>
                  To choose a different plan select the option you want below..
                </Text>
                <Text color='primary.600' fontWeight='semibold' fontSize={20} fontStyle='italic'>
                  Team plans are coming soon!
                </Text>
              </Box>
            </Container>
            <PlanCards
              currentPlan={subscription?.plan.slug}
              plans={subscriptionPlans}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
          </>
        )) || <LoadingFailed />}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SubscriptionsEdit)
