import React from 'react'
import { Pressable as NbPressable } from 'native-base'

import { Props } from './types'

function Pressable({
  children,
  hitSlop = 10,
  isDisabled = false,
  ...props
}: Props): React.ReactElement {
  return (
    <NbPressable
      disabled={isDisabled}
      hitSlop={hitSlop}
      {...props}
    >
      {children}
    </NbPressable>
  )
}

export default React.memo(Pressable)

export * from './types'
