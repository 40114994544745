import {
  useCallback,
  useEffect,
} from 'react'

import { lang } from '@real-work/common'

import { useToast } from '@real-work/ui'

import useLocalStorage from '@/hooks/useLocalStorage'
import useNavigate from '@/hooks/useNavigate'

import {
  useDeleteSessionMutation,
  useGetSessionQuery,
  usePostSessionMutation,
} from '@/services/session'

function useSession() {
  const navigate = useNavigate()
  const toast = useToast()

  const [
    accessToken,
    setAccessToken,
  ] = useLocalStorage<string | undefined>('accessToken', undefined)

  const [
    sessionId,
    setSessionId,
  ] = useLocalStorage<string | undefined>('sessionId', undefined)

  const {
    data,
    error,
    isFetching: isFetchingSession,
    isLoading: isLoadingSession,
    refetch: refetchSession,
  } = useGetSessionQuery({ params: { sessionId: sessionId || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !sessionId || !accessToken,
  })

  const [
    postSession,
    { isLoading: isLoadingSignIn },
  ] = usePostSessionMutation()

  const signIn = useCallback(
    async (credentials: {
      email: string,
      password: string,
    }) => postSession({ body: credentials })
      .unwrap()
      .then(async response => {
        setAccessToken(response.accessToken)
        setSessionId(response.sessionId)

        return response
      }),
    [
      postSession,
      setAccessToken,
      setSessionId,
    ],
  )

  const [
    deleteSession,
    { isLoading: isLoadingSignOut },
  ] = useDeleteSessionMutation()

  const signOut = useCallback(async () => {
    if (!sessionId) {
      return Promise.resolve()
    }

    const response = await deleteSession({ params: { sessionId: sessionId || '' } })

    setAccessToken(undefined)
    setSessionId(undefined)

    return response
  }, [
    deleteSession,
    sessionId,
    setAccessToken,
    setSessionId,
  ])

  useEffect(() => {
    if (!error) {
      return
    }

    const flushSession = async () => {
      setAccessToken(undefined)
      setSessionId(undefined)

      toast.show({
        description: lang().messages.genericErrorHeading(),
        title: lang().messages.sessionExpired(),
        type: 'error',
      })

      navigate('/sign-in')
    }

    flushSession()
  }, [
    error,
    navigate,
    setAccessToken,
    setSessionId,
    signOut,
    toast,
  ])

  return {
    ...data,
    isFetchingSession,
    isLoadingSession,
    isLoadingSignIn,
    isLoadingSignOut,
    refetchSession,
    setSessionId,
    signIn,
    signOut,
  }
}

export default useSession
