import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  api,
  lang,
} from '@real-work/common'

import {
  Box,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  Image,
  Input,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import { usePostUserMutation } from '@/services/user'

import Terms from '../components/Terms'

import headerImage from './assets/header-image-scaffold.jpg'

import {
  FormValues,
  OnSubmit,
} from './types'

function SignUpWorker(): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()
  const navigate = useNavigate()
  const {
    isLoadingSignIn,
    signIn,
  } = useSession()

  const [
    postUser,
    { isLoading: isPostUserLoading },
  ] = usePostUserMutation()

  const isLoading = useMemo(() => isLoadingSignIn || isPostUserLoading, [
    isLoadingSignIn,
    isPostUserLoading,
  ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    postUser({ body: values })
      .unwrap()
      .then(() => {
        signIn({
          email: values.email,
          password: values.password,
        })
          .then(() => {
            navigate('/dashboard')
          })
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    navigate,
    postUser,
    signIn,
  ])

  return (
    <DocumentWrapper title='Real Work | Realworker Sign Up'>
      <ScreenWrapper hasHeroImage={true}>
        <Image
          alt='Construction Work'
          source={headerImage}
          width='100%'
          height='330'
          mb='6'
          alignSelf='center'
        />

        <Container>
          <Heading>Welcome! Create a free Real Worker account</Heading>
          <Text>
            Share your details and begin building the life you want.
          </Text>
        </Container>

        <Container mt='4'>
          <Heading size='md' color='brand.primary'>Real Worker Details</Heading>
          <Divider />
          <Form<FormValues>
            initialValues={{
              email: '',
              fullName: '',
              password: '',
              phone: '',
              role: 'worker',
            }}
            rules={api.endpoints.users.post.validation.body.rules}
            onSubmit={onSubmit}
          >
            <FormControl
              name='fullName'
              label='Full Name'
            >
              <Input
                type='text'
              />
            </FormControl>
            <FormControl
              name='email'
              label='Email'
            >
              <Input
                type='email'
              />
            </FormControl>
            <FormControl
              name='phone'
              label='Phone'
            >
              <Input
                type='phone'
              />
            </FormControl>
            <FormControl
              name='zipCode'
              label='Zip Code'
            >
              <Input
                type='zipCode'
              />
            </FormControl>
            <FormControl
              name='password'
              label='Password'
            >
              <Text fontSize='md' mb='1'>{lang().validation.passwordHelperText()}</Text>
              <Input
                type='password'
              />
            </FormControl>
            <Terms />
            <Box flexDirection='row' mt='4' mb='4'>
              <SubmitButton isLoading={isLoading}>Create Account</SubmitButton>
            </Box>
          </Form>
        </Container>
        <Container>
          <Box flexDirection='row' mb='5'>
            <Text>
              Already have an account?&nbsp;
            </Text>
            <Link to='/sign-in' variant='emphasis'>
              Sign In &gt;
            </Link>
          </Box>
          <Divider mt='4' mb='8' />
          <Heading size='md' color='brand.primary'>Employers</Heading>
          <Text mb='8'>Looking for your ideal Real Worker?</Text>
          <Link type='button' to='/sign-up/employer'>Employer Sign Up</Link>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SignUpWorker)
