import React from 'react'
import { Badge as NbBadge } from 'native-base'

import { Props } from './types'

function Badge({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbBadge {...props}>{children}</NbBadge>
  )
}

export default React.memo(Badge)

export * from './types'
