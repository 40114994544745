import React, {
  useCallback,
  useMemo,
} from 'react'
import { FormControl as NBFormControl } from 'native-base'
import {
  FormikContextType,
  useFormikContext,
} from 'formik'

import type { Value } from '../../hooks/useFormInput'

import useFormError from '../../hooks/useFormError'

import HStack from '../HStack'
import InputError from '../InputError'
import Text from '../Text'
import Tooltip from '../Tooltip'
import TooltipModal from '../TooltipModal'
import VStack from '../VStack'

import type { Props } from './types'

function FormControl({
  children: childrenProp,
  dataType = undefined,
  label,
  labelNote,
  name,
  tooltip,
  tooltipModal,
  ...props
}: Props): React.ReactElement {
  const { isValid } = useFormError(name)

  const {
    initialValues,
    submitForm,
  } = useFormikContext() as FormikContextType<{ [name: string]: Value }>

  const onInputKeyPress = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && event.currentTarget.nodeName.toLowerCase() !== 'textarea') {
      submitForm()
    }
  }, [ submitForm ])

  const children = useMemo(() => React.Children.map(childrenProp, (child: React.ReactNode) => {
    let injectedProps = {}
    if (dataType === 'array') {
      // example name: 'inputarrays.0'
      const nameSlices = name.split('.')
      if (nameSlices.length === 2) {
        const index = parseInt(nameSlices[1], 10)
        injectedProps = {
          onKeyPress: onInputKeyPress,
          defaultValue: ((initialValues[nameSlices[0]] as Value[]) && (initialValues[nameSlices[0]] as Value[])[index]) || undefined,
          dataType,
          index,
          name,
        }
      }
    }
    else {
      injectedProps = {
        onKeyPress: onInputKeyPress,
        defaultValue: initialValues[name] || undefined,
        dataType,
        name,
      }
    }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, injectedProps)
    }

    return child
  }), [
    childrenProp,
    dataType,
    initialValues,
    name,
    onInputKeyPress,
  ])

  return (
    <NBFormControl
      isInvalid={!isValid}
      {...props}
    >
      <VStack
        {...props}
        mb='2'
      >
        {label && (
          <HStack alignItems='center'>
            <NBFormControl.Label flexShrink='1'>{label}</NBFormControl.Label>
            {labelNote && (<Text fontSize='md' ml='1'>{labelNote}</Text>)}
            {tooltip && <Tooltip iconProps={{ ml: '1' }} {...tooltip} />}
            {tooltipModal && <TooltipModal ml='1' {...tooltipModal} />}
          </HStack>
        )}

        {children}

        <InputError name={name} />
      </VStack>
    </NBFormControl>
  )
}

export default React.memo(FormControl)

export * from './types'
