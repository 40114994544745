import React from 'react'
import { VStack as NbVStack } from 'native-base'

import { Props } from './types'

function VStack({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbVStack {...props}>{children}</NbVStack>
  )
}

export default React.memo(VStack)

export * from './types'
