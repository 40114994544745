import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import type { GetAllSpecialtiesResponse } from './types'

const path = 'v1/specialties'

const specialtyService = realWorkApi.injectEndpoints({ endpoints: build => ({ getAllSpecialties: build.query<api.AsJson<GetAllSpecialtiesResponse>, void>({ query: () => ({ url: path }) }) }) })

export default specialtyService

export const { useGetAllSpecialtiesQuery } = specialtyService

export const { endpoints: { getAllSpecialties } } = specialtyService
