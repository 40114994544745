import React, { useMemo } from 'react'
import {
  lang,
  querystring,
  url,
} from '@real-work/common'
import {
  Box,
  Container,
  Divider,
  Heading,
  HStack,
  Image,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
  SectionHeader,
  Text,
  useMediaQuery,
  VStack,
  WorkerProfileCompletenessProgress,
} from '@real-work/ui'

import type { RequestQuery } from '@real-work/common/src/api/endpoints/jobs/get/types'

import DocumentWrapper from '@/components/DocumentWrapper'
import Job from '@/components/Job'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'
import Worker from '@/components/Worker'

import useSession from '@/hooks/useSession'

import {
  useGetJobsQuery,
  useGetUpdatedJobsQuery,
} from '@/services/job'
import { useGetWorkerByUserIdQuery } from '@/services/worker'
import { useGetWorkerTipOfTheWeekQuery } from '@/services/workerTip'

import dateTime from '@/utils/dateTime'

import feedback from '../assets/giveFeedback.svg'
import findJobs from '../assets/findWorkers.svg'
import jobMatches from '../assets/jobMatches.svg'
import jobUpdates from '../assets/jobUpdates.svg'
import profile from '../assets/profile.svg'
import profileStats from '../assets/profileStats.svg'
import proTipBulb from '../assets/proTipBulb.svg'
import share from '../assets/share.svg'
import shortcuts from '../assets/shortcuts.svg'
import support from '../assets/contactSupport.svg'

function DashboardWorker(): React.ReactElement {
  const [ breakpoint ] = useMediaQuery({ minWidth: 1000 })
  const { user } = useSession()

  const {
    data: { jobs: updatedJobs } = {},
    isLoading: isUpdatedJobsLoading,
  } = useGetUpdatedJobsQuery({
    query: {
      iAmInterested: true,
      perPage: 5,
    },
  }, { refetchOnMountOrArgChange: true })

  const matchingJobsQuery: RequestQuery = useMemo(() => ({
    status: 'open',
    ...((user?.worker?.specialties && { specialtyIds: user?.worker?.specialties?.map(specialty => specialty.specialtyId) }) || {}),
    ...((user?.worker?.travelDistance?.value && { travelDistance: Number(user?.worker?.travelDistance?.value) }) || {}),
    travelDistanceFromZip: user?.worker?.zipCode,
    perPage: 5,
  }), [
    user?.worker?.specialties,
    user?.worker?.travelDistance?.value,
    user?.worker?.zipCode,
  ])

  const {
    data: { jobs: matchingJobs } = {},
    isLoading: isMatchingJobsLoading,
  } = useGetJobsQuery({ query: matchingJobsQuery }, { refetchOnMountOrArgChange: true })

  const {
    data: { worker } = {},
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !user?.id,
  })

  const { data: { workerTip } = {} } = useGetWorkerTipOfTheWeekQuery()

  const percentComplete = worker?.profileCompleteness?.percentComplete || 0

  const numCertificationsExpiringWithin12Months = useMemo(
    () => worker?.certifications?.filter(certification => certification.expiresAt && dateTime.parseISO(certification.expiresAt) <= dateTime.addMonths(new Date(), 12)).length,
    [ worker?.certifications ],
  )

  const numLicensesExpiringWithin12Months = useMemo(
    () => worker?.licenses?.filter(license => license.expiresAt && dateTime.parseISO(license.expiresAt) <= dateTime.addMonths(new Date(), 12)).length,
    [ worker?.licenses ],
  )

  const matchingJobsUrlQuery = { specialtyIds: user?.worker?.specialties?.map(specialty => specialty.specialtyId).join(',') }

  return (
    <DocumentWrapper title='Real Work | Worker Dashboard'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (worker && (
          <>
            <Container>
              <HStack
                justifyContent='space-between'
                alignItems='center'
                width='100%'
                flexDir={breakpoint ? 'row' : 'column-reverse'}
              >
                <Worker
                  mode='card'
                  worker={worker}
                />
                {workerTip
                && (
                  <Box>
                    <HStack borderColor='muted.200' borderWidth='1' borderRadius={20} p='4'>
                      <Box mr='4'>
                        <Image
                          source={proTipBulb}
                          alt='Real Work'
                          width={36}
                          height={43}
                        />
                      </Box>
                      <VStack flexShrink='initial'>
                        <Heading size='sm' textTransform='uppercase'>Pro Tip of the Week</Heading>
                        <Text>{workerTip.text}</Text>
                        <Link to={workerTip.linkPath} variant='emphasis'>{workerTip.callToAction} &gt;</Link>
                      </VStack>
                    </HStack>
                  </Box>
                )}
              </HStack>
            </Container>
            <SectionHeader
              container={{ variant: 'large' }}
              image={{
                source: profileStats,
                height: 10,
                width: 10,
                alt: 'Profile Stats Section Icon',
              }}
              title='Profile Stats'
            />
            <Container>
              <Heading size='sm' textTransform='uppercase' mt='2'>Your profile is {percentComplete}% complete</Heading>
              <HStack flexDirection={breakpoint ? 'row' : 'column'} space='4' alignItems={breakpoint ? 'center' : 'flex-start'} width='100%'>
                <Box flex='1' width='100%'>
                  <WorkerProfileCompletenessProgress percentComplete={percentComplete} />
                </Box>
                <Link to='/profile/edit' type='button'>{percentComplete < 100 ? 'Complete' : 'Edit'} Profile</Link>
              </HStack>
              <HStack flexDirection={breakpoint ? 'row' : 'column'} space='12' alignItems={breakpoint ? 'center' : 'flex-start'} width='100%' mb='2' mt='6'>
                <VStack>
                  <Heading size='sm' textTransform='uppercase' mb='2'>Certifications in your Profile</Heading>
                  {(!worker.certifications?.length && (
                    <HStack alignItems='center' space='4'>
                      <Text>You have no certifications in your profile.</Text>
                      <Link to='/profile/certifications/add' variant='emphasis'>Add Certification &gt;</Link>
                    </HStack>
                  )) || (
                    <HStack alignItems='center' space='4'>
                      <Text variant='heroNumber'>{numCertificationsExpiringWithin12Months}</Text>
                      <Text>Expire{numCertificationsExpiringWithin12Months === 1 ? 's' : ''} within 12 months</Text>
                      <Link to='/profile/certifications' variant='emphasis'>View Certifications &gt;</Link>
                    </HStack>
                  )}
                </VStack>
                <Divider orientation={breakpoint ? 'vertical' : 'horizontal'} my='6' />
                <VStack>
                  <Heading size='sm' textTransform='uppercase' mb='2'>Licenses in your Profile</Heading>
                  {(!worker.licenses?.length && (
                    <HStack alignItems='center' space='4'>
                      <Text>You have no licenses in your profile.</Text>
                      <Link to='/profile/licenses/add' variant='emphasis'>Add License &gt;</Link>
                    </HStack>
                  )) || (
                    <HStack alignItems='center' space='4'>
                      <Text variant='heroNumber'>{numLicensesExpiringWithin12Months}</Text>
                      <Text>Expire{numLicensesExpiringWithin12Months === 1 ? 's' : ''} within 12 months</Text>
                      <Link to='/profile/licenses' variant='emphasis'>View Licenses &gt;</Link>
                    </HStack>
                  )}
                </VStack>
              </HStack>
            </Container>
            <SectionHeader
              container={{ variant: 'large' }}
              image={{
                source: jobMatches,
                height: 12,
                width: 12,
                alt: 'Job Matches Section Icon',
              }}
              title='Job Matches'
            />
            <Container mb='4'>
              {(isMatchingJobsLoading && <LoadingIndicator />) || (matchingJobs && (
                (matchingJobs.length === 0 && (
                  <NoContent isWholeScreen={false}>
                    {lang().messages.noItemsToShow('jobs')}
                  </NoContent>
                )) || (
                  <>
                    {matchingJobs.map(job => (
                      <React.Fragment key={job.id}>
                        <Job job={job} />
                        <Divider />
                      </React.Fragment>
                    ))}
                    <Link mt='2' to={`/jobs?${querystring.stringify(matchingJobsUrlQuery)}`} variant='emphasis'>View Jobs &gt;</Link>
                  </>
                )
              )) || <LoadingFailed />}
            </Container>
            <SectionHeader
              container={{ variant: 'large' }}
              image={{
                source: jobUpdates,
                height: 9,
                width: 12,
                alt: 'Job Updates Section Icon',
              }}
              title='Job Updates'
            />
            <Container mb='4'>
              {(isUpdatedJobsLoading && <LoadingIndicator />) || (updatedJobs && (
                (updatedJobs.length === 0 && (
                  <NoContent isWholeScreen={false}>
                    {lang().messages.noItemsToShow('jobs')}
                  </NoContent>
                )) || (
                  <>
                    {updatedJobs.map(job => (
                      <React.Fragment key={job.id}>
                        <Job job={job} />
                        <Divider />
                      </React.Fragment>
                    ))}
                    <Link mt='2' to='/jobs' variant='emphasis'>View Jobs &gt;</Link>
                  </>
                )
              )) || <LoadingFailed />}
            </Container>
            <SectionHeader
              container={{ variant: 'large' }}
              image={{
                source: shortcuts,
                height: 10,
                width: 10,
                alt: 'Shortcuts Section Icon',
              }}
              title='Shortcuts'
            />
            <Container>
              <HStack alignSelf='center' flexWrap='wrap' justifyContent='center' my='4'>
                <Link
                  to='/jobs'
                  type='squareIconButton'
                  icon={{
                    source: findJobs,
                    alt: 'Jobs Icon',
                  }}
                >Find{'\n'}Jobs
                </Link>
                <Link
                  to={`/workers/${user?.id}`}
                  type='squareIconButton'
                  icon={{
                    source: profile,
                    alt: 'Profile Icon',
                  }}
                >Review{'\n'}Profile
                </Link>
                <Link
                  to={url.marketingSite('/')}
                  isExternal={true}
                  type='squareIconButton'
                  icon={{
                    source: share,
                    alt: 'Share Icon',
                  }}
                >Share{'\n'}Real Work
                </Link>
                <Link
                  isExternal={true}
                  to={url.marketingSite('/feedback')}
                  type='squareIconButton'
                  icon={{
                    source: feedback,
                    alt: 'Feedback Icon',
                  }}
                >Give{'\n'}Feedback
                </Link>
                <Link
                  to={url.marketingSite('/support')}
                  type='squareIconButton'
                  icon={{
                    source: support,
                    alt: 'Support Icon',
                  }}
                >Contact{'\n'}Support
                </Link>
              </HStack>
            </Container>
          </>
        )) || <LoadingFailed />}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DashboardWorker)
