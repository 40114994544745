import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import type {
  TriggerWeeklyDigestEmployerResponse,
  TriggerWeeklyDigestWorkerResponse,
} from './types'

const path = 'v1/dev'

const devService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    triggerWeeklyDigestEmployer: build.mutation<api.AsJson<TriggerWeeklyDigestEmployerResponse>, void>({
      query: () => ({
        url: `${path}/trigger-weekly-digest-employer`,
        method: 'POST',
      }),
    }),
    triggerWeeklyDigestWorker: build.mutation<api.AsJson<TriggerWeeklyDigestWorkerResponse>, void>({
      query: () => ({
        url: `${path}/trigger-weekly-digest-worker`,
        method: 'POST',
      }),
    }),
  }),
})

export default devService

export const {
  useTriggerWeeklyDigestEmployerMutation,
  useTriggerWeeklyDigestWorkerMutation,
} = devService

export const {
  endpoints: {
    triggerWeeklyDigestEmployer,
    triggerWeeklyDigestWorker,
  },
} = devService
