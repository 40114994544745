import React, {
  useCallback,
  useState,
} from 'react'

import {
  Box,
  Button,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
  VStack,
} from '@real-work/ui'

import { useGetEventNotificationsQuery } from '@/services/eventNotification'

import { hasMorePages } from '@/utils/pagination'

import Notification from './Notification'

function Notifications() {
  const [
    page,
    setPage,
  ] = useState(1)

  const {
    data: {
      eventNotifications,
      pagination,
    } = {},
    isError,
    isLoading,
    isFetching,
  } = useGetEventNotificationsQuery(
    {
      query: {
        deliveryMethod: 'app',
        perPage: 5,
        page,
      },
    },
    {
      pollingInterval: 60000, // ms
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  )

  const onClickShowMoreButton = useCallback(() => setPage(prevPage => (prevPage || 1) + 1), [])

  return (isError && <LoadingFailed />) || (isLoading && <LoadingIndicator />) || (
    <VStack maxH='400'>
      {(eventNotifications || []).length === 0 ? <NoContent isWholeScreen={false} alignSelf='center'>Nothing to show.</NoContent> : (eventNotifications || []).map(eventNotification => (
        <Notification
          key={eventNotification.id}
          eventNotification={eventNotification}
        />
      ))}
      {hasMorePages(pagination) && (
        <Box mx='auto' mt='6'>
          <Button
            isLoading={isFetching}
            onPress={onClickShowMoreButton}
          >
            Show More
          </Button>
        </Box>
      )}
    </VStack>
  )
}

export default React.memo(Notifications)
