import React, { useState } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import { url } from '@real-work/common'
import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  PlanCards,
  PlanCardsTypes,
  Text,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'

import { useGetSubscriptionPlansQuery } from '@/services/subscriptionPlan'

import {
  setSubscriptionPlanSlug,
  signUpSelectors,
} from '@/state/slices/signUp'

function SignUpEmployerChoosePlan(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const reduxSubscriptionPlanSlug = useSelector(signUpSelectors.subscriptionPlanSlug)

  const [
    selectedPlan,
    setSelectedPlan,
  ] = useState<PlanCardsTypes.PlanSlug | undefined>(reduxSubscriptionPlanSlug)

  const {
    data: { subscriptionPlans } = {},
    isLoading,
  } = useGetSubscriptionPlansQuery()

  return (
    <DocumentWrapper title='Real Work | Employer Sign Up'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (subscriptionPlans && (
          <>
            <Container>
              <Box textAlign='center' maxWidth='640px' alignSelf='center' mb='6'>
                <Heading mb='6'>Choose Your Plan</Heading>
                <Text>
                  Real Work offers plans to fit the requirements and scale of Employers.
                </Text>
                <Text color='primary.600' fontWeight='semibold' fontSize={20} fontStyle='italic'>
                  Team accounts are coming soon!
                </Text>
              </Box>
            </Container>
            <PlanCards
              selectedPlan={selectedPlan}
              plans={subscriptionPlans}
              setSelectedPlan={setSelectedPlan}
            />
            <Container>
              <Text textAlign='center' maxWidth='480' alignSelf='center' my='6'>Need different account type to support your team? <Link to={url.marketingSite('/feedback')} _text={{ color: 'black' }}>Reach out</Link>!
              </Text>
              <Button
                isDisabled={!selectedPlan}
                onPress={() => {
                  dispatch(setSubscriptionPlanSlug(selectedPlan))

                  navigate('/sign-up/employer/details')
                }}
              >
                Continue
              </Button>
              <Box flexDirection='row' mt='5'>
                <Text>
                  Already have an account?&nbsp;
                </Text>
                <Link to='/sign-in' variant='emphasis'>
                  Sign In &gt;
                </Link>
              </Box>
              <Divider mt='8' mb='6' />
              <Heading size='md' color='brand.primary'>Real Workers</Heading>
              <Text mb='8'>Ready to find work that fits your life?</Text>
              <Link type='button' to='/sign-up/worker'>Real Worker Sign Up</Link>
            </Container>
          </>
        )) || <LoadingFailed />}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SignUpEmployerChoosePlan)
