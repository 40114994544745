import React, { useCallback } from 'react'

import { lang } from '@real-work/common'
import {
  Container,
  Divider,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  ProfileList,
  ProfileListItem,
  Text,
  useToast,
} from '@real-work/ui'

import {
  useGetWorkerByUserIdQuery,
  useDeleteWorkExperienceMutation,
} from '@/services/worker'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import Link from '../../../../../components/Link'

import DocumentWrapper from '../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../components/ScreenWrapper'

function WorkExperience(): React.ReactElement {
  const navigate = useNavigate()
  const toast = useToast()

  const { user } = useSession()

  const {
    data,
    isError,
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !user?.id,
  })
  const [ deleteWorkExperience ] = useDeleteWorkExperienceMutation()

  const handleDelete = useCallback((id: string) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    deleteWorkExperience({
      params: {
        userId: user.id,
        id,
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
      })
      .catch(error => {
        toast.show({
          description: error.data?.message || lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    deleteWorkExperience,
    toast,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Work Experience'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
          <>
            <Container>
              <Heading>Work Experience</Heading>
              <Text>
                Add additional Work Experience, or, make edits below.
              </Text>
            </Container>
            <ProfileList heading='All Work Experience'>
              {data?.worker.workExperiences?.map(workExperience => (
                <ProfileListItem
                  key={workExperience.id}
                  title={workExperience.title}
                  description={workExperience.company}
                  onPressDelete={() => handleDelete(workExperience.id)}
                  onPressEdit={() => navigate(`/profile/work-experiences/${workExperience.id}/edit`)}
                  onPressDeleteConfirmText={lang().messages.removeFromProfileConfirmation('work experience')}
                />
              ))}
              <Link to='/profile/work-experiences/add' type='button'>Add Another</Link>
              <Divider my='8' />
              <Link to='/profile/edit' variant='emphasis'>&lt; Back to Edit Profile</Link>
            </ProfileList>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(WorkExperience)
