import React, { useCallback } from 'react'
import { Switch as NbSwitch } from 'native-base'

import useFormInput from '../../hooks/useFormInput'

import {
  OnChange,
  Props,
} from './types'

function Switch({
  name,
  onChange: onChangeProp,
  ...props
}: Props): React.ReactElement {
  const {
    setValue,
    value,
  } = useFormInput({
    dataType: 'boolean',
    name,
  })

  const onChange = useCallback<OnChange>(isSelected => {
    setValue && setValue(isSelected)

    onChangeProp && onChangeProp(isSelected)
  }, [
    onChangeProp,
    setValue,
  ])

  return (
    <NbSwitch
      {...props}
      isChecked={Boolean(value)}
      onToggle={onChange}
    />
  )
}

export default React.memo(Switch)

export * from './types'
