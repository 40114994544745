import React, {
  useCallback,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import type { Models } from '@real-work/orm'
import {
  makeMockEmployerUser,
  makeMockWorkerUser,
} from '@real-work/orm/dist/src/mocks/user'

import { lang } from '@real-work/common'

import {
  Box,
  Button,
  Pressable,
  Text,
  useToast,
} from '@real-work/ui'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import {
  useTriggerWeeklyDigestEmployerMutation,
  useTriggerWeeklyDigestWorkerMutation,
} from '@/services/dev'
import sessionService from '@/services/session'

import store from '@/state'

import styles from './styles'

const MOCK_ACCESS_TOKEN = 'abc123'
const MOCK_SESSION_ID = 'abc123'

const useAppDispatch: () => typeof store.dispatch = useDispatch

function DevMenu(): React.ReactElement {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { setSessionId } = useSession()
  const toast = useToast()

  const [
    triggerWeeklyDigestEmployer,
    { isLoading: isLoadingTriggerWeeklyDigestEmployer },
  ] = useTriggerWeeklyDigestEmployerMutation()

  const [
    triggerWeeklyDigestWorker,
    { isLoading: isLoadingTriggerWeeklyDigestWorker },
  ] = useTriggerWeeklyDigestWorkerMutation()

  const [
    isExpanded,
    setIsExpanded,
  ] = useState(false)

  const toggle = useCallback(() => setIsExpanded(prev => !prev), [])

  const confirm = useCallback((callback: () => void, content?: string) => () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(content)) {
      return
    }

    callback()
  }, [])

  const onPress = useCallback((callback: () => void) => () => {
    setIsExpanded(false)

    callback()
  }, [])

  const signInAsMockUser = (mockUser: Models.User.SessionUser) => {
    setSessionId(MOCK_SESSION_ID)

    dispatch(
      sessionService.util.updateQueryData('getSession', { params: { sessionId: MOCK_SESSION_ID } }, () => ({
        accessToken: MOCK_ACCESS_TOKEN,
        sessionId: MOCK_SESSION_ID,
        user: mockUser,
      })),
    )
  }

  const signInAsMockPublishedWorker = () => {
    signInAsMockUser(makeMockWorkerUser() as Models.User.SessionUser)
  }

  const signInAsMockUnpublishedWorker = () => {
    signInAsMockUser(makeMockWorkerUser(() => ({ worker: { profilePublishedAt: undefined } })) as Models.User.SessionUser)
  }

  const signInAsMockPaidEmployerAccountOwner = () => {
    signInAsMockUser(makeMockEmployerUser(user => ({
      employer: {
        companyOwned: {
          accountOwnerUserId: user.id,
          stripeSubscriptionId: 'MOCK_PAID',
        },
      },
    })) as Models.User.SessionUser)
  }

  const signInAsMockUnpaidEmployerAccountOwner = () => {
    signInAsMockUser(makeMockEmployerUser(user => ({
      employer: {
        companyOwned: {
          accountOwnerUserId: user.id,
          stripeSubscriptionId: 'MOCK_UNPAID',
        },
      },
    })) as Models.User.SessionUser)
  }

  const signInAsMockEmployerTeamMember = () => {
    signInAsMockUser(makeMockEmployerUser(() => ({
      employer: {
        companyOwned: {
          accountOwnerUserId: 'not-me',
          stripeSubscriptionId: 'MOCK_PAID',
        },
      },
    })) as Models.User.SessionUser)
  }

  return (
    <Box
      style={[
        styles.container,
        isExpanded && styles.containerExpanded,
      ]}
    >
      <Pressable onPress={toggle}>
        <Text style={styles.toggleText}>
          {isExpanded ? 'x' : '+'}
        </Text>
      </Pressable>

      {isExpanded && (
        <Box style={styles.contentWrapper}>
          <Button size='lg' onPress={onPress(() => navigate('/dev/scratch'))}>
            Dev Scratch
          </Button>

          <hr />

          <Button
            onPress={confirm(onPress(() => {
              throw Error('Test error (intentionally thrown)')
            }), 'This will crash the app.')}
            size='lg'
          >
            ⚠️ Throw error
          </Button>
          <Button
            isLoading={isLoadingTriggerWeeklyDigestEmployer}
            onPress={confirm(onPress(() => {
              triggerWeeklyDigestEmployer()
                .unwrap()
                .then(({ message }) => {
                  toast.show({
                    description: message || 'Success',
                    title: lang().messages.genericSuccessHeading(),
                    type: 'success',
                  })
                })
                .catch(() => {
                  toast.show({
                    description: lang().messages.unknownError(),
                    title: lang().messages.genericErrorHeading(),
                    type: 'error',
                  })
                })
            }), 'This will trigger the same task as the employer weekly digest email cron job. It may result in a lot of emails being sent.')}
            size='lg'
          >
            ⚠️ Trigger Employer Weekly Digest email job
          </Button>
          <Button
            isLoading={isLoadingTriggerWeeklyDigestWorker}
            onPress={confirm(onPress(() => {
              triggerWeeklyDigestWorker()
                .unwrap()
                .then(({ message }) => {
                  toast.show({
                    description: message || 'Success',
                    title: lang().messages.genericSuccessHeading(),
                    type: 'success',
                  })
                })
                .catch(() => {
                  toast.show({
                    description: lang().messages.unknownError(),
                    title: lang().messages.genericErrorHeading(),
                    type: 'error',
                  })
                })
            }), 'This will trigger the same task as the worker weekly digest email cron job. It may result in a lot of emails being sent.')}
            size='lg'
          >
            ⚠️ Trigger Worker Weekly Digest email job
          </Button>
          <hr />

          <Button
            size='lg'
            onPress={onPress(signInAsMockPublishedWorker)}
          >
            Sign In as Worker + Published
          </Button>
          <Button
            size='lg'
            onPress={onPress(signInAsMockUnpublishedWorker)}
          >
            Sign In as Worker + Unpublished
          </Button>
          <Button
            size='lg'
            onPress={onPress(signInAsMockPaidEmployerAccountOwner)}
          >
            Sign In as Employer + Account Owner + Paid
          </Button>
          <Button
            size='lg'
            onPress={onPress(signInAsMockUnpaidEmployerAccountOwner)}
          >
            Sign In as Employer + Account Owner + Unpaid
          </Button>
          <Button
            size='lg'
            onPress={onPress(signInAsMockEmployerTeamMember)}
          >
            Sign In as Employer + Team Member
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(DevMenu)
