import React, { useRef } from 'react'
import { AlertDialog } from 'native-base'

import Button, { ButtonGroup } from '../Button'

import { Props } from './types'

function Confirm({
  children = 'Are you sure?',
  cancelText = 'No',
  confirmText = 'Yes',
  isOpen,
  isSubmitting = false,
  onCancel,
  onConfirm,
  title = 'Are you sure?',
  ...props
}: Props): React.ReactElement {
  const cancelRef = useRef()

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onCancel}
      {...props}
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{title}</AlertDialog.Header>
        <AlertDialog.Body>{children}</AlertDialog.Body>
        <AlertDialog.Footer>
          <ButtonGroup space={2}>
            <Button variant='unstyled' _text={{ color: 'gray.400' }} onPress={onCancel}>
              {cancelText}
            </Button>
            <Button
              colorScheme='danger'
              isLoading={isSubmitting}
              onPress={onConfirm}
            >
              {confirmText}
            </Button>
          </ButtonGroup>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  )
}

export default React.memo(Confirm)

export * from './types'
