import React, {
  useCallback,
  useMemo,
} from 'react'
import { isIOS } from 'react-device-detect'
import ReactSelect, {
  MenuPosition,
  OnChangeValue,
} from 'react-select'

import { object } from '@real-work/common'

import { SmallCloseIcon } from 'native-base'

import useFormInput from '../../hooks/useFormInput'

import Box from '../Box'
import Pressable from '../Pressable'
import HStack from '../HStack'
import Text from '../Text'

import {
  Option,
  Props,
  Value,
} from './types'

function Select<IsMulti extends boolean>({
  dataType,
  defaultValue,
  name,
  isMulti = false as IsMulti,
  menuPortalTarget = document?.body || undefined,
  onChange,
  options,
  pillsLocation = 'inside',
  placeholder = isMulti ? 'Choose one or more...' : 'Choose one...',
  styles,
  ...props
}: Props<IsMulti>): React.ReactElement {
  const {
    setValue,
    value,
  } = useFormInput({
    dataType: (isMulti && 'array') || dataType,
    defaultValue,
    name,
  })

  const onChangeHandler = useCallback<(value: OnChangeValue<Option, IsMulti>) => void>(value => {
    let newValue: Value | Value[] | undefined

    if (!value) {
      setValue && setValue(null) // eslint-disable-line no-null/no-null
      onChange && onChange(undefined)

      return
    }

    if (isMulti) {
      newValue = ((Array.isArray(value) && value) || []).map(option => option.value)
    }
    else {
      newValue = (value as Option).value
    }

    setValue && setValue(newValue)

    onChange && onChange(newValue)
  }, [
    isMulti,
    onChange,
    setValue,
  ])

  const displayValue = useMemo<Option | Option[]>(() => {
    if (isMulti) {
      return ((Array.isArray(value) && value) || [])?.map(value => options?.find(option => option.value === value) as Option) || ([ {} ] as Option[])
    }

    return options?.find(option => option.value === value) || ({} as Option)
  }, [
    isMulti,
    options,
    value,
  ])

  const onRemoveHandler = useCallback((valueToRemove: Value) => () => {
    if (!isMulti || !Array.isArray(displayValue)) {
      return
    }

    const newValue = displayValue.filter(item => item.value !== valueToRemove) as unknown as OnChangeValue<Option, typeof isMulti>

    onChangeHandler(newValue)
  }, [
    displayValue,
    isMulti,
    onChangeHandler,
  ])

  const menuPosition = useMemo(() => {
    const menuPortalTargetPosition = menuPortalTarget && window.getComputedStyle(menuPortalTarget)?.position

    if (menuPortalTargetPosition && [
      'absolute',
      'fixed',
    ].includes(menuPortalTargetPosition)) {
      return window.getComputedStyle(menuPortalTarget)?.position as MenuPosition
    }

    return undefined
  }, [ menuPortalTarget ])

  const isSearchable = useMemo(() => {
    if (menuPosition && isIOS) {
      // Disable isSearchable on iOS if we are using fixed positioning
      // Resolves https://flowerpress.atlassian.net/browse/RW-236
      return false
    }

    return props.isSearchable
  }, [
    menuPosition,
    props.isSearchable,
  ])

  const SelectInput = (
    <ReactSelect<Option, IsMulti, never>
      controlShouldRenderValue={pillsLocation === 'inside'}
      isMulti={isMulti}
      isSearchable={isSearchable}
      menuPortalTarget={menuPortalTarget}
      menuPosition={menuPosition}
      placeholder={placeholder}
      onChange={onChangeHandler}
      options={options}
      value={displayValue}
      {...props}
      styles={{
        container: (base, state) => object.merge({
          ...base,
          fontSize: '18px',
          minHeight: '39px',
          fontFamily: 'BarlowRegular',
        }, (styles?.container ? styles.container(base, state) : {})),
        control: (base, state) => object.merge({
          ...base,
          borderColor: state.isFocused ? '#0891B2' : '#D4D4D4',
          outlineColor: '#0891B2',
          boxShadow: state.isFocused ? 'none' : 'none',
          '&:hover': { borderColor: state.isFocused ? '#0891B2' : '#D4D4D4' },
          fontFamily: 'BarlowRegular',
        }, (styles?.control ? styles.control(base, state) : {})),
        input: (base, state) => object.merge({
          ...base,
          fontFamily: 'BarlowRegular',
        }, (styles?.input ? styles.input(base, state) : {})),
        menuList: (base, state) => object.merge({
          ...base,
          padding: 0,
          fontFamily: 'BarlowRegular',
        }, (styles?.menuList ? styles.menuList(base, state) : {})),
        menuPortal: (base, state) => object.merge({ ...base }, (styles?.menuPortal ? styles.menuPortal(base, state) : {})),
        option: (base, state) => object.merge({
          ...base,
          backgroundColor: state.isFocused ? 'rgba(8, 145, 178, .2)' : '#ffffff',
          '&:hover': { backgroundColor: 'rgba(8, 145, 178, .2)' },
          fontFamily: 'BarlowRegular',
          color: '#000000',
        }, (styles?.option ? styles.option(base, state) : {})),
        placeholder: (base, state) => object.merge({
          color: '#A3A3A3',
          position: 'absolute',
          left: '12px',
        }, (styles?.placeholder ? styles.placeholder(base, state) : {})),
      }}
    />
  )

  return (
    <>
      {SelectInput}

      {pillsLocation === 'outside' && (
        <HStack mt='2' flexWrap='wrap' justifyContent='flex-start' flexShrink='1'>
          {isMulti && Array.isArray(displayValue) && displayValue.map(val => val === undefined ? null : ( // eslint-disable-line no-null/no-null
            <Pressable onPress={onRemoveHandler(val.value)} key={val.value.toString()}>
              <HStack
                backgroundColor='warmGray.200'
                py='1'
                pl='2'
                borderRadius='md'
                mb='2'
                mr='2'
                pr='20px'
                alignItems='center'
                maxW='300px'
              >
                <SmallCloseIcon size='3' position='absolute' right='4px' />
                <Box flexShrink='1'><Text fontSize='sm'>{val.pillLabel ? val.pillLabel : val.label}</Text></Box>
              </HStack>
            </Pressable>
          ))}
        </HStack>
      )}
    </>
  )
}

export default Select
