import React, {
  useCallback,
  useMemo,
} from 'react'

import {
  api,
  lang,
  object,
} from '@real-work/common'

import {
  Box,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  HStack,
  Input,
  LoadingFailed,
  LoadingIndicator,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import {
  useGetUserByIdQuery,
  usePatchUserByIdMutation,
} from '@/services/user'
import { usePatchWorkerByIdMutation } from '@/services/worker'

import type {
  FormValues,
  OnSubmit,
} from './types'

function AccountEditPersonalDetails(): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()
  const navigate = useNavigate()
  const session = useSession()
  const toast = useToast()

  const {
    data: { user } = {},
    isLoading,
    isError,
  } = useGetUserByIdQuery({ params: { id: session.user?.id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !session.user?.id,
  })

  const [
    patchUserById,
    { isLoading: isLoadingPatchUser },
  ] = usePatchUserByIdMutation()

  const [
    patchWorkerById,
    { isLoading: isLoadingPatchWorker },
  ] = usePatchWorkerByIdMutation()

  const isSubmitting = useMemo(() => isLoadingPatchUser || isLoadingPatchWorker, [
    isLoadingPatchUser,
    isLoadingPatchWorker,
  ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    Promise.all([
      patchUserById({
        body: object.except(values, 'zipCode'),
        params: { id: user.id },
      }).unwrap(),
      (session.user?.role.slug === 'worker' && patchWorkerById({
        body: { zipCode: values.zipCode },
        params: { userId: user.id },
      }).unwrap()),
    ])
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
      .then(() => {
        toast.show({
          description: lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate(-1)
      })
      .catch(() => {
        toast.show({
          description: lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    handleFormApiErrors,
    navigate,
    patchUserById,
    patchWorkerById,
    session.user?.role.slug,
    toast,
    user?.id,
  ])

  const userValidationRules = api.endpoints.users.patchById.validation.body.rules
  const workerValidationRules = api.endpoints.workers.patchById.validation.body.rules

  const rules = useMemo(() => ({
    email: userValidationRules.email.required(),
    firstName: userValidationRules.firstName.required(),
    lastName: userValidationRules.lastName.required(),
    phone: userValidationRules.phone.required(),
    position: user?.role.slug === 'employer' ? userValidationRules.position.required() : userValidationRules.position,
    zipCode: user?.role.slug === 'worker' ? workerValidationRules.zipCode.required() : workerValidationRules.zipCode,
  }), [
    user?.role.slug,
    userValidationRules,
    workerValidationRules.zipCode,
  ])

  return (
    <DocumentWrapper title='Real Work | My Account'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (user && !isError && (
          <>
            <Container>
              <Heading>My Account</Heading>
              <Text>
                Edit your contact info below.
              </Text>
            </Container>
            <Container mt='4'>
              <Form<FormValues>
                initialValues={{
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  phone: user.phone,
                  position: session.user?.role.slug === 'employer' ? session.user.employer?.position : undefined,
                  zipCode: session.user?.role.slug === 'worker' ? {
                    displayLocation: session.user.worker?.zipCode || '',
                    googlePlaceId: session.user.worker?.googlePlaceId || '',
                    lonLat: {
                      lat: 0,
                      lon: 0,
                    },
                  } : undefined,
                }}
                onSubmit={onSubmit}
                rules={rules}
              >
                <Heading size='md' variant='secondary'>Personal Details</Heading>
                <Divider />
                <Box maxWidth='620'>
                  <FormControl
                    name='firstName'
                    label='First Name'
                  >
                    <Input type='text' />
                  </FormControl>

                  <FormControl
                    name='lastName'
                    label='Last Name'
                  >
                    <Input type='text' />
                  </FormControl>

                  {user.role.slug === 'employer' && (
                    <FormControl
                      name='position'
                      label='Position'
                    >
                      <Input type='text' />
                    </FormControl>
                  )}

                  <FormControl
                    name='email'
                    label='Email'
                  >
                    <Input type='email' />
                  </FormControl>

                  <FormControl
                    name='phone'
                    label='Phone'
                  >
                    <Input type='phone' />
                  </FormControl>

                  {session.user?.role.slug === 'worker' && (
                    <FormControl
                      name='zipCode'
                      label='Zip Code'
                    >
                      <Input type='zipCode' />
                    </FormControl>
                  )}

                  <HStack flexDirection='row' mt='4' mb='4' space='4'>
                    <SubmitButton isLoading={isSubmitting}>Save Changes</SubmitButton>
                    <Link
                      to='/account'
                      type='button'
                      button={{ variant: 'outline' }}
                    >
                      Cancel
                    </Link>
                  </HStack>
                </Box>
              </Form>
            </Container>
          </>
        )) || <LoadingFailed />}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(AccountEditPersonalDetails)
