import React, {
  useCallback,
  useMemo,
} from 'react'
import type { Area } from 'react-easy-crop/types'
import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'

import type { Models } from '@real-work/orm'

import useFormInput from '../../hooks/useFormInput'

import FileInput from './index.presentational'

import {
  FileInputProps,
  FileResult,
  MediaResult,
  Props,
} from './types'

function FileInputContainer({
  addButtonText,
  mode = 'file',
  name,
  ...props
}: Props): React.ReactElement {
  const {
    setValue,
    value,
  } = useFormInput({ name })

  const {
    setValue: setCropValue,
    value: cropValue,
  } = useFormInput<Models.Image.Crop>({ name: `${name}Crop` })

  const canCrop = useMemo(() => !!props.crop && !!cropValue, [
    cropValue,
    props.crop,
  ])

  const onCropComplete = useCallback((crop: Area, zoom: number) => {
    setCropValue && setCropValue({
      ...crop,
      zoom,
    })
  }, [ setCropValue ])

  const onOpen = useCallback(() => {
    const go = async () => {
      if (mode === 'image') {
        return ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: false,
          allowsMultipleSelection: false,
          quality: 1,
          ...props,
        })
      }

      return DocumentPicker.getDocumentAsync({
        type: (props as unknown as { type: unknown } as FileInputProps).type,
        multiple: false,
        ...props,
      })
    }

    go()
      .then(result => {
        if (mode === 'image' && (result as MediaResult).canceled) {
          return
        }

        if (mode === 'file' && (result as FileResult).type === 'cancel') {
          return
        }

        setValue && setValue((mode === 'image' && (result as MediaResult)?.assets?.at(0)?.uri) || (result as { name: string })?.name || '')
      })
      .catch(error => {
        throw error
      })
  }, [
    mode,
    props,
    setValue,
  ])

  const onRemove = useCallback(() => {
    // eslint-disable-next-line no-null/no-null
    setValue && setValue(null)
  }, [ setValue ])

  return React.createElement(
    FileInput,
    {
      ...props,
      addButtonText,
      aspectRatio: props.crop?.aspectRatio,
      canCrop,
      data: value?.toString(),
      initialCrop: cropValue as Models.Image.Crop | undefined,
      initialZoom: (cropValue as Models.Image.Crop)?.zoom,
      mode,
      onCropComplete,
      onOpen,
      onRemove,
    },
  )
}

export default FileInputContainer
