import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import { LicensesResponse } from './types'

const path = 'v1/licenses'

const licenseService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getLicenses: build.query<api.AsJson<LicensesResponse>, void>({
      query: () => ({
        url: path,
        method: 'GET',
      }),
    }),
  }),
})

export default licenseService

export const { useGetLicensesQuery } = licenseService

export const { endpoints: { getLicenses } } = licenseService
