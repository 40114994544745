import React from 'react'
import {
  InfoIcon,
  Tooltip as NbTooltip,
} from 'native-base'

import { Props } from './types'

function Tooltip({
  children,
  label,
  placement = 'top',
  iconProps,
  ...props
}: Props): React.ReactElement {
  return (
    <NbTooltip
      label={label}
      placement={placement}
      {...props}
    >
      {children || <InfoIcon size='5' color='primary.600' {...iconProps} />}
    </NbTooltip>
  )
}

export default React.memo(Tooltip)

export * from './types'
