import React from 'react'
import { Modal as NbModal } from 'native-base'

import { Props } from './types'

function Modal({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbModal {...props}>{children}</NbModal>
  )
}

Modal.Content = NbModal.Content
Modal.CloseButton = NbModal.CloseButton
Modal.Header = NbModal.Header
Modal.Footer = NbModal.Footer
Modal.Body = NbModal.Body

export default Modal

export * from './types'
