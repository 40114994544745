import React, {
  useCallback,
  useMemo,
} from 'react'
import { Formik } from 'formik'

import { validation } from '@real-work/common'

import VStack from '../VStack'

import {
  DefaultFormValues,
  Helpers,
  Props,
} from './types'

function Form<T extends DefaultFormValues = DefaultFormValues>({
  children,
  initialErrors = {},
  initialValues = {} as T,
  onSubmit: onSubmitProp,
  space = 2,
  rules = {},
  validateOnBlur = false,
  validateOnChange = false,
  width = '100%',
  maxWidth = '620px',
  ...props
}: Props<T>) {
  const onSubmit = useCallback((values: T, helpers: Helpers<T>) => {
    onSubmitProp && onSubmitProp(values, helpers)
  }, [ onSubmitProp ])

  const formikProps = useMemo(() => ({
    ...props,
    initialErrors,
    initialValues,
    onSubmit,
    validationSchema: validation.schema(rules),
    validateOnBlur,
    validateOnChange,
  }), [
    props,
    initialErrors,
    initialValues,
    onSubmit,
    rules,
    validateOnBlur,
    validateOnChange,
  ])

  const vStackProps = useMemo(() => ({
    ...props,
    space,
    width,
    maxWidth,
  }), [
    props,
    space,
    width,
    maxWidth,
  ])

  return (
    <Formik<T> {...formikProps}>
      {formik => (
        <VStack {...vStackProps}>
          {typeof children === 'function' ? children(formik) : children}
        </VStack>
      )}
    </Formik>
  )
}

export default Form

export * from './types'
