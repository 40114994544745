import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  View,
} from 'react-native'
import {
  Input,
  Pressable,
} from 'native-base'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
  dateTime,
  isTest,
} from '@real-work/common'

import useFormInput from '../../hooks/useFormInput'

import Image from '../Image'

import calendar from './assets/calendar.svg'
import clock from './assets/clock.svg'

import {
  OnChange,
  Props,
} from './types'

function DateTimeInput({
  isOptional = false,
  mode = 'date',
  defaultValue = (!isOptional && mode === 'date' && new Date(new Date().setHours(0, 0, 0, 0))) || (!isOptional && new Date()) || null, // eslint-disable-line no-null/no-null
  ...props
}: Props): React.ReactElement|null {
  const {
    InputLabel,
    setValue: formSetValue,
    value: formValue,
  } = useFormInput<typeof DatePicker>({
    ...props,
    dataType: 'date',
    defaultValue: isOptional ? null : new Date(), // eslint-disable-line no-null/no-null
  })

  const [
    value,
    setValue,
  ] = useState<Date | null>(defaultValue as Date | null)

  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  // Set initial value on load
  useEffect(() => {
    if (isTest) {
      return // fixes tests
    }

    const newValue = (mode === 'date' && value && value) || formValue

    if (formSetValue) {
      formSetValue(mode === 'date' ? dateValue(newValue as Date | null) : newValue)
    }

    setValue(newValue as Date | null)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onInputKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (isOpen && event.nativeEvent.key.toLowerCase() === 'tab') {
      setIsOpen(false)
    }
  }

  const onChange = useCallback<OnChange>((event, value) => {
    let newValue: Date | null | undefined
    if (mode === 'date') {
      // eslint-disable-next-line no-null/no-null
      newValue = (value && value) || (isOptional ? null : new Date(defaultValue as Date) || undefined)
    }
    else {
      // eslint-disable-next-line no-null/no-null
      newValue = value || (isOptional ? null : new Date(defaultValue as Date || undefined))
    }

    if (formSetValue) {
      formSetValue(mode === 'date' ? dateValue(newValue) : newValue)
    }

    setValue(newValue)
    setIsOpen(false)
  }, [
    defaultValue,
    formSetValue,
    isOptional,
    mode,
  ])

  const iconSource = useMemo(() => mode === 'time' ? clock : calendar, [ mode ])
  const dateFormat = useMemo(() => (mode === 'date' && dateTime.dateFormat) || (mode === 'time' && dateTime.timeFormat) || dateTime.dateTimeFormat, [ mode ])

  return (
    <View>
      {InputLabel}

      <DatePicker
        customInput={(
          <Input
            onKeyPress={onInputKeyPress}
            InputRightElement={(
              <Pressable onPress={() => setIsOpen(true)}>
                <Image
                  source={iconSource}
                  alt='calendar'
                  height={4}
                  width={4}
                  marginRight='1'
                />
              </Pressable>
            )}
          />
        )}
        data-testid={props.testID}
        dateFormat={dateFormat}
        name={props.name}
        onBlur={() => setIsOpen(false)}
        onChange={(date: Date) => onChange(undefined, date)}
        onClickOutside={() => setIsOpen(false)}
        onFocus={() => setIsOpen(true)}
        onInputClick={() => setIsOpen(true)}
        open={isOpen}
        portalId='root'
        selected={value}
        showMonthDropdown={mode !== 'time'}
        showTimeSelect={mode !== 'date'}
        showTimeSelectOnly={mode === 'time'}
        showYearDropdown={mode !== 'time'}
      />
    </View>
  )
}

const dateValue = (value: Date | null) => {
  if (!value) {
    return value
  }

  const newValue = new Date()
  newValue.setUTCFullYear(value.getFullYear())
  newValue.setUTCMonth(value.getMonth())
  newValue.setUTCDate(value.getDate())
  newValue.setUTCHours(0, 0, 0, 0)

  return newValue
}

export default DateTimeInput

export * from './types'
