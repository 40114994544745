import React, {
  useCallback,
  useMemo,
} from 'react'

import {
  api,
  lang,
} from '@real-work/common'

import {
  ButtonGroup,
  Container,
  Form,
  FormControl,
  Heading,
  Input,
  LoadingFailed,
  LoadingIndicator,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import {
  useGetCompanyByIdQuery,
  useUpsertCompanyLocationMutation,
} from '@/services/company'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'

import DocumentWrapper from '../../../../components/DocumentWrapper'
import Link from '../../../../components/Link'
import ScreenWrapper from '../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function LocationEdit({ mode }: Props): React.ReactElement {
  const navigate = useNavigate()

  const handleFormApiErrors = useHandleFormApiErrors()

  const toast = useToast()

  const {
    locationId,
    id,
  } = useRouteParams()

  const {
    data: { company } = {},
    isError,
    isLoading,
  } = useGetCompanyByIdQuery({ params: { id: id || '' } })

  const [
    upsertCompanyLocation,
    { isLoading: isSubmitting },
  ] = useUpsertCompanyLocationMutation()

  const companyLocation = company?.locations?.find(location => location.id === locationId)

  const initialValues = useMemo(() => ({
    hours: companyLocation?.hours || '',
    phone: companyLocation?.phone || '',
    title: companyLocation?.title || '',
    geocode: {
      displayLocation: companyLocation?.displayLocation || '',
      googlePlaceId: companyLocation?.googlePlaceId || '',
      lonLat: {
        lat: 0,
        lon: 0,
      },
    },
  }), [ companyLocation ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!company?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertCompanyLocation({
      params: {
        companyId: company.id,
        id: companyLocation?.id || undefined,
      },
      body: {
        hours: values.hours,
        phone: values.phone,
        title: values.title,
        geocode: {
          displayLocation: values.geocode.displayLocation,
          googlePlaceId: values.geocode.googlePlaceId,
          lonLat: values.geocode.lonLat,
        },
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
        navigate(`/companies/${id}`)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    company,
    companyLocation,
    handleFormApiErrors,
    id,
    navigate,
    toast,
    upsertCompanyLocation,
  ])

  return (
    <DocumentWrapper title={`Real Work | ${mode === 'edit' ? 'Edit' : 'Add'} Company Location`}>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || ((isError || !company) && <LoadingFailed />) || (
          <Container>
            <Heading>{mode === 'edit' ? 'Edit' : 'Add'} Company Location</Heading>
            <Text mb='6'>
              {mode === 'edit' ? 'Edit' : 'Add'} your company location.
            </Text>
            <Form<FormValues>
              initialValues={initialValues}
              onSubmit={onSubmit}
              rules={api.endpoints.companies.putCompanyLocation.validation.body.rules}
            >
              <>
                <FormControl
                  name='title'
                  label='Location Title'
                >
                  <Input
                    type='text'
                  />
                </FormControl>
                <FormControl
                  name='geocode'
                  label='Address'
                >
                  <Input type='address' />
                </FormControl>
                <FormControl
                  name='hours'
                  label='Hours'
                >
                  <Input
                    type='text'
                  />
                </FormControl>
                <FormControl
                  name='phone'
                  label='Phone Number'
                >
                  <Input
                    type='phone'
                  />
                </FormControl>
                <ButtonGroup my='4'>
                  <SubmitButton isLoading={isSubmitting}>Save Changes</SubmitButton>
                  <Link type='button' to={`/companies/${id}`}>
                    Cancel
                  </Link>
                </ButtonGroup>
              </>
            </Form>
          </Container>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(LocationEdit)
