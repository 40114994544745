import React from 'react'

import useMediaQuery from '../../../hooks/useMediaQuery'

import Box from '../../Box'
import Button from '../../Button'
import Divider from '../../Divider'
import HStack from '../../HStack'
import Image from '../../Image'
import Text from '../../Text'

import taskComplete from './assets/task-complete.svg'
import taskIncomplete from './assets/task-incomplete.svg'

import { Props } from './types'

function ProfileChecklistItem({
  hasDivider = true,
  isCompleted = false,
  isOptional = false,
  onPressButton,
  title,
}: Props): React.ReactElement {
  const [ isDesktop ] = useMediaQuery({ minWidth: 860 })

  return (
    <Box width='100%' pl={isDesktop ? '0' : '6'}>
      <HStack alignItems='center'>
        <HStack
          alignItems='center'
          flexWrap='wrap'
          width={isDesktop ? 'auto' : '200'}
        >
          <Image
            source={taskIncomplete}
            display={isCompleted ? 'none' : undefined}
            alt='Checkbox'
            height={isDesktop ? '8' : '6'}
            width={isDesktop ? '8' : '6'}
            mr='1'
            position='absolute'
            left={isDesktop ? '-40' : '-30'}
            top='0'
          />
          <Image
            source={taskComplete}
            display={isCompleted ? undefined : 'none'}
            alt='Checkmark'
            height={isDesktop ? '8' : '6'}
            width={isDesktop ? '8' : '6'}
            mr='1'
            position='absolute'
            left={isDesktop ? '-40' : '-30'}
            top='0'
          />
          <Text
            bold={true}
            fontSize={isDesktop ? '2xl' : 'lg'}
            mr='1'
          >{title}
          </Text>
          {isOptional && <Text mr='1'> (Optional)</Text> }
        </HStack>
        <Box ml='auto'>
          <Button
            onPress={onPressButton}
            size='sm'
            _text={{ fontSize: 'sm' }}
          >
            {isCompleted ? 'Edit' : 'Add'}
          </Button>
        </Box>
      </HStack>
      {hasDivider && <Divider my='3' />}
    </Box>
  )
}

export default React.memo(ProfileChecklistItem)
