import React, {
  useCallback,
  useMemo,
} from 'react'

import {
  api,
  lang,
} from '@real-work/common'

import {
  Box,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  Input,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useRouterParms from '@/hooks/useRouteParams'

import { usePostResetPasswordMutation } from '@/services/passwordReset'

import {
  FormValues,
  OnSubmit,
} from './types'

function ResetPassword(): React.ReactElement {
  const {
    code,
    id,
  } = useRouterParms()

  const handleFormApiErrors = useHandleFormApiErrors()

  const navigate = useNavigate()

  const toast = useToast()

  const initialValues = useMemo(() => ({
    password: '',
    passwordConfirm: '',
    code: code || '',
  }), [ code ])

  const [
    postResetPassword,
    { isLoading },
  ] = usePostResetPasswordMutation()

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!id || false) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    postResetPassword({
      params: { id },
      body: values,
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response.message,
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate('/sign-in')
      })
      .catch(error => {
        handleFormApiErrors({
          defaultMessage: lang().messages.unableToResetPassword(),
          error,
          setErrors,
        })
      })
  }, [
    id,
    handleFormApiErrors,
    navigate,
    postResetPassword,
    toast,
  ])

  return (
    <DocumentWrapper title='Real Work | Reset Password'>
      <ScreenWrapper>
        <Container>
          <Heading>Reset Password</Heading>
          <Text>
            Please enter your new password below.
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>Personal Details</Heading>
          <Divider />
          <Form<FormValues>
            initialValues={initialValues}
            rules={api.endpoints.passwordReset.reset.validation.body.rules}
            onSubmit={onSubmit}
          >
            <FormControl
              name='password'
              label='Password'
            >
              <Text fontSize='md' mb='1'>{lang().validation.passwordHelperText()}</Text>
              <Input
                type='password'
              />
            </FormControl>
            <FormControl
              name='passwordConfirm'
              label='Re-Enter Password'
            >
              <Input
                type='password'
              />
            </FormControl>
            <Box flexDirection='row' mt='4' mb='4'>
              <SubmitButton isLoading={isLoading}>Save New Password</SubmitButton>
            </Box>
          </Form>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(ResetPassword)
