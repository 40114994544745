import React from 'react'

import { Stack as NbStack } from 'native-base'

import { Props } from './types'

function Stack({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbStack {...props}>{children}</NbStack>
  )
}

export default Stack

export * from './types'
