import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  api,
  lang,
  url,
} from '@real-work/common'
import {
  Button,
  ButtonGroup,
  Container,
  DateTimeInput,
  Divider,
  Form,
  FormControl,
  Heading,
  Input,
  LoadingFailed,
  LoadingIndicator,
  Select,
  SubmitButton,
  Text,
  TooltipModalTypes,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import { useGetUsStatesQuery } from '@/services/usState'
import {
  useGetWorkerByUserIdQuery,
  useUpsertWorkerCertificationMutation,
} from '@/services/worker'
import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import { useGetCertificationsQuery } from '@/services/certification'

import DocumentWrapper from '../../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function SkillsAddEdit({ mode }: Props): React.ReactElement {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const toast = useToast()
  const { id } = useRouteParams()
  const { user } = useSession()
  const {
    data: certificationsData,
    isError: isCertificationsError,
    isLoading: isLicensesLoading,
  } = useGetCertificationsQuery()
  const {
    data: usStatesData,
    isError: isUsStatesError,
    isLoading: isUsStatesLoading,
  } = useGetUsStatesQuery()
  const {
    data: workerData,
    isError: isWorkerError,
    isLoading: isWorkerLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, { skip: !user?.id })
  const [
    upsertWorkerCertification,
    { isLoading: isSubmitting },
  ] = useUpsertWorkerCertificationMutation()

  const workerCertification = workerData?.worker.certifications?.find(item => item.id === id)

  const initialUsStateAbbr = useMemo(() => {
    const getInitialUsStateAbbr = () => {
      if (workerCertification) {
        return certificationsData?.certifications?.find(certification => certification.id === workerCertification.certificationId)?.usStateAbbr
      }

      return undefined
    }

    return getInitialUsStateAbbr()
  }, [
    certificationsData?.certifications,
    workerCertification,
  ])
  const [
    usStateAbbr,
    setUsStateAbbr,
  ] = useState(initialUsStateAbbr)
  const validationRules = api.endpoints.workers.putCertification.validation.body.rules

  const rules = useMemo(() => ({
    certificationId: validationRules.certificationId,
    certificationNumber: validationRules.certificationNumber,
  }), [ validationRules ])

  const certificationsOptions = useMemo(() => {
    const usStateAbbrOfCertifications = usStateAbbr || (usStateAbbr !== '' && initialUsStateAbbr) // usStateAbbr='' means selecting N/A

    const certifications = certificationsData?.certifications.filter(certification => {
      if (!usStateAbbrOfCertifications) {
        return certification.usStateAbbr === null // eslint-disable-line no-null/no-null
      }

      return certification.usStateAbbr === usStateAbbrOfCertifications
    }) || []

    const options = certifications?.map(certification => ({
      label: certification.name,
      value: certification.id,
    }))

    return options
  }, [
    initialUsStateAbbr,
    usStateAbbr,
    certificationsData?.certifications,
  ])

  const usStateOptions = useMemo(() => [
    {
      label: 'N/A',
      value: '',
    },
    ...(usStatesData?.usStates || []).map(state => ({
      label: state.name,
      value: state.abbr,
    })),
  ], [ usStatesData ])

  const isLoading = useMemo(() => isWorkerLoading || isLicensesLoading, [
    isLicensesLoading,
    isWorkerLoading,
  ])
  const isError = useMemo(() => isWorkerError || isCertificationsError || (mode === 'edit' && !workerCertification), [
    isCertificationsError,
    isWorkerError,
    mode,
    workerCertification,
  ])

  const initialValues = useMemo(() => ({
    certificationId: workerCertification?.certificationId || '',
    certificationNumber: workerCertification?.certificationNumber || '',
    expiresAt: api.dates.apiValueToInputValue(workerCertification?.expiresAt) || undefined,
    receivedAt: api.dates.apiValueToInputValue(workerCertification?.receivedAt) || undefined,
    usStateAbbr: initialUsStateAbbr,
  }), [
    initialUsStateAbbr,
    workerCertification,
  ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertWorkerCertification({
      params: {
        userId: user.id,
        id,
      },
      body: {
        certificationId: values.certificationId,
        certificationNumber: values.certificationNumber,
        expiresAt: api.dates.inputValueToApiValueOptional(values.expiresAt),
        receivedAt: api.dates.inputValueToApiValueOptional(values.receivedAt),
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
        navigate('/profile/certifications')
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    id,
    navigate,
    toast,
    upsertWorkerCertification,
    user?.id,
  ])

  const tooltipButtons = useCallback<TooltipModalTypes.Buttons>(({ setIsOpen }) => (
    <Button
      size='sm'
      _text={{ fontSize: 'md' }}
      onPress={() => {
        setIsOpen(false)
        window.location.href = url.marketingSite('/feedback')
      }}
    >
      Contact Us
    </Button>
  ), [])

  return (
    <DocumentWrapper title='Real Work | Certifications'>
      <ScreenWrapper>
        <Container>
          <Heading>Certifications</Heading>
          <Text>
            {mode === 'edit' ? 'Edit a certification below.' : 'Add a certification below.'}
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>{mode === 'edit' ? 'Edit Certification' : 'Add a Certification'}</Heading>
          <Divider />
          {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
            <Form<FormValues>
              initialValues={initialValues}
              rules={rules}
              onSubmit={onSubmit}
            >
              {(isUsStatesLoading && <LoadingIndicator />) || (isUsStatesError && <LoadingFailed />)
              || (
                <FormControl
                  name='usStateAbbr'
                  label='State'
                >
                  <Select
                    options={usStateOptions}
                    onChange={value => setUsStateAbbr(value as string)}
                  />
                </FormControl>
              )}
              <FormControl
                name='certificationId'
                label='Certification'
                tooltipModal={{
                  title: 'Certification',
                  buttons: tooltipButtons,
                  children: <Text>Don’t see your certification listed? Let us know.</Text>,
                }}
              >
                <Select
                  options={certificationsOptions}
                />
              </FormControl>
              <FormControl
                name='certificationNumber'
                label='Certification Number'
              >
                <Input
                  type='text'
                />
              </FormControl>
              <FormControl
                name='receivedAt'
                label='Date Received'
                labelNote='(Optional)'
              >
                <DateTimeInput
                  mode='date'
                  isOptional={true}
                />
              </FormControl>
              <FormControl
                name='expiresAt'
                label='Expiration Date'
                labelNote='(Optional)'
              >
                <DateTimeInput
                  isOptional={true}
                />
              </FormControl>
              {/* <FormControl
                  name='enrollInReminders'
                >
                  <Checkbox my={2}>
                    Enroll in certification expiration reminders via email?
                  </Checkbox>
                </FormControl>
                {values.enrollInReminders === 'true' && (
                  <FormControl
                    name='expDate'
                    label='Email address(es) where you wish to receive notifications'
                  >
                    <Text mb='2'>(separate with a comma)</Text>
                    <TextArea />
                  </FormControl>
                )} */}
              <ButtonGroup>
                <SubmitButton isLoading={isSubmitting}>Save</SubmitButton>
                <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
              </ButtonGroup>
            </Form>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SkillsAddEdit)
