import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import { GetSubscriptionPlansResponse } from './types'

const path = 'v1/subscription-plans'

const subscriptionPlanService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getSubscriptionPlans: build.query<api.AsJson<GetSubscriptionPlansResponse>, void>({
      query: () => ({
        url: path,
        method: 'GET',
      }),
      providesTags: [ 'SubscriptionPlans' ],
    }),
  }),
})

export default subscriptionPlanService

export const { useGetSubscriptionPlansQuery } = subscriptionPlanService

export const { endpoints: { getSubscriptionPlans } } = subscriptionPlanService
