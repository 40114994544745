import React from 'react'

import {
  HStack,
  Text,
  VStack,
} from '@real-work/ui'

import { usePatchEventNotificationByIdMutation } from '@/services/eventNotification'

import dateTime from '@/utils/dateTime'

import Link from '../../Link'

import { Props } from './types'

function Notification({ eventNotification }: Props): React.ReactElement {
  const [ patchEventNotificationByIdMutation ] = usePatchEventNotificationByIdMutation()

  const onPressLink = async () => {
    patchEventNotificationByIdMutation({
      params: { id: eventNotification.id },
      body: { readAt: new Date().toISOString() },
    })
  }

  const isUnread = !eventNotification.readAt

  return (
    <HStack
      alignItems='center'
      pb='4'
    >
      {/* <Box backgroundColor='brand.primary' width='45px' height='45px' borderRadius='full' alignItems='center' justifyContent='center' marginRight='2'><Text color='white'>{userInitials}</Text></Box> */}
      <VStack flex='1'>
        <Text fontSize='md' lineHeight='.8rem'>{dateTime.format(dateTime.parseISO(eventNotification.createdAt))}</Text>
        <VStack>
          <Text fontSize='md' bold={isUnread}>{eventNotification.content || 'Notification'}</Text>
          {eventNotification.linkUrl && (
            <Link
              onPress={onPressLink}
              to={eventNotification.linkUrl}
              variant='emphasis'
              _text={{ fontSize: 'md' }}
            >
              {eventNotification.linkText || 'View'} &gt;
            </Link>
          )}
        </VStack>
      </VStack>
    </HStack>
  )
}

export default React.memo(Notification)
