"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toWords = void 0;
// eslint-disable-next-line import/prefer-default-export
const toWords = (number) => {
    if (number === 0) {
        return 'zero';
    }
    if (number === 1) {
        return 'one';
    }
    if (number === 2) {
        return 'two';
    }
    if (number === 3) {
        return 'three';
    }
    if (number === 4) {
        return 'four';
    }
    if (number === 5) {
        return 'five';
    }
    if (number === 6) {
        return 'six';
    }
    if (number === 7) {
        return 'seven';
    }
    if (number === 8) {
        return 'eight';
    }
    if (number === 9) {
        return 'nine';
    }
    if (number === 10) {
        return 'ten';
    }
    throw new Error('number.toWords() currently only supports values between 0-10.');
};
exports.toWords = toWords;
