import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import { GetWorkerTipResponse } from './types'

const path = 'v1/worker-tip-of-the-week'

const workerTipService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getWorkerTipOfTheWeek: build.query<api.AsJson<GetWorkerTipResponse>, void>({
      query: () => ({
        url: path,
        method: 'GET',
      }),
    }),
  }),
})

export default workerTipService

export const { useGetWorkerTipOfTheWeekQuery } = workerTipService

export const { endpoints: { getWorkerTipOfTheWeek } } = workerTipService
