import React from 'react'
import { Spinner } from '@real-work/ui'

import useSession from '@/hooks/useSession'

import { useGetUsStatesQuery } from '@/services/usState'

function Cache(): React.ReactElement | null {
  const { user } = useSession()

  const { isLoading: isLoadingUsStates } = useGetUsStatesQuery(undefined, { skip: !user })

  return (isLoadingUsStates && (
    <Spinner
      position='absolute'
      bottom={4}
      right={4}
      zIndex={2}
      opacity={0.8}
    />
  )) || null // eslint-disable-line no-null/no-null
}

export default React.memo(Cache)
