import React from 'react'

import {
  Box,
  Container,
  Heading,
  HStack,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import { FiltersProvider } from '@/components/Filters/context'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'
import Tabs, { TabContent } from '@/components/Tabs'

import useSession from '@/hooks/useSession'

import JobsClosed from './Closed'
import JobsInterested from './Interested'
import JobsOpen from './Open'

const employerTabs = {
  0: {
    label: 'Open Listings',
    slug: 'open',
  },
  1: {
    label: 'Closed Listings',
    slug: 'closed',
  },
}

const workerTabs = {
  0: {
    label: 'Open Jobs',
    slug: 'open',
  },
  1: {
    label: 'Jobs I Am Interested In',
    slug: 'interested',
  },
}

function Jobs(): React.ReactElement {
  const { user } = useSession()

  return (
    <DocumentWrapper title='Real Work | Jobs'>
      <ScreenWrapper>
        <Container>
          <HStack alignItems='center' width='100%' justifyContent='space-between'>
            <Heading mb='6'>Jobs</Heading>
            {user?.role.slug === 'employer' && <Link type='button' to='/jobs/new'>Add Job</Link>}
          </HStack>
          <Box width='100%'>
            {(user?.role.slug === 'employer' && (
              <Tabs
                aria-label='Jobs'
                tabs={employerTabs}
              >
                {tabs => (
                  <>
                    <TabContent
                      index={0}
                      tabs={tabs}
                    >
                      <JobsOpen />
                    </TabContent>

                    <TabContent
                      index={1}
                      tabs={tabs}
                    >
                      <JobsClosed />
                    </TabContent>
                  </>
                )}
              </Tabs>
            )) || (user?.role.slug === 'worker' && (
              <Tabs
                aria-label='Jobs'
                tabs={workerTabs}
              >
                {tabs => (
                  <>
                    <TabContent
                      index={0}
                      tabs={tabs}
                    >
                      <FiltersProvider>
                        <JobsOpen />
                      </FiltersProvider>
                    </TabContent>

                    <TabContent
                      index={1}
                      tabs={tabs}
                    >
                      <JobsInterested />
                    </TabContent>
                  </>
                )}
              </Tabs>
            )) || <JobsOpen />}
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Jobs)
