import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import { CertificationResponse } from './types'

const path = 'v1/certifications'

const certificationService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getCertifications: build.query<api.AsJson<CertificationResponse>, void>({
      query: () => ({
        url: path,
        method: 'GET',
      }),
    }),
  }),
})

export default certificationService

export const { useGetCertificationsQuery } = certificationService

export const { endpoints: { getCertifications } } = certificationService
