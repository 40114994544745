import {
  api,
  querystring,
} from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import {
  DeleteJobByIdResponse,
  GetJobByIdRequest,
  GetJobByIdResponse,
  GetJobsRequest,
  GetJobsResponse,
  PatchJobByIdResponse,
  PostJobInterestedWorkerResponse,
  UpsertJobRequest,
  UpsertJobResponse,
} from './types'

const path = 'v1/jobs'

const jobService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    deleteJobById: build.mutation<api.AsJson<DeleteJobByIdResponse>, unknown>({
      query: ({ params }) => ({
        url: `${path}/${params.jobId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Jobs' ],
    }),
    getJobById: build.query<api.AsJson<GetJobByIdResponse>, GetJobByIdRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.id}`,
        method: 'GET',
      }),
    }),
    getJobs: build.query<api.AsJson<GetJobsResponse>, GetJobsRequest>({
      providesTags: [ 'Jobs' ],
      merge: (currentCache, response, { arg }) => {
        const newJobs = ((arg.query?.page ?? 0) > 1 ? currentCache.jobs : [])

        newJobs.push(...response.jobs)

        currentCache.jobs = newJobs
        currentCache.pagination = response.pagination
      },
      query: ({ query }) => ({
        url: `${path}?${querystring.stringify(query)}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
    }),
    getJobsWithInterestedWorkers: build.query<api.AsJson<GetJobsResponse>, GetJobsRequest>({
      query: ({ query }) => ({
        url: `${path}?${querystring.stringify(query)}`,
        method: 'GET',
      }),
    }),
    getClosedJobs: build.query<api.AsJson<GetJobsResponse>, GetJobsRequest>({
      merge: (currentCache, response, { arg }) => {
        const newJobs = ((arg.query?.page ?? 0) > 1 ? currentCache.jobs : [])

        newJobs.push(...response.jobs)

        currentCache.jobs = newJobs
        currentCache.pagination = response.pagination
      },
      query: ({ query }) => ({
        url: `${path}?${querystring.stringify({
          status: 'closed',
          ...query,
        })}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
    }),
    getJobsImInterestedIn: build.query<api.AsJson<GetJobsResponse>, GetJobsRequest>({
      providesTags: [ 'JobsImInterestedIn' ],
      merge: (currentCache, response, { arg }) => {
        const newJobs = ((arg.query?.page ?? 0) > 1 ? currentCache.jobs : [])

        newJobs.push(...response.jobs)

        currentCache.jobs = newJobs
        currentCache.pagination = response.pagination
      },
      query: ({ query }) => ({
        url: `${path}?${querystring.stringify({
          ...query,
          iAmInterested: true,
        })}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
    }),
    getUpdatedJobs: build.query<api.AsJson<GetJobsResponse>, GetJobsRequest>({
      providesTags: [ 'Jobs' ],
      merge: (currentCache, response, { arg }) => {
        const newJobs = ((arg.query?.page ?? 0) > 1 ? currentCache.jobs : [])

        newJobs.push(...response.jobs)

        currentCache.jobs = newJobs
        currentCache.pagination = response.pagination
      },
      query: ({ query }) => ({
        url: `${path}?${querystring.stringify({
          ...query,
          sort: '-statusUpdatedAt',
          statusUpdated: true,
        })}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
    }),
    postJobInterestedWorker: build.mutation<api.AsJson<PostJobInterestedWorkerResponse>, unknown>({
      query: ({ params }) => ({
        url: `${path}/${params.jobId}/interested-workers/${params.userId}`,
        method: 'POST',
      }),
      invalidatesTags: [ 'JobsImInterestedIn' ],
    }),
    updateJobById: build.mutation<api.AsJson<PatchJobByIdResponse>, unknown>({
      query: ({
        body,
        params,
      }) => ({
        url: `${path}/${params.jobId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [ 'Jobs' ],
    }),
    upsertJob: build.mutation<api.AsJson<UpsertJobResponse>, UpsertJobRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.id === undefined ? '' : params.id}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export default jobService

export const {
  useDeleteJobByIdMutation,
  useGetClosedJobsQuery,
  useGetJobByIdQuery,
  useGetJobsQuery,
  useGetJobsImInterestedInQuery,
  useGetJobsWithInterestedWorkersQuery,
  useGetUpdatedJobsQuery,
  usePostJobInterestedWorkerMutation,
  useUpdateJobByIdMutation,
  useUpsertJobMutation,
} = jobService

export const {
  endpoints: {
    getClosedJobs,
    getJobById,
    getJobs,
    getJobsImInterestedIn,
    getJobsWithInterestedWorkers,
    getUpdatedJobs,
    postJobInterestedWorker,
    upsertJob,
  },
} = jobService
