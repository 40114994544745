import React, { useState } from 'react'

import Box from '../Box'
import Divider from '../Divider'
import HStack from '../HStack'
import Heading from '../Heading'
import Pressable from '../Pressable'
import Text from '../Text'
import VStack from '../VStack'

import { Props } from './types'

function ExpandableSection({
  children,
  isExpanded: initialIsExpanded,
  title,
  ...props
}: Props): React.ReactElement {
  const [
    isExpanded,
    setIsExpanded,
  ] = useState(initialIsExpanded)

  return (
    <VStack
      mt='4'
      {...props}
    >
      <HStack position='relative'>
        <Pressable onPress={() => setIsExpanded(prev => !prev)} width='100%'>
          <Heading variant='secondary' size='md' paddingRight='10'>{title}</Heading>
          <Text fontSize='4xl' color='brand.primary' lineHeight='0' position='absolute' right='2' top='2'>{isExpanded ? '-' : '+'}</Text>
        </Pressable>
      </HStack>
      {isExpanded && <Box>{children}</Box>}
      <Divider />
    </VStack>
  )
}

export default React.memo(ExpandableSection)
