import React from 'react'

import {
  Box,
  LoadingIndicator,
} from '@real-work/ui'

import type { Props } from './types'

function TabContent({
  children,
  index,
  tabs,
  unmountWhileUnfocused = false,
  ...props
}: Props) {
  return (
    <Box
      aria-labelledby={`${tabs.id}-${index}`}
      id={`${tabs.id}-content-${index}`}
      role='tabpanel'
      style={{ display: tabs.currentIndex !== index ? 'none' : undefined }}
      {...props}
    >
      {((!unmountWhileUnfocused || tabs.currentIndex === index) && children) || <LoadingIndicator />}
    </Box>
  )
}

export default React.memo(TabContent)
