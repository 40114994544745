import React, { useState } from 'react'

import {
  Box,
  CloseX,
  Image,
  Modal,
  Pressable,
  Text,
  useMediaQuery,
} from '@real-work/ui'

import { useGetNumTotalUnreadEventNotificationsQuery } from '@/services/eventNotification'

import Notifications from '../index'

import bellImage from './assets/bell.svg'

function Bell() {
  const [ mobileBreakpoint ] = useMediaQuery({ minWidth: 640 })
  const [
    isPopoutVisible,
    setIsPopoutVisible,
  ] = useState(false)

  const { data: { numTotalUnread } = {} } = useGetNumTotalUnreadEventNotificationsQuery(
    { query: { deliveryMethod: 'app' } },
    {
      pollingInterval: 60000,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  )

  return (
    <Box position='relative' pl='1' pr='1'>
      {isPopoutVisible ? (
        <Box
          position='absolute'
          width={mobileBreakpoint ? '380px' : '320px'}
          top='70px'
          right={mobileBreakpoint ? '-80px' : '0px'}
        >
          <Modal.Content maxHeight='600px'>
            <Modal.Header>Notifications <CloseX onPress={() => setIsPopoutVisible(prev => !prev)} position='absolute' right={0} top='-20px' /></Modal.Header>
            <Modal.Body>
              <Notifications />
            </Modal.Body>
          </Modal.Content>
        </Box>
      ) : undefined}
      <Pressable onPress={() => setIsPopoutVisible(prev => !prev)}>
        <Image
          source={bellImage}
          alt='Notifications'
          opacity={numTotalUnread ? 1 : 0.6}
          width={31}
          height={37}
        />
        {!!numTotalUnread && (
          <Box
            backgroundColor='white'
            borderColor='brand.secondary'
            borderWidth='2'
            borderRadius={8}
            alignItems='center'
            justifyContent='center'
            position='absolute'
            flexDirection='row'
            right='0'
            top='3px'
            pl='4px'
            pr='4px'
          >
            <Text color='brand.secondary' fontSize='13px' lineHeight='sm'>
              {numTotalUnread}
            </Text>
          </Box>
        )}
      </Pressable>
    </Box>
  )
}

export default React.memo(Bell)
