import React, {
  useEffect,
  useState,
} from 'react'
import { lang } from '@real-work/common'
import {
  Container,
  Divider,
  Heading,
  HStack,
  Form,
  LoadingIndicator,
  SubmitButton,
  Text,
  useToast,
} from '@real-work/ui'

import Link from '@/components/Link'

import StripeFormWrapper from '@/components/StripeFormWrapper'

import { useCreateSetupIntentMutation } from '@/services/stripe'
import useCompanySubscription from '@/hooks/useCompanySubscription'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

function EditPaymentInfo(): React.ReactElement {
  const toast = useToast()

  const [
    clientSecret,
    setClientSecret,
  ] = useState<string | undefined>()

  const [
    isLoading,
    setIsLoading,
  ] = useState(true)

  const { subscription } = useCompanySubscription()

  const [ createSetupIntent ] = useCreateSetupIntentMutation()

  useEffect(() => {
    if (!subscription?.customerId) {
      return
    }

    setIsLoading(true)

    createSetupIntent({ body: { stripeCustomerId: subscription?.customerId } })
      .unwrap()
      .then(response => {
        setClientSecret(response.clientSecret)
      })
      .catch(() => {
        toast.show({
          description: lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
      .finally(() => setIsLoading(false))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DocumentWrapper title='Real Work | Edit Payment Info'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (
          <>
            <Container>
              <Heading>Payment Info</Heading>
              <Text>
                Only one payment method can be on file at a time. Update your payment method using the form below.
              </Text>
            </Container>
            <Container mt='4'>
              <Heading size='md' variant='secondary'>Payment Method</Heading>
              <Divider />
              {(isLoading && <LoadingIndicator />) || (
                <StripeFormWrapper
                  clientSecret={clientSecret}
                  onSuccesssRedirectTo='/account'
                  type='updatePaymentMethod'
                >
                  {({
                    isSubmitting,
                    onSubmit,
                    PaymentForm,
                  }) => (
                    <Form onSubmit={onSubmit}>
                      {PaymentForm}
                      <HStack flexDirection='row' mt='4' mb='4' space='4' flexWrap='wrap'>
                        <SubmitButton isLoading={isSubmitting}>Save Changes</SubmitButton>
                        <Link type='button' button={{ isLoading: isSubmitting }} to='/account'>Cancel</Link>
                      </HStack>
                    </Form>
                  )}
                </StripeFormWrapper>
              )}
            </Container>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(EditPaymentInfo)
