import {
  makeTheme,
  themeTools,
} from '@real-work/ui'

import type { ThemeConfig } from '@real-work/ui'

// Customize theme for just the web app
const webTheme: ThemeConfig = {}

const theme = makeTheme(webTheme)

export const getColor = themeTools.getColor(theme)

export const getComponentTheme = themeTools.getComponentTheme(theme)

export default theme
