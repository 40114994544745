import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import Drawer from '@mui/material/Drawer'

import { CloseIcon } from 'native-base'

import {
  api,
  array,
  object,
} from '@real-work/common'

import { Models } from '@real-work/orm'

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  DescriptionList,
  ExpandableSection,
  Form,
  FormTypes,
  FormControl,
  Heading,
  HStack,
  Input,
  Select,
  Spinner,
  SubmitButton,
  Text,
  useMediaQuery,
  VStack,
} from '@real-work/ui'

import useInterdependentSelects, { InterdependentSelectOptions } from '@/hooks/useInterdependentSelects'

import { useGetCertificationsQuery } from '@/services/certification'
import { useGetLicensesQuery } from '@/services/license'
import { useGetOptionsQuery } from '@/services/option'
import { useGetTradesQuery } from '@/services/trade'

import type {
  CurrentFilter,
  FormValues,
  Props,
} from './types'

import { FiltersContext } from './context'

const numEmployeesToCompanySize = {
  '1 - 9': 'Small',
  '10 - 99': 'Medium',
  '100 +': 'Large',
}

function Filters({ mode }: Props): React.ReactElement {
  const [ isMobile ] = useMediaQuery({ minWidth: 550 })

  const filtersContext = useContext(FiltersContext)

  const [
    menuPortalTarget,
    setMenuPortalTarget,
  ] = useState<HTMLElement | undefined>()

  const {
    data: { certifications } = {},
    isLoading: isLoadingCertifications,
  } = useGetCertificationsQuery()

  const {
    data: { licenses } = {},
    isLoading: isLoadingLicenses,
  } = useGetLicensesQuery()

  const {
    data: { options } = {},
    isLoading: isLoadingOptions,
  } = useGetOptionsQuery()

  const {
    data: { trades } = {},
    isLoading: isLoadingTrades,
  } = useGetTradesQuery()

  const numberOfEmployeesOptions = useMemo(() => (options ?? [])
    .filter(opt => opt.type === 'numberOfEmployees')
    .map(opt => ({
      label: numEmployeesToCompanySize[opt.value as keyof typeof numEmployeesToCompanySize],
      value: opt.value,
    })), [ options ])

  const jobTypeOptions = useMemo(() => (options ?? [])
    .filter(opt => opt.type === 'jobType')
    .map(opt => ({
      label: opt.label,
      value: opt.value,
    })), [ options ])

  const stateAndCertificationOptions: InterdependentSelectOptions[] = useMemo(
    () => {
      if (!certifications) return []

      const stateMap: Record<string, api.AsJson<Models.Certification.default>[]> = { 'N/A': [] }

      certifications.forEach(cert => {
        const usStateAbbr = cert.usStateAbbr || 'N/A'

        if (!stateMap[usStateAbbr]) {
          stateMap[usStateAbbr] = []
        }

        stateMap[usStateAbbr].push(cert)
      })

      const allStateAbbrs = Object.keys(stateMap).sort(sortStatesAlphabetically)

      const options = allStateAbbrs.map(stateAbbr => ({
        label: stateAbbr,
        value: stateAbbr,
        children: stateMap[stateAbbr].map(cert => ({
          label: cert.name,
          value: cert.id,
          pillLabel: stateAbbr === 'N/A' ? cert.name : `${stateAbbr} | ${cert.name}`,
        })),
      }))

      return options
    },
    [ certifications ],
  )

  const stateAndLicenseOptions: InterdependentSelectOptions[] = useMemo(
    () => {
      if (!licenses) return []

      const stateMap: Record<string, api.AsJson<Models.License.default>[]> = { 'N/A': [] }

      licenses.forEach(license => {
        const usStateAbbr = license.usStateAbbr || 'N/A'

        if (!stateMap[usStateAbbr]) {
          stateMap[usStateAbbr] = []
        }

        stateMap[usStateAbbr].push(license)
      })

      const allStateAbbrs = Object.keys(stateMap).sort(sortStatesAlphabetically)

      const options = allStateAbbrs.map(stateAbbr => ({
        label: stateAbbr,
        value: stateAbbr,
        children: stateMap[stateAbbr].map(license => ({
          label: license.name,
          value: license.id,
          pillLabel: stateAbbr === 'N/A' ? license.name : `${stateAbbr} | ${license.name}`,
        })),
      }))

      return options
    },
    [ licenses ],
  )

  const tradeAndSpecialtyOptions: InterdependentSelectOptions[] = useMemo(
    () => (trades ?? [])
      .map(trade => ({
        label: trade.name,
        value: trade.id,
        children: (trade.specialties ?? []).map(specialty => ({
          label: specialty.name,
          value: specialty.id,
          pillLabel: `${trade.name} | ${specialty.name}`,
        })),
      })),
    [ trades ],
  )

  const travelDistanceOptions = useMemo(() => (options ?? [])
    .filter(opt => opt.type === 'travelDistance')
    .map(opt => ({
      label: opt.label,
      value: Number(opt.value),
    })), [ options ])

  const workShiftOptions = useMemo(() => (options ?? [])
    .filter(opt => opt.type === 'workShift')
    .map(opt => ({
      label: opt.label,
      value: opt.value,
    })), [ options ])

  const {
    availableChildOptions: certificationOptions,
    availableParentOptions: certificationStateOptions,
    onSelectChild: onSelectCertification,
    onSelectParent: onSelectStateForCertification,
    selectedChildOptions: selectedCertifications,
  } = useInterdependentSelects(stateAndCertificationOptions, filtersContext.values.certificationIds || [], 'N/A')

  const {
    availableChildOptions: licenseOptions,
    availableParentOptions: licenseStateOptions,
    onSelectChild: onSelectLicense,
    onSelectParent: onSelectStateForLicense,
    selectedChildOptions: selectedLicenses,
  } = useInterdependentSelects(stateAndLicenseOptions, filtersContext.values.licenseIds || [], 'N/A')

  const {
    availableChildOptions: specialtyOptions,
    availableParentOptions: tradeOptions,
    onSelectChild: onSelectSpecialty,
    onSelectParent: onSelectTrade,
    selectedChildOptions: selectedSpecialties,
  } = useInterdependentSelects(tradeAndSpecialtyOptions, filtersContext.values.specialtyIds || [])

  const onPressClose = useCallback((formik: FormTypes.FormikProps<FormValues>) => {
    filtersContext.closeDrawer()
    formik.setValues(filtersContext.values)
  }, [ filtersContext ])

  const onPressOpen = useCallback(() => {
    filtersContext.openDrawer()
  }, [ filtersContext ])

  const onSubmit = useCallback<(values: FormValues) => void>(values => {
    const sanitizedValues = object.except<FormValues>(values, [
      'trade',
      'certificationStateAbbr',
      'licenseStateAbbr',
    ])

    // These "helper" keys come from internal Formik state
    Object.keys(sanitizedValues).forEach(key => {
      if (key.startsWith('jobTypes_') || key.startsWith('workShifts_') || key.startsWith('numberOfEmployees_')) {
        delete sanitizedValues[key as keyof typeof sanitizedValues]
      }
    })

    filtersContext.setValues(sanitizedValues)
    filtersContext.closeDrawer()
  }, [ filtersContext ])

  const currentFilters: CurrentFilter[] = useMemo(
    () => {
      const {
        certificationIds,
        hourlyRateMax,
        hourlyRateMin,
        jobTypes,
        licenseIds,
        numberOfEmployees,
        specialtyIds,
        travelDistanceFromZip,
        travelDistance,
        workShifts,
      } = filtersContext.values

      const filters = []

      if (travelDistanceFromZip && (travelDistance || -1) >= 0) {
        filters.push({
          name: 'location',
          description: {
            title: 'Location',
            description: `Within ${travelDistance} miles of ${travelDistanceFromZip}`,
          },
        } as CurrentFilter)
      }

      if (specialtyIds && specialtyIds.length > 0) {
        const selectedSpecialties = tradeAndSpecialtyOptions.reduce((selected: string[], trade) => {
          const selectedSpecialtiesForTrade = trade.children.filter(specialty => (specialtyIds || []).includes(specialty.value.toString()))
          selected.push(...selectedSpecialtiesForTrade.map(specialty => specialty.pillLabel ?? 'Unknown'))

          return selected
        }, [])

        if (selectedSpecialties.length > 0) {
          filters.push({
            name: 'trades',
            description: {
              title: 'Trades',
              description: array.toListString(selectedSpecialties),
            },
          } as CurrentFilter)
        }
      }

      if (certificationIds && certificationIds.length >= 0) {
        const selectedCertifications = stateAndCertificationOptions.reduce((selected: string[], state) => {
          const selectedCertificationsForState = state.children.filter(cert => (certificationIds || []).includes(cert.value.toString()))
          selected.push(...selectedCertificationsForState.map(cert => cert.pillLabel ?? 'Unknown'))

          return selected
        }, [])

        if (selectedCertifications.length > 0) {
          filters.push({
            name: 'certifications',
            description: {
              title: 'Certifications',
              description: array.toListString(selectedCertifications),
            },
          } as CurrentFilter)
        }
      }

      if (licenseIds && licenseIds.length >= 0) {
        const selectedLicenses = stateAndLicenseOptions.reduce((selected: string[], state) => {
          const selectedLicensesForState = state.children.filter(license => (licenseIds || []).includes(license.value.toString() || ''))
          selected.push(...selectedLicensesForState.map(license => license.pillLabel ?? 'Unknown'))

          return selected
        }, [])

        if (selectedLicenses.length > 0) {
          filters.push({
            name: 'licenses',
            description: {
              title: 'Licenses',
              description: array.toListString(selectedLicenses),
            },
          } as CurrentFilter)
        }
      }

      if (jobTypes && jobTypes.length >= 0) {
        const selectedJobTypes = jobTypes.map(jobType => {
          const option = jobTypeOptions.find(option => option.value === jobType)

          return option?.label ?? 'Unknown'
        })

        if (selectedJobTypes.length > 0) {
          filters.push({
            name: 'jobTypes',
            description: {
              title: 'Job Types',
              description: array.toListString(selectedJobTypes),
            },
          } as CurrentFilter)
        }
      }

      let hourlyRateString
      if (hourlyRateMax && hourlyRateMin) {
        hourlyRateString = `Between $${hourlyRateMin} and $${hourlyRateMax}`
      }
      else if (hourlyRateMax) {
        hourlyRateString = `$${hourlyRateMax} and below`
      }
      else if (hourlyRateMin) {
        hourlyRateString = `$${hourlyRateMin} and above`
      }

      if (hourlyRateString) {
        filters.push({
          name: 'hourlyRate',
          description: {
            title: 'Hourly Rate',
            description: hourlyRateString,
          },
        } as CurrentFilter)
      }

      if (workShifts && workShifts.length >= 0) {
        const selectedWorkShifts = workShifts.map(workShift => {
          const option = workShiftOptions.find(option => option.value === workShift)

          return option?.label ?? 'Unknown'
        })

        if (selectedWorkShifts.length > 0) {
          filters.push({
            name: 'workShifts',
            description: {
              title: 'Work Hours',
              description: array.toListString(selectedWorkShifts),
            },
          } as CurrentFilter)
        }
      }

      if (numberOfEmployees && numberOfEmployees.length >= 0) {
        const selectedNumbersOfEmployees = numberOfEmployees.map(num => {
          const option = numberOfEmployeesOptions.find(option => option.value === num)

          return option?.label ?? 'Unknown'
        })

        if (selectedNumbersOfEmployees.length > 0) {
          filters.push({
            name: 'numberOfEmployees',
            description: {
              title: 'Company Size',
              description: array.toListString(selectedNumbersOfEmployees),
            },
          } as CurrentFilter)
        }
      }

      return filters
    },
    [
      filtersContext.values,
      jobTypeOptions,
      numberOfEmployeesOptions,
      stateAndCertificationOptions,
      stateAndLicenseOptions,
      tradeAndSpecialtyOptions,
      workShiftOptions,
    ],
  )

  const resetForm = useCallback((formik: FormTypes.FormikProps<FormValues>) => {
    onSelectSpecialty([])
    onSelectLicense([])
    onSelectCertification([])
    formik.resetForm({ values: {} as FormValues })
  }, [
    onSelectCertification,
    onSelectLicense,
    onSelectSpecialty,
  ])

  return (
    <Form<FormValues>
      initialValues={filtersContext.values}
      maxWidth='100%'
      rules={api.endpoints.jobs.get.validation.query.rules}
      onSubmit={onSubmit}
    >
      {formik => (
        <HStack
          flexDirection={isMobile ? 'row-reverse' : 'column'}
          flexWrap='wrap'
          my='4'
        >
          <Box
            ml={isMobile ? 'auto' : '0'}
            mb={isMobile ? '0' : '4'}
            maxW='130px'
          >
            <Button
              size='sm'
              _text={{ fontSize: 'sm' }}
              onPress={onPressOpen}
            >
              + Filters (5)
            </Button>

            <Drawer
              anchor='right'
              onClose={() => onPressClose(formik)}
              open={filtersContext.isDrawerOpen}
              PaperProps={{
                id: 'Drawer',
                sx: {
                  width: '100%',
                  maxWidth: '860px',
                },
              }}
            >
              <CloseIcon
                color='singletons.black'
                onClick={() => onPressClose(formik)}
                position='absolute'
                right='2'
                size='20px'
                style={{ zIndex: '3' }}
                top='5'
              />

              <Box
                flex='1'
                onLayout={() => setMenuPortalTarget(document.getElementById('Drawer') || undefined)}
                p='4'
              >
                <Heading>Filters</Heading>
                <Text>
                  All {mode === 'jobs' ? 'jobs' : 'candidates'} show by default. Make selections to narrow the list.
                </Text>
                <Box>
                  <ButtonGroup my='4' justifyContent='flex-start'>
                    <SubmitButton
                      size='sm'
                      _text={{ fontSize: 'sm' }}
                    >
                      Apply Filters
                    </SubmitButton>
                    <Button
                      size='sm'
                      _text={{ fontSize: 'sm' }}
                      variant='outline'
                      onPress={() => resetForm(formik)}
                    >
                      Clear All
                    </Button>
                    <Button
                      size='sm'
                      _text={{ fontSize: 'sm' }}
                      variant='outline'
                      onPress={() => onPressClose(formik)}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Box>
                <ExpandableSection
                  isExpanded={!!currentFilters.find(filter => filter.name === 'location')}
                  title={mode === 'jobs' ? 'Locations' : 'Candidate Location'}
                >
                  <HStack space='4' flexDirection={isMobile ? 'row' : 'column'}>
                    <FormControl
                      name='travelDistanceFromZip'
                      label='Zip Code'
                      flex='1'
                    >
                      <Input
                        type='text'
                      />
                    </FormControl>
                    <FormControl
                      name='travelDistance'
                      label='Within'
                      flex='1'
                    >
                      {((isLoadingOptions || !menuPortalTarget) && <Spinner />) || (
                        <Select
                          options={travelDistanceOptions}
                          menuPortalTarget={menuPortalTarget}
                        />
                      )}
                    </FormControl>
                  </HStack>
                </ExpandableSection>

                <ExpandableSection
                  isExpanded={!!currentFilters.find(filter => filter.name === 'trades')}
                  title='Trades'
                >
                  <HStack space='4' flexDirection={isMobile ? 'row' : 'column'}>
                    <FormControl
                      name='trade'
                      label='Trade'
                      flex='1'
                    >
                      {((isLoadingTrades || !menuPortalTarget) && <Spinner />) || (
                        <Select
                          menuPortalTarget={menuPortalTarget}
                          onChange={onSelectTrade}
                          options={tradeOptions}
                        />
                      )}
                    </FormControl>
                    <FormControl
                      name='specialtyIds'
                      label='Specialty'
                      flex='1'
                    >
                      {((isLoadingTrades || !menuPortalTarget) && <Spinner />) || (
                        <Select
                          isMulti={true}
                          menuPortalTarget={menuPortalTarget}
                          onChange={onSelectSpecialty}
                          options={specialtyOptions}
                          pillsLocation='outside'
                          value={selectedSpecialties}
                        />
                      )}
                    </FormControl>
                  </HStack>
                </ExpandableSection>

                <ExpandableSection
                  isExpanded={!!currentFilters.find(filter => filter.name === 'certifications')}
                  title='Certifications'
                >
                  <HStack space='4' flexDirection={isMobile ? 'row' : 'column'}>
                    <FormControl
                      name='certificationStateAbbr'
                      label='State'
                      flex='1'
                    >
                      {((isLoadingCertifications || !menuPortalTarget) && <Spinner />) || (
                        <Select
                          menuPortalTarget={menuPortalTarget}
                          onChange={onSelectStateForCertification}
                          options={certificationStateOptions}
                        />
                      )}
                    </FormControl>
                    <FormControl
                      name='certificationIds'
                      label='Certification'
                      flex='1'
                    >
                      {((isLoadingCertifications || !menuPortalTarget) && <Spinner />) || (
                        <Select
                          isMulti={true}
                          menuPortalTarget={menuPortalTarget}
                          onChange={onSelectCertification}
                          options={certificationOptions}
                          pillsLocation='outside'
                          value={selectedCertifications}
                        />
                      )}
                    </FormControl>
                  </HStack>
                </ExpandableSection>

                <ExpandableSection
                  isExpanded={!!currentFilters.find(filter => filter.name === 'licenses')}
                  title='Licenses'
                >
                  <HStack space='4' flexDirection={isMobile ? 'row' : 'column'}>
                    <FormControl
                      name='licenseStateAbbr'
                      label='State'
                      flex='1'
                    >
                      {((isLoadingLicenses || !menuPortalTarget) && <Spinner />) || (
                        <Select
                          menuPortalTarget={menuPortalTarget}
                          onChange={onSelectStateForLicense}
                          options={licenseStateOptions}
                        />
                      )}
                    </FormControl>
                    <FormControl
                      name='licenseIds'
                      label='License'
                      flex='1'
                    >
                      {((isLoadingLicenses || !menuPortalTarget) && <Spinner />) || (
                        <Select
                          isMulti={true}
                          menuPortalTarget={menuPortalTarget}
                          onChange={onSelectLicense}
                          options={licenseOptions}
                          pillsLocation='outside'
                          value={selectedLicenses}
                        />
                      )}
                    </FormControl>
                  </HStack>
                </ExpandableSection>

                <ExpandableSection
                  isExpanded={!!currentFilters.find(filter => filter.name === 'jobTypes')}
                  title='Job Type'
                >
                  <FormControl
                    mt='2'
                    name='jobTypes'
                  >
                    {(isLoadingOptions && <Spinner />) || (
                      <VStack space='2'>
                        {jobTypeOptions.map(option => (
                          <Checkbox
                            isChecked={formik.values.jobTypes?.includes(option.value) ?? false}
                            key={option.value}
                            name={`jobTypes_${option.value}`}
                            onChange={checked => {
                              const currentValues = [ ...formik.values.jobTypes ?? [] ]

                              if (checked) {
                                currentValues.push(option.value)
                              }
                              else {
                                const ix = currentValues.indexOf(option.value)
                                if (ix > -1) {
                                  currentValues.splice(ix, 1)
                                }
                              }

                              formik.setFieldValue('jobTypes', currentValues)
                            }}
                          >
                            {option.label}
                          </Checkbox>
                        ))}
                      </VStack>
                    )}
                  </FormControl>
                </ExpandableSection>

                <ExpandableSection
                  isExpanded={!!currentFilters.find(filter => filter.name === 'hourlyRate')}
                  title='Hourly Rate'
                >
                  <HStack space='4' flexDirection={isMobile ? 'row' : 'column'}>
                    <FormControl
                      name='hourlyRateMin'
                      label='Minimum'
                      flex='1'
                    >
                      <Input
                        type='number'
                      />
                    </FormControl>
                    <FormControl
                      name='hourlyRateMax'
                      label='Maximum'
                      flex='1'
                    >
                      <Input
                        type='number'
                      />
                    </FormControl>
                  </HStack>
                </ExpandableSection>

                <ExpandableSection
                  isExpanded={!!currentFilters.find(filter => filter.name === 'workShifts')}
                  title='Work Hours'
                >
                  <FormControl
                    mt='2'
                    name='workShifts'
                  >
                    {(isLoadingOptions && <Spinner />) || (
                      <VStack space='2'>
                        {workShiftOptions.map(option => (
                          <Checkbox
                            isChecked={formik.values.workShifts?.includes(option.value) ?? false}
                            key={option.value}
                            name={`workShifts_${option.value}`}
                            onChange={checked => {
                              const currentValues = [ ...formik.values.workShifts ?? [] ]

                              if (checked) {
                                currentValues.push(option.value)
                              }
                              else {
                                const ix = currentValues.indexOf(option.value)
                                if (ix > -1) {
                                  currentValues.splice(ix, 1)
                                }
                              }

                              formik.setFieldValue('workShifts', currentValues)
                            }}
                          >
                            {option.label}
                          </Checkbox>
                        ))}
                      </VStack>
                    )}
                  </FormControl>
                </ExpandableSection>

                {mode === 'jobs' && (
                  <ExpandableSection
                    isExpanded={!!currentFilters.find(filter => filter.name === 'numberOfEmployees')}
                    title='Company Size'
                  >
                    <FormControl
                      mt='2'
                      name='numberOfEmployees'
                    >
                      {(isLoadingOptions && <Spinner />) || (
                        <VStack space='2'>
                          {numberOfEmployeesOptions.map(option => (
                            <Checkbox
                              isChecked={formik.values.numberOfEmployees?.includes(option.value) ?? false}
                              key={option.value}
                              name={`numberOfEmployees_${option.value}`}
                              onChange={checked => {
                                const currentValues = [ ...formik.values.numberOfEmployees ?? [] ]

                                if (checked) {
                                  currentValues.push(option.value)
                                }
                                else {
                                  const ix = currentValues.indexOf(option.value)
                                  if (ix > -1) {
                                    currentValues.splice(ix, 1)
                                  }
                                }

                                formik.setFieldValue('numberOfEmployees', currentValues)
                              }}
                            >
                              {option.label}
                            </Checkbox>
                          ))}
                        </VStack>
                      )}
                    </FormControl>
                  </ExpandableSection>
                )}
              </Box>
            </Drawer>
          </Box>

          {!!currentFilters.length && (
            <VStack alignItems='flex-start'>
              <DescriptionList items={currentFilters.map(filter => filter.description)} />
              <Button
                size='sm'
                _text={{ fontSize: 'sm' }}
                variant='outline'
                mt='6'
                onPress={() => {
                  resetForm(formik)

                  formik.submitForm()
                }}
              >
                Clear Filters
              </Button>
            </VStack>
          )}
        </HStack>
      )}
    </Form>
  )
}

const sortStatesAlphabetically = (a: string, b: string) => {
  const comp = (a === 'N/A' && -1) || (b === 'N/A' && 1) || a.localeCompare(b)

  return comp
}

export default React.memo(Filters)

export * from './types'

export { FiltersContext } from './context'
