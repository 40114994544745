import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  Link as RouterLink,
  useNavigate,
} from 'react-router-dom'
import {
  StyleProp,
  ViewStyle,
} from 'react-native'
import { ITextProps } from 'native-base'

import { string } from '@real-work/common'

import {
  Box,
  Button,
  Image,
  Link as UiLink,
  Text,
  VStack,
} from '@real-work/ui'

import {
  getColor,
  getComponentTheme,
} from '../../theme'

import IconExternal from './assets/icon-external.svg'

import { Props } from './types'

function Link({
  _text,
  button,
  children,
  color,
  icon,
  isExternal: isExternalProp = undefined,
  routerLink,
  style,
  tabIndex = 0,
  target: targetProp = undefined,
  to,
  type = undefined,
  ...props
}: Props): React.ReactElement {
  const navigate = useNavigate()

  const routerLinkStyle: React.CSSProperties = useMemo(() => ({
    textDecoration: 'none',
    ...(routerLink?.style || {}),
  }), [ routerLink ])

  const uiLinkStyle: StyleProp<ViewStyle> = useMemo(() => ({
    alignItems: 'center',
    ...(typeof style === 'object' ? style : {}),
  }), [ style ])
  const uiLinkTextStyle: Partial<ITextProps> = useMemo(() => ({
    ...(color ? { color } : {}),
    ...(typeof _text === 'object' ? _text : {}),
  }), [
    color,
    _text,
  ])

  const isToExternalUrl = useMemo(() => string.isUrl(to), [ to ])

  const isExternal = useMemo(() => (isToExternalUrl && isExternalProp !== false) || isExternalProp, [
    isExternalProp,
    isToExternalUrl,
  ])

  const target = useMemo(() => targetProp || (isExternal && '_blank') || undefined, [
    isExternal,
    targetProp,
  ])

  const onPressButton = useCallback(() => {
    if (isToExternalUrl) {
      return window.open(to as string, '_blank')
    }

    return navigate(to)
  }, [
    isToExternalUrl,
    navigate,
    to,
  ])

  if (type === 'button') {
    return (
      <Button
        color={color}
        onPress={onPressButton}
        {...(button || {})}
      >
        <Text
          {...getComponentTheme('Button', button)?._text || {}}
          {...((button || {})._text || {})}
        >
          {children}
          {isExternal && (
            <Box ml='2'>
              <IconExternal
                color={getColor(color || getComponentTheme('Button', button)?._text?.color)}
              />
            </Box>
          )}
        </Text>
      </Button>
    )
  }

  if (type === 'squareIconButton') {
    return (
      <Button
        color={color}
        borderRadius='8'
        onPress={onPressButton}
        {...(button || {})}
        width='110'
        height='110'
        alignItems='center'
        textAlign='center'
        paddingLeft='0'
        paddingRight='0'
        mx='1'
      >
        <VStack alignItems='center' justifyContent='space-between' height='80px' width='100px'>
          {isExternal && (
            <Box position='absolute' right='0' top='-8px'>
              <IconExternal
                color={getColor(color || getComponentTheme('Button', button)?._text?.color)}
              />
            </Box>
          )}
          {icon && (
            <Image
              height={8}
              width={8}
              {...icon}
            />
          )}
          <Text fontWeight='bold' color='white' lineHeight='1.2rem'>
            {children}
          </Text>
        </VStack>
      </Button>
    )
  }

  return (
    <RouterLink
      tabIndex={tabIndex}
      {...(routerLink || {})}
      target={target}
      to={to}
      style={routerLinkStyle}
    >
      <UiLink
        {...props}
        color={color}
        style={uiLinkStyle}
        _text={uiLinkTextStyle}
      >
        {children}
        {isExternal && (
          <Box ml='1'>
            <IconExternal
              color={getColor(uiLinkTextStyle.color || getComponentTheme('Link', props)?._text?.color)}
            />
          </Box>
        )}
      </UiLink>
    </RouterLink>
  )
}

export * from './types'

export default React.memo(Link)
