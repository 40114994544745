import type { api } from '@real-work/common'
import { querystring } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import {
  DeleteWorkerCertificationRequest,
  DeleteWorkerCertificationResponse,
  DeleteEducationRequest,
  DeleteEducationResponse,
  DeleteWorkerLicenseRequest,
  DeleteWorkerLicenseResponse,
  DeleteMembershipRequest,
  DeleteMembershipResponse,
  DeleteProductExperienceRequest,
  DeletProductExperienceResponse,
  DeleteSkillRequest,
  DeleteSkillResponse,
  DeleteWorkerSpecialtyRequest,
  DeleteWorkerSpecialtyResponse,
  DeleteWorkExperienceRequest,
  DeleteWorkExperienceResponse,
  GetWorkerByIdRequest,
  GetWorkerByIdResponse,
  GetWorkersRequest,
  GetWorkersResponse,
  PatchWorkerByIdRequest,
  PatchWorkerByIdResponse,
  PutProductExperienceRequest,
  PutProductExperienceResponse,
  PutSkillRequest,
  PutSkillResponse,
  PutWorkerCertificationResponse,
  PutWorkerCertificationRequest,
  PutWorkExperienceResponse,
  PutWorkExperienceRequest,
  PutWorkerEducationRequest,
  PutWorkerEducationResponse,
  PutWorkerLicenseRequest,
  PutWorkerLicenseResponse,
  PutWorkerMembershipRequest,
  PutWorkerMembershipResponse,
  PutWorkerSpecialtyRequest,
  PutWorkerSpecialtyResponse,
} from './types'

const path = 'v1/workers'

const workerService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    deleteWorkerCertification: build.mutation<api.AsJson<DeleteWorkerCertificationResponse>, DeleteWorkerCertificationRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.userId}/certifications/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    deleteProductExperience: build.mutation<api.AsJson<DeletProductExperienceResponse>, DeleteProductExperienceRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.userId}/product-experiences/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    deleteWorkerEducation: build.mutation<api.AsJson<DeleteEducationResponse>, DeleteEducationRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.userId}/educations/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    deleteWorkerLicense: build.mutation<api.AsJson<DeleteWorkerLicenseResponse>, DeleteWorkerLicenseRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.userId}/licenses/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    deleteWorkerMembership: build.mutation<api.AsJson<DeleteMembershipResponse>, DeleteMembershipRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.userId}/memberships/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    deleteWorkerSkill: build.mutation<api.AsJson<DeleteSkillResponse>, DeleteSkillRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.userId}/skills/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    deleteWorkerSpecialty: build.mutation<api.AsJson<DeleteWorkerSpecialtyResponse>, DeleteWorkerSpecialtyRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.userId}/specialties/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'Sessions',
        'Workers',
      ],
    }),
    deleteWorkExperience: build.mutation<api.AsJson<DeleteWorkExperienceResponse>, DeleteWorkExperienceRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.userId}/work-experiences/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    getWorkerByUserId: build.query<api.AsJson<GetWorkerByIdResponse>, GetWorkerByIdRequest>({
      query: ({ params }) => `${path}/${params.userId}`,
      providesTags: [ 'Workers' ],
    }),
    getWorkers: build.query<api.AsJson<GetWorkersResponse>, GetWorkersRequest>({
      providesTags: [ 'Workers' ],
      merge: (currentCache, response, { arg }) => {
        const newWorkers = ((arg.query?.page ?? 0) > 1 ? currentCache.workers : [])

        newWorkers.push(...response.workers)

        currentCache.workers = newWorkers
        currentCache.pagination = response.pagination
      },
      query: ({ query }) => ({
        url: `${path}?${querystring.stringify(query)}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
    }),
    patchWorkerById: build.mutation<api.AsJson<PatchWorkerByIdResponse>, PatchWorkerByIdRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.userId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'Sessions',
        'Workers',
      ],
    }),
    upsertWorkerCertification: build.mutation<api.AsJson<PutWorkerCertificationResponse>, PutWorkerCertificationRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.userId}/certifications${params.id ? `/${params.id}` : ''}`,
        method: 'PUT',
        body,
      }),
    }),
    upsertWorkerEducation: build.mutation<api.AsJson<PutWorkerEducationResponse>, PutWorkerEducationRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.userId}/educations${params.id ? `/${params.id}` : ''}`,
        method: 'PUT',
        body,
      }),
    }),
    upsertWorkerLicense: build.mutation<api.AsJson<PutWorkerLicenseResponse>, PutWorkerLicenseRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.userId}/licenses${params.id ? `/${params.id}` : ''}`,
        method: 'PUT',
        body,
      }),
    }),
    upsertWorkerMembership: build.mutation<api.AsJson<PutWorkerMembershipResponse>, PutWorkerMembershipRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.userId}/memberships${params.id ? `/${params.id}` : ''}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    upsertWorkerProductExperience: build.mutation<api.AsJson<PutProductExperienceResponse>, PutProductExperienceRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.userId}/product-experiences${params.id ? `/${params.id}` : ''}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    upsertWorkerSkill: build.mutation<api.AsJson<PutSkillResponse>, PutSkillRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.userId}/skills${params.id ? `/${params.id}` : ''}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ 'Workers' ],
    }),
    upsertWorkerSpecialty: build.mutation<api.AsJson<PutWorkerSpecialtyResponse>, PutWorkerSpecialtyRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.userId}/specialties${params.id ? `/${params.id}` : ''}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'Sessions',
        'Workers',
      ],
    }),
    upsertWorkerWorkExperience: build.mutation<api.AsJson<PutWorkExperienceResponse>, PutWorkExperienceRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.userId}/work-experiences${params.id ? `/${params.id}` : ''}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ 'Workers' ],
    }),
  }),
})

export default workerService

export const {
  useDeleteProductExperienceMutation,
  useDeleteWorkerCertificationMutation,
  useDeleteWorkerEducationMutation,
  useDeleteWorkerLicenseMutation,
  useDeleteWorkerMembershipMutation,
  useDeleteWorkerSkillMutation,
  useDeleteWorkerSpecialtyMutation,
  useDeleteWorkExperienceMutation,
  useGetWorkerByUserIdQuery,
  useGetWorkersQuery,
  usePatchWorkerByIdMutation,
  useUpsertWorkerCertificationMutation,
  useUpsertWorkerEducationMutation,
  useUpsertWorkerLicenseMutation,
  useUpsertWorkerMembershipMutation,
  useUpsertWorkerProductExperienceMutation,
  useUpsertWorkerSkillMutation,
  useUpsertWorkerSpecialtyMutation,
  useUpsertWorkerWorkExperienceMutation,
} = workerService

export const {
  endpoints: {
    deleteProductExperience,
    deleteWorkerCertification,
    deleteWorkerEducation,
    deleteWorkerLicense,
    deleteWorkerMembership,
    deleteWorkerSkill,
    deleteWorkerSpecialty,
    deleteWorkExperience,
    getWorkerByUserId,
    getWorkers,
    patchWorkerById,
    upsertWorkerCertification,
    upsertWorkerEducation,
    upsertWorkerLicense,
    upsertWorkerMembership,
    upsertWorkerProductExperience,
    upsertWorkerSkill,
    upsertWorkerSpecialty,
    upsertWorkerWorkExperience,
  },
} = workerService
