import React, {
  useEffect,
  useMemo,
} from 'react'
import { Outlet } from 'react-router-dom'

import {
  LoadingIndicator,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useCompanySubscription from '@/hooks/useCompanySubscription'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import Error403 from '@/screens/Error403'

import {
  Props,
  ProtectedRouteTest,
} from './types'

function ProtectedRoute({
  children,
  otherwise,
  test,
  toast,
}: Props): React.ReactElement {
  const routeParams = useRouteParams()
  const subscription = useCompanySubscription()
  const session = useSession()

  const { show: showToast } = useToast()

  const otherwises = useMemo(() => Array.isArray(otherwise) ? otherwise : [ otherwise ], [ otherwise ])
  const tests = useMemo(() => Array.isArray(test) ? test : [ test ], [ test ])
  const toasts = useMemo(() => Array.isArray(toast) ? toast : [ toast ], [ toast ])

  const testResults = tests.map(test => test({
    routeParams,
    session,
    subscription,
  }))

  const failedTestIndex = testResults.findIndex(result => !result.passed)

  useEffect(() => {
    if (failedTestIndex >= 0 && !testResults[failedTestIndex].isLoading) {
      if (toasts.length === 1) {
        toasts[0] && showToast(toasts[0])

        return
      }

      const theToast = toasts[failedTestIndex]
      if (theToast) {
        showToast(theToast)
      }
    }
  }, [
    failedTestIndex,
    showToast,
    testResults,
    toasts,
  ])

  if (testResults.find(result => result.isLoading)) {
    return (
      <DocumentWrapper title='Real Work | Loading...'>
        <ScreenWrapper>
          <LoadingIndicator />
        </ScreenWrapper>
      </DocumentWrapper>
    )
  }

  if (failedTestIndex >= 0) {
    if (otherwises.length === 1) {
      return otherwises[0] || <Error403 />
    }

    if (otherwises[failedTestIndex]) {
      return otherwises[failedTestIndex] || <Error403 />
    }

    return <Error403 />
  }

  return children || <Outlet />
}

export default React.memo(ProtectedRoute)

export const isCompanySubscriptionActive: ProtectedRouteTest = ({ subscription }) => ({
  isLoading: subscription.isLoading ?? true,
  passed: !!subscription.subscription?.isActive,
})

export const isCompanySubscriptionComplete: ProtectedRouteTest = ({ subscription }) => ({
  isLoading: subscription.isLoading ?? true,
  passed: !!subscription.subscription?.didComplete,
})

export const isCompanySubscriptionIncomplete: ProtectedRouteTest = ({ subscription }) => ({
  isLoading: subscription.isLoading ?? true,
  passed: !subscription.subscription?.didComplete,
})

export const hasCompanySubscription: ProtectedRouteTest = ({ subscription }) => ({
  isLoading: subscription.isLoading ?? true,
  passed: !!subscription.subscription,
})

export const isLoggedIn: ProtectedRouteTest = ({ session }) => ({
  isLoading: session.isLoadingSession,
  passed: !!session?.user,
})

export const isNotLoggedIn: ProtectedRouteTest = ({ session }) => ({
  isLoading: session.isLoadingSession,
  passed: !session?.user,
})

export const isUserEmailUnverified: ProtectedRouteTest = ({ session }) => ({
  isLoading: session.isLoadingSession,
  passed: !session?.user?.emailVerifiedAt,
})

export const isUserEmailVerified: ProtectedRouteTest = ({ session }) => ({
  isLoading: session.isLoadingSession,
  passed: !!session?.user?.emailVerifiedAt,
})

export const isUserOwnerOfRouteParamIdCompany: ProtectedRouteTest = ({
  routeParams,
  session,
}) => ({
  isLoading: session.isLoadingSession,
  passed: session.user?.employer?.companyOwned?.id === routeParams.id,
})

export const isWorkerProfilePublished: ProtectedRouteTest = ({ session }) => ({
  isLoading: session.isLoadingSession,
  passed: !!session?.user?.worker?.profilePublishedAt,
})
