import React, { useContext } from 'react'

import Drawer from '@mui/material/Drawer'

import { url } from '@real-work/common'

import {
  Box,
  Divider,
  HStack,
  LoadingIndicator,
  Text,
} from '@real-work/ui'

import Link from '@/components/Link'

import useCompanySubscription from '@/hooks/useCompanySubscription'
import useSession from '@/hooks/useSession'

import { MenuContext } from './context'

function Menu(): React.ReactElement {
  const companySubscription = useCompanySubscription()
  const session = useSession()

  const {
    isMenuOpen,
    closeMenu,
  } = useContext(MenuContext)

  return (
    <Drawer
      anchor='left'
      onClose={closeMenu}
      open={isMenuOpen}
      PaperProps={{ sx: { backgroundColor: '#E5E5E5' } }}
    >
      <Box backgroundColor='muted.200' p='4' flex='1'>
        {(!session?.user && (
          <>
            <Link
              to={url.marketingSite('/')}
              isExternal={true}
              mb='4'
              variant='topnav'
            >
              REALWORK.COM
            </Link>
            <Link
              to='/sign-up/employer'
              variant='topnav'
            >
              EMPLOYER SIGN UP
            </Link>
            <Link
              to='/sign-up/worker'
              variant='topnav'
            >
              WORKER SIGN UP
            </Link>
          </>
        )) || (
          <Box mb='3'>
            <Text>Hi, {session?.user?.firstName}</Text>
            <Link
              to='/sign-out'
              variant='subnav'
            >
              Sign Out
            </Link>
          </Box>
        )}

        {session?.user?.role?.slug === 'worker' && !session?.user?.worker?.profilePublishedAt && (
          <Link
            to='/profile/edit'
            variant='subnav'
          >
            Create Profile
          </Link>
        )}
        {session?.user?.role?.slug === 'worker' && session?.user?.worker?.profilePublishedAt && (
          <>
            <Link
              to='/dashboard'
              variant='topnav'
            >
              DASHBOARD
            </Link>

            <Divider background='muted.500' />

            <Text bold={true} fontSize='xl' color='muted.400'>JOBS</Text>
            <Link
              to='/jobs/?tab=open'
              variant='subnav'
            >
              Open Jobs
            </Link>
            <Link
              to='/jobs/?tab=interested'
              variant='subnav'
            >
              Jobs I’m Interested In
            </Link>

            <Divider background='muted.500' />

            <Text bold={true} fontSize='xl' color='muted.400'>MY PROFILE</Text>
            <Link
              to={`/workers/${session.user.id}`}
              variant='subnav'
            >
              View Profile
            </Link>
            <Link
              to='/profile/edit'
              variant='subnav'
            >
              Edit Profile
            </Link>
          </>
        )}

        {(session?.user?.role?.slug === 'employer' && companySubscription.isLoading && <LoadingIndicator />) || (session?.user?.role?.slug === 'employer' && companySubscription.subscription?.isActive && (
          <>
            <Link
              to='/dashboard'
              variant='topnav'
            >
              DASHBOARD
            </Link>

            <Divider background='muted.500' />

            <Text bold={true} fontSize='xl' color='muted.400'>REAL WORKERS</Text>
            <Link
              to='/workers'
              variant='subnav'
            >
              All Workers
            </Link>
            <Link
              to='/workers?tab=favorite'
              variant='subnav'
            >
              Favorite Workers
            </Link>
            <Link
              to='/workers?tab=interested'
              variant='subnav'
            >
              Interested Workers
            </Link>

            <Divider background='muted.500' />

            <Text bold={true} fontSize='xl' color='muted.400'>JOBS</Text>
            <Link
              to='/jobs/?tab=open'
              variant='subnav'
            >
              Open Listings
            </Link>
            <Link
              to='/jobs/?tab=closed'
              variant='subnav'
            >
              Closed Listings
            </Link>
            <Link
              to='/jobs/new'
              variant='subnav'
            >
              Add Job
            </Link>

            {(session?.user?.employer?.companyOwned && (
              <>
                <Divider background='muted.500' />

                <Text bold={true} fontSize='xl' color='muted.400'>COMPANY</Text>
                <Link
                  to={`/companies/${session.user.employer.companyOwned.id}`}
                  variant='subnav'
                >
                  View Profile
                </Link>
                <Link
                  to={`/companies/${session.user.employer.companyOwned.id}/edit`}
                  variant='subnav'
                >
                  Edit Profile
                </Link>

                <Divider background='muted.500' />

                <Text bold={true} fontSize='xl' color='muted.400'>TEAM</Text>
                <Link
                  to='/company/team'
                  variant='subnav'
                >
                  My Team
                </Link>
                <Link
                  to='/company/team/invites'
                  variant='subnav'
                >
                  Pending Invites
                </Link>
                <Link
                  to='/company/team/invites/new'
                  variant='subnav'
                >
                  Add Team Member
                </Link>
              </>
            ))}
          </>
        ))}

        {session?.user && (
          <>
            <Divider background='muted.500' />
            <Link
              to='/account'
              variant='topnav'
            >
              ACCOUNT
            </Link>
            <Link
              to={url.marketingSite('/about-us')}
              variant='topnav'
              isExternal={true}
            >
              ABOUT US
            </Link>
            <Link
              to={url.marketingSite('/feedback')}
              variant='topnav'
              isExternal={true}
            >
              FEEDBACK
            </Link>
            <Link
              to={url.marketingSite('/support')}
              variant='topnav'
              isExternal={true}
            >
              SUPPORT
            </Link>
          </>
        )}

        <HStack justifyContent='center' marginTop='60px' alignSelf='center'>
          <Link
            to={url.marketingSite('/privacy')}
            _text={{
              color: 'black',
              fontSize: '16px',
            }}
            _hover={{ _text: { color: 'secondary.600' } }}
          >
            Privacy Policy
          </Link><Text mx='1' fontSize='16px'>and</Text>
          <Link
            to={url.marketingSite('/terms')}
            _text={{
              color: 'black',
              fontSize: '16px',
            }}
            _hover={{ _text: { color: 'secondary.600' } }}
          >
            Terms & Conditions
          </Link>
        </HStack>
      </Box>
    </Drawer>
  )
}

export default React.memo(Menu)

export { MenuContext } from './context'

export * from './types'
