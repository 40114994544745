import React from 'react'

import Text from '../../Text'
import VStack from '../../VStack'

import { Props } from './types'

function DescriptionList({
  items,
  ...props
}: Props): React.ReactElement | null {
  items = items.filter(item => !!item.description)

  if (!items.length) {
    return null // eslint-disable-line no-null/no-null
  }

  return (
    <VStack
      space='.5'
      {...props}
    >
      {items.map(({
        description,
        title,
      }) => (
        <Text fontFamily='BarlowMedium' key={title}>{title}: <Text fontFamily='BarlowRegular'>{description}</Text></Text>
      ))}
    </VStack>
  )
}

export default React.memo(DescriptionList)

export * from './types'
