import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import {
  Box,
  Button,
  Divider,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
} from '@real-work/ui'

import Job from '@/components/Job'

import { useGetJobsImInterestedInQuery } from '@/services/job'

import { hasMorePages } from '@/utils/pagination'

function JobsInterested(): React.ReactElement {
  const [
    page,
    setPage,
  ] = useState(1)

  const {
    data: {
      jobs,
      pagination,
    } = {},
    isError,
    isFetching,
  } = useGetJobsImInterestedInQuery({ query: { page } }, { refetchOnMountOrArgChange: true })

  const isLoadingFirstPage = useMemo(() => (isFetching && (page || 1) < 2), [
    isFetching,
    page,
  ])

  const onClickShowMoreButton = useCallback(() => setPage(prevPage => (prevPage || 1) + 1), [])

  return (
    (isLoadingFirstPage && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
      <>
        {jobs?.length === 0 ? <NoContent /> : jobs?.map(job => (
          <React.Fragment key={job.id}>
            <Job job={job} />
            <Divider />
          </React.Fragment>
        ))}

        {hasMorePages(pagination) && (
          <Box mx='auto' mt='6'>
            <Button
              isLoading={isFetching}
              onPress={onClickShowMoreButton}
            >
              Show More
            </Button>
          </Box>
        )}
      </>
    )
  )
}

export default React.memo(JobsInterested)
