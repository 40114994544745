import React, {
  useCallback,
  useState,
} from 'react'

import { lang } from '@real-work/common'

import Confirm from '../../Confirm'
import Button from '../../Button'
import Divider from '../../Divider'
import HStack from '../../HStack'
import Stack from '../../Stack'
import Text from '../../Text'

import { Props } from './types'

function ProfileListItem({
  description,
  onPressDelete: onPressDeleteProp,
  onPressDeleteConfirmText = lang().messages.removeFromProfileConfirmation(),
  onPressEdit,
  expDate,
  title,
}: Props): React.ReactElement {
  const [
    isConfirmDeleteOpen,
    setIsConfirmDeleteOpen,
  ] = useState(false)

  const showConfirmDeleteDialog = useCallback(() => setIsConfirmDeleteOpen(true), [])

  const hideConfirmDeleteDialog = useCallback(() => setIsConfirmDeleteOpen(false), [])

  const onConfirmDelete = useCallback(() => {
    onPressDeleteProp && onPressDeleteProp()

    hideConfirmDeleteDialog()
  }, [
    hideConfirmDeleteDialog,
    onPressDeleteProp,
  ])

  return (
    <>
      <Stack
        direction={[
          'column',
          'column',
          'row',
        ]}
        width='100%'
        alignItems={[
          'flex-start',
          'flex-start',
          'center',
        ]}
      >
        <HStack flex='1' flexWrap='wrap'>
          {title && <Text bold={true} isTruncated={true} maxW='200'>{title}</Text>}
          {description && <Text>{title && ' | '}{description}</Text>}
          {expDate && <Text> | Expires {expDate}</Text>}
        </HStack>
        <HStack space='2'>
          {onPressEdit && <Button size='sm' onPress={onPressEdit}>Edit</Button>}
          {onPressDeleteProp && <Button size='sm' onPress={showConfirmDeleteDialog}>Delete</Button>}
        </HStack>
      </Stack>
      <Divider my='8' />

      {onPressDeleteProp && (
        <Confirm
          cancelText='Cancel'
          confirmText='Delete'
          isOpen={isConfirmDeleteOpen}
          onCancel={hideConfirmDeleteDialog}
          onConfirm={onConfirmDelete}
        >
          {onPressDeleteConfirmText}
        </Confirm>
      )}
    </>
  )
}

export default React.memo(ProfileListItem)
