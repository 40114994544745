import React, { useContext } from 'react'

import {
  HamburgerIcon,
  IconButton,
} from 'native-base'

import {
  Box,
  Image,
} from '@real-work/ui'

import Link from '../../Link'

import Menu, { MenuContext } from './Menu'
import Profile from './Profile'

import logo from './assets/logo-white.svg'

function Header(): React.ReactElement {
  const { toggleMenu } = useContext(MenuContext)

  return (
    <Box position='fixed' top='0' width='100%' zIndex='2'>
      <Box mb='4'>
        <Box backgroundColor='brand.secondary' p='4' alignItems='center' minHeight='70px'>
          <Box flexDirection='row' justifyContent='space-between' alignItems='center' width='100%' position='relative' maxWidth='1440px'>
            <Box zIndex='2'>
              <IconButton
                accessibilityLabel='menu-button'
                icon={(
                  <HamburgerIcon
                    color='singletons.white'
                    size='30px'
                  />
                )}
                onPress={toggleMenu}
              />
            </Box>
            <Box position='absolute' width='100%' justifyContent='center' flexDirection='row'>
              <Link to='/'>
                <Image
                  source={logo}
                  alt='Real Work'
                  width={50}
                  height={50}
                />
              </Link>
            </Box>

            <Profile />
          </Box>
        </Box>
      </Box>

      <Menu />
    </Box>
  )
}

export default React.memo(Header)

export * from './types'
