import {
  useContext,
  useEffect,
} from 'react'

import { MenuContext } from '@/components/ScreenWrapper/Header/Menu'

import useLocation from '@/hooks/useLocation'

function OnNavigationChange(): null {
  const { closeMenu } = useContext(MenuContext)

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)

    closeMenu()
  }, [ location ]) // eslint-disable-line react-hooks/exhaustive-deps

  return null // eslint-disable-line no-null/no-null
}

export default OnNavigationChange
