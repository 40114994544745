import React from 'react'

import {
  number,
  string,
} from '@real-work/common'

import useMediaQuery from '../../hooks/useMediaQuery'

import Box from '../Box'

import PlanCard from './Card'

import { Props } from './types'

function PlanCards({
  currentPlan,
  plans,
  selectedPlan,
  setSelectedPlan,
}: Props): React.ReactElement {
  const [ isDesktop ] = useMediaQuery({ minWidth: 1060 })

  return (
    <Box
      flexDirection={isDesktop ? 'row' : 'column'}
      maxWidth='1056'
      alignSelf='center'
    >
      {plans.map(plan => !!plan.price && (
        <PlanCard
          key={plan.slug}
          slug={plan.slug}
          bullets={[
            `${(plan.maxTeamMembers === -1 && 'Unlimited') || string.toTitleCase(number.toWords(plan.maxTeamMembers))} ${plan.maxTeamMembers === 1 ? 'organization' : 'team member'} account${plan.maxTeamMembers === 1 ? '' : 's'}`,
            `${(plan.maxOpenJobs === -1 && 'Unlimited') || string.toTitleCase(number.toWords(plan.maxOpenJobs))} job ${plan.maxOpenJobs === 1 ? 'listing' : 'posting'}${plan.maxOpenJobs === 1 ? '' : 's'}`,
            'Targeted filtering for qualified candidates',
          ]}
          currentPlan={currentPlan}
          description={`Our ${plan.name} plan includes the following features:`}
          isActive={plan.isActive}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          title={`${plan.name} – $${plan.price / 100}/mo`}
        />
      ))}
    </Box>
  )
}

export default React.memo(PlanCards)

export * from './types'
