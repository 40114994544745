"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    messages: {
        accountMustBePaidForFeature: () => 'Your account must be paid in order to use this feature.',
        changesSaved: () => 'Your changes have been saved.',
        credentialIncorrect: () => 'The credentials you entered are incorrect.',
        customerCreationFailed: () => 'Unable to create customer.',
        entryHasBeenDeleted: () => 'Your entry has been deleted.',
        genericErrorHeading: () => 'Oops!',
        genericSuccessHeading: () => 'Success',
        inactiveEmployerCannotContact: () => "This employer's account is inactive on Real Work, so you cannot contact this company. Please try again later",
        inactiveEmployerCannotExpressInterest: () => "This employer's account is inactive on Real Work, so you cannot express interest in this job role. Please try again later.",
        jobInterestSuccess: () => 'Success! You have expressed interest in the job. The employer will be notified. You can expect the recruiter to reach out to you soon if you are a good fit.',
        jobInterestAlreadyAssigned: () => 'You have already expressed interest in this job.',
        jobMustBeClosedBeforeDelete: () => 'Job must be closed before it may be deleted.',
        jobStatusChanged: (status) => `${(status === 'open' && 'Your listing has been reopened.') || (status === 'closed' && 'Your listing has been closed.')}`,
        loginIncorrect: () => 'Incorrect username or password.',
        mustBeAuthedToContactCompany: () => 'You must be signed in to contact this company.',
        mustBeAuthedToContactWorker: () => 'You must be signed in to contact this worker.',
        noItemsToShow: (items) => `There are no ${items || 'items'} to show at this time.`,
        notFound: (item) => item ? `${item} not found.` : 'Not found.',
        nowAMemberMessage: () => 'You are now a member of Real Work!',
        passwordResetCodeExpired: () => 'Your code has expired. Please request a new code.',
        passwordResetCodeInvalid: () => 'Invalid reset code.',
        permissionDenied: () => 'Permission denied.',
        profilePublished: () => 'Your profile has been published.',
        refreshTokenExpired: () => 'Your refresh token is expired or invalid.',
        removeFromProfileConfirmation: (item) => `Are you sure you want to remove this${item ? ` ${item}` : ''} from your profile?`,
        sentEmail: () => 'We have sent you an email. Please check your inbox.',
        sessionExpired: () => 'Your session has expired. Please log back in.',
        specialtyAlreadyAssigned: () => 'You already have that specialty assigned.',
        subscriptionCancellationFailed: () => 'Unable to cancel subscription.',
        subscriptionCreationFailed: () => 'Unable to create subscription.',
        unauthorized: () => 'You are not signed in.',
        unableToUnassignSpecialty: () => 'Unable to unassign that specialty.',
        unableToFindEmail: (email) => `We were unable to find a user with the email ${email}. Please try again.`,
        unableToResetPassword: () => 'We were unable to reset your password.',
        unableToVerifyAccount: () => 'Unable to verify your account.',
        requestTooLarge: (maxRequestSize) => `Request must be no larger than ${maxRequestSize}.`,
        userCreated: () => 'User created.',
        userCreationFailed: () => 'Unable to create user.',
        userDeleted: () => 'Your account has been deleted.',
        userEmailNotFound: () => 'Your email was not found',
        userEmailVerified: () => 'Your email has been verified.',
        userPasswordUpdateSuccessful: () => 'Your password has been reset. Please sign in to continue.',
        unknownError: () => 'An unknown error has occurred.',
        verificationCodeSent: () => 'We have sent you an email. Please check your inbox.',
        workerSavedToFavorites: () => 'This worker has been updated in your favorites.',
    },
    validation: {
        atLeastOneItemRequired: (type) => `You must have at least one ${type}.`,
        emailAlreadyTaken: () => 'Email is already taken.',
        emailNotFound: () => 'Email not found.',
        fieldIsNotAllowed: (name) => name ? `${name} is not allowed.` : 'One of the fields passed is not allowed.',
        incorrectCurrentPassword: () => 'Incorrect current password.',
        invalidBase64Image: () => 'Must be a valid base64 image string.',
        invalidBase64ImageOrBuffer: () => 'Must be a valid base64 image string or buffer.',
        invalidEmail: () => 'Must be a valid email address',
        invalidCrop: (label = 'Crop information') => `${label} must be valid.`,
        invalidGeocode: (label = 'Address') => `${label} must be a valid address selection.`,
        invalidId: (type) => `Invalid${type ? ` ${type}` : ''} id`,
        invalidImageVariant: () => "Image variant must be 'original', 'cropped', or '{width}x{height}'.",
        invalidLonLat: () => 'Must be a valid longitude+latitude',
        invalidTime: () => 'Time must be formatted hh:mm:ss(.mmmm)',
        invalidType: (type) => `Must be a valid ${type}.`,
        mustBeAfter: (field, after) => `${field} must be after ${after}.`,
        passwordHelperText: () => 'Include a minimum of 8 characters made up of numbers, letters and a special character.',
        passwordLowerCaseLetterRequired: () => 'Password must have at least one lower-case letter.',
        passwordNumerRequired: () => 'Password must have at least one number.',
        passwordSpecialCharacterRequired: () => 'Password must have at least one special character.',
        passwordMinimumLength: (min) => `Password must be at least ${min.toString()} characters long.`,
        passwordsMustMatch: () => 'Passwords must match.',
        phoneNumberUs: () => 'Phone number must be in format 000-000-0000.',
        userRoleNotFound: () => 'User Role not found.',
        validationFailed: () => 'Validation failed.',
        verificationCodeFormatWrong: () => 'Verification Code must be exactly 6 digits.',
        zipCodeMaxLength: (len) => `Zip code must be no more than ${len.toString()} characters long.`,
        zipCodeMinLength: (len) => `Zip code must be at least ${len.toString()} characters long.`,
        zipCodeSyntax: () => 'Zip code must be in format 00000-0000 or 00000.',
    },
};
