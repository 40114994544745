import { extendTheme } from 'native-base'

import { object } from '@real-work/common'

import {
  avatarBoxVariants,
  avatarTextVariants,
} from '../components/Avatar/variants'

import { ThemeConfig } from './types'

export * as themeTools from './tools'

export const makeTheme = (theme: ThemeConfig) => {
  const mergedTheme = object.merge<ThemeConfig>(defaultTheme, theme)

  return extendTheme(mergedTheme)
}

export const defaultTheme: ThemeConfig = {
  components: {
    Box: { variants: avatarBoxVariants },
    Badge: {
      defaultProps: {
        _text: {
          lineHeight: '1rem',
          textTransform: 'uppercase',
        },
      },
    },
    Button: {
      baseStyle: { _text: { color: 'singletons.white' } },
      defaultProps: {
        borderRadius: '24',
        paddingLeft: '6',
        paddingRight: '6',
        _text: {
          fontWeight: 'bold',
          alignSelf: 'center',
          fontSize: 'lg',
        },
      },
      variants: {
        outline: {
          _text: { color: 'primary.600' },
          borderColor: 'primary.600',
          borderWidth: '2',
          paddingBottom: '2',
          paddingTop: '2',
        },
        small: {
          _text: { fontSize: 'md' },
          backgroundColor: 'primary.600',
          size: 'sm',
        },
      },
    },
    ButtonGroup: {
      defaultProps: {
        space: 4,
        flexWrap: 'wrap',
        justifyContent: 'center',
        flex: 1,
      },
    },
    Container: {
      baseStyle: { maxWidth: '1072px' },
      defaultProps: {
        alignSelf: 'center',
        width: '100%',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
      variants: {
        large: {
          maxWidth: '1237px',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    Divider: {
      baseStyle: { background: 'muted.300' },
      variants: {
        dark: {
          background: 'primary.800',
          height: '2px',
        },
      },
    },
    FormControlLabel: {
      baseStyle: {
        _text: {
          fontSize: 'lg',
          color: 'singletons.black',
        },
      },
    },
    Heading: {
      baseStyle: { color: 'singletons.black' },
      defaultProps: { size: 'lg' },
      sizes: {
        lg: { fontSize: '30px' },
        md: { fontSize: '22px' },
        sm: { fontSize: '20px' },
      },
      variants: { secondary: { color: 'primary.600' } },
    },
    Input: { defaultProps: { size: 'xl' } },
    Link: {
      baseStyle: { _text: { color: 'primary.600' } },
      variants: {
        subnav: {
          _text: {
            color: 'singletons.black',
            textDecoration: 'none',
            lineHeight: '1.5em',
          },
          _hover: { _text: { color: 'secondary.600' } },
        },
        topnav: {
          _text: {
            color: 'singletons.black',
            textDecoration: 'none',
            fontSize: 'xl',
            fontWeight: 'bold',
            lineHeight: '1.5em',
          },
          _hover: { _text: { color: 'secondary.600' } },
        },
        emphasis: {
          _text: {
            color: 'primary.600',
            fontWeight: 'bold',
            fontSize: 'md',
          },
        },
      },
    },
    ModalHeader: { baseStyle: { _text: { fontSize: '22px' } } },
    Radio: {
      defaultProps: {
        _text: {
          fontSize: 'sm',
          fontWeight: 'bold',
        },
      },
    },
    Text: {
      baseStyle: {
        fontSize: 'lg',
        maxWidth: '640px',
        lineHeight: '1.4em',
      },
      variants: {
        ...avatarTextVariants,
        heroNumber: {
          fontSize: '4xl',
          fontWeight: 'bold',
        },
      },
    },
    Toast: { defaultProps: { _overlay: { style: { zIndex: 100 } } } },
  },
  fontConfig: {
    Barlow: {
      100: {
        normal: 'BarlowLight',
        italic: 'BarlowLightItalic',
      },
      200: {
        normal: 'BarlowLight',
        italic: 'BarlowLightItalic',
      },
      300: {
        normal: 'BarlowLight',
        italic: 'BarlowLightItalic',
      },
      400: {
        normal: 'BarlowRegular',
        italic: 'BarlowItalic',
      },
      500: {
        normal: 'BarlowMedium',
        italic: 'BarlowMediumItalic',
      },
      600: {
        normal: 'BarlowMedium',
        italic: 'BarlowMediumItalic',
      },
      700: {
        normal: 'BarlowBold',
        italic: 'BarlowBoldItalic',
      },
      800: {
        normal: 'BarlowBold',
        italic: 'BarlowBoldItalic',
      },
      900: {
        normal: 'BarlowBold',
        italic: 'BarlowBoldItalic',
      },
    },
  },
  fonts: {
    body: 'Barlow',
    heading: 'Barlow',
  },
  fontSizes: {
    '2xl': 22,
    '3xl': 26,
  },
  colors: {
    brand: {
      primary: '#0891B2',
      secondary: '#FF5F00',
    },
    secondary: {
      50: '#FFEFE6',
      100: '#FFDFCC',
      200: '#FFCFB3',
      300: '#FFBF99',
      400: '#FF9F66',
      500: '#FF7F33',
      600: '#FF5F00',
      700: '#E65600',
      800: '#CC4C00',
      900: '#993900',
    },
    yellow: {
      100: '#FCD34D',
      600: '#FCD34D',
    },
    green: { 600: '#22C55E' },
    info: {
      100: '#0EA5E9',
      600: '#0EA5E9',
    },
    singletons: {
      white: '#fff',
      black: '#000',
    },
    gray: { 200: '#e6e7e8' },
    blueGray: { 600: '#424a6a' },
    muted: { 600: '#A3A3A3' },
  },
}

export * from './types'
