import React, { useCallback } from 'react'
import {
  api,
  lang,
} from '@real-work/common'
import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  Input,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import {
  useGetWorkerByUserIdQuery,
  useUpsertWorkerSkillMutation,
} from '@/services/worker'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import DocumentWrapper from '../../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function SkillsAddEdit({ mode }: Props): React.ReactElement {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const toast = useToast()

  const { id } = useRouteParams()
  const { user } = useSession()

  const {
    data,
    isError,
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, { skip: !user?.id })

  const [
    upsertWorkerSkill,
    { isLoading: isSubmitting },
  ] = useUpsertWorkerSkillMutation()

  const skill = data?.worker.skills?.find(item => item.id === id)

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertWorkerSkill({
      params: {
        userId: user.id,
        id,
      },
      body: values,
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate('/profile/skills')
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    id,
    navigate,
    toast,
    upsertWorkerSkill,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Skills'>
      <ScreenWrapper>
        <Container>
          <Heading>Skills</Heading>
          <Text>
            {mode === 'edit' ? 'Edit a skill below.' : 'Add a skill below.'}
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>{mode === 'edit' ? 'Edit a Skill' : 'Add a Skill'}</Heading>
          <Divider />
          {(isLoading && <LoadingIndicator />) || (id && (isError || !skill) && <LoadingFailed />) || (
            <Form<FormValues>
              initialValues={{ name: skill?.name || '' }}
              rules={api.endpoints.workers.putSkill.validation.body.rules}
              onSubmit={onSubmit}
            >
              <FormControl
                name='name'
                label='Skill'
              >
                <Input
                  type='text'
                />
              </FormControl>
              <ButtonGroup>
                <SubmitButton isLoading={isSubmitting}>Save</SubmitButton>
                <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
              </ButtonGroup>
            </Form>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SkillsAddEdit)
