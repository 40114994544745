import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { RootState } from '../../types'

import {
  PlanSlug,
  SignUpState,
} from './types'

export const initialState: SignUpState = { subscriptionPlanSlug: undefined }

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setSubscriptionPlanSlug: (state, action: PayloadAction<PlanSlug | undefined>) => ({
      ...state,
      subscriptionPlanSlug: action.payload,
    }),
  },
})

export const { setSubscriptionPlanSlug } = signUpSlice.actions

export const signUpSelectors = { subscriptionPlanSlug: (state: Partial<RootState> & { signUp: SignUpState }) => state.signUp.subscriptionPlanSlug }

export default signUpSlice.reducer

export * from './types'
