import React from 'react'
import { Center as NbCenter } from 'native-base'

import { Props } from './types'

function Center({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbCenter {...props}>{children}</NbCenter>
  )
}

export default React.memo(Center)

export * from './types'
