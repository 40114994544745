import type { PaginationMeta } from '@real-work/common/src/api/pagination/offset'

// eslint-disable-next-line import/prefer-default-export
export const hasMorePages = (meta?: PaginationMeta) => {
  if (!meta) {
    return false
  }

  return (meta?.totalPages || 0) > (meta?.currentPage || 0)
}
