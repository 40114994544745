import React from 'react'
import { CloseIcon } from 'native-base'

import Pressable from '../Pressable'

import { Props } from './types'

function CloseX({
  color,
  size,
  onPress,
  ...props
}: Props): React.ReactElement {
  return (
    <Pressable
      hitSlop={20}
      onPress={onPress}
    >
      <CloseIcon
        color={color}
        size={size}
        {...props}
      />
    </Pressable>
  )
}

export default React.memo(CloseX)

export * from './types'
