import React from 'react'
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isProduction } from '@real-work/common'

import useLocation from '@/hooks/useLocation'

import Account from '@/screens/Account'
import AccountCancelSuccess from '@/screens/Account/Cancel/Success'
import AccountNotificationsEdit from '@/screens/Account/EditNotifications'
import AccountPasswordEdit from '@/screens/Account/EditPassword'
import AccountPaymentMethodEdit from '@/screens/Account/EditPaymentMethod'
import AccountPersonalDetailsEdit from '@/screens/Account/EditPersonalDetails'
import AccountSubscriptionEdit from '@/screens/Account/EditSubscription'
// import AccountSubscriptionConfirmPaymentMethod from '@/screens/Account/EditSubscription/ConfirmPaymentMethod'

import Company from '@/screens/Companies/Company'
import CompanyEdit from '@/screens/Companies/Company/Edit'
import CompanyLocationAddEdit from '@/screens/Companies/Location/AddEdit'

import DashboardEmployer from '@/screens/Dashboard/Employer'
import DashboardWorker from '@/screens/Dashboard/Worker'

import DevScratch from '@/screens/DevScratch'

import EmailVerification from '@/screens/EmailVerification'

import Error403 from '@/screens/Error403'
import Error404 from '@/screens/Error404'
import Error500 from '@/screens/Error500'

import Job from '@/screens/Jobs/Job'
import JobAddEdit from '@/screens/Jobs/Job/AddEdit'
import Jobs from '@/screens/Jobs'

import ProfileSectionAboutMeAddEdit from '@/screens/Workers/Edit/Sections/AboutMe/AddEdit'
import ProfileSectionCertifications from '@/screens/Workers/Edit/Sections/Certifications'
import ProfileSectionCertificationsAddEdit from '@/screens/Workers/Edit/Sections/Certifications/AddEdit'
import ProfileSectionEducation from '@/screens/Workers/Edit/Sections/Education'
import ProfileSectionEducationAddEdit from '@/screens/Workers/Edit/Sections/Education/AddEdit'
import ProfileSectionLicenses from '@/screens/Workers/Edit/Sections/Licenses'
import ProfileSectionLicensesAddEdit from '@/screens/Workers/Edit/Sections/Licenses/AddEdit'
import ProfileSectionMemberships from '@/screens/Workers/Edit/Sections/Memberships'
import ProfileSectionMembershipsAddEdit from '@/screens/Workers/Edit/Sections/Memberships/AddEdit'
import ProfileSectionPictureAddEdit from '@/screens/Workers/Edit/Sections/Picture/AddEdit'
import ProfileSectionProductExperience from '@/screens/Workers/Edit/Sections/ProductExperience'
import ProfileSectionProductExperienceAddEdit from '@/screens/Workers/Edit/Sections/ProductExperience/AddEdit'
import ProfileSectionSkills from '@/screens/Workers/Edit/Sections/Skills'
import ProfileSectionSkillsAddEdit from '@/screens/Workers/Edit/Sections/Skills/AddEdit'
import ProfileSectionTrades from '@/screens/Workers/Edit/Sections/Trades'
import ProfileSectionTradesAddEdit from '@/screens/Workers/Edit/Sections/Trades/AddEdit'
import ProfileSectionWorkExperience from '@/screens/Workers/Edit/Sections/WorkExperience'
import ProfileSectionWorkExperienceAddEdit from '@/screens/Workers/Edit/Sections/WorkExperience/AddEdit'
import ProfileSectionWorkPreferencesAddEdit from '@/screens/Workers/Edit/Sections/WorkPreferences/AddEdit'

import SignIn from '@/screens/SignIn'
import ForgotPassword from '@/screens/SignIn/ForgotPassword'
import ResetPassword from '@/screens/SignIn/ForgotPassword/ResetPassword'
import SignOut from '@/screens/SignOut'

import SignUpEmployerChoosePlan from '@/screens/SignUp/Employer/ChoosePlan'
import SignUpEmployerDetails from '@/screens/SignUp/Employer/Details'
import SignUpEmployerPayment from '@/screens/SignUp/Employer/Payment'
import SignUpWorker from '@/screens/SignUp/Worker'

import WorkerProfile from '@/screens/Workers/Detail'
import WorkerProfileEdit from '@/screens/Workers/Edit'
import Workers from '@/screens/Workers'

import { signUpSelectors } from '@/state/slices/signUp'

import CanEditJob from './ProtectedRoute/CanEditJob'
import ProtectedRoute, {
  hasCompanySubscription,
  isCompanySubscriptionActive,
  isCompanySubscriptionComplete,
  isCompanySubscriptionIncomplete,
  isNotLoggedIn,
  isUserEmailVerified,
  isUserEmailUnverified,
  isUserOwnerOfRouteParamIdCompany,
  isWorkerProfilePublished,
} from './ProtectedRoute'

import RequireSignIn from './RequireSignIn'

export { default as history } from './history'

function Router(): React.ReactElement {
  const location = useLocation()
  const subscriptionPlanSlug = useSelector(signUpSelectors.subscriptionPlanSlug)

  return (
    <Routes>
      <Route path='dev/scratch' element={isProduction ? <Error404 /> : <DevScratch />} />

      <Route
        path='/'
        element={(
          <RequireSignIn otherwise={<Navigate to='/sign-in' />}>
            <Navigate to='/dashboard' />
          </RequireSignIn>
        )}
      />

      <Route path='/account' element={(<RequireSignIn />)}>
        <Route path='' element={<Account />} />
        <Route path='notifications/edit' element={<AccountNotificationsEdit />} />
        <Route path='password/edit' element={<AccountPasswordEdit />} />
        <Route path='payment-method/edit' element={<ProtectedRoute test={hasCompanySubscription} otherwise={<Error403 />}><AccountPaymentMethodEdit /></ProtectedRoute>} />
        <Route path='personal-details/edit' element={<AccountPersonalDetailsEdit />} />
        <Route path='subscription/edit' element={<AccountSubscriptionEdit />} />
        {/* <Route path='subscription/confirm-payment-method' element={<AccountSubscriptionConfirmPaymentMethod />} /> */}
      </Route>
      <Route path='/account/cancel/success' element={<AccountCancelSuccess />} />

      <Route path='/companies'>
        <Route path='' element={<Error404 />} />

        <Route path=':id'>
          <Route path='' element={<Company />} />
          <Route
            path='edit'
            element={(
              <RequireSignIn roleSlug='employer'>
                <ProtectedRoute
                  test={[
                    isUserOwnerOfRouteParamIdCompany,
                    isCompanySubscriptionActive,
                  ]}
                  otherwise={[
                    <Error403 key='forbidden' />,
                    <Navigate to='/account/payment-method/edit' key='subscription-not-paid' />,
                  ]}
                >
                  <CompanyEdit />
                </ProtectedRoute>
              </RequireSignIn>
            )}
          />

          <Route path='locations'>
            <Route
              path='add'
              element={(
                <RequireSignIn roleSlug='employer'>
                  <ProtectedRoute
                    test={[
                      isUserOwnerOfRouteParamIdCompany,
                      isCompanySubscriptionActive,
                    ]}
                    otherwise={[
                      <Error403 key='forbidden' />,
                      <Navigate to='/account/payment-method/edit' key='subscription-not-paid' />,
                    ]}
                  >
                    <CompanyLocationAddEdit mode='add' />
                  </ProtectedRoute>
                </RequireSignIn>
              )}
            />
            <Route
              path=':locationId/edit'
              element={(
                <RequireSignIn roleSlug='employer'>
                  <ProtectedRoute
                    test={[
                      isUserOwnerOfRouteParamIdCompany,
                      isCompanySubscriptionActive,
                    ]}
                    otherwise={[
                      <Error403 key='forbidden' />,
                      <Navigate to='/account/payment-method/edit' key='subscription-not-paid' />,
                    ]}
                  >
                    <CompanyLocationAddEdit mode='edit' />
                  </ProtectedRoute>
                </RequireSignIn>
              )}
            />
          </Route>
        </Route>
      </Route>

      <Route path='/dashboard'>
        <Route
          path=''
          element={(
            <RequireSignIn
              roleSlug='employer'
              otherwise={(
                <RequireSignIn
                  roleSlug='worker'
                  otherwise={<Navigate to='/sign-in' />}
                >
                  <Navigate to='/dashboard/worker' state={location.state || undefined} />
                </RequireSignIn>
              )}
            >
              <Navigate to='/dashboard/employer' state={location.state || undefined} />
            </RequireSignIn>
          )}
        />

        <Route
          path='employer'
          element={(
            <RequireSignIn roleSlug='employer'>
              <ProtectedRoute
                test={[
                  isUserEmailVerified,
                  isCompanySubscriptionComplete,
                  isCompanySubscriptionActive,
                ]}
                otherwise={[
                  <Navigate key='emailNotVerified' to='/email-verification' />,
                  <Navigate key='companySubscriptionNotComplete' to='/sign-up/employer/payment' />,
                  <Navigate key='companySubscriptionNotActive' to='/account/payment-method/edit' />,
                ]}
              >
                <DashboardEmployer />
              </ProtectedRoute>
            </RequireSignIn>
          )}
        />
        <Route
          path='worker'
          element={(
            <RequireSignIn roleSlug='worker'>
              <ProtectedRoute
                test={[
                  isUserEmailVerified,
                  isWorkerProfilePublished,
                ]}
                otherwise={[
                  <Navigate key='emailNotVerified' to='/email-verification' />,
                  <Navigate key='workerProfileNotPublished' to='/profile/edit' />,
                ]}
              >
                <DashboardWorker />
              </ProtectedRoute>
            </RequireSignIn>
          )}
        />
      </Route>

      <Route path='/email-verification' element={(<RequireSignIn><ProtectedRoute test={isUserEmailUnverified} otherwise={<Navigate to='/' />}><EmailVerification /></ProtectedRoute></RequireSignIn>)} />

      <Route path='/jobs'>
        <Route path='' element={<RequireSignIn only={({ routeQuery }) => routeQuery.get('tab') === 'closed' || routeQuery.get('tab') === 'interested'}><Jobs /></RequireSignIn>} />
        <Route
          path='new'
          element={(
            <RequireSignIn roleSlug='employer'>
              <ProtectedRoute
                test={isCompanySubscriptionActive}
                otherwise={<Navigate to='/account/payment-method/edit' />}
              >
                <JobAddEdit mode='add' />
              </ProtectedRoute>
            </RequireSignIn>
          )}
        />
        <Route path=':id' element={<Job />} />
        <Route
          path=':id/edit'
          element={(
            <CanEditJob otherwise={<Error403 />}>
              <ProtectedRoute test={isCompanySubscriptionActive} otherwise={<Navigate to='/account/payment-method/edit' />}>
                <JobAddEdit mode='edit' />
              </ProtectedRoute>
            </CanEditJob>
          )}
        />
        <Route path='closed' element={<Navigate to='/jobs/?tab=closed' />} />
        <Route path='interested' element={<Navigate to='/jobs/?tab=interested' />} />
        <Route path='open' element={<Navigate to='/jobs/?tab=open' />} />
      </Route>

      <Route path='/profile' element={<RequireSignIn />}>
        <Route path='' element={<Error404 />} />

        <Route path='edit' element={<WorkerProfileEdit />} />

        <Route path='about-me'>
          <Route path='add' element={<ProfileSectionAboutMeAddEdit mode='add' />} />
          <Route path='edit' element={<ProfileSectionAboutMeAddEdit mode='edit' />} />
        </Route>

        <Route path='certifications'>
          <Route path='' element={<ProfileSectionCertifications />} />
          <Route path='add' element={<ProfileSectionCertificationsAddEdit mode='add' />} />
          <Route path=':id/edit' element={<ProfileSectionCertificationsAddEdit mode='edit' />} />
        </Route>

        <Route path='educations'>
          <Route path='' element={<ProfileSectionEducation />} />
          <Route path='add' element={<ProfileSectionEducationAddEdit mode='add' />} />
          <Route path=':id/edit' element={<ProfileSectionEducationAddEdit mode='edit' />} />
        </Route>

        <Route path='licenses'>
          <Route path='' element={<ProfileSectionLicenses />} />
          <Route path='add' element={<ProfileSectionLicensesAddEdit mode='add' />} />
          <Route path=':id/edit' element={<ProfileSectionLicensesAddEdit mode='edit' />} />
        </Route>

        <Route path='memberships'>
          <Route path='' element={<ProfileSectionMemberships />} />
          <Route path='add' element={<ProfileSectionMembershipsAddEdit mode='add' />} />
          <Route path=':id/edit' element={<ProfileSectionMembershipsAddEdit mode='edit' />} />
        </Route>

        <Route path='picture'>
          <Route path='add' element={<ProfileSectionPictureAddEdit mode='add' />} />
          <Route path='edit' element={<ProfileSectionPictureAddEdit mode='edit' />} />
        </Route>

        <Route path='product-experiences'>
          <Route path='' element={<ProfileSectionProductExperience />} />
          <Route path='add' element={<ProfileSectionProductExperienceAddEdit mode='add' />} />
          <Route path=':id/edit' element={<ProfileSectionProductExperienceAddEdit mode='edit' />} />
        </Route>

        <Route path='skills'>
          <Route path='' element={<ProfileSectionSkills />} />
          <Route path='add' element={<ProfileSectionSkillsAddEdit mode='add' />} />
          <Route path=':id/edit' element={<ProfileSectionSkillsAddEdit mode='edit' />} />
        </Route>

        <Route path='trades'>
          <Route path='' element={<ProfileSectionTrades />} />
          <Route path='add' element={<ProfileSectionTradesAddEdit mode='add' />} />
          <Route path=':id/edit' element={<ProfileSectionTradesAddEdit mode='edit' />} />
        </Route>

        <Route path='work-experiences'>
          <Route path='' element={<ProfileSectionWorkExperience />} />
          <Route path='add' element={<ProfileSectionWorkExperienceAddEdit mode='add' />} />
          <Route path=':id/edit' element={<ProfileSectionWorkExperienceAddEdit mode='edit' />} />
        </Route>

        <Route path='work-preferences'>
          <Route path='add' element={<ProfileSectionWorkPreferencesAddEdit />} />
          <Route path='edit' element={<ProfileSectionWorkPreferencesAddEdit />} />
        </Route>
      </Route>

      <Route path='/workers' element={<RequireSignIn only={({ routeQuery }) => routeQuery.get('tab') === 'favorite' || routeQuery.get('tab') === 'interested'} />}>
        <Route path='' element={<Workers />} />
        <Route path=':id' element={<WorkerProfile />} />
        <Route path='all' element={<Navigate to='/workers/?tab=all' />} />
        <Route path='favorite' element={<Navigate to='/workers/?tab=favorite' />} />
        <Route path='interested' element={<Navigate to='/workers/?tab=interested' />} />
      </Route>

      <Route path='/sign-in' element={<ProtectedRoute test={isNotLoggedIn} otherwise={<Navigate to='/' />} />}>
        <Route path='' element={<SignIn />} />

        <Route path='forgot-password'>
          <Route path='' element={<ForgotPassword />} />
          <Route path='reset/:id/:code' element={<ResetPassword />} />
        </Route>
      </Route>

      <Route path='/sign-out' element={<RequireSignIn><SignOut /></RequireSignIn>} />

      <Route path='/sign-up'>
        <Route path='' element={<Navigate to='/sign-in' />} />

        <Route path='employer'>
          <Route path='' element={<ProtectedRoute test={isNotLoggedIn} otherwise={<Navigate to='/dashboard' />}><SignUpEmployerChoosePlan /></ProtectedRoute>} />
          <Route path='details' element={<ProtectedRoute test={args => ({ passed: isNotLoggedIn(args) && !!subscriptionPlanSlug })} otherwise={<Navigate to='/sign-up/employer' />}><SignUpEmployerDetails /></ProtectedRoute>} />
          <Route
            path='payment'
            element={(
              <RequireSignIn roleSlug='employer'>
                <ProtectedRoute
                  test={isCompanySubscriptionIncomplete}
                  otherwise={<Navigate to='/dashboard' />}
                >
                  <SignUpEmployerPayment />
                </ProtectedRoute>
              </RequireSignIn>
            )}
          />
        </Route>

        <Route path='worker' element={<ProtectedRoute test={isNotLoggedIn} otherwise={<Navigate to='/dashboard' />}><SignUpWorker /></ProtectedRoute>} />
      </Route>

      <Route path='/403' element={<Error403 />} />
      <Route path='/500' element={<Error500 />} />
      <Route path='*' element={<Error404 />} />
    </Routes>
  )
}

export default Router
