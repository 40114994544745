import React from 'react'

import HStack from '../../../HStack'
import Text, { Props } from '../../../Text'

function BulletListItem({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <HStack
      space='1'
      {...props}
    >
      <Text>{'\u2022'}</Text>
      {children}
    </HStack>
  )
}

export default React.memo(BulletListItem)
