import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import { GetIndustriesResponse } from './types'

const path = 'v1/industries'

const industryService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getIndustries: build.query<api.AsJson<GetIndustriesResponse>, void>({
      query: () => ({
        url: path,
        method: 'GET',
      }),
    }),
  }),
})

export default industryService

export const { useGetIndustriesQuery } = industryService

export const { endpoints: { getIndustries } } = industryService
