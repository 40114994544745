import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  lang,
  url,
} from '@real-work/common'

import {
  Box,
  Button,
  Checklist,
  ChecklistItem,
  Container,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  SectionHeader,
  useToast,
  WorkerProfileCompletenessProgress,
} from '@real-work/ui'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import { usePatchWorkerByIdMutation } from '@/services/worker'

import { Props } from './types'

function ProfileChecklist({
  isLoading,
  showProgressBar = false,
  worker,
}: Props): React.ReactElement {
  const navigate = useNavigate()
  const toast = useToast()
  const { user } = useSession()

  const [
    isJustPublished,
    setIsJustPublished,
  ] = useState(false)

  const [
    patchWorkerById,
    { isLoading: isSubmitting },
  ] = usePatchWorkerByIdMutation()

  const onPressButton = useCallback<(linkTo: string, isCompleted?: boolean, hasListView?: boolean) => () => void>((linkTo, isCompleted, hasListView = false) => () => {
    navigate(`${url.stripTrailingSlash(linkTo)}${(hasListView && isCompleted && '/') || (isCompleted && '/edit') || '/add'}`)
  }, [ navigate ])

  const onHandlePublish = useCallback(() => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    patchWorkerById({
      params: { userId: user?.id },
      body: { isProfilePublished: true },
    })
      .unwrap()
      .then(async () => {
        setIsJustPublished(true)

        toast.show({
          description: lang().messages.profilePublished(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
      })
      .catch(() => {
        toast.show({
          description: lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    patchWorkerById,
    toast,
    user?.id,
  ])

  useEffect(() => {
    if (!isJustPublished) {
      return
    }

    if (user?.worker?.profilePublishedAt) {
      navigate('/dashboard')
    }
  }, [
    isJustPublished,
    navigate,
    user?.worker?.profilePublishedAt,
  ])

  const {
    canPublish,
    sections,
    percentComplete,
  } = worker?.profileCompleteness || {
    canPublish: false,
    percentComplete: 0,
  }

  return (isLoading && <LoadingIndicator />) || (!worker && <LoadingFailed />) || (
    <>
      {showProgressBar && (
        <Container>
          <Box maxWidth='620px' width='100%' my='4'>
            <Heading size='sm' textTransform='uppercase' mb='2'>Your profile is {percentComplete}% complete</Heading>
            <WorkerProfileCompletenessProgress percentComplete={percentComplete} />
          </Box>
        </Container>
      )}

      <SectionHeader title='Required' />
      <Checklist>
        <ChecklistItem title='Trades' isCompleted={sections?.trades.isCompleted} isOptional={sections?.trades.isOptional} onPressButton={onPressButton('/profile/trades', sections?.trades.isCompleted, true)} />
        <ChecklistItem title='Work Preferences' isCompleted={sections?.workPreferences.isCompleted} isOptional={sections?.workPreferences.isOptional} hasDivider={false} onPressButton={onPressButton('/profile/work-preferences', sections?.workPreferences.isCompleted)} />
      </Checklist>
      <SectionHeader title='Not Required' />
      <Checklist>
        <ChecklistItem title='Profile Picture' isCompleted={sections?.picture.isCompleted} isOptional={sections?.picture.isOptional} onPressButton={onPressButton('/profile/picture', sections?.picture.isCompleted)} />
        <ChecklistItem title='About Me' isCompleted={sections?.aboutMe.isCompleted} isOptional={sections?.aboutMe.isOptional} onPressButton={onPressButton('/profile/about-me', sections?.aboutMe.isCompleted)} />
        <ChecklistItem title='Licenses' isCompleted={sections?.licenses.isCompleted} isOptional={sections?.licenses.isOptional} onPressButton={onPressButton('/profile/licenses', sections?.licenses.isCompleted, true)} />
        <ChecklistItem title='Certifications / Other Qualifications' isCompleted={sections?.certifications.isCompleted} isOptional={sections?.certifications.isOptional} onPressButton={onPressButton('/profile/certifications', sections?.certifications.isCompleted, true)} />
        <ChecklistItem title='Product Experience' isCompleted={sections?.productExperience.isCompleted} isOptional={sections?.productExperience.isOptional} onPressButton={onPressButton('/profile/product-experiences', sections?.productExperience.isCompleted, true)} />
        <ChecklistItem title='Skills' isCompleted={sections?.skills.isCompleted} isOptional={sections?.skills.isOptional} onPressButton={onPressButton('/profile/skills', sections?.skills.isCompleted, true)} />
        <ChecklistItem title='Work Experience' isCompleted={sections?.workExperience.isCompleted} isOptional={sections?.workExperience.isOptional} onPressButton={onPressButton('/profile/work-experiences', sections?.workExperience.isCompleted, true)} />
        <ChecklistItem title='Education' isCompleted={sections?.education.isCompleted} isOptional={sections?.education.isOptional} onPressButton={onPressButton('/profile/educations', sections?.education.isCompleted, true)} />
        <ChecklistItem title='Memberships' isCompleted={sections?.memberships.isCompleted} isOptional={sections?.memberships.isOptional} onPressButton={onPressButton('/profile/memberships', sections?.memberships.isCompleted, true)} />
      </Checklist>

      {!worker?.profilePublishedAt && (
        <Container mt='6'>
          <Button
            onPress={onHandlePublish}
            isDisabled={!canPublish}
            isLoading={isSubmitting}
          >
            Publish Profile
          </Button>
        </Container>
      )}
    </>
  )
}

export default React.memo(ProfileChecklist)
