import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { AppRegistry } from 'react-native'
import { createRoot } from 'react-dom/client'

import store from './state'

import App from './App'

function Root() {
  return (
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  )
}

AppRegistry.registerComponent('App', () => Root)

const rootTag = createRoot(document.getElementById('root') as HTMLElement)
rootTag.render(<Root />)
