import React, { useMemo } from 'react'

import {
  Box,
  ScrollView,
} from '@real-work/ui'

import Header from './Header'
import Footer from './Footer'

import { Props } from './types'

function ScreenWrapper({
  children,
  hasHeroImage = false,
}: Props): React.ReactElement {
  const pt = useMemo(() => hasHeroImage ? '70px' : '110px', [ hasHeroImage ])

  return (
    <>
      <Header />
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        pt={pt}
      >
        <Box mb='8'>
          {children}
        </Box>
        <Footer />
      </ScrollView>
    </>
  )
}

export default React.memo(ScreenWrapper)

export * from './types'
