import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import {
  lang,
  string,
  url,
} from '@real-work/common'

import {
  Button,
  ButtonGroup,
  Confirm,
  Container,
  Divider,
  Heading,
  HStack,
  LoadingFailed,
  LoadingIndicator,
  Modal,
  Text,
  useToast,
  VStack,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useCompanySubscription from '@/hooks/useCompanySubscription'
import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import {
  useDeleteUserByIdMutation,
  useGetUserByIdQuery,
} from '@/services/user'

import AccountList from './components/AccountList'

function AccountEmployer(): React.ReactElement {
  const navigate = useNavigate()
  const session = useSession()
  const toast = useToast()

  const [
    isCancelAccountModalOpen,
    setIsCancelAccountModalOpen,
  ] = useState(false)

  const [
    isCancelAccountConfirmOpen,
    setIsCancelAccountConfirmOpen,
  ] = useState(false)

  const {
    isLoading: isSubscriptionLoading,
    subscription,
  } = useCompanySubscription()

  const paymentMethod = useMemo(() => subscription?.paymentMethod.brand || subscription?.paymentMethod.last4
    ? `${string.toTitleCase(subscription?.paymentMethod.brand || '')}${subscription?.paymentMethod.brand && subscription?.paymentMethod.last4 ? ' ' : ''}${subscription?.paymentMethod.last4 || ''}`
    : 'Unknown', [ subscription?.paymentMethod ])

  const {
    data: { user } = {},
    isLoading,
    isError,
  } = useGetUserByIdQuery({ params: { id: session.user?.id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !session.user?.id,
  })

  const [
    deleteUserById,
    { isLoading: isLoadingDeleteUser },
  ] = useDeleteUserByIdMutation()

  const deleteUser = useCallback(() => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    deleteUserById({ params: { id: user.id } })
      .unwrap()
      .then(() => {
        toast.show({
          description: (
            <Text fontSize='md' flexShrink={1} color='black'>
              <Text>We hope to see you again soon.</Text>
              <Link
                to={url.marketingSite('/feedback')}
              >
                Please reach out to us any time
              </Link>.
            </Text>
          ),
          title: lang().messages.userDeleted(),
          type: 'success',
        })

        session.signOut()
          .then(() => {
            navigate('/account/cancel/success')
          })
      })
      .catch(error => {
        toast.show({
          description: (
            <Text fontSize='md' flexShrink={1} color='black'>
              <Text>{error.data.message || lang().messages.unknownError()}</Text>
              <Link
                to={url.marketingSite('/feedback')}
              >
                Please contact support
              </Link>.
            </Text>
          ),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    deleteUserById,
    navigate,
    session,
    toast,
    user?.id,
  ])

  const isLoadingCancelAccount = useMemo(() => isLoadingDeleteUser || session.isLoadingSignOut, [
    isLoadingDeleteUser,
    session.isLoadingSignOut,
  ])

  return (
    <DocumentWrapper title='Real Work | My Account'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (user && !isError && (
          <Container>
            <Heading>My Account</Heading>
            <Text mb='6'>
              Edit your contact information, notification preferences and password.
            </Text>
            <AccountList
              title='Personal Details'
              editButtonUrl='/account/personal-details/edit'
              items={[
                {
                  title: 'Full Name',
                  description: user.fullName,
                },
                {
                  title: 'Role',
                  description: user.role.name,
                },
                ...(user.role.slug === 'employer' ? [
                  {
                    title: 'Position',
                    description: session.user?.employer?.position || 'Unknown',
                  },
                ] : []),
                {
                  title: 'Email',
                  description: user.email,
                },
                {
                  title: 'Phone',
                  description: user.phone,
                },
                ...(session.user?.role.slug === 'worker' ? [
                  {
                    title: 'Zip Code',
                    description: session.user?.worker?.zipCode || '',
                  },
                ] : []),
              ]}
            />
            <AccountList
              title='Password'
              editButtonUrl='/account/password/edit'
              items={[
                {
                  title: 'Password',
                  description: '********',
                },
              ]}
            />
            <AccountList
              title='Notifications'
              editButtonUrl='/account/notifications/edit'
              items={[
                {
                  title: 'High-Priority SMS',
                  description: user.isNotificationsSmsHighPriorityEnabled ? 'On' : 'Off',
                },
                {
                  title: 'High-Priority Emails',
                  description: user.isNotificationsEmailHighPriorityEnabled ? 'On' : 'Off',
                },
                {
                  title: 'Weekly Digest Emails',
                  description: user.isNotificationsEmailDigestEnabled ? 'On' : 'Off',
                },
              ]}
            />
            {user.role.slug === 'employer' && (
              <>
                <AccountList
                  title='Subscription Plan'
                  editButtonUrl='/account/subscription/edit'
                  items={[
                    {
                      title: 'Subscription Plan',
                      description: isSubscriptionLoading ? '...' : subscription?.plan.name || 'None',
                    },
                  ]}
                />
                <AccountList
                  title='Payment Method'
                  editButtonUrl='/account/payment-method/edit'
                  items={[
                    {
                      title: 'Payment Method',
                      description: isSubscriptionLoading ? '...' : paymentMethod,
                    },
                  ]}
                />
              </>
            )}

            <Heading size='md' variant='secondary'>Account Management</Heading>
            <Divider />
            <HStack justifyContent='space-between' alignItems='center' width='100%' my='6'>
              <VStack flex='1'>
                <HStack>
                  <Text fontWeight='700' mr='1'>Account Status:</Text><Text>Active</Text>
                </HStack>
              </VStack>
              <HStack>
                <Button size='sm' onPress={() => setIsCancelAccountModalOpen(true)}>Cancel</Button>
              </HStack>
            </HStack>

            <Modal isOpen={isCancelAccountModalOpen} onClose={() => setIsCancelAccountModalOpen(false)}>
              <Modal.Content maxWidth='400px'>
                <Modal.CloseButton />
                <Modal.Header>Cancel Account</Modal.Header>
                <Modal.Body>
                  <Text>We are sorry to see you go! Please <Link to={url.marketingSite('/feedback')}>contact us</Link> if there is anything we can do to help change your mind.</Text>
                </Modal.Body>
                <Modal.Footer>
                  <ButtonGroup space={2}>
                    <Button
                      variant='outline'
                      colorScheme='primary'
                      onPress={() => {
                        setIsCancelAccountModalOpen(false)
                      }}
                    >
                      Nevermind
                    </Button>
                    <Button
                      onPress={() => {
                        setIsCancelAccountModalOpen(false)
                        setIsCancelAccountConfirmOpen(true)
                      }}
                    >
                      Cancel Account
                    </Button>
                  </ButtonGroup>
                </Modal.Footer>
              </Modal.Content>
            </Modal>

            <Confirm
              cancelText='No'
              confirmText='Yes, Delete My Account'
              isOpen={isCancelAccountConfirmOpen}
              isSubmitting={isLoadingCancelAccount}
              onCancel={() => setIsCancelAccountConfirmOpen(false)}
              onConfirm={deleteUser}
            >
              Are you sure you want to delete your account? This is not undoable!
            </Confirm>
          </Container>
        )) || <LoadingFailed />}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(AccountEmployer)
