import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Box,
  Button,
  Divider,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
} from '@real-work/ui'

import useSession from '@/hooks/useSession'

import Filters, { FiltersContext } from '@/components/Filters'
import Worker from '@/components/Worker'

import { useGetWorkersQuery } from '@/services/worker'

import { hasMorePages } from '@/utils/pagination'

function AllWorkers(): React.ReactElement {
  const filtersContext = useContext(FiltersContext)

  const { user } = useSession()

  const [
    getWorkersParams,
    setGetWorkersParams,
  ] = useState(filtersContext.values)

  useEffect(() => {
    setGetWorkersParams({
      ...filtersContext.values,
      page: 1, // Page must be reset to 1 each time the filters changes to prevent mixed filtered results from merging into the workers list
    })
  }, [ filtersContext.values ])

  const {
    data: {
      workers,
      pagination,
    } = {},
    isError,
    isFetching,
  } = useGetWorkersQuery({
    query: {
      ...getWorkersParams,
      perPage: 25,
    },
  }, { refetchOnMountOrArgChange: true })

  const isLoadingFirstPage = useMemo(() => (isFetching && (getWorkersParams?.page || 1) < 2), [
    getWorkersParams?.page,
    isFetching,
  ])

  const onClickShowMoreButton = useCallback(() => {
    setGetWorkersParams(prev => ({
      ...prev,
      page: (prev.page || 1) + 1,
    }))
  }, [])

  return (
    <>
      {user?.role.slug === 'employer' && <Filters mode='workers' />}

      {(isLoadingFirstPage && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
        <>
          {workers?.length === 0 ? <NoContent /> : workers?.map(worker => (
            <React.Fragment key={worker.userId}>
              <Worker
                mode='list'
                worker={worker}
              />

              <Divider />
            </React.Fragment>
          ))}
          {hasMorePages(pagination) && (
            <Box mx='auto' mt='6'>
              <Button
                isLoading={isFetching}
                onPress={onClickShowMoreButton}
              >
                Show More
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default React.memo(AllWorkers)
