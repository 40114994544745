import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { FormTypes } from '@real-work/ui'

import useSession from '@/hooks/useSession'

import {
  FiltersContextType,
  FormValues,
} from './types'

const FiltersContext = createContext<FiltersContextType>({
  closeDrawer: () => undefined,
  isDrawerOpen: false,
  openDrawer: () => undefined,
  setValues: () => undefined,
  values: {},
})

export function FiltersProvider({ children }: { children: React.ReactNode }) {
  const session = useSession()

  const [
    searchParams,
    setSearchParams,
  ] = useSearchParams()

  const [
    isDrawerOpen,
    setIsDrawerOpen,
  ] = useState(false)

  const closeDrawer = useCallback(() => setIsDrawerOpen(false), [])

  const openDrawer = useCallback(() => setIsDrawerOpen(true), [])

  const getQueryString = () => {
    const params: Record<string, string> = {}

    searchParams.forEach((value, key) => {
      params[key] = decodeURIComponent(value)
    })

    return params
  }

  const [
    values,
    _setValues,
  ] = useState<FormTypes.Values<FormValues>>({
    ...getQueryString(),
    certificationIds: searchParams.get('certificationIds')?.split(','),
    certificationStateAbbr: 'N/A',
    jobTypes: searchParams.get('jobTypes')?.split(',') || undefined,
    licenseIds: searchParams.get('licenseIds')?.split(','),
    licenseStateAbbr: 'N/A',
    numberOfEmployees: searchParams.get('numberOfEmployees')?.split(',')?.map(val => decodeURIComponent(val)) || undefined,
    specialtyIds: searchParams.get('specialtyIds')?.split(',') || undefined,
    travelDistance:
      Number(searchParams.get('travelDistance'))
      || Number(session?.user?.worker?.travelDistance?.value)
      || 20,
    travelDistanceFromZip:
      searchParams.get('travelDistanceFromZip')
      || session?.user?.worker?.zipCode
      || undefined,
    workShifts: searchParams.get('workShifts')?.split(',') || undefined,
  })

  const updateQueryString = useCallback((values?: FormValues) => {
    const newSearchParams = !values ? undefined : Object.keys(values).reduce((acc: Record<string, string>, key: string) => {
      const value = values[key as keyof typeof values]

      if (value && (typeof value === 'string' || typeof value === 'number')) {
        acc[key] = encodeURIComponent(value.toString())
      }
      else if (value && Array.isArray(value)) {
        acc[key] = value.join(',')
      }

      return acc
    }, {})

    setSearchParams(newSearchParams)
  }, [ setSearchParams ])

  const setValues = useCallback((values: FormValues) => {
    _setValues(values)
    updateQueryString(values)
  }, [
    _setValues,
    updateQueryString,
  ])

  const value = useMemo(() => ({
    closeDrawer,
    isDrawerOpen,
    openDrawer,
    setValues,
    values,
  }), [
    closeDrawer,
    isDrawerOpen,
    openDrawer,
    setValues,
    values,
  ])

  return (
    <FiltersContext.Provider value={value}>
      {children}
    </FiltersContext.Provider>
  )
}

export { FiltersContext }
