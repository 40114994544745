import React from 'react'

import log from '@real-work/common/dist/src/log/browser'

import {
  Box,
  Container,
  Form,
  FormControl,
  HStack,
  Heading,
  NoContent,
  SectionHeader,
  SectionSubHeader,
  Select,
  SubmitButton,
  useMediaQuery,
  Text,
} from '@real-work/ui'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'
import Link from '../../components/Link'

const optionsStates = [
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AL',
    label: 'Alabama',
  },
]

const options = [
  {
    value: '1',
    label: 'Option One',
  },
  {
    value: '2',
    label: 'Option Two',
  },
  {
    value: '3',
    label: 'Option Three',
  },
  {
    value: '4',
    label: 'Option four has a lot of words will they wrap inside a container',
  },
  {
    value: '5',
    label: 'Option Five',
  },
  {
    value: '6',
    label: 'Option Six',
  },
  {
    value: '7',
    label: 'Option Seven',
  },
  {
    value: '8',
    label: 'Option Eight is Great',
  },
]

function DevScratch(): React.ReactElement {
  const [ isMobile ] = useMediaQuery({ minWidth: 800 })

  return (
    <DocumentWrapper title='Real Work | Dev Scratch'>
      <ScreenWrapper>
        <Container>
          <Box mb='6'>
            <Heading>Dev Scratch</Heading>
            <Link to='/'>Home</Link>
          </Box>
        </Container>

        <SectionHeader title='Section Header Has Default Margin' />
        <Container>
          <NoContent isWholeScreen={false} />
        </Container>
        <SectionHeader title='Section Header Has Default Margin' />
        <Container>
          <SectionSubHeader title='Licenses' />
          <NoContent isWholeScreen={false} my='4' />
          <Text>Unmargined content is above and below</Text>
        </Container>
        <Container zIndex='2'>
          <Form
            onSubmit={values => log('info', 'Form submitted', values)}
          >
            <FormControl
              name='pillsInside'
              label='Pills Inside'
            >
              <Select
                isMulti={true}
                options={options}
                isSearchable={false}
              />
            </FormControl>

            <FormControl
              name='pillsOutside'
              label='Pills Outside'
            >
              <Select
                isMulti={true}
                options={options}
                pillsLocation='outside'
              />
            </FormControl>
            <HStack space='4' flexDirection={isMobile ? 'row' : 'column'}>
              <FormControl
                name='licenseState'
                label='State'
                flexShrink={isMobile ? '1' : '0'}
              >
                <Select
                  options={optionsStates}
                  isSearchable={false}
                />
              </FormControl>
              <FormControl
                name='license'
                label='License'
                flexShrink={isMobile ? '1' : '0'}
              >
                <Select
                  options={options}
                  isSearchable={false}
                />
              </FormControl>
            </HStack>

            <SubmitButton>Show values in console</SubmitButton>
          </Form>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DevScratch)
