import React from 'react'

import {
  Box,
  HStack,
  Text,
} from '@real-work/ui'

import Version from '../../Version'

function Footer(): React.ReactElement {
  return (
    <Box backgroundColor='muted.600' marginTop='auto' alignItems='center' justifyContent='center' minHeight='80px'>
      <HStack alignItems='center'><Text color='white' fontSize='xs' mr='1'>Ⓒ</Text><Text color='white' fontSize='sm'>Real Work {new Date().getFullYear()}.</Text></HStack>
      <Version />
    </Box>
  )
}

export default React.memo(Footer)
