import React, { useMemo } from 'react'

import { Badge } from '@real-work/ui'

import { Props } from './types'

function Job({
  job,
  ...props
}: Props): React.ReactElement {
  const {
    colorScheme,
    text,
  } = useMemo(() => job.status.toLowerCase() === 'open' ? {
    colorScheme: 'green',
    text: 'Open',
  } : {
    colorScheme: 'red',
    text: 'Closed',
  }, [ job.status ])

  return (
    <Badge
      colorScheme={colorScheme}
      variant='solid'
      {...props}
    >
      {text}
    </Badge>
  )
}

export default React.memo(Job)

export * from './types'
