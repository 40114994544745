import React, { useCallback } from 'react'

import {
  api,
  lang,
} from '@real-work/common'

import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  TextArea,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import {
  useGetWorkerByUserIdQuery,
  usePatchWorkerByIdMutation,
} from '@/services/worker'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function AboutMeAddEdit({ mode }: Props): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()

  const navigate = useNavigate()

  const { user } = useSession()

  const toast = useToast()

  const { data } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } })

  const [
    patchWorker,
    { isLoading },
  ] = usePatchWorkerByIdMutation()

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    patchWorker({
      body: values,
      params: { userId: user.id },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate(-1)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    handleFormApiErrors,
    patchWorker,
    navigate,
    toast,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | About Me'>
      <ScreenWrapper>
        <Container>
          <Heading>About Me</Heading>
          <Text>
            {mode === 'edit' ? 'Edit your About Me section.' : 'Is there anything else you want employers to know about you? Write a sentence or two to tell them what makes you, you!'}
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>{mode === 'edit' ? 'Edit About Me' : 'Add About Me'}</Heading>
          <Divider />

          <Form<FormValues>
            enableReinitialize={true}
            initialValues={{ personalSummary: data?.worker?.personalSummary || '' }}
            rules={api.endpoints.workers.patchById.validation.body.rules}
            onSubmit={onSubmit}
          >
            <FormControl
              name='personalSummary'
              label='Personal Summary'
            >
              <TextArea />
            </FormControl>
            <ButtonGroup>
              <SubmitButton isLoading={isLoading}>Save</SubmitButton>
              <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
            </ButtonGroup>
          </Form>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(AboutMeAddEdit)
