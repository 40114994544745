import { config } from '@real-work/common'

import packageJson from '../package.json'

export const { version } = packageJson

export default {
  ...config,
  stripe: { publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '' },
}
