import {
  useEffect,
  useState,
} from 'react'

import { api } from '@real-work/common'

import type { Models } from '@real-work/orm'

import realWorkApi, {
  baseUrl,
  getAuthHeaders,
} from '@/state/apis/realWork'

import type {
  GetAssetByIdRequest,
  GetAssetByIdResponse,
  GetCompanyProfilePictureByCompanyIdRequest,
  GetWorkerProfilePictureByUserIdRequest,
} from './types'

const path = 'v1/assets'

const assetService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getAssetById: build.query<api.AsJson<GetAssetByIdResponse>, GetAssetByIdRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.id}`,
        method: 'GET',
      }),
    }),
  }),
})

export default assetService

export const { useGetAssetByIdQuery } = assetService

export const { endpoints: { getAssetById } } = assetService

export const useGetCompanyProfilePictureByCompanyIdQuery = (
  { params }: GetCompanyProfilePictureByCompanyIdRequest,
  options?: { skip?: boolean },
) => useGetProfilePicture(`${baseUrl}/v1/companies/${params.companyId}/profile-picture/${params.variant || ''}`, options)

export const useGetWorkerProfilePictureByUserIdQuery = (
  { params }: GetWorkerProfilePictureByUserIdRequest,
  options?: { skip?: boolean },
) => useGetProfilePicture(`${baseUrl}/v1/workers/${params.userId}/profile-picture/${params.variant || ''}`, options)

const useGetProfilePicture = (url: string, options?: { skip?: boolean }) => {
  const [
    base64,
    setBase64,
  ] = useState<string>()

  const [
    blob,
    setBlob,
  ] = useState<Blob>()

  const [
    crop,
    setCrop,
  ] = useState<Models.Image.Crop>()

  const [
    isLoading,
    setIsLoading,
  ] = useState(!options?.skip)

  useEffect(() => {
    if (options?.skip) {
      return
    }

    fetch(url, {
      method: 'get',
      headers: getAuthHeaders(),
    })
      .then(response => {
        setCrop({
          height: Number(response.headers.get('X-crop-height')),
          width: Number(response.headers.get('X-crop-width')),
          x: Number(response.headers.get('X-crop-x')),
          y: Number(response.headers.get('X-crop-y')),
          zoom: Number(response.headers.get('X-crop-zoom')),
        })

        return response.blob()
      })
      .then(async blob => {
        if (!blob.size) {
          return
        }

        setBlob(blob)

        const base64 = await new Promise<string | undefined>(resolve => {
          const reader = new FileReader()

          reader.onloadend = () => resolve(reader.result?.toString())

          reader.readAsDataURL(blob)
        })

        setBase64(base64)
      })
      .catch(error => {
        throw error
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    options?.skip,
    url,
  ])

  return {
    base64,
    blob,
    crop,
    isLoading,
  }
}
