import React from 'react'
import { Text as NbText } from 'native-base'

import { Props } from './types'

function Text({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbText {...props}>{children}</NbText>
  )
}

export default React.memo(Text)

export * from './types'
