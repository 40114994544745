import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import {
  DeleteCompanyLocationByIdRequest,
  DeleteCompanyLocationByIdResponse,
} from './types'

const path = 'v1/company-locations'

const companyService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    deleteCompanyLocationById: build.mutation<api.AsJson<DeleteCompanyLocationByIdResponse>, DeleteCompanyLocationByIdRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Companies' ],
    }),
  }),
})

export default companyService

export const { useDeleteCompanyLocationByIdMutation } = companyService

export const { endpoints: { deleteCompanyLocationById } } = companyService
