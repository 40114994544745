import React from 'react'
import { Flex as NbFlex } from 'native-base'

import { Props } from './types'

function Flex({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbFlex {...props}>{children}</NbFlex>
  )
}

export default React.memo(Flex)

export * from './types'
