"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobStatuses = exports.jobSorts = void 0;
exports.jobSorts = [
    'createdAt',
    '-createdAt',
    'statusUpdatedAt',
    '-statusUpdatedAt',
    'updatedAt',
    '-updatedAt',
];
exports.jobStatuses = [
    'closed',
    'open',
];
