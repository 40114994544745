import React from 'react'

import { url } from '@real-work/common'

import {
  AnimatorFadeIn,
  Center,
  Divider,
  LoadingFailed,
} from '@real-work/ui'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

import Link from '../../components/Link'

function Error500(): React.ReactElement {
  return (
    <DocumentWrapper title='Real Work | Not Found'>
      <ScreenWrapper>
        <Center py='6' px='4'>
          <AnimatorFadeIn>
            <LoadingFailed heading='Oops! 500 - Server error.'>Something went wrong. Please try again later.</LoadingFailed>
          </AnimatorFadeIn>
          <Divider my='6' maxWidth='1040px' />
          <Link to={url.marketingSite('/feedback')} type='button'>Contact Us</Link>
        </Center>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Error500)
