import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  api,
  lang,
  object,
  ValidationTypes,
} from '@real-work/common'
import type { Models } from '@real-work/orm'
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  HStack,
  Input,
  InputRepeating,
  LoadingFailed,
  LoadingIndicator,
  Select,
  SubmitButton,
  Text,
  TextArea,
  useHandleFormApiErrors,
  useMediaQuery,
  useToast,
  VStack,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'
import useInterdependentSelects, { InterdependentSelectOptions } from '@/hooks/useInterdependentSelects'

import { useGetCertificationsQuery } from '@/services/certification'
import { useGetCompanyByIdQuery } from '@/services/company'
import {
  useGetJobByIdQuery,
  useUpsertJobMutation,
} from '@/services/job'
import { useGetLicensesQuery } from '@/services/license'
import { useGetOptionsQuery } from '@/services/option'
import { useGetTradesQuery } from '@/services/trade'

import type {
  FormValues,
  Props,
  OnSubmit,
  Values,
} from './types'

function JobAddEdit({ mode }: Props): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()
  const toast = useToast()

  const [ isDesktop ] = useMediaQuery({ minWidth: 860 })

  const navigate = useNavigate()
  const { id } = useRouteParams()
  const { user } = useSession()

  const {
    data: { certifications } = {},
    isLoading: isCertificationsLoading,
    isError: isCertificationsError,
  } = useGetCertificationsQuery()
  const {
    data: { licenses } = {},
    isLoading: isLicensesLoading,
    isError: isLicensesError,
  } = useGetLicensesQuery()

  const {
    data: { options } = {},
    isLoading: isOptionsLoading,
    isError: isOptionsError,
  } = useGetOptionsQuery()

  const {
    data: { trades } = {},
    isLoading: isTradesLoading,
    isError: isTradesError,
  } = useGetTradesQuery()

  const {
    data: { company } = {},
    isError: isCompanyError,
    isLoading: isCompanyLoading,
  } = useGetCompanyByIdQuery({ params: { id: user?.employer?.companyOwned?.id || '' } }, { skip: !user?.employer?.companyOwned?.id })

  const {
    data: { job } = {},
    isError: isJobError,
    isLoading: isJobLoading,
  } = useGetJobByIdQuery({ params: { id: id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  })

  const [
    upsertJob,
    { isLoading: isJobUpserting },
  ] = useUpsertJobMutation()

  const validationRules = object.except<ValidationTypes.ValidationRules>(api.endpoints.jobs.putJob.validation.body.rules, [
    'companyId',
    'status',
  ])

  const initialValues = useMemo(() => {
    if (!job) {
      return undefined
    }

    return ({
      certificationIds: job.certifications.map(certification => certification.certificationId),
      companyId: job.companyId,
      companyLocationIds: (job.companyLocations || []).map(location => location.companyLocationId),
      description: job.description,
      experience: job.experience,
      hourlyRate: job.hourlyRate as unknown as number,
      jobTypeOptionId: job.jobTypeOption.id,
      licenseIds: job.licenses.map(license => license.licenseId),
      otherCompensation: job.otherCompensation,
      skills: (job.skills || [ { name: '' } ]).map(skill => ({ name: skill.name })),
      specialtyIds: (job.specialties || []).map(specialty => specialty.specialtyId),
      title: job.title,
      workShiftOptionIds: (job.workShifts || []).map(workShift => workShift.optionId),
    })
  }, [ job ])

  const companyLocationOptions = useMemo(() => (company?.locations || [])
    .map(location => ({
      label: location.title,
      value: location.id,
    })), [ company ])

  const jobTypeOptions = useMemo(() => (options ?? [])
    .filter(opt => opt.type === 'jobType')
    .map(opt => ({
      label: opt.label,
      value: opt.id,
    })), [ options ])

  const workShiftOptions = useMemo(() => (options ?? [])
    .filter(opt => opt.type === 'workShift')
    .map(opt => ({
      id: opt.id,
      label: opt.label,
      value: opt.value,
    })), [ options ])

  const stateAndCertificationOptions: InterdependentSelectOptions[] = useMemo(
    () => {
      if (!certifications) return []

      const stateMap: Record<string, api.AsJson<Models.Certification.default>[]> = { 'N/A': [] }

      certifications.forEach(cert => {
        const usStateAbbr = cert.usStateAbbr || 'N/A'

        if (!stateMap[usStateAbbr]) {
          stateMap[usStateAbbr] = []
        }

        stateMap[usStateAbbr].push(cert)
      })

      const allStateAbbrs = Object.keys(stateMap).sort(sortStatesAlphabetically)

      const options = allStateAbbrs.map(stateAbbr => ({
        label: stateAbbr,
        value: stateAbbr,
        children: stateMap[stateAbbr].map(cert => ({
          label: cert.name,
          value: cert.id,
          pillLabel: stateAbbr === 'N/A' ? cert.name : `${stateAbbr} | ${cert.name}`,
        })),
      }))

      return options
    },
    [ certifications ],
  )

  const stateAndLicenseOptions: InterdependentSelectOptions[] = useMemo(
    () => {
      if (!licenses) return []

      const stateMap: Record<string, api.AsJson<Models.License.default>[]> = { 'N/A': [] }

      licenses.forEach(license => {
        const usStateAbbr = license.usStateAbbr || 'N/A'

        if (!stateMap[usStateAbbr]) {
          stateMap[usStateAbbr] = []
        }

        stateMap[usStateAbbr].push(license)
      })

      const allStateAbbrs = Object.keys(stateMap).sort(sortStatesAlphabetically)

      const options = allStateAbbrs.map(stateAbbr => ({
        label: stateAbbr,
        value: stateAbbr,
        children: stateMap[stateAbbr].map(license => ({
          label: license.name,
          value: license.id,
          pillLabel: stateAbbr === 'N/A' ? license.name : `${stateAbbr} | ${license.name}`,
        })),
      }))

      return options
    },
    [ licenses ],
  )

  const tradeAndSpecialtyOptions: InterdependentSelectOptions[] = useMemo(
    () => (trades ?? [])
      .map(trade => ({
        label: trade.name,
        value: trade.id,
        children: (trade.specialties ?? []).map(specialty => ({
          label: specialty.name,
          value: specialty.id,
          pillLabel: `${trade.name} | ${specialty.name}`,
        })),
      })),
    [ trades ],
  )

  const {
    availableChildOptions: certificationOptions,
    availableParentOptions: certificationStateOptions,
    onSelectChild: onSelectCertification,
    onSelectParent: onSelectStateForCertification,
    selectedChildOptions: selectedCertifications,
  } = useInterdependentSelects(stateAndCertificationOptions, initialValues?.certificationIds || [], 'N/A')

  const {
    availableChildOptions: licenseOptions,
    availableParentOptions: licenseStateOptions,
    onSelectChild: onSelectLicense,
    onSelectParent: onSelectStateForLicense,
    selectedChildOptions: selectedLicenses,
  } = useInterdependentSelects(stateAndLicenseOptions, initialValues?.licenseIds || [], 'N/A')

  const {
    availableChildOptions: specialtyOptions,
    availableParentOptions: tradeOptions,
    onSelectChild: onSelectSpecialty,
    onSelectParent: onSelectTrade,
    selectedChildOptions: selectedSpecialties,
  } = useInterdependentSelects(tradeAndSpecialtyOptions, initialValues?.specialtyIds || [])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!company) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertJob({
      body: {
        ...object.except(values, [
          'licensesState',
          'certificationsState',
          'trade',
        ]),
        companyId: company.id,
        hourlyRate: Number(values.hourlyRate),
      },
      params: { id },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate(`/jobs/${response.job.id}`)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    company,
    id,
    navigate,
    handleFormApiErrors,
    toast,
    upsertJob,
  ])

  return (
    <DocumentWrapper title='Real Work | Add Job'>
      <ScreenWrapper>
        {(isJobLoading && <LoadingIndicator />) || ((isJobError || isCompanyError) && <LoadingFailed />) || (
          <>
            <Container>
              <Heading>{mode === 'edit' ? 'Edit Job' : 'Add Job'}</Heading>
              <Text>
                {mode === 'edit' ? 'Edit job details.' : 'Enter details below to post a job.'}
              </Text>
            </Container>
            <Container mt='4'>
              <Form<FormValues>
                enableReinitialize={true}
                initialValues={{
                  skills: [ { name: '' } ],
                  ...initialValues,
                } as Values<FormValues>}
                onSubmit={onSubmit}
                rules={validationRules}
              >
                <Heading size='md' variant='secondary'>Overview</Heading>
                <Divider />
                <Box
                  maxWidth='620'
                >
                  <FormControl
                    name='title'
                    label='Job Title'
                  >
                    <Input
                      type='text'
                    />
                  </FormControl>
                  <HStack
                    justifyContent='space-between'
                    flexDirection={isDesktop ? 'row' : 'column'}
                  >
                    {(isTradesLoading && <LoadingIndicator />) || (isTradesError && <LoadingFailed />) || (
                      <FormControl
                        name='trade'
                        label='Trade'
                        maxWidth={isDesktop ? '300' : '100%'}
                      >
                        <Select
                          onChange={onSelectTrade}
                          options={tradeOptions}
                        />
                      </FormControl>
                    )}
                    <FormControl
                      name='specialtyIds'
                      label='Specialty'
                      maxWidth={isDesktop ? '300' : '100%'}
                    >
                      <Select
                        isMulti={true}
                        onChange={onSelectSpecialty}
                        options={specialtyOptions}
                        pillsLocation='outside'
                        value={selectedSpecialties}
                      />
                    </FormControl>
                  </HStack>
                  {(isCompanyLoading && <LoadingIndicator />) || (isCompanyError && <LoadingFailed />) || (
                    <FormControl
                      name='companyLocationIds'
                      label='Location'
                    >
                      <Select
                        isMulti={true}
                        options={companyLocationOptions}
                      />
                    </FormControl>
                  )}
                  {(!isJobLoading && !isCompanyLoading && !company?.locations?.length) && (
                    <Alert
                      type='error'
                      title='Oops!'
                      width='100%'
                      maxW='none'
                      description={<Text>You must enter a location in your company profile to list a job. <Link to={`/companies/${user?.employer?.companyOwned?.id}/locations/add`} color='black'>Add Location</Link></Text>}
                    />
                  )}
                  <FormControl
                    name='jobTypeOptionId'
                    label='Job Type'
                  >
                    <Select
                      options={jobTypeOptions}
                    />
                  </FormControl>
                  {(isOptionsLoading && <LoadingIndicator />) || (isOptionsError && <LoadingFailed />) || (
                    <FormControl
                      name='workShiftOptionIds'
                      label='Work Hours'
                    >
                      <CheckboxGroup>
                        <VStack space='1' mt='1'>
                          {workShiftOptions.map(option => <Checkbox key={option.value} value={option.id}>{option.label}</Checkbox>)}
                        </VStack>
                      </CheckboxGroup>
                    </FormControl>
                  )}
                  <FormControl
                    name='description'
                    label='Description'
                  >
                    <TextArea />
                  </FormControl>

                  <HStack alignItems='center' space='1' mt='4'>
                    <Heading size='md' variant='secondary'>Licenses</Heading> <Text fontSize='xl' fontWeight='medium' color='gray.400'>(Optional)</Text>
                  </HStack>
                  <Divider />
                  {(isLicensesLoading && <LoadingIndicator />) || (isLicensesError && <LoadingFailed />) || (
                    <HStack
                      justifyContent='space-between'
                      flexDirection={isDesktop ? 'row' : 'column'}
                    >
                      <FormControl
                        name='licensesState'
                        label='State'
                        maxWidth={isDesktop ? '300' : '100%'}
                      >
                        <Select
                          onChange={onSelectStateForLicense}
                          options={licenseStateOptions}
                        />
                      </FormControl>
                      <FormControl
                        name='licenseIds'
                        label='License'
                        maxWidth={isDesktop ? '300' : '100%'}
                      >
                        <Select
                          isMulti={true}
                          onChange={onSelectLicense}
                          options={licenseOptions}
                          pillsLocation='outside'
                          value={selectedLicenses}
                        />
                      </FormControl>
                    </HStack>
                  )}

                  <HStack alignItems='center' space='1' mt='4'>
                    <Heading size='md' variant='secondary'>Certifications</Heading> <Text fontSize='xl' fontWeight='medium' color='gray.400'>(Optional)</Text>
                  </HStack>
                  <Divider />
                  {(isCertificationsLoading && <LoadingIndicator />) || (isCertificationsError && <LoadingFailed />) || (
                    <HStack
                      justifyContent='space-between'
                      flexDirection={isDesktop ? 'row' : 'column'}
                    >
                      <FormControl
                        name='certificationsState'
                        label='State'
                        maxWidth={isDesktop ? '300' : '100%'}
                      >
                        <Select
                          onChange={onSelectStateForCertification}
                          options={certificationStateOptions}
                        />
                      </FormControl>
                      <FormControl
                        name='certificationIds'
                        label='Certification'
                        maxWidth={isDesktop ? '300' : '100%'}
                      >
                        <Select
                          isMulti={true}
                          pillsLocation='outside'
                          onChange={onSelectCertification}
                          options={certificationOptions}
                          value={selectedCertifications}
                        />
                      </FormControl>
                    </HStack>
                  )}

                  <Heading size='md' variant='secondary' mt='4'>Skills & Experience</Heading>
                  <Divider />
                  <FormControl
                    name='experience'
                    label='Experience'
                  >
                    <TextArea />
                  </FormControl>

                  <FormControl
                    label='Skills'
                    name='skills'
                  >
                    <InputRepeating<Models.JobSkill>
                      addAnotherText='Add Another Skill'
                      name='skills'
                      fields={[
                        {
                          name: 'name',
                          render: ({
                            errorElement,
                            name,
                            removeElement,
                          }) => (
                            <React.Fragment key={name}>
                              <Input
                                name={name}
                                paddingRight={8}
                                type='text'
                              />
                              {removeElement}

                              {errorElement}
                            </React.Fragment>
                          ),
                        },
                      ]}
                    />
                  </FormControl>

                  <Heading size='md' variant='secondary' mt='4'>Salary & Compensation</Heading>
                  <Divider />
                  <FormControl
                    name='hourlyRate'
                    label='Hourly Rate'
                  >
                    <Input
                      type='text'
                    />
                  </FormControl>
                  <FormControl
                    name='otherCompensation'
                    label='Other Compensation'
                    labelNote='(Optional)'
                  >
                    <TextArea />
                  </FormControl>

                  <ButtonGroup mt='6'>
                    <SubmitButton isLoading={isJobUpserting}>Save Changes</SubmitButton>
                    <Button
                      onPress={() => navigate(-1)}
                      variant='outline'
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Box>
              </Form>
            </Container>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

const sortStatesAlphabetically = (a: string, b: string) => {
  const comp = (a === 'N/A' && -1) || (b === 'N/A' && 1) || a.localeCompare(b)

  return comp
}

export default React.memo(JobAddEdit)
