import React from 'react'

import {
  Box,
  Container,
  Heading,
  HStack,
  Image,
  LoadingFailed,
  LoadingIndicator,
  NoContent,
  Text,
  VStack,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useSession from '@/hooks/useSession'

import { useGetWorkerByUserIdQuery } from '@/services/worker'

import ProfileChecklist from './components/ProfileChecklist'

import headerImage from './assets/create-header.png'

function ProfileEdit(): React.ReactElement {
  const session = useSession()

  const {
    data: { worker } = {},
    error,
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: session.user?.id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !session.user?.id,
  })

  return (
    <DocumentWrapper title='Real Work | Profile Check List'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (worker && (
          <>
            {(!worker.profilePublishedAt && (
              <>
                <Image
                  alt='Construction Work'
                  source={headerImage}
                  width='100%'
                  height='330'
                  mt='-4'
                  mb='6'
                  alignSelf='center'
                />

                <Container>
                  <Heading>{`Hi ${worker.user.firstName}`}</Heading>
                  <Text>
                    The more details you provide the closer you are to finding your ideal job. We’ll save your work each time, so make sure you come back to keep your info up-to-date.
                  </Text>
                </Container>
              </>
            )) || (
              <Container>
                <HStack flexWrap='wrap' justifyContent='space-between' width='100%'>
                  <VStack>
                    <Heading>Edit Profile</Heading>
                  </VStack>
                  <Box>
                    <Link
                      to={`/workers/${worker.userId}`}
                      type='button'
                      button={{ variant: 'small' }}
                    >
                      Done
                    </Link>
                  </Box>
                </HStack>
                <Text>
                  Update your profile using the sections below. The more information you can provide,
                  the more likely you are to match with your ideal position.
                </Text>
              </Container>
            )}

            <ProfileChecklist
              isLoading={isLoading}
              showProgressBar={!!worker.profilePublishedAt}
              worker={worker}
            />
          </>
        )) || ((error as { status: number })?.status === 404 && <NoContent isWholeScreen={true} />) || <LoadingFailed />}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(ProfileEdit)
