import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  api,
  lang,
} from '@real-work/common'

import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  Form,
  FormTypes,
  FormControl,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  Select,
  SelectTypes,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import { useGetTradesQuery } from '@/services/trade'

import {
  useGetWorkerByUserIdQuery,
  useUpsertWorkerSpecialtyMutation,
} from '@/services/worker'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function TradesAddEdit({ mode }: Props): React.ReactElement {
  const { id } = useRouteParams()

  const navigate = useNavigate()

  const { user } = useSession()
  const handleFormApiErrors = useHandleFormApiErrors()
  const toast = useToast()

  const {
    data: { worker } = {},
    isLoading: isWorkerLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, { skip: !user?.id })

  const workerSpecialty = worker?.specialties?.find(item => item.id === id)

  const {
    data: { trades } = {},
    isError,
    isLoading: isTradesLoading,
  } = useGetTradesQuery()

  const isLoading = useMemo(() => isTradesLoading || isWorkerLoading, [
    isTradesLoading,
    isWorkerLoading,
  ])

  const [
    upsertWorkerSpecialty,
    { isLoading: isSubmitting },
  ] = useUpsertWorkerSpecialtyMutation()

  const tradeOptions = useMemo<SelectTypes.Option[]>(() => trades?.map(trade => ({
    label: trade.name,
    value: trade.id,
  })) || [], [ trades ])

  const [
    specialtyOptions,
    setSpecialtyOptions,
  ] = useState<SelectTypes.Option[]>([])

  const handleTradeIdChange = useCallback((tradeId?: string) => {
    const trade = trades?.find(trade => trade.id === tradeId)

    const options = (trade?.specialties || []).map(specialty => ({
      label: specialty.name,
      value: specialty.id,
    }))

    setSpecialtyOptions(options)
  }, [ trades ])

  useEffect(() => {
    if (!workerSpecialty?.specialty?.tradeId) {
      return
    }

    handleTradeIdChange(workerSpecialty.specialty.tradeId)
  }, [
    handleTradeIdChange,
    workerSpecialty,
  ])

  const onChangeTradeId = useCallback<(formik: FormTypes.FormikProps<FormValues>) => SelectTypes.OnChange>(formik => value => {
    if (value === formik.values.tradeId) {
      return
    }

    handleTradeIdChange(value as string)

    formik.setFieldValue('specialtyId', undefined)
  }, [ handleTradeIdChange ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertWorkerSpecialty({
      params: {
        id,
        userId: user.id,
      },
      body: {
        isPrimary: values.isPrimary,
        specialtyId: values.specialtyId,
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate(-1)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    id,
    navigate,
    toast,
    upsertWorkerSpecialty,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Trades'>
      <ScreenWrapper>
        <Container>
          <Heading>Trades</Heading>
          <Text>
            {mode === 'edit' ? 'Edit a trade below.' : 'Add a trade below.'}
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>{mode === 'edit' ? 'Edit a Trade' : 'Add a Trade'}</Heading>
          <Divider />

          {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
            <Form<FormValues>
              enableReinitialize={true}
              initialValues={{
                specialtyId: workerSpecialty?.specialtyId || '',
                tradeId: workerSpecialty?.specialty?.tradeId || '',
              }}
              rules={api.endpoints.workers.putSpecialty.validation.body.rules}
              onSubmit={onSubmit}
            >
              {formik => (
                <>
                  <FormControl
                    name='tradeId'
                    label='Trade'
                  >
                    <Select<false>
                      onChange={onChangeTradeId(formik)}
                      options={tradeOptions}
                    />
                  </FormControl>

                  <FormControl
                    name='specialtyId'
                    label='Specialty'
                  >
                    <Select
                      isDisabled={specialtyOptions.length === 0}
                      options={specialtyOptions}
                    />
                  </FormControl>

                  <ButtonGroup>
                    <SubmitButton
                      display={!worker?.specialties?.length ? 'none' : undefined}
                      isLoading={isSubmitting}
                    >
                      Save
                    </SubmitButton>
                    <SubmitButton
                      display={workerSpecialty?.isPrimary ? 'none' : undefined}
                      isLoading={isSubmitting}
                      onPress={() => formik.setFieldValue('isPrimary', true)}
                      variant={worker?.specialties?.length ? 'subtle' : undefined}
                    >
                      Save & Make Primary
                    </SubmitButton>
                    <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
                  </ButtonGroup>
                </>
              )}
            </Form>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(TradesAddEdit)
