import React from 'react'

import {
  AnimatorFadeIn,
  Center,
  Divider,
  Heading,
  Image,
  Text,
} from '@real-work/ui'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

import Link from '../../components/Link'

import pageNotFound from './assets/pageNotFound.svg'

function Error404(): React.ReactElement {
  return (
    <DocumentWrapper title='Real Work | Not Found'>
      <ScreenWrapper>
        <Center py='6' px='4'>
          <AnimatorFadeIn>
            <Image
              source={pageNotFound}
              width={219}
              height={175}
              alt='Not Found'
            />
          </AnimatorFadeIn>
          <Heading my='6'>Oops! 404 - Page not found.</Heading>
          <Text mb='4'>We can’t seem to find the page you are looking for.</Text>
          <Divider my='6' maxWidth='1040px' />
          <Link to='/' type='button'>Go Back Home</Link>
        </Center>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Error404)
