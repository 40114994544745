import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  api,
  lang,
} from '@real-work/common'

import {
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  DateTimeInput,
  Divider,
  Form,
  FormControl,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  Input,
  Select,
  SubmitButton,
  Text,
  TextArea,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import type { WorkExperienceType } from '@real-work/orm/dist/src/models/workerWorkExperience/types'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import { useGetAllSpecialtiesQuery } from '@/services/specialty'
import {
  useGetWorkerByUserIdQuery,
  useUpsertWorkerWorkExperienceMutation,
} from '@/services/worker'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

const typeOptions = [
  {
    value: 'contract',
    label: 'Contract',
  },
  {
    value: 'full-time',
    label: 'Full Time',
  },
]

function WorkExperienceAddEdit({ mode }: Props): React.ReactElement {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const toast = useToast()

  const { id } = useRouteParams()
  const { user } = useSession()

  const { data: specialtiesData } = useGetAllSpecialtiesQuery()

  const {
    data,
    isError,
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, { skip: !user?.id })
  const [
    upsertWorkerWorkExperience,
    { isLoading: isSubmitting },
  ] = useUpsertWorkerWorkExperienceMutation()

  const validationRules = api.endpoints.workers.putWorkExperience.validation.body.rules

  const rules = useMemo(() => ({
    specialties: validationRules.specialties,
    title: validationRules.title,
    type: validationRules.type,
    company: validationRules.company,
    location: validationRules.location,
    isCurrentJob: validationRules.isCurrentJob,
    description: validationRules.description,
  }), [ validationRules ])

  const specialtyOptions = useMemo(() => specialtiesData?.specialties.map(specialty => ({
    label: `${specialty.trade?.name} - ${specialty.name}`,
    value: specialty.id,
  })).sort((a, b) => a.label > b.label ? 1 : -1), [ specialtiesData ])

  const workExperience = data?.worker.workExperiences?.find(item => item.id === id)

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertWorkerWorkExperience({
      params: {
        userId: user.id,
        id,
      },
      body: {
        ...values,
        startedAt: api.dates.inputValueToApiValue(values.startedAt),
        endedAt: api.dates.inputValueToApiValueOptional(values.endedAt),
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message,
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate('/profile/work-experiences')
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    id,
    navigate,
    toast,
    upsertWorkerWorkExperience,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Work Experience'>
      <ScreenWrapper>
        <Container>
          <Heading>Work Experience</Heading>
          <Text>
            {mode === 'edit' ? 'Edit work experience below.' : 'Add work experience below.'}
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>{mode === 'edit' ? 'Edit Work Experience' : 'Add Work Experience'}</Heading>
          <Divider />
          {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
            <Form<FormValues>
              initialValues={{
                specialties: workExperience?.specialties.map(specialty => specialty.specialtyId) || [],
                title: workExperience?.title || '',
                type: workExperience?.type as WorkExperienceType,
                company: workExperience?.company || '',
                location: workExperience?.location || '',
                isCurrentJob: workExperience?.isCurrentJob || false,
                startedAt: api.dates.apiValueToInputValue(workExperience?.startedAt) || new Date(),
                endedAt: api.dates.apiValueToInputValue(workExperience?.endedAt) || undefined,
                description: workExperience?.description,
              }}
              rules={rules}
              onSubmit={onSubmit}
            >
              {({ values }) => (
                <>
                  <FormControl
                    name='title'
                    label='Job Title'
                  >
                    <Input
                      type='text'
                    />
                  </FormControl>
                  <FormControl
                    name='type'
                    label='Contract or Full-Time'
                    labelNote='(Optional)'
                  >
                    <Select
                      isClearable={true}
                      options={typeOptions}
                    />
                  </FormControl>
                  <FormControl
                    name='specialties'
                    label='My trades used in this role included...'
                  >
                    <Select
                      isMulti={true}
                      options={specialtyOptions}
                    />
                  </FormControl>
                  <FormControl
                    name='company'
                    label='Company'
                  >
                    <Input
                      type='text'
                    />
                  </FormControl>
                  <FormControl
                    name='location'
                    label='City and State'
                  >
                    <Input
                      type='text'
                    />
                  </FormControl>
                  <FormControl
                    name='startedAt'
                    label='Start Date'
                  >
                    <DateTimeInput
                      mode='date'
                    />
                  </FormControl>
                  <FormControl
                    name='isCurrentJob'
                    mt='1'
                  >
                    <Checkbox
                      accessibilityLabel='I am currently working here.'
                    >
                      I am currently working here.
                    </Checkbox>
                  </FormControl>
                  {!values.isCurrentJob && (
                    <FormControl
                      name='endedAt'
                      label='End Date'
                      labelNote='(Optional)'
                    >
                      <DateTimeInput
                        isOptional={true}
                        mode='date'
                      />
                    </FormControl>
                  )}
                  <FormControl
                    name='description'
                    label='Description'
                    labelNote='(Optional)'
                  >
                    <TextArea />
                  </FormControl>
                  <ButtonGroup>
                    <SubmitButton isLoading={isSubmitting}>Save</SubmitButton>
                    <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
                  </ButtonGroup>
                </>
              )}
            </Form>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(WorkExperienceAddEdit)
