import type {
  StyledProps,
  Theme,
} from 'native-base'

import { object } from '@real-work/common'

import type {
  ColorType,
  ComponentTheme,
} from '..'

export const getColor = (theme: Theme) => (color?: ColorType | string): ColorType | undefined => (color && (object.get(theme.colors || {}, color as string) as string)) || color

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getComponentTheme = (theme: Theme) => (component: keyof typeof theme.components, props: any = {}): ComponentTheme => {
  const rootComponentTheme = theme.components[component] as ComponentTheme & { variants: Record<string, ComponentTheme> } || undefined

  const componentVariants = rootComponentTheme?.variants
  const componentVariantInput = props?.variant && componentVariants[props.variant as keyof typeof componentVariants]
  const componentVariant = typeof componentVariantInput === 'function' ? componentVariantInput(props) : componentVariantInput

  const componentThemeStyles = getComponentStyles(theme)(rootComponentTheme)

  const mergedComponentTheme = object.merge(componentThemeStyles, componentVariant || {})

  return mergedComponentTheme || {}
}

export const getComponentStyles = (theme: Theme) => (componentTheme?: ComponentTheme): StyledProps => {
  if (!componentTheme) {
    return {} as StyledProps
  }

  const baseStyle = (typeof componentTheme?.baseStyle === 'function' && componentTheme?.baseStyle({ theme }))
    || componentTheme?.baseStyle
    || {}

  return object.merge(baseStyle, componentTheme.defaultProps || {})
}
