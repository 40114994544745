import React from 'react'

import {
  AnimatorFadeIn,
  Button,
  Center,
  Divider,
  Heading,
  Image,
  Text,
} from '@real-work/ui'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

import useNavigate from '../../hooks/useNavigate'

import permissionDeniedImage from './assets/permissionDenied.svg'

function Error403(): React.ReactElement {
  const navigate = useNavigate()

  return (
    <DocumentWrapper title='Real Work | Not Found'>
      <ScreenWrapper>
        <Center py='6' px='4'>
          <AnimatorFadeIn>
            <Image
              source={permissionDeniedImage}
              width={219}
              height={175}
              alt='Access Denied'
            />
          </AnimatorFadeIn>
          <Heading my='6'>403 - Access denied.</Heading>
          <Text mb='4'>Sorry, you do not have permission to access this page.</Text>
          <Divider my='6' maxWidth='1040px' />
          <Button onPress={() => navigate(-1)}>Go Back</Button>
        </Center>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Error403)
