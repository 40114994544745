"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocationComponentsFromGoogleAddressComponents = exports.getDisplayLocationFromPlace = void 0;
const getDisplayLocationFromPlace = (place, format = 'oneLineAddress') => {
    const { city, streetAddress, usStateAbbr, zipCode, } = (0, exports.getLocationComponentsFromGoogleAddressComponents)(place.address_components) || {};
    if (format === 'cityCommaState') {
        return (city && usStateAbbr) ? `${city}, ${usStateAbbr}` : `${city || ''}${usStateAbbr || ''}`;
    }
    if (format === 'zipCode') {
        return zipCode || '';
    }
    const addressCommaCity = (streetAddress && city) ? `${streetAddress}, ${city}` : `${streetAddress || ''}${city || ''}`;
    const oneLineAddress = (addressCommaCity && usStateAbbr) ? `${addressCommaCity.match(/,/) ? addressCommaCity : `${addressCommaCity},`} ${usStateAbbr}` : `${addressCommaCity || ''}${usStateAbbr || ''}`;
    return oneLineAddress;
};
exports.getDisplayLocationFromPlace = getDisplayLocationFromPlace;
const getLocationComponentsFromGoogleAddressComponents = (googleAddressComponents) => {
    if (!googleAddressComponents) {
        return undefined;
    }
    const result = {};
    googleAddressComponents.forEach(component => {
        const { short_name: shortName, types, } = component;
        if (types.includes('locality') && shortName) {
            result.city = shortName;
        }
        if (types.includes('administrative_area_level_1') && shortName) {
            result.usStateAbbr = shortName;
        }
        if (types.includes('street_number') && shortName) {
            result.streetAddress = shortName;
        }
        if (types.includes('route')) {
            result.streetAddress = result.streetAddress ? `${result.streetAddress} ${shortName}` : shortName;
        }
        if (types.includes('postal_code')) {
            result.zipCode = shortName;
        }
    });
    return result;
};
exports.getLocationComponentsFromGoogleAddressComponents = getLocationComponentsFromGoogleAddressComponents;
__exportStar(require("./types"), exports);
