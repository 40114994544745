import React, { useMemo } from 'react'

import {
  Container,
  Divider,
  Form,
  Heading,
  HStack,
  LoadingIndicator,
  SubmitButton,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import StripeFormWrapper from '@/components/StripeFormWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useCompanySubscription from '@/hooks/useCompanySubscription'

import SelectedPlanAlert from '../components/SelectedPlanAlert'

function SignUpEmployerPayment(): React.ReactElement {
  const {
    isLoading,
    subscription,
  } = useCompanySubscription()

  const clientSecret = useMemo(() => subscription?.paymentIntent.clientSecret || undefined, [ subscription?.paymentIntent.clientSecret ])

  return (
    <DocumentWrapper title='Real Work | Employer Sign Up'>
      <ScreenWrapper>
        <Container>
          <Heading>Payment Info</Heading>
          <SelectedPlanAlert />
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>Please enter your payment method</Heading>
          <Divider />

          {(isLoading && <LoadingIndicator />) || (
            <StripeFormWrapper
              clientSecret={clientSecret}
              onSuccesssRedirectTo='/dashboard/employer'
              type='submitPayment'
            >
              {({
                isSubmitting,
                onSubmit,
                PaymentForm,
              }) => (
                <Form onSubmit={onSubmit}>
                  {PaymentForm}

                  <HStack flexDirection='row' mt='4' mb='4' space='4' flexWrap='wrap'>
                    <SubmitButton isLoading={isSubmitting}>Pay Now</SubmitButton>
                  </HStack>
                </Form>
              )}
            </StripeFormWrapper>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SignUpEmployerPayment)
