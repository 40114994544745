import React, {
  useMemo,
  useState,
} from 'react'

import { string } from '@real-work/common'

import Box from '../../Box'
import Image from '../../Image'
import Spinner from '../../Spinner'
import Text from '../../Text'

import { getVariant } from '../variants'

import type { Props } from './types'

function AvatarWorker({
  alt,
  isLarge = false,
  isLoading: isDataLoading = false,
  profilePicture,
  ...props
}: Props): React.ReactElement {
  const variant = getVariant(alt)

  const [
    isImgComponentLoading,
    setIsImgComponentLoading,
  ] = useState(!!profilePicture)

  const isLoading = useMemo(() => isDataLoading || isImgComponentLoading, [
    isDataLoading,
    isImgComponentLoading,
  ])

  const acronym = useMemo(() => alt && string.toAcronym(alt), [ alt ])

  return (
    <Box
      {...props}
    >
      <Box
        variant={variant}
        borderRadius='lg'
        overflow='hidden'
        shadow='4'
        width={isLarge ? 150 : 120}
        height={isLarge ? 150 : 120}
        justifyContent='center'
        alignItems='center'
      >
        {isLoading && <Spinner />}

        {(!isLoading && !profilePicture) && (
          <Text
            fontSize={isLarge ? '72px' : '60px'}
            fontWeight='medium'
            variant={variant}
            mt='-2'
          >
            {acronym}
          </Text>
        )}

        {!isDataLoading && (
          <Image
            alt={acronym}
            display={isImgComponentLoading ? 'none' : undefined}
            position='absolute'
            top='0'
            width={isLarge ? 150 : 120}
            height={isLarge ? 150 : 120}
            onLoadEnd={() => setIsImgComponentLoading(false)}
            src={profilePicture ? URL.createObjectURL(profilePicture) : undefined}
          />
        )}
      </Box>
    </Box>
  )
}

export default React.memo(AvatarWorker)

export * from './types'
