import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import {
  PostStripeSetupIntentRequest,
  PostStripeSetupIntentResponse,
  PutStripePaymentMethodRequest,
  PutStripePaymentMethodResponse,
} from './types'

const path = 'v1/stripe'

const stripeService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    createSetupIntent: build.mutation<api.AsJson<PostStripeSetupIntentResponse>, PostStripeSetupIntentRequest>({
      query: ({ body }) => ({
        url: `${path}/setup-intents`,
        method: 'POST',
        body,
      }),
    }),
    updatePaymentMethod: build.mutation<api.AsJson<PutStripePaymentMethodResponse>, PutStripePaymentMethodRequest>({
      query: ({ body }) => ({
        url: `${path}/payment-methods`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export default stripeService

export const {
  useCreateSetupIntentMutation,
  useUpdatePaymentMethodMutation,
} = stripeService

export const {
  endpoints: {
    createSetupIntent,
    updatePaymentMethod,
  },
} = stripeService
