import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  api,
  lang,
} from '@real-work/common'

import {
  Button,
  ButtonGroup,
  Container,
  DateTimeInput,
  Divider,
  Form,
  FormControl,
  Heading,
  Input,
  LoadingFailed,
  LoadingIndicator,
  SubmitButton,
  Text,
  TextArea,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import {
  useGetWorkerByUserIdQuery,
  useUpsertWorkerMembershipMutation,
} from '@/services/worker'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import DocumentWrapper from '../../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function MembershipsAddEdit({ mode }: Props): React.ReactElement {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const toast = useToast()

  const { id } = useRouteParams()
  const { user } = useSession()

  const {
    data,
    isError,
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, { skip: !id })
  const [
    upsertWorkerMembership,
    { isLoading: isSubmitting },
  ] = useUpsertWorkerMembershipMutation()

  const membership = data?.worker.memberships?.find(membership => membership.id === id)

  const initialValues = useMemo(() => ({
    title: membership?.title || '',
    notes: membership?.notes || '',
    startedAt: api.dates.apiValueToInputValue(membership?.startedAt) || new Date(),
    endedAt: api.dates.apiValueToInputValue(membership?.endedAt) || undefined,
  }), [ membership ])

  const validationRules = api.endpoints.workers.putMembership.validation.body.rules

  const rules = useMemo(() => ({
    title: validationRules.title.required(),
    notes: validationRules.notes.nullable(),
  }), [ validationRules ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertWorkerMembership({
      params: {
        userId: user.id,
        id,
      },
      body: {
        ...values,
        startedAt: api.dates.inputValueToApiValue(values.startedAt),
        endedAt: api.dates.inputValueToApiValueOptional(values.endedAt),
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
        navigate('/profile/memberships')
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    id,
    navigate,
    toast,
    upsertWorkerMembership,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Memberships'>
      <ScreenWrapper>
        <Container>
          <Heading>Memberships</Heading>
          <Text>
            {mode === 'edit' ? 'Edit a membership below.' : 'Add a membership below.'}
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>{mode === 'edit' ? 'Edit a Membership' : 'Add a Membership'}</Heading>
          <Divider />
          {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
            <Form<FormValues>
              initialValues={initialValues}
              rules={rules}
              onSubmit={onSubmit}
            >
              <FormControl
                name='title'
                label='Membership Title'
              >
                <Input
                  type='text'
                />
              </FormControl>
              <FormControl
                name='startedAt'
                label='Start Date'
              >
                <DateTimeInput
                  mode='date'
                />
              </FormControl>
              <FormControl
                name='endedAt'
                label='End Date'
                labelNote='(Optional)'
              >
                <DateTimeInput
                  isOptional={true}
                  mode='date'
                />
              </FormControl>
              <FormControl
                name='notes'
                label='Notes'
                labelNote='(Optional)'
              >
                <TextArea />
              </FormControl>
              <ButtonGroup>
                <SubmitButton isLoading={isSubmitting}>Save</SubmitButton>
                <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
              </ButtonGroup>
            </Form>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(MembershipsAddEdit)
