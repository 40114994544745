import React from 'react'

import Progress from '../Progress'

import { Props } from './types'

function WorkerProfileCompletenessProgress({
  percentComplete,
  ...props
}: Props): React.ReactElement {
  return (
    <Progress
      colorScheme={(percentComplete === 100 && 'green') || (percentComplete >= 50 && 'yellow') || 'warning'}
      height='4'
      value={percentComplete || 1}
      {...props}
    />
  )
}

export default React.memo(WorkerProfileCompletenessProgress)

export * from './types'
