import React, { useState } from 'react'
import { InfoIcon } from 'native-base'

import Button from '../Button'
import HStack from '../HStack'
import Modal from '../Modal'
import Pressable from '../Pressable'

import { Props } from './types'

function TooltipModal({
  buttons,
  children,
  title,
  ...props
}: Props): React.ReactElement {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false)

  return (
    <>
      <Pressable
        {...props}
        onPress={() => setIsOpen(true)}
      >
        <InfoIcon size='5' color='primary.600' />
      </Pressable>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Modal.Content maxWidth='400px'>
          <Modal.CloseButton />
          {title && <Modal.Header>{title}</Modal.Header>}
          <Modal.Body>
            {children}
          </Modal.Body>
          <Modal.Footer>
            <HStack space={2}>
              <Button
                variant='outline'
                size='sm'
                _text={{ fontSize: 'md' }}
                onPress={() => {
                  setIsOpen(false)
                }}
              >
                Cancel
              </Button>
              {buttons && buttons({ setIsOpen })}
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default React.memo(TooltipModal)

export * from './types'
