import React from 'react'

import { AvatarCompany as UiAvatarCompany } from '@real-work/ui'

import { useGetCompanyProfilePictureByCompanyIdQuery } from '@/services/asset'

import { Props } from './types'

function AvatarCompany({
  company,
  isLarge = true,
  ...props
}: Props): React.ReactElement {
  const {
    blob: profilePicture,
    isLoading: isLoadingProfilePicture,
  } = useGetCompanyProfilePictureByCompanyIdQuery({
    params: {
      variant: isLarge ? '120x120' : '80x80',
      companyId: company.id,
    },
  })

  return (
    <UiAvatarCompany
      alt={company.name}
      isLarge={isLarge}
      isLoading={isLoadingProfilePicture}
      profilePicture={profilePicture}
      {...props}
    />
  )
}

export default React.memo(AvatarCompany)

export * from './types'
