import React, { useMemo } from 'react'

import { array } from '@real-work/common'

import {
  Box,
  Badge,
  Heading,
  HStack,
  VStack,
  Text,
  useMediaQuery,
} from '@real-work/ui'

import AvatarCompany from '@/components/Avatar/Company'
import JobStatusBadge from '@/components/JobStatusBadge'
import Link from '@/components/Link'

import useSession from '@/hooks/useSession'

import dateTime from '@/utils/dateTime'

import { Props } from './types'

function Job({
  isExpanded = true,
  job,
  ...props
}: Props): React.ReactElement {
  const [ isDesktop ] = useMediaQuery({ minWidth: 1080 })
  const [ isMobile ] = useMediaQuery({ minWidth: 800 })
  const { user } = useSession()

  const companyLocationsText = useMemo(() => {
    const locations = job.companyLocations.map(location => location.companyLocation?.title).filter(location => !!location)

    if (!locations.length) {
      return undefined
    }

    return array.toListString(locations as string[])
  }, [ job.companyLocations ])

  const expressedInterestDate = useMemo(() => {
    const jobInterestedWorker = job.interestedWorkers?.find(interestedWorker => interestedWorker.workerUserId === user?.id)

    return (jobInterestedWorker?.createdAt && dateTime.parseISO(jobInterestedWorker.createdAt)) || undefined
  }, [
    job.interestedWorkers,
    user?.id,
  ])

  return (
    <HStack
      justifyContent='space-between'
      alignItems={isMobile ? 'center' : 'flex-start'}
      py='6'
      space='6'
      flexWrap='wrap'
      width='100%'
      flexDir={isMobile ? 'row' : 'column'}
      {...props}
    >
      <Link to={`/jobs/${job.id}`}>
        <HStack space='6' flex='1' flexDir={isMobile ? 'row' : 'column'}>
          <Box mb={isMobile ? '0' : '4'}>
            <AvatarCompany company={job.company} isLarge={isExpanded} />
          </Box>
          <VStack justifyContent='space-evenly' mb={isMobile ? '0' : '4'}>
            <VStack maxW='360' mb='4'>
              <Heading size='md' mb='2'>{job.title}</Heading>
              <HStack><Text fontWeight='medium' mr='1'>Date Posted</Text> <Text>{dateTime.format(dateTime.parseISO(job.createdAt))}</Text></HStack>
            </VStack>
            {isExpanded && (
              <VStack maxW='360' mb='2'>
                <Text mb='1'>{job.jobTypeOption.label}</Text>
                <Text mb='1'>{job.company.name}</Text>
                {companyLocationsText && <Text mb='1'>{companyLocationsText}</Text>}
              </VStack>
            )}
          </VStack>
        </HStack>
      </Link>
      <HStack space='8' flexDir={isDesktop ? 'row' : 'column'} alignItems={isDesktop ? 'center' : 'flex-start'}>
        <Box>
          <Text>Job Status <JobStatusBadge job={job} ml='1' my='1' /></Text>
        </Box>

        {user?.role.slug === 'worker' && <Text>Expressed Interest? <Badge ml='1' my='1' colorScheme='info' variant='solid'>{expressedInterestDate ? `YES, ${dateTime.format(expressedInterestDate)}` : 'Not Yet'}</Badge></Text>}
      </HStack>
    </HStack>
  )
}

export default React.memo(Job)

export * from './types'
