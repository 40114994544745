import React from 'react'
import { Spinner as NbSpinner } from 'native-base'

import { Props } from './types'

function Spinner({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbSpinner {...props}>{children}</NbSpinner>
  )
}

export default React.memo(Spinner)

export * from './types'
