import React, { useCallback } from 'react'

import { lang } from '@real-work/common'
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  FileInput,
  Form,
  FormControl,
  Heading,
  HStack,
  LoadingIndicator,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import { useGetWorkerProfilePictureByUserIdQuery } from '@/services/asset'
import { usePatchWorkerByIdMutation } from '@/services/worker'

import rules from './validation'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PictureAddEdit(props: Props): React.ReactElement {
  const navigate = useNavigate()
  const toast = useToast()
  const handleFormApiErrors = useHandleFormApiErrors()

  const { user } = useSession()

  const {
    base64: profilePicture,
    crop: profilePictureCrop,
    isLoading: isLoadingProfilePicture,
  } = useGetWorkerProfilePictureByUserIdQuery({
    params: {
      variant: 'original',
      userId: user?.worker?.userId || '',
    },
  }, { skip: !user?.worker?.userId })

  const [
    patchWorkerById,
    { isLoading: isSubmitting },
  ] = usePatchWorkerByIdMutation()

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    patchWorkerById({
      params: { userId: user.id },
      body: values,
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message,
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
        navigate(-1)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    handleFormApiErrors,
    navigate,
    patchWorkerById,
    toast,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Profile Edit Picture'>
      <ScreenWrapper>
        <Container>
          <Heading>{`Hi ${user?.firstName}`}</Heading>
          <Text>
            {user?.worker?.profilePictureImageId ? 'Edit your profile picture.' : 'Please upload a profile picture.'}
          </Text>
          <Heading mt='4' size='md' color='brand.primary'>{user?.worker?.profilePictureImageId ? 'Edit Profile Picture' : 'Add Profile Picture'}</Heading>
          <Divider mb='4' />
          {(isLoadingProfilePicture && <LoadingIndicator />) || (
            <Form<FormValues>
              enableReinitialize={true}
              initialValues={{
                profilePicture,
                profilePictureCrop,
              }}
              rules={rules}
              onSubmit={onSubmit}
              style={{ maxWidth: '100%' }}
            >
              <HStack space='2'>
                <Box>
                  <FormControl
                    name='profilePicture'
                  >
                    <FileInput
                      mode='image'
                      addButtonText='Browse for Photo'
                      crop={{ aspectRatio: 1 }}
                      name='profilePicture'
                      removeButtonText='Remove Photo'
                    />
                  </FormControl>
                </Box>
              </HStack>
              <Divider />
              <ButtonGroup justifyContent='flex-start'>
                <SubmitButton isLoading={isSubmitting}>Save</SubmitButton>
                <Button onPress={() => navigate(-1)}>Cancel</Button>
              </ButtonGroup>
            </Form>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(PictureAddEdit)
