import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'

import log from '@real-work/common/dist/src/log/browser'

import { Models } from '@real-work/orm'

import config from '@/config'

const mutex = new Mutex()

export const baseUrl = config.apiBaseUrl

export const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: 'include',
  prepareHeaders: headers => getAuthHeaders(headers),
})

export const getAuthHeaders = (headers?: Headers) => {
  // localStorage wraps the value in quotation marks - strip them
  const accessToken = localStorage.getItem('accessToken')?.replace(/^"/, '').replace(/"$/, '')

  headers = headers || new Headers()

  if (accessToken) {
    headers.set('Access-Control-Allow-Credentials', 'true')
    headers.set('Authorization', `Bearer ${accessToken}`)
  }

  return headers
}

const updateSessionDataInLocalStorage = (accessToken: string, sessionId: string) => {
  localStorage.setItem('accessToken', JSON.stringify(accessToken))
  localStorage.setItem('sessionId', JSON.stringify(sessionId))
}

const baseQueryWithRefresh: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)

  if (api.endpoint === 'postSession') {
    return result
  }

  if (result.error?.status === 401 && (result.error?.data as { isSessionExpired?: boolean })?.isSessionExpired) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()

      try {
        const refreshResult = (await baseQuery(
          {
            credentials: 'include',
            method: 'post',
            url: 'v1/sessions/refresh',
          },
          api,
          extraOptions,
        )) || undefined

        if (refreshResult?.data) {
          const {
            accessToken,
            sessionId,
          } = refreshResult.data as { accessToken: string, sessionId: string, user: Models.User.default }

          updateSessionDataInLocalStorage(accessToken, sessionId)

          log('info', '✅ Successfully refreshed accessToken after expiration.')

          // Retry the initial query
          result = await baseQuery(args, api, extraOptions)
        }
        else {
          forgetAccessToken()

          window.location.href = '/sign-in'
        }
      }
      finally {
        // release must be called once the mutex should be released again.
        release()
      }
    }
    else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

const forgetAccessToken = () => localStorage.removeItem('accessToken')

const api = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: () => ({}),
  reducerPath: 'realWorkApi',
  tagTypes: [
    'Companies',
    'EventNotifications',
    'Jobs',
    'JobsImInterestedIn',
    'Sessions',
    'SubscriptionPlans',
    'Users',
    'Workers',
  ],
})

export default api
