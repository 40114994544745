import {
  api,
  querystring,
} from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import {
  GetEventNotificationsResponse,
  GetEventNotificationsRequest,
  PatchEventNotificationByIdResponse,
  PatchEventNotificationByIdRequest,
} from './types'

const path = 'v1/event-notifications'

const eventNotificationService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getEventNotifications: build.query<api.AsJson<GetEventNotificationsResponse>, GetEventNotificationsRequest>({
      providesTags: [ 'EventNotifications' ],
      merge: (currentCache, response, { arg }) => {
        const newEventNotifications = (arg.query?.page ?? 0) > 1 ? currentCache.eventNotifications : []

        newEventNotifications.push(...response.eventNotifications)

        currentCache.eventNotifications = newEventNotifications
        currentCache.pagination = response.pagination
      },
      query: ({ query }) => ({
        url: `${path}?${querystring.stringify(query)}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
    }),
    getNumTotalUnreadEventNotifications: build.query<api.AsJson<GetEventNotificationsResponse>, GetEventNotificationsRequest>({
      query: ({ query }) => ({
        url: `${path}?${querystring.stringify(query)}`,
        method: 'GET',
      }),
    }),
    patchEventNotificationById: build.mutation<api.AsJson<PatchEventNotificationByIdResponse>, PatchEventNotificationByIdRequest>({
      query: ({
        body,
        params,
      }) => ({
        url: `${path}/${params.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [ 'EventNotifications' ],
    }),
  }),
})

export default eventNotificationService

export const {
  useGetEventNotificationsQuery,
  useGetNumTotalUnreadEventNotificationsQuery,
  usePatchEventNotificationByIdMutation,
} = eventNotificationService

export const {
  endpoints: {
    getEventNotifications,
    getNumTotalUnreadEventNotifications,
    patchEventNotificationById,
  },
} = eventNotificationService
