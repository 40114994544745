import React, {
  useCallback,
  useMemo,
} from 'react'

import type { Models } from '@real-work/orm'

import {
  api,
  lang,
} from '@real-work/common'

import {
  Button,
  ButtonGroup,
  Container,
  DateTimeInput,
  Divider,
  Form,
  FormControl,
  Heading,
  Input,
  LoadingFailed,
  LoadingIndicator,
  Select,
  SelectTypes,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import { useGetOptionsQuery } from '@/services/option'
import {
  useGetWorkerByUserIdQuery,
  usePatchWorkerByIdMutation,
} from '@/services/worker'

import {
  FormValues,
  OnSubmit,
} from './types'

const yesNoOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

const publicPrivateOptions = [
  {
    label: 'Public',
    value: true,
  },
  {
    label: 'Private',
    value: false,
  },
]

function WorkPreferencesAddEdit(): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()

  const navigate = useNavigate()

  const toast = useToast()

  const { user } = useSession()

  const {
    data: { options } = { options: undefined },
    isLoading: isOptionsLoading,
    isError: isOptionsError,
  } = useGetOptionsQuery()

  const {
    data: { worker } = { worker: undefined },
    isLoading: isWorkerLoading,
    isError: isWorkerError,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } })

  const [
    patchWorker,
    { isLoading: isSubmitting },
  ] = usePatchWorkerByIdMutation()

  const initialValues = useMemo(() => ({
    availableStartingAt: api.dates.apiValueToInputValue(worker?.availableStartingAt) || undefined,
    companySizes: worker?.companySizes?.map(companySize => companySize.optionId),
    hourlyRate: worker?.hourlyRate ? Number(worker?.hourlyRate) : undefined,
    isOpenToWork: !!worker?.openToWorkSince,
    isProfilePublic: !!worker?.profilePublicSince,
    jobTypes: worker?.jobTypes?.map(jobType => jobType.optionId),
    travelDistanceId: worker?.travelDistanceId,
    workShifts: worker?.workShifts?.map(workShift => workShift.optionId),
  }), [ worker ])

  const validationRules = api.endpoints.workers.patchById.validation.body.rules
  const rules = useMemo(() => ({
    isOpenToWork: validationRules.isOpenToWork.required(),
    isProfilePublic: validationRules.isProfilePublic.required(),
    jobTypes: validationRules.jobTypes.min(1).required(),
    hourlyRate: validationRules.hourlyRate.required(),
    companySizes: validationRules.companySizes.min(1).required(),
    workShifts: validationRules.workShifts.min(1).required(),
    travelDistanceId: validationRules.travelDistanceId.required(),
  }), [ validationRules ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    patchWorker({
      body: {
        ...values,
        availableStartingAt: api.dates.inputValueToApiValue(values.availableStartingAt),
        hourlyRate: Number(values.hourlyRate),
      },
      params: { userId: user.id },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate(-1)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    navigate,
    patchWorker,
    toast,
    user?.id,
  ])

  const selectOptions = useMemo(() => options?.reduce((acc, option) => {
    if (option.type === 'companySize' || option.type === 'jobType' || option.type === 'travelDistance' || option.type === 'workShift') {
      if (!acc[option.type]) {
        acc[option.type] = []
      }

      acc[option.type].push({
        label: option.label,
        value: option.id,
      })
    }

    return acc
  }, {} as Record<Models.Option.OptionType, SelectTypes.Option[]>), [ options ])

  const isLoading = useMemo(() => isOptionsLoading || isWorkerLoading, [
    isOptionsLoading,
    isWorkerLoading,
  ])

  const isError = useMemo(() => isOptionsError || isWorkerError, [
    isOptionsError,
    isWorkerError,
  ])

  return (
    <DocumentWrapper title='Real Work | Work Experience'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
          <Container>
            <Heading>Work Preferences</Heading>
            <Text>
              Please complete the following work preferences below.
            </Text>
            <Heading mt='4' size='md' color='brand.primary'>Edit Preferences</Heading>
            <Divider />
            <Form<FormValues>
              initialValues={initialValues}
              rules={rules}
              onSubmit={onSubmit}
            >
              <FormControl
                dataType='boolean'
                name='isOpenToWork'
                label='Are you currently open to work?'
              >
                <Select
                  options={yesNoOptions}
                />
              </FormControl>
              <FormControl
                dataType='boolean'
                name='isProfilePublic'
                label='Do you want your profile to be public or private?'
              >
                <Select
                  options={publicPrivateOptions}
                />
              </FormControl>
              <FormControl
                name='jobTypes'
                label='Are you seeking full-time or part-time work?'
              >
                <Select
                  isMulti={true}
                  options={selectOptions?.jobType}
                />
              </FormControl>
              <FormControl
                name='availableStartingAt'
                label='How soon would you like to find a position?'
              >
                <DateTimeInput
                  mode='date'
                />
              </FormControl>
              <FormControl
                name='hourlyRate'
                label='What are your hourly rate requirements?'
                labelNote='(USD)'
              >
                <Input
                  placeholder='$'
                  type='number'
                />
              </FormControl>
              <FormControl
                name='companySizes'
                label='What is your ideal company size?'
              >
                <Select
                  isMulti={true}
                  options={selectOptions?.companySize}
                />
              </FormControl>
              <FormControl
                name='workShifts'
                label='What are your ideal work hours'
              >
                <Select
                  isMulti={true}
                  options={selectOptions?.workShift}
                />
              </FormControl>
              <FormControl
                name='travelDistanceId'
                label='How far are you willing to travel?'
              >
                <Select
                  options={selectOptions?.travelDistance}
                />
              </FormControl>
              <ButtonGroup>
                <SubmitButton isLoading={isSubmitting}>Save</SubmitButton>
                <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
              </ButtonGroup>
            </Form>
          </Container>

        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(WorkPreferencesAddEdit)
