import React from 'react'

import {
  Divider,
  Heading,
  HStack,
  VStack,
} from '@real-work/ui'

import Link from '../../../../components/Link'

import { Props } from './types'

import AccountListItem from './Item'

function AccountList({
  items,
  editButtonText = 'Edit',
  editButtonUrl,
  title,
}: Props): React.ReactElement {
  return (
    <>
      <Heading size='md' variant='secondary'>{title}</Heading>
      <Divider />
      <HStack justifyContent='space-between' alignItems='center' width='100%' my='6'>
        <VStack flex='1'>
          {items.map(item => (
            <AccountListItem
              key={item.title}
              {...item}
            />
          ))}
        </VStack>
        <HStack>
          <Link to={editButtonUrl} type='button' button={{ size: 'sm' }}>{editButtonText}</Link>
        </HStack>
      </HStack>
    </>
  )
}

export default React.memo(AccountList)

export * from './types'
