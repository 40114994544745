import React from 'react'
import { TextArea as NBTextArea } from 'native-base'

import useFormInput from '../../hooks/useFormInput'

import { Props } from './types'

function TextArea({
  autoComplete = undefined,
  defaultValue,
  name = '',
  ...props
}: Props): React.ReactElement {
  const {
    value,
    setValue,
  } = useFormInput({
    defaultValue,
    name,
  })

  return (
    <NBTextArea
      h={40}
      w='100%'
      fontSize='lg'
      autoCompleteType={autoComplete}
      onChangeText={setValue}
      value={value?.toString()}
      {...props}
    />
  )
}

export default React.memo(TextArea)

export * from './types'
