import React from 'react'

import { Text } from '@real-work/ui'

import config, { version } from '@/config'

function Version() {
  const appEnv = (config.appEnv === 'production') ? undefined : (config.appEnv || '??').toUpperCase()

  return (
    <Text
      fontSize='sm'
      color='gray.400'
    >
      v{version}{appEnv ? ` (${appEnv})` : ''}
    </Text>
  )
}

export default React.memo(Version)
