import React from 'react'

import { Toast } from 'native-base'

import Alert from '../../components/Alert'

import { ToastShowProps } from './types'

export default {
  show: ({
    allowDuplicate = true,
    id,
    isClosable = true,
    placement = 'top',
    ...args
  }: ToastShowProps) => {
    const {
      close,
      isActive,
      show,
    } = Toast

    if (!allowDuplicate && isActive(id)) {
      return
    }

    show({
      ...args,
      id,
      duration: !isClosable ? null : args.duration, // eslint-disable-line no-null/no-null
      placement,
      render: ({ id }) => (
        <Alert
          description={args.description}
          {...(isClosable && { onClose: () => close(id) })}
          title={args.title}
          type={args.type}
        />
      ),
    })
  },
  close: (id: string) => {
    const {
      close,
      isActive,
    } = Toast

    if (isActive(id)) {
      close(id)
    }
  },
}

export * from './types'
