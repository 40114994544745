import React, { useCallback } from 'react'

import {
  api,
  lang,
} from '@real-work/common'

import {
  Container,
  Divider,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  ProfileList,
  ProfileListItem,
  Text,
  useToast,
} from '@real-work/ui'

import {
  useDeleteProductExperienceMutation,
  useGetWorkerByUserIdQuery,
} from '@/services/worker'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import Link from '../../../../../components/Link'

import DocumentWrapper from '../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../components/ScreenWrapper'

function ProductExperience(): React.ReactElement {
  const [ deleteProductExperience ] = useDeleteProductExperienceMutation()

  const navigate = useNavigate()
  const { user } = useSession()
  const toast = useToast()
  const {
    data,
    isLoading,
    isError,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !user?.id,
  })

  const handleDelete = useCallback((id: string) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    deleteProductExperience({
      params: {
        userId: user?.id,
        id,
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
      })
      .catch(error => {
        toast.show({
          description: (error as { data: api.ApiError })?.data?.message || lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    deleteProductExperience,
    toast,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Product Experience'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
          <>
            <Container>
              <Heading>Product Experience</Heading>
              <Text>
                Add additional product experience, or, make edits below.
              </Text>
            </Container>
            <ProfileList heading='All Product Experience'>
              {data?.worker.productExperiences?.map(experience => (
                <ProfileListItem
                  key={experience.id}
                  title={experience.product}
                  description={experience.manufacturer}
                  onPressDelete={() => handleDelete(experience.id)}
                  onPressEdit={() => navigate(`/profile/product-experiences/${experience.id}/edit`)}
                  onPressDeleteConfirmText={lang().messages.removeFromProfileConfirmation('product experience')}
                />
              ))}

              <Link to='/profile/product-experiences/add' type='button'>Add Another</Link>
              <Divider my='8' />
              <Link to='/profile/edit' variant='emphasis'>&lt; Back to Edit Profile</Link>
            </ProfileList>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(ProductExperience)
