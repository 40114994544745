import useSession from '@/hooks/useSession'

import { useGetCompanySubscriptionByCompanyIdQuery } from '@/services/company'

function useCompanySubscription() {
  const session = useSession()

  /**
   * !! MOCK SUBSCRIPTIONS !!
   * This section is purely for early-stage testing purposes and mocking for review
   * Remove entirely once all employer subscription functionality is approved and working
   */
  const isMock = session?.user?.employer?.companyOwned?.stripeSubscriptionId === 'MOCK_PAID' || session?.user?.employer?.companyOwned?.stripeSubscriptionId === 'MOCK_UNPAID'
  let mockSubscription
  if (isMock) {
    mockSubscription = {
      id: 'sub_123',
      customerId: 'customer_123',
      didComplete: session?.user?.employer?.companyOwned?.stripeSubscriptionId === 'MOCK_PAID',
      isActive: session?.user?.employer?.companyOwned?.stripeSubscriptionId === 'MOCK_PAID',
      status: session?.user?.employer?.companyOwned?.stripeSubscriptionId === 'MOCK_PAID' ? 'active' : 'inactive',
      paymentIntent: {
        id: 'pi_123',
        clientSecret: 'xyz456',
        status: session?.user?.employer?.companyOwned?.stripeSubscriptionId === 'MOCK_PAID' ? 'succeeded' : 'incomplete',
      },
      paymentMethod: {
        brand: 'Visa',
        last4: '9999',
      },
      plan: {
        id: 'pl_123',
        name: 'Starter',
        slug: 'starter',
      },
    }
  }

  const {
    data: { subscription } = {},
    error,
    isLoading,
    isError,
    refetch: refetchCompanySubscription,
  } = useGetCompanySubscriptionByCompanyIdQuery({ params: { companyId: session?.user?.employer?.companyOwned?.id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !session?.user?.employer?.companyOwned?.id
      || isMock, // This is purely for early-stage testing purposes and mocking for review - Remove entirely once all employer subscription functionality is approved and working
  })

  return ({
    error,
    isError,
    isLoading,
    refetchCompanySubscription,
    subscription: mockSubscription || subscription,
  })
}

export default useCompanySubscription
