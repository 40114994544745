import React from 'react'

import Spinner from '../../Spinner'
import Text from '../../Text'
import VStack from '../../VStack'

import { Props } from './types'

function LoadingIndicator({
  text = 'Loading',
  ...props
}: Props): React.ReactElement {
  return (
    <VStack
      space={2}
      justifyContent='center'
      {...props}
    >
      <Spinner accessibilityLabel={text} />
      <Text
        alignSelf='center'
        color='primary.600'
        fontSize='md'
        textAlign='center'
      >
        {text}
      </Text>
    </VStack>
  )
}

export default React.memo(LoadingIndicator)

export * from './types'
