import React, { useCallback } from 'react'
import { lang } from '@real-work/common'

import {
  Container,
  Divider,
  Heading,
  LoadingFailed,
  LoadingIndicator,
  ProfileList,
  ProfileListItem,
  Text,
  useToast,
} from '@real-work/ui'

import {
  useDeleteWorkerMembershipMutation,
  useGetWorkerByUserIdQuery,
} from '@/services/worker'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import Link from '../../../../../components/Link'

import DocumentWrapper from '../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../components/ScreenWrapper'

function Memberships(): React.ReactElement {
  const navigate = useNavigate()
  const { user } = useSession()
  const toast = useToast()

  const [ deleteWorkerMembership ] = useDeleteWorkerMembershipMutation()

  const {
    data,
    isError,
    isLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !user?.id,
  })

  const handleDelete = useCallback((id: string) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    deleteWorkerMembership({
      params: {
        userId: user.id,
        id,
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
      })
      .catch(error => {
        toast.show({
          description: error.data?.message || lang().messages.unknownError(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    deleteWorkerMembership,
    toast,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Memberships'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
          <>
            <Container>
              <Heading>Memberships</Heading>
              <Text>
                Add additional memberships, or, make edits below.
              </Text>
            </Container>
            <ProfileList heading='All Memberships'>
              {data?.worker.memberships?.map(membership => (
                <ProfileListItem
                  key={membership.id}
                  title={membership.title}
                  onPressDelete={() => handleDelete(membership.id)}
                  onPressEdit={() => navigate(`/profile/memberships/${membership.id}/edit`)}
                  onPressDeleteConfirmText={lang().messages.removeFromProfileConfirmation('membership')}
                />
              ))}

              <Link to='/profile/memberships/add' type='button'>Add Another</Link>
              <Divider my='8' />
              <Link to='/profile/edit' variant='emphasis'>&lt; Back to Edit Profile</Link>
            </ProfileList>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Memberships)
