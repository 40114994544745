import React from 'react'

import VStack, { Props } from '../../VStack'

function BulletList({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <VStack
      mb='6'
      space='.5'
      {...props}
    >
      {children}
    </VStack>
  )
}

export default React.memo(BulletList)
