import React from 'react'
import { Link as NbLink } from 'native-base'

import { isLocal } from '@real-work/common'
import log from '@real-work/common/dist/src/log/browser'

import Alert from '../Alert'

import { Props } from './types'

function Link({
  children,
  ...props
}: Props): React.ReactElement {
  if (isLocal) {
    const hasButtons: boolean[] = React.Children.map(children, child => {
      if ((child?.type?.name || child?.type?.type?.name) === 'Button') {
        return true
      }

      return false
    })

    if (hasButtons.find(hasButton => hasButton)) {
      log('error', "🐞 <Button> cannot be used inside <Link>. Instead, use <Link type='button' button={{ ... }}}>")

      return <Alert type='error' title='Code Error' description='See console' />
    }
  }

  return (
    <NbLink
      {...props}
    >
      {children}
    </NbLink>
  )
}

export default React.memo(Link)

export * from './types'
