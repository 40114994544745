import React from 'react'

import Container from '../Container'
import Divider from '../Divider'
import Heading from '../Heading'

import { Props } from './types'

function Profilelist({
  children,
  heading,
  ...props
}: Props): React.ReactElement {
  return (
    <Container mt='4' {...props}>
      <Heading size='md' color='brand.primary'>{heading}</Heading>
      <Divider mb='8' />
      {children}
    </Container>
  )
}

export default React.memo(Profilelist)
