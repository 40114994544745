import React from 'react'
import { HStack as NbHStack } from 'native-base'

import { Props } from './types'

function HStack({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <NbHStack {...props}>{children}</NbHStack>
  )
}

export default React.memo(HStack)

export * from './types'
