import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import type { GetAllTradeResponse } from './types'

const path = 'v1/trades'

const tradeService = realWorkApi.injectEndpoints({ endpoints: build => ({ getTrades: build.query<api.AsJson<GetAllTradeResponse>, void>({ query: () => ({ url: path }) }) }) })

export default tradeService

export const { useGetTradesQuery } = tradeService

export const { endpoints: { getTrades } } = tradeService
