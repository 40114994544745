import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  api,
  lang,
  url,
} from '@real-work/common'
import {
  Button,
  ButtonGroup,
  Container,
  DateTimeInput,
  Divider,
  Form,
  FormControl,
  Heading,
  Input,
  LoadingFailed,
  LoadingIndicator,
  Select,
  SubmitButton,
  Text,
  TooltipModalTypes,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import { useGetLicensesQuery } from '@/services/license'

import { useGetUsStatesQuery } from '@/services/usState'
import {
  useGetWorkerByUserIdQuery,
  useUpsertWorkerLicenseMutation,
} from '@/services/worker'

import useNavigate from '@/hooks/useNavigate'
import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import DocumentWrapper from '../../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function LicensesAddEdit({ mode }: Props): React.ReactElement {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const toast = useToast()

  const { id } = useRouteParams()
  const { user } = useSession()

  const {
    data: licensesData,
    isError: isLicensesError,
    isLoading: isLicensesLoading,
  } = useGetLicensesQuery()
  const {
    data: usStatesData,
    isError: isUsStatesError,
    isLoading: isUsStatesLoading,
  } = useGetUsStatesQuery()

  const {
    data: workerData,
    isError: isWorkerError,
    isLoading: isWorkerLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, { skip: !user?.id })
  const [
    upsertWorkerLicense,
    { isLoading: isSubmitting },
  ] = useUpsertWorkerLicenseMutation()

  const workerLicense = workerData?.worker.licenses?.find(item => item.id === id)

  const initialUsStateAbbr: string | undefined = useMemo(() => {
    const getInitialUsStateAbbr = () => {
      if (workerLicense) {
        return licensesData?.licenses?.find(license => license.id === workerLicense.licenseId)?.usStateAbbr || undefined
      }

      return undefined
    }

    return getInitialUsStateAbbr()
  }, [
    licensesData?.licenses,
    workerLicense,
  ])

  const [
    usStateAbbr,
    setUsStateAbbr,
  ] = useState<string | undefined>(initialUsStateAbbr)

  const validationRules = api.endpoints.workers.putLicense.validation.body.rules

  const rules = useMemo(() => ({
    licenseId: validationRules.licenseId,
    licenseNumber: validationRules.licenseNumber,
  }), [ validationRules ])

  const licensesOptions = useMemo(() => {
    const usStateAbbrOfLicenses = usStateAbbr || (usStateAbbr !== '' && initialUsStateAbbr) // usStateAbbr='' means selecting N/A

    const licenses = licensesData?.licenses.filter(license => {
      if (!usStateAbbrOfLicenses) {
        return license.usStateAbbr === null // eslint-disable-line no-null/no-null
      }

      return license.usStateAbbr === usStateAbbrOfLicenses
    }) || []

    const options = licenses?.map(license => ({
      label: license.name,
      value: license.id,
    }))

    return options
  }, [
    initialUsStateAbbr,
    usStateAbbr,
    licensesData?.licenses,
  ])

  const usStateOptions = useMemo(() => [
    {
      label: 'N/A',
      value: '',
    },
    ...(usStatesData?.usStates || []).map(state => ({
      label: state.name,
      value: state.abbr,
    })),
  ], [ usStatesData ])

  const isLoading = useMemo(() => isWorkerLoading || isLicensesLoading, [
    isLicensesLoading,
    isWorkerLoading,
  ])

  const isError = useMemo(() => isWorkerError || isLicensesError || (mode === 'edit' && !workerLicense), [
    isLicensesError,
    isWorkerError,
    mode,
    workerLicense,
  ])

  const initialValues = useMemo(() => ({
    licenseId: workerLicense?.licenseId || '',
    licenseNumber: workerLicense?.licenseNumber || '',
    expiresAt: api.dates.apiValueToInputValue(workerLicense?.expiresAt) || undefined,
    receivedAt: api.dates.apiValueToInputValue(workerLicense?.receivedAt) || undefined,
    usStateAbbr: initialUsStateAbbr,
  }), [
    initialUsStateAbbr,
    workerLicense,
  ])

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertWorkerLicense({
      params: {
        userId: user.id,
        id,
      },
      body: {
        licenseId: values.licenseId,
        licenseNumber: values.licenseNumber,
        expiresAt: api.dates.inputValueToApiValueOptional(values.expiresAt),
        receivedAt: api.dates.inputValueToApiValueOptional(values.receivedAt),
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate('/profile/licenses')
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    id,
    navigate,
    toast,
    upsertWorkerLicense,
    user?.id,
  ])

  const tooltipButtons = useCallback<TooltipModalTypes.Buttons>(({ setIsOpen }) => (
    <Button
      size='sm'
      _text={{ fontSize: 'md' }}
      onPress={() => {
        window.location.href = url.marketingSite('/feedback')

        setIsOpen(false)
      }}
    >
      Contact Us
    </Button>
  ), [])

  return (
    <DocumentWrapper title='Real Work | Licenses'>
      <ScreenWrapper>
        <Container>
          <Heading>Licenses</Heading>
          <Text>
            {mode === 'edit' ? 'Edit your license information below.' : 'Add your license information below.'}
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>{mode === 'edit' ? 'Edit a License' : 'Add a License'}</Heading>
          <Divider />

          {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
            <Form<FormValues>
              initialValues={initialValues}
              rules={rules}
              onSubmit={onSubmit}
            >
              {(isUsStatesLoading && <LoadingIndicator />) || (isUsStatesError && <LoadingFailed />) || (
                <FormControl
                  name='usStateAbbr'
                  label='State'
                >
                  <Select
                    options={usStateOptions}
                    onChange={value => setUsStateAbbr(value as string)}
                  />
                </FormControl>
              )}
              <FormControl
                name='licenseId'
                label='License'
                tooltipModal={{
                  title: 'License',
                  buttons: tooltipButtons,
                  children: <Text>Don’t see your license listed? Let us know.</Text>,
                }}
              >
                <Select
                  options={licensesOptions}
                />
              </FormControl>
              <FormControl
                name='licenseNumber'
                label='License Number'
              >
                <Input
                  type='text'
                />
              </FormControl>
              <FormControl
                name='receivedAt'
                label='Date Received'
                labelNote='(Optional)'
              >
                <DateTimeInput
                  isOptional={true}
                  mode='date'
                />
              </FormControl>
              <FormControl
                name='expiresAt'
                label='Expiration Date'
                labelNote='(Optional)'
              >
                <DateTimeInput
                  isOptional={true}
                  mode='date'
                />
              </FormControl>
              {/* <FormControl
                name='enrollInReminders'
              >
                <Checkbox my={2}>
                  Enroll in license expiration reminders via email?
                </Checkbox>
              </FormControl>
              {values.enrollInReminders === 'true' && (
                <FormControl
                  name='expDate'
                  label='Email address(es) where you wish to receive notifications'
                >
                  <Text mb='2'>(separate with a comma)</Text>
                  <TextArea />
                </FormControl>
              )} */}
              <ButtonGroup>
                <SubmitButton isLoading={isSubmitting}>Save</SubmitButton>
                <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
              </ButtonGroup>
            </Form>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(LicensesAddEdit)
