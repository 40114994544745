import {
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native'

const styles: {
  container: ViewStyle,
  containerExpanded: ViewStyle,
  toggleText: TextStyle,
  contentWrapper: ViewStyle,
} = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    backgroundColor: '#ccc',
    zIndex: 1,
    opacity: 0.125,
  },
  containerExpanded: { opacity: 1 },
  toggleText: {
    fontSize: 20,
    paddingVertical: 7,
    paddingHorizontal: 17,
    textAlign: 'right',
  },
  contentWrapper: { padding: 20 },
})

export default styles
