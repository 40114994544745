import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import { Input as NBInput } from 'native-base'

import { isTest } from '@real-work/common'
import type { Geocode } from '@real-work/orm'

import useFormInput, { HandleChange } from '../../hooks/useFormInput'

import Image from '../Image'
import Pressable from '../Pressable'

import AddressInput from './Address'

import {
  OnChange,
  Props,
} from './types'

import eye from './assets/eye.svg'
import eyeClosed from './assets/eye-closed.svg'

const phoneRegExp = /^(\d{3})-?(\d{3})-?(\d{4})/

function Input({
  defaultValue,
  dataType,
  index = 0,
  name = '',
  autoCapitalize: autoCapitalizeProp,
  autoComplete: autoCompleteProp,
  autoCorrect: autoCorrectProp,
  clearButtonMode: clearButtonModeProp,
  keyboardType: keyboardTypeProp,
  textContentType: textContentTypeProp,
  type,
  ...props
}: Props): React.ReactElement {
  const {
    setValue,
    value,
  } = useFormInput({
    dataType,
    defaultValue,
    index,
    name,
  })

  const onChangeText = useCallback<OnChange>(newValue => {
    if (type === 'phone') {
      if (newValue?.match(phoneRegExp)) {
        newValue = newValue.replace(phoneRegExp, '$1-$2-$3')
      }
    }

    setValue && setValue(newValue)
  }, [
    setValue,
    type,
  ])

  const [
    isPasswordShown,
    setIsPasswordShown,
  ] = useState(false)

  let autoCapitalize = autoCapitalizeProp ?? 'sentences'
  let autoComplete = autoCompleteProp
  let autoCorrect = autoCorrectProp ?? true
  let clearButtonMode = clearButtonModeProp ?? 'while-editing'
  let keyboardType = keyboardTypeProp ?? 'default'
  let nativeBaseType: 'text' | 'password' = 'text'
  let textContentType = textContentTypeProp

  if (type === 'email') {
    autoCapitalize = autoCapitalizeProp ?? 'none'
    autoComplete = autoCompleteProp ?? 'email'
    autoCorrect = autoCorrectProp ?? false
    keyboardType = keyboardTypeProp ?? 'email-address'
    textContentType = textContentTypeProp ?? 'emailAddress'
  }
  else if (type === 'name') {
    autoCapitalize = autoCapitalizeProp ?? 'words'
    autoComplete = autoCompleteProp ?? 'name'
    autoCorrect = autoCorrectProp ?? false
    textContentType = textContentTypeProp ?? 'name'
  }
  else if (type === 'number' || type === 'integer') {
    autoCapitalize = autoCapitalizeProp ?? 'none'
    autoComplete = autoCompleteProp ?? 'off'
    autoCorrect = autoCorrectProp ?? false
    keyboardType = keyboardTypeProp ?? (type === 'integer' ? 'number-pad' : 'numeric')
  }
  else if (type === 'oneTimeCode') {
    autoCapitalize = autoCapitalizeProp ?? 'none'
    autoComplete = autoCompleteProp ?? 'off'
    autoCorrect = autoCorrectProp ?? false
    keyboardType = keyboardTypeProp ?? 'number-pad'
    textContentType = textContentTypeProp ?? 'oneTimeCode'
  }
  else if (type === 'password' || type === 'newPassword') {
    autoCapitalize = autoCapitalizeProp ?? 'none'
    autoComplete = autoCompleteProp ?? (type === 'newPassword' ? 'password-new' : 'password')
    autoCorrect = autoCorrectProp ?? false
    clearButtonMode = 'never'
    nativeBaseType = isPasswordShown ? 'text' : 'password'

    textContentType = textContentTypeProp ?? type
  }
  else if (type === 'phone') {
    autoCapitalize = autoCapitalizeProp ?? 'none'
    autoComplete = autoCompleteProp ?? 'tel'
    autoCorrect = autoCorrectProp ?? false
    keyboardType = keyboardTypeProp ?? 'numbers-and-punctuation'
    textContentType = textContentTypeProp ?? 'telephoneNumber'
  }
  else if (type === 'url') {
    autoCapitalize = autoCapitalizeProp ?? 'none'
    autoComplete = autoCompleteProp ?? 'off'
    autoCorrect = autoCorrectProp ?? false
    keyboardType = keyboardTypeProp ?? 'url'
    textContentType = textContentTypeProp ?? 'URL'
  }

  const InputRightElement = useMemo(() => {
    if (isTest) {
      return undefined
    }

    if (type === 'password') {
      return (
        <Pressable
          marginRight='1'
          onPress={() => setIsPasswordShown(prev => !prev)}
        >
          <Image
            source={eyeClosed}
            display={isPasswordShown ? undefined : 'none'}
            alt='closed eye'
            height={3.5}
            width={6}
          />
          <Image
            source={eye}
            display={isPasswordShown ? 'none' : undefined}
            alt='eye'
            height={3.5}
            width={6}
          />
        </Pressable>
      )
    }

    return undefined
  }, [
    isPasswordShown,
    type,
  ])

  if (type === 'address' || type === 'zipCode') {
    return (
      <AddressInput
        {...props}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        clearButtonMode={clearButtonMode}
        keyboardType={keyboardType}
        setValue={setValue as HandleChange<Partial<Geocode>>}
        textContentType={textContentType}
        type={type}
        value={value as Partial<Geocode>}
        InputRightElement={InputRightElement}
      />
    )
  }

  return (
    <NBInput
      {...props}
      onChangeText={onChangeText}
      autoCapitalize={autoCapitalize}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      clearButtonMode={clearButtonMode}
      keyboardType={keyboardType}
      textContentType={textContentType}
      type={nativeBaseType}
      value={value as string | undefined}
      InputRightElement={InputRightElement}
    />
  )
}

export default Input

export * from './types'
