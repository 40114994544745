import React, { useCallback } from 'react'
import {
  api,
  lang,
} from '@real-work/common'

import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  Input,
  LoadingFailed,
  LoadingIndicator,
  SubmitButton,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import useRouterParms from '@/hooks/useRouteParams'

import {
  useGetWorkerByUserIdQuery,
  useUpsertWorkerProductExperienceMutation,
} from '@/services/worker'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import DocumentWrapper from '../../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function ProductExperienceAddEdit({ mode }: Props): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()

  const navigate = useNavigate()
  const toast = useToast()

  const { id } = useRouterParms()
  const { user } = useSession()
  const {
    data,
    isError: isWorkerError,
    isLoading: isWorkerLoading,
  } = useGetWorkerByUserIdQuery({ params: { userId: user?.id || '' } }, { skip: !id })

  const [
    upsertWorkerProductExperience,
    { isLoading: isSubmitting },
  ] = useUpsertWorkerProductExperienceMutation()

  const productExperience = data?.worker.productExperiences?.find(item => item.id === id)

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    upsertWorkerProductExperience({
      body: values,
      params: {
        userId: user.id,
        id,
      },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message || lang().messages.changesSaved(),
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate('/profile/product-experiences')
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    id,
    navigate,
    toast,
    upsertWorkerProductExperience,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | Product Experience'>
      <ScreenWrapper>
        <Container>
          <Heading>Product Experience</Heading>
          <Text>
            {mode === 'edit' ? 'Edit product experience below.' : 'Add product experience below.'}
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>{mode === 'edit' ? 'Edit Product Experience' : 'Add Product Experience'}</Heading>
          <Divider />
          {(isWorkerLoading && <LoadingIndicator />) || (id && isWorkerError && <LoadingFailed />) || (
            <Form<FormValues>
              initialValues={{
                manufacturer: productExperience?.manufacturer || '',
                product: productExperience?.product || '',
              }}
              rules={api.endpoints.workers.putProductExperience.validation.body.rules}
              onSubmit={onSubmit}
            >
              <FormControl
                name='manufacturer'
                label='Manufacturer'
              >
                <Input
                  type='text'
                />
              </FormControl>
              <FormControl
                name='product'
                label='Product or System'
              >
                <Input
                  type='text'
                />
              </FormControl>
              <ButtonGroup>
                <SubmitButton isLoading={isSubmitting}>Save</SubmitButton>
                <Button variant='outline' onPress={() => navigate(-1)}>Cancel</Button>
              </ButtonGroup>
            </Form>
          )}
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(ProductExperienceAddEdit)
