import React from 'react'

import { string } from '@real-work/common'

import Box from '../../Box'
import Image from '../../Image'
import Spinner from '../../Spinner'

import { getVariant } from '../variants'

import { Props } from './types'

function AvatarCompany({
  alt,
  isLarge,
  isLoading = false,
  profilePicture,
  ...props
}: Props): React.ReactElement {
  const variant = getVariant(alt)

  return (
    <Box
      {...props}
    >
      <Box
        variant={variant}
        borderRadius='lg'
        overflow='hidden'
        shadow='4'
        width={isLarge ? '120px' : '80px'}
        height={isLarge ? '120px' : '80px'}
        justifyContent='center'
        alignItems='center'
      >
        {(isLoading && <Spinner />) || (
          <Image
            alt={alt}
            width={isLarge ? '120px' : '80px'}
            height={isLarge ? '120px' : '80px'}
            src={profilePicture ? URL.createObjectURL(profilePicture) : getDefaultImage(alt)}
          />
        )}
      </Box>
    </Box>
  )
}

const getDefaultImage = (name?: string) => {
  const nameAlphabeticIndex = string.getAlphabeticIndex(name)

  const imageIndex = Math.ceil((nameAlphabeticIndex + 1) / 2)

  let image
  try {
    // eslint-disable-next-line global-require,import/no-dynamic-require,@typescript-eslint/no-var-requires
    image = require(`./assets/default${imageIndex}.svg`)
  }
  catch {
    return undefined
  }

  return image
}

export default React.memo(AvatarCompany)

export * from './types'
