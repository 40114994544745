import React, {
  useCallback,
  useEffect,
} from 'react'

import {
  AnimatorFadeIn,
  Button,
  Center,
  LoadingIndicator,
  Text,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

function SignOut(): React.ReactElement {
  const navigate = useNavigate()
  const { signOut } = useSession()

  useEffect(() => {
    signOut()
      .then(() => {
        navigate('/', { state: { isSigningOut: true } })
      })
  }, [
    navigate,
    signOut,
  ])

  const forceSignOut = useCallback(() => {
    window.location.href = '/sign-in'
  }, [])

  return (
    <DocumentWrapper title='Real Work | Loading...'>
      <ScreenWrapper>
        <LoadingIndicator />

        <AnimatorFadeIn timing={{ delay: 2000 }}>
          <Center mx='6' py='20'>
            <Text textAlign='center' mb='2'>If this screen does not reload, use this button to reload.</Text>
            <Button onPress={forceSignOut}>Reload</Button>
          </Center>
        </AnimatorFadeIn>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SignOut)
