import { api } from '@real-work/common'

import realWorkApi from '../../state/apis/realWork'

import {
  GetCompanyByIdRequest,
  GetCompanyByIdResponse,
  GetCompanySubscriptionByCompanyIdRequest,
  GetCompanySubscriptionByCompanyIdResponse,
  GetFavoriteWorkersByCompanyIdRequest,
  GetFavoriteWorkersByCompanyIdResponse,
  PatchCompanyByIdRequest,
  PatchCompanyByIdResponse,
  PutCompanyLocationRequest,
  PutCompanyLocationResponse,
} from './types'

const path = 'v1/companies'

const companyService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    getCompanyById: build.query<api.AsJson<GetCompanyByIdResponse>, GetCompanyByIdRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.id}`,
        method: 'GET',
      }),
      providesTags: [ 'Companies' ],
    }),
    getCompanySubscriptionByCompanyId: build.query<api.AsJson<GetCompanySubscriptionByCompanyIdResponse>, GetCompanySubscriptionByCompanyIdRequest>({
      query: ({ params }) => ({
        url: `${path}/${params.companyId}/subscription`,
        method: 'GET',
      }),
    }),
    getFavoriteWorkersByCompanyId: build.query<api.AsJson<GetFavoriteWorkersByCompanyIdResponse>, GetFavoriteWorkersByCompanyIdRequest>({
      query: ({
        params,
        query,
      }) => ({
        url: `${path}/${params.companyId}/favorite-workers`,
        method: 'GET',
        params: query,
      }),
    }),
    patchCompanyById: build.mutation<api.AsJson<PatchCompanyByIdResponse>, PatchCompanyByIdRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.id}`,
        method: 'PATCH',
        body,
      }),
    }),
    upsertCompanyLocation: build.mutation<api.AsJson<PutCompanyLocationResponse>, PutCompanyLocationRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.companyId}/locations${params.id ? `/${params.id}` : ''}`,
        method: 'PUT',
        body,
        invalidatesTags: [ 'Companies' ],
      }),
    }),
  }),
})

export default companyService

export const {
  useGetCompanyByIdQuery,
  useGetCompanySubscriptionByCompanyIdQuery,
  useGetFavoriteWorkersByCompanyIdQuery,
  usePatchCompanyByIdMutation,
  useUpsertCompanyLocationMutation,
} = companyService

export const {
  endpoints: {
    getCompanyById,
    getCompanySubscriptionByCompanyId,
    getFavoriteWorkersByCompanyId,
    patchCompanyById,
    upsertCompanyLocation,
  },
} = companyService
