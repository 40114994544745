import React from 'react'
import { FormControl as NBFormControl } from 'native-base'

import useFormError from '../../hooks/useFormError'

import type { Props } from './types'

function InputError({ name }: Props): React.ReactElement {
  const { message } = useFormError(name)

  return (
    <NBFormControl.ErrorMessage isInvalid={!!message || undefined}>
      {typeof message === 'string' ? message : 'An unknown error has occurred.'}
    </NBFormControl.ErrorMessage>
  )
}

export default React.memo(InputError)
