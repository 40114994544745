import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import {
  ThemeProvider, createTheme,
} from '@mui/material/styles'

import {
  Divider,
  fonts,
} from '@real-work/ui'

import useLocation from '@/hooks/useLocation'
import useRouteQuery from '@/hooks/useRouteQuery'

import type {
  Props,
  Tabs,
} from './types'

const tabsTheme = createTheme({
  palette: { primary: { main: '#0891B2' } },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          fontFamily: fonts.BarlowRegular,
          fontWeight: 'bold',
          color: '#000000',
        },
      },
    },
  },
})

function Tabs({
  children,
  current,
  id = 'tab',
  onChange: onChangeProp,
  tabs,
  ...props
}: Props): React.ReactElement {
  const location = useLocation()
  const routeQuery = useRouteQuery()

  const currentTab = useMemo(() => current || routeQuery.get(id) || '', [
    current,
    id,
    routeQuery,
  ])

  const a11yProps = useCallback((slug: string) => ({
    id: `${id}-${slug}`,
    'aria-controls': `${id}-content-${slug}`,
  }), [ id ])

  const tabIndexFromQuery = useMemo(() => {
    if (Object.values(tabs).findIndex(tab => tab.slug === currentTab) >= 0) {
      return Object.values(tabs).findIndex(tab => tab.slug === currentTab)
    }

    return 0
  }, [
    currentTab,
    tabs,
  ])

  const [
    currentIndex,
    setCurrentIndex,
  ] = React.useState<keyof typeof tabs>(tabIndexFromQuery)

  useEffect(() => {
    setCurrentIndex(tabIndexFromQuery)
  }, [ tabIndexFromQuery ])

  const onChangeTab = useCallback((event: React.SyntheticEvent, index: keyof typeof tabs) => {
    window.history.replaceState(undefined, tabs[index].label, `${location.pathname.replace(/\/$/, '')}/?${id}=${tabs[index].slug}`)

    setCurrentIndex(index)

    onChangeProp && onChangeProp(event, index)
  }, [
    id,
    location.pathname,
    onChangeProp,
    tabs,
  ])

  return (
    <ThemeProvider theme={tabsTheme}>
      <MuiTabs
        onChange={onChangeTab}
        value={currentIndex}
        variant='fullWidth'
        {...props}
      >
        {Object.keys(tabs).map(key => (
          <MuiTab
            key={key}
            label={tabs[Number(key)].label}
            {...a11yProps(key)}
          />
        ))}
      </MuiTabs>
      <Divider mt='0' />

      {children({
        currentIndex,
        id,
      })}
    </ThemeProvider>
  )
}

export default React.memo(Tabs)

export { default as TabContent } from './TabContent'
