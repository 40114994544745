import React, { useCallback } from 'react'

import {
  api,
  lang,
} from '@real-work/common'

import {
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  HStack,
  LoadingFailed,
  LoadingIndicator,
  SubmitButton,
  Switch,
  Text,
  useHandleFormApiErrors,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import Link from '@/components/Link'
import ScreenWrapper from '@/components/ScreenWrapper'

import useNavigate from '@/hooks/useNavigate'
import useSession from '@/hooks/useSession'

import {
  useGetUserByIdQuery,
  usePatchUserByIdMutation,
} from '@/services/user'

import type {
  FormValues,
  OnSubmit,
} from './types'

function AccountEditNotifications(): React.ReactElement {
  const handleFormApiErrors = useHandleFormApiErrors()
  const navigate = useNavigate()
  const session = useSession()
  const toast = useToast()

  const {
    data: { user } = {},
    isLoading,
    isError,
  } = useGetUserByIdQuery({ params: { id: session.user?.id || '' } }, {
    refetchOnMountOrArgChange: true,
    skip: !session.user?.id,
  })

  const [
    patchUserById,
    { isLoading: isSubmitting },
  ] = usePatchUserByIdMutation()

  const onSubmit = useCallback<OnSubmit>(async (values, { setErrors }) => {
    if (!user?.id) {
      toast.show({
        description: lang().messages.unknownError(),
        title: lang().messages.genericErrorHeading(),
        type: 'error',
      })

      return
    }

    patchUserById({
      body: values,
      params: { id: user.id },
    })
      .unwrap()
      .then(response => {
        toast.show({
          description: response?.message,
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })

        navigate(-1)
      })
      .catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
        })
      })
  }, [
    handleFormApiErrors,
    navigate,
    patchUserById,
    toast,
    user?.id,
  ])

  return (
    <DocumentWrapper title='Real Work | My Account'>
      <ScreenWrapper>
        {(isLoading && <LoadingIndicator />) || (isError && <LoadingFailed />) || (
          <>
            <Container>
              <Heading>My Account</Heading>
              <Text>
                Edit your notification preferences.
              </Text>
            </Container>
            <Container mt='4'>
              <Form<FormValues>
                initialValues={{
                  isNotificationsEmailDigestEnabled: user?.isNotificationsEmailDigestEnabled || false,
                  isNotificationsEmailHighPriorityEnabled: user?.isNotificationsEmailHighPriorityEnabled || false,
                  isNotificationsSmsHighPriorityEnabled: user?.isNotificationsSmsHighPriorityEnabled || false,
                }}
                onSubmit={onSubmit}
                rules={api.endpoints.users.patchById.validation.body.rules}
              >
                <Heading size='md' variant='secondary'>Notifications</Heading>
                <Divider />
                <FormControl
                  name='isNotificationsSmsHighPriorityEnabled'
                  label='High-Priority SMS'
                >
                  <Switch />
                </FormControl>
                <FormControl
                  name='isNotificationsEmailHighPriorityEnabled'
                  label='High-Priority Emails'
                >
                  <Switch />
                </FormControl>
                <FormControl
                  name='isNotificationsEmailDigestEnabled'
                  label='Weekly Digest Emails'
                >
                  <Switch />
                </FormControl>
                <HStack flexDirection='row' mt='4' mb='4' space='4'>
                  <SubmitButton isLoading={isSubmitting}>Save Changes</SubmitButton>
                  <Link
                    to='/account'
                    type='button'
                    button={{ variant: 'outline' }}
                  >
                    Cancel
                  </Link>
                </HStack>
              </Form>
            </Container>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(AccountEditNotifications)
