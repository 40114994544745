import { api } from '@real-work/common'
import type {
  Request as ResetRequest,
  ResponseBody as ResetResponse,
} from '@real-work/common/src/api/endpoints/passwordReset/reset/post/types'

import realWorkApi from '../../state/apis/realWork'

import {
  PasswordResetRequest,
  PasswordResetResponse,
} from './types'

const path = 'v1/password-reset'

const passwordResetService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    postPasswordReset: build.mutation<api.AsJson<PasswordResetResponse>, PasswordResetRequest>({
      query: ({ body }) => ({
        url: path,
        method: 'POST',
        body,
      }),
    }),
    postResetPassword: build.mutation<api.AsJson<ResetResponse>, ResetRequest>({
      query: ({
        params,
        body,
      }) => ({
        url: `${path}/${params.id}`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export default passwordResetService

export const {
  usePostPasswordResetMutation,
  usePostResetPasswordMutation,
} = passwordResetService

export const {
  endpoints: {
    postPasswordReset,
    postResetPassword,
  },
} = passwordResetService
