import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'

import { LoadingIndicator } from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import useRouteParams from '@/hooks/useRouteParams'
import useSession from '@/hooks/useSession'

import { useGetJobByIdQuery } from '@/services/job'
import Error403 from '@/screens/Error403'

import { Props } from './types'

function CanEditJob({
  children,
  otherwise,
}: Props): React.ReactElement {
  const { id } = useRouteParams()
  const {
    data: { job } = {}, isLoading,
  } = useGetJobByIdQuery({ params: { id: id || '' } }, { skip: !id })

  const session = useSession()

  const canEditJob = useMemo(() => job?.company.accountOwnerUserId === session.user?.id, [
    job?.company.accountOwnerUserId,
    session.user?.id,
  ])

  if (isLoading) {
    return (
      <DocumentWrapper title='Real Work | Loading...'>
        <ScreenWrapper>
          <LoadingIndicator />
        </ScreenWrapper>
      </DocumentWrapper>
    )
  }

  if (!canEditJob) {
    return otherwise || <Error403 />
  }

  return children || <Outlet />
}

export default React.memo(CanEditJob)
