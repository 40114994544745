import React, { useCallback } from 'react'

import {
  api,
  lang,
} from '@real-work/common'
import {
  Box,
  Container,
  Divider,
  Form,
  FormControl,
  Heading,
  Input,
  SubmitButton,
  Text,
  useToast,
} from '@real-work/ui'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import { usePostPasswordResetMutation } from '@/services/passwordReset'

import {
  FormValues,
  OnSubmit,
} from './types'

function ForgotPassword(): React.ReactElement {
  const toast = useToast()
  const [
    postPasswordReset,
    { isLoading },
  ] = usePostPasswordResetMutation()

  const onSubmit = useCallback<OnSubmit>(async values => {
    postPasswordReset({ body: values })
      .unwrap()
      .then(response => {
        toast.show({
          description: response.message,
          title: lang().messages.genericSuccessHeading(),
          type: 'success',
        })
      })
      .catch(() => {
        toast.show({
          description: lang().messages.unableToResetPassword(),
          title: lang().messages.genericErrorHeading(),
          type: 'error',
        })
      })
  }, [
    postPasswordReset,
    toast,
  ])

  return (
    <DocumentWrapper title='Real Work | Forgot Password'>
      <ScreenWrapper>
        <Container>
          <Heading>Forgot Password</Heading>
          <Text>
            Please enter your email below.
          </Text>
        </Container>
        <Container mt='4'>
          <Heading size='md' color='brand.primary'>Personal Details</Heading>
          <Divider />
          <Form<FormValues>
            onSubmit={onSubmit}
            rules={api.endpoints.passwordReset.post.validation.body.rules}
          >
            <FormControl
              name='email'
              label='Email'
            >
              <Input
                type='email'
              />
            </FormControl>
            <Box flexDirection='row' mt='4' mb='6'>
              <SubmitButton isLoading={isLoading}>Send Reset Email</SubmitButton>
            </Box>
          </Form>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(ForgotPassword)
