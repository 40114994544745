import BarlowBold from './assets/Barlow-Bold.ttf'
import BarlowBoldItalic from './assets/Barlow-BoldItalic.ttf'
import BarlowItalic from './assets/Barlow-Italic.ttf'
import BarlowLight from './assets/Barlow-Light.ttf'
import BarlowLightItalic from './assets/Barlow-LightItalic.ttf'
import BarlowMedium from './assets/Barlow-Medium.ttf'
import BarlowMediumItalic from './assets/Barlow-MediumItalic.ttf'
import BarlowRegular from './assets/Barlow-Regular.ttf'

export default {
  BarlowBold,
  BarlowBoldItalic,
  BarlowItalic,
  BarlowLight,
  BarlowLightItalic,
  BarlowMedium,
  BarlowMediumItalic,
  BarlowRegular,
}
