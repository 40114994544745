import React, { useCallback } from 'react'
import { Checkbox as NbCheckbox } from 'native-base'

import useFormInput from '../../hooks/useFormInput'

import {
  OnChange,
  Props,
} from './types'

function Checkbox({
  children,
  defaultValue,
  name,
  onChange: onChangeProp,
  ...props
}: Props): React.ReactElement {
  const {
    setValue,
    value,
  } = useFormInput({
    dataType: 'boolean',
    defaultValue,
    name,
  })

  const onChange = useCallback<OnChange>(isSelected => {
    if (name) {
      setValue && setValue(isSelected)
    }

    onChangeProp && onChangeProp(isSelected)
  }, [
    name,
    onChangeProp,
    setValue,
  ])

  return (
    <NbCheckbox
      defaultIsChecked={Boolean(defaultValue)}
      isChecked={Boolean(value)}
      value={Boolean(value).toString()}
      _text={{ marginLeft: '0' }}
      {...props}
      onChange={onChange}
    >
      {children}
    </NbCheckbox>
  )
}

export default React.memo(Checkbox)

export * from './types'
