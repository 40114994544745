import React from 'react'

import Center from '../Center'
import AnimatorFadeIn from '../Animator/FadeIn'
import Box from '../Box'
import Text from '../Text'
import HStack from '../HStack'
import Heading from '../Heading'
import Image from '../Image'

import noDataImg from './assets/noData.svg'
import warningImg from './assets/warning.svg'

import { Props } from './types'

function NoContent({
  children,
  isWholeScreen = true,
  ...props
}: Props): React.ReactElement {
  const heading = 'Nothing to see here.'
  const message = children || 'You do not have any content to display.'

  if (isWholeScreen) {
    return (
      <Center
        {...props}
        px='4'
        py='6'
      >
        <AnimatorFadeIn>
          <Image
            source={noDataImg}
            width={219}
            height={175}
            alt={heading}
          />
        </AnimatorFadeIn>
        <Heading my='6'>{heading}</Heading>
        <Text maxWidth='620px' textAlign='center' fontSize='3xl'>{message}</Text>
      </Center>
    )
  }

  return (
    <HStack {...props} space='1' my='4'>
      <Image
        source={warningImg}
        width={25}
        height={25}
        alt={heading}
        mr='2'
        mt='0'
      />
      <Box flexShrink={1}>
        <Heading size='md'>{heading}</Heading>
        <Text textAlign={isWholeScreen ? 'center' : 'left'} fontSize='xl'>{message}</Text>
      </Box>
    </HStack>
  )
}

export default React.memo(NoContent)
