import React from 'react'

import { lang } from '@real-work/common'

import Center from '../../Center'
import AnimatorFadeIn from '../../Animator/FadeIn'
import Text from '../../Text'
import Heading from '../../Heading'
import Image from '../../Image'

import errorImage from './assets/error.svg'
import permissionDeniedImage from './assets/permissionDenied.svg'

import { Props } from './types'

function LoadingFailed({
  isPermissionDenied = false,
  children = isPermissionDenied ? 'You are now allowed to view this.' : 'Something went wrong. Try to refresh this page or feel free to contact us if the problem persists.',
  heading = isPermissionDenied ? 'Permission Denied' : lang().messages.genericErrorHeading(),
  ...props
}: Props): React.ReactElement {
  return (
    <Center
      {...props}
      px='4'
      py='6'
    >
      <AnimatorFadeIn>
        {(isPermissionDenied && (
          <Image
            source={permissionDeniedImage}
            width={219}
            height={175}
            alt={heading}
          />
        )) || (
          <Image
            source={errorImage}
            width={219}
            height={175}
            alt={heading}
          />
        )}
      </AnimatorFadeIn>
      <Heading my='6'>{heading}</Heading>
      <Text maxWidth='420px' textAlign='center'>{children}</Text>
    </Center>
  )
}

export default React.memo(LoadingFailed)
