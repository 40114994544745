import React from 'react'

import type { Props } from '@/types/svgReactComponent'

function IconExternal({ color }: Props) {
  return (
    <svg width='15' height='15' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M16.0923 0H16.0029H10.2812C9.74478 0 9.38717 0.357895 9.38717 0.894737C9.38717 1.43158 9.74478 1.78947 10.2812 1.78947H13.9467L7.86734 7.87368C7.50974 8.23158 7.50974 8.76842 7.86734 9.12632C8.22495 9.48421 8.76136 9.48421 9.11897 9.12632L15.1983 3.04211V6.71053C15.1983 7.24737 15.5559 7.60526 16.0923 7.60526C16.6287 7.60526 16.9863 7.24737 16.9863 6.71053V0.984211C17.0757 0.536842 16.7181 0 16.1817 0H16.0923ZM3.39726 1.34211C1.51983 1.34211 0 2.86316 0 4.74211V13.6895C0 15.4789 1.51983 17 3.39726 17H12.3374C14.2149 17 15.7347 15.4789 15.7347 13.6895V9.39474C15.7347 8.85789 15.3771 8.5 14.8407 8.5C14.3043 8.5 13.9467 8.85789 13.9467 9.39474V13.6895C13.9467 14.5842 13.2314 15.2105 12.3374 15.2105H3.39726C2.50325 15.2105 1.78803 14.4947 1.78803 13.6895V4.74211C1.78803 3.84737 2.50325 3.13158 3.39726 3.13158H7.68854C8.22495 3.13158 8.58256 2.77368 8.58256 2.23684C8.58256 1.7 8.22495 1.34211 7.68854 1.34211H3.39726Z' fill={color as string} />
      <desc>External Link</desc>
    </svg>
  )
}

export default IconExternal
