import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Input as NBInput } from 'native-base'
import { Loader as GoogleLoader } from '@googlemaps/js-api-loader'

import {
  config,
  geocode,
} from '@real-work/common'

import type { Props } from './types'

function AddressInput({
  autoCapitalize,
  autoComplete,
  autoCorrect,
  clearButtonMode,
  InputRightElement,
  keyboardType,
  setValue,
  textContentType,
  type,
  value,
  ...props
}: Props): React.ReactElement {
  const inputRef = useRef(undefined)

  const [
    displayValue,
    setDisplayValue,
  ] = useState(value.displayLocation || '')

  const onChangeText = useCallback((text: string) => {
    setValue({
      displayLocation: text,
      googlePlaceId: undefined,
      lonLat: undefined,
    })

    setDisplayValue(text)
  }, [ setValue ])

  const onChoosePlace = useCallback(
    (autocomplete: google.maps.places.Autocomplete) => {
      const place = autocomplete.getPlace()
      const displayLocation = geocode.getDisplayLocationFromPlace(place, type === 'zipCode' ? 'zipCode' : undefined)
      const lat = place.geometry?.location?.lat()
      const lon = place.geometry?.location?.lng()

      const lonLat = lat && lon ? {
        lat,
        lon,
      } : undefined

      const geocodePayload: Partial<geocode.Geocode> = {
        displayLocation,
        lonLat,
        googlePlaceId: place.place_id,
      }

      setValue(geocodePayload)

      setDisplayValue(displayLocation)
    },
    [
      setValue,
      type,
    ],
  )

  const initPlacesAutocomplete = useCallback(async (places: google.maps.PlacesLibrary) => {
    if (!inputRef.current) return

    const autocomplete = new places.Autocomplete(
      inputRef.current,
      {
        componentRestrictions: { country: 'us' },
        types: type === 'zipCode' ? [ 'postal_code' ] : [],
      },
    )

    autocomplete.setFields([
      'address_component',
      'geometry',
      'place_id',
    ])

    autocomplete.addListener('place_changed', () => onChoosePlace(autocomplete))

    const placesService = new places.PlacesService(inputRef.current)

    if (value.googlePlaceId) {
      placesService.getDetails({ placeId: value.googlePlaceId }, data => {
        const lat = data?.geometry?.location?.lat()
        const lon = data?.geometry?.location?.lng()
        const lonLat = lat && lon ? {
          lat,
          lon,
        } : undefined

        setValue({
          ...value,
          lonLat,
        })
      })
    }
    // Do not re-run this method when `value` changes:
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    onChoosePlace,
    setValue,
    type,
  ])

  useEffect(() => {
    const googleLoader = new GoogleLoader({
      apiKey: config.google.apiKey,
      libraries: [ 'places' ],
    })

    googleLoader
      .importLibrary('places')
      .then(initPlacesAutocomplete)
  }, [ initPlacesAutocomplete ])

  return (
    <NBInput
      {...props}
      ref={inputRef}
      onChangeText={onChangeText}
      autoCapitalize={autoCapitalize}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      clearButtonMode={clearButtonMode}
      keyboardType={keyboardType}
      textContentType={textContentType}
      type='text'
      value={displayValue}
      InputRightElement={InputRightElement}
    />
  )
}

export default AddressInput

export * from '../types'
