import realWorkApi from '@/state/apis/realWork'

import {
  DeleteSessionRequest,
  DeleteSessionResponse,
  GetSessionRequest,
  GetSessionResponse,
  PostSessionRequest,
  PostSessionResponse,
} from './types'

const path = 'v1/sessions'

const sessionService = realWorkApi.injectEndpoints({
  endpoints: build => ({
    deleteSession: build.mutation<DeleteSessionResponse, DeleteSessionRequest>({
      invalidatesTags: [ 'Sessions' ],
      query: ({ params }) => ({
        url: `${path}/${params.sessionId}`,
        method: 'DELETE',
      }),
    }),
    getSession: build.query<GetSessionResponse, GetSessionRequest>({
      providesTags: [ 'Sessions' ],
      query: ({ params }) => ({
        url: `${path}/${params.sessionId}`,
        method: 'GET',
      }),
    }),
    postSession: build.mutation<PostSessionResponse, PostSessionRequest>({
      invalidatesTags: [ 'Sessions' ],
      query: ({ body }) => ({
        url: path,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export default sessionService

export const {
  useDeleteSessionMutation,
  useGetSessionQuery,
  usePostSessionMutation,
} = sessionService

export const {
  endpoints: {
    deleteSession,
    getSession,
    postSession,
  },
} = sessionService
